import React from "react";

// If the user wants to see rounded values in the metrics table, ex: $1 instead
// of $1.11 or 11% instead of 11.11%
export const ShowFractionsContext = React.createContext<
  [boolean, (show: boolean) => void]
>([
  false,
  () => {
    return;
  }
]);

// If the primary metrics are loading and we should show a loading state.
export const PrimaryMetricsLoadingContext = React.createContext<boolean>(false);

// If the compare metrics are loading and we should show a loading state.
export const CompareMetricsLoadingContext = React.createContext<boolean>(false);
