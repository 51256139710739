import React from "react";
import { Button, ButtonProps, Popup } from "semantic-ui-react";

import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import {
  IconInButtonWithoutText,
  IconInButtonWithText
} from "ExtensionV2/components/ObjectFilterButton";
import BudgetPlanningChart from "ExtensionV2/components/BudgetPlanningChart";
import { CampaignConfigurationsByCampaignIdResult } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { Retailer } from "Common/proto/common/retailer_pb";

const BudgetPlanningChartButton: React.FC<{
  filteredCampaignIds: Array<string>;
  campaignConfigurationsByCampaignId: CampaignConfigurationsByCampaignIdResult;
  title: string;
  campaignPlatform: CampaignPlatform.Option;
  retailer: Retailer.Option;
  campaignNameFilter: string;
  setCampaignNameFilter: (filter: string) => void;
  showPlanningChart: boolean;
  setShowPlanningChart: React.Dispatch<React.SetStateAction<boolean>>;
  compact?: boolean;
}> = ({
  filteredCampaignIds,
  campaignConfigurationsByCampaignId,
  title,
  campaignPlatform,
  retailer,
  campaignNameFilter,
  setCampaignNameFilter,
  showPlanningChart,
  setShowPlanningChart,
  compact
}) => {
  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    _data: ButtonProps
  ) => {
    e.stopPropagation();

    if (showPlanningChart) {
      setShowPlanningChart(false);
    } else {
      setShowPlanningChart(true);
    }
  };

  return (
    <Popup
      trigger={
        <div>
          <SimpleTooltip tooltip="Budget Planning">
            <Button
              className={compact || !title ? "icon" : undefined}
              size={compact ? "mini" : "medium"}
              color={showPlanningChart ? "black" : undefined}
              onClick={handleButtonClick}
            >
              {compact || !title ? (
                <>
                  <IconInButtonWithoutText name="calculator" />
                </>
              ) : (
                <>
                  {title}
                  <IconInButtonWithText name="calculator" />
                </>
              )}
            </Button>
          </SimpleTooltip>
        </div>
      }
      position="bottom right"
      wide="very"
      on="click"
      open={showPlanningChart}
    >
      <div style={{ width: "60vw" }} onClick={e => e.stopPropagation()}>
        <BudgetPlanningChart
          campaignPlatform={campaignPlatform}
          retailer={retailer}
          campaignNameFilter={campaignNameFilter}
          setCampaignNameFilter={setCampaignNameFilter}
          filteredCampaignIds={filteredCampaignIds}
          campaignConfigurationsByCampaignId={
            campaignConfigurationsByCampaignId
          }
        />
      </div>
    </Popup>
  );
};

export default BudgetPlanningChartButton;
