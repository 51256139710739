import { useCallback, useRef } from "react";
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query";

import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";
import {
  GetSiteDashboardTableMetricsReply,
  GetSiteDashboardTableMetricsRequest,
  DashboardTable as DashboardTableProto,
  DashboardTableMetrics as DashboardTableMetricsProto
} from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";
import { buildDateRangeV2Proto } from "Common/utils/DateUtilities";
import { Retailer } from "Common/proto/common/retailer_pb";
import { CacheBehavior } from "Common/proto/common/cache_pb";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { None } from "Common/utils/tsUtils";

export const DashboardTableObjectType = DashboardTableProto.ObjectType.Option;
export type DashboardTableObjectType = DashboardTableProto.ObjectType.Option;
export type DashboardTableMetrics = DashboardTableMetricsProto.AsObject;

export type DashboardTableMetricsByCampaignIdByObjectType = {
  [objectType in DashboardTableObjectType]?: {
    [campaignId: string]: Array<DashboardTableMetrics>;
  };
};

// Hook to return an object whose keys are the various object types that have
// been requested, and whose values are object that map campaign IDs (as strings)
// to DashboardTableMetrics objects.
export const useDashboardTableMetrics = ({
  objectTypes,
  siteAlias,
  retailer,
  campaignPlatform = CampaignPlatform.Option.GOOGLE_ADS,
  campaignIds,
  dateRangeStartDate,
  dateRangeEndDate,
  queryByDay,
  respectAdAttributionDate
}: {
  objectTypes: Array<DashboardTableObjectType>;
  siteAlias: string;
  retailer: Retailer.Option;
  campaignPlatform?: CampaignPlatform.Option;
  campaignIds: Array<string>;
  dateRangeStartDate: string;
  dateRangeEndDate: string;
  queryByDay?: boolean;
  respectAdAttributionDate?: boolean;
}): UseQueryResult<DashboardTableMetricsByCampaignIdByObjectType, unknown> & {
  refreshServerCache: () => Promise<void>;
} => {
  const queryClient = useQueryClient();
  const cacheBehavior = useRef<CacheBehavior.Option>(
    CacheBehavior.Option.CACHE_FIRST
  );

  // This callback will refetch the metrics, but will pass the REFRESH_CACHE flag so that
  // server will not use the metric values cached by MDS.  This flag is passed as a React ref
  // instead of a prop because we only want it temporarily.
  // This approach is based on the code sample offered in the final comment of
  // https://stackoverflow.com/a/75737156:
  // https://codesandbox.io/p/sandbox/wild-wave-k93512?file=%2Fsrc%2FApp.tsx
  const refreshServerCache = useCallback(async () => {
    if (cacheBehavior) {
      cacheBehavior.current = CacheBehavior.Option.REFRESH_CACHE;
    }
    // Invalidate all dashboard table metric queries for the site.
    await queryClient.invalidateQueries(["dashboardTableMetrics", siteAlias]);
    if (cacheBehavior) {
      cacheBehavior.current = CacheBehavior.Option.CACHE_FIRST;
    }
    return;
  }, [queryClient, siteAlias]);

  const queryResult = useQuery<
    DashboardTableMetricsByCampaignIdByObjectType,
    unknown
  >(
    getDashboardTableMetricsQueryArgs({
      queryName: "dashboardTableMetrics",
      siteAlias,
      campaignPlatform,
      retailer,
      objectTypes,
      dateRangeStartDate,
      dateRangeEndDate,
      campaignIds,
      queryByDay,
      cacheBehavior,
      respectAdAttributionDate: respectAdAttributionDate ?? true
    })
  );

  return { ...queryResult, refreshServerCache };
};

export function getDashboardTableMetricsQueryArgs({
  queryName,
  siteAlias,
  campaignPlatform,
  retailer,
  objectTypes,
  dateRangeStartDate,
  dateRangeEndDate,
  campaignIds,
  queryByDay,
  cacheBehavior,
  respectAdAttributionDate = true,
  staleTime = 0,
  cacheTime = 0
}: {
  queryName: string;
  siteAlias: string;
  campaignPlatform: CampaignPlatform.Option;
  retailer: Retailer.Option;
  objectTypes: Array<DashboardTableObjectType>;
  dateRangeStartDate: string | None;
  dateRangeEndDate: string | None;
  campaignIds: Array<string>;
  queryByDay?: boolean;
  cacheBehavior?: React.MutableRefObject<CacheBehavior.Option>;
  respectAdAttributionDate?: boolean;
  staleTime?: number;
  cacheTime?: number;
}): UseQueryOptions<DashboardTableMetricsByCampaignIdByObjectType, unknown> {
  const queryKey = [
    queryName,
    siteAlias,
    campaignPlatform,
    retailer,
    objectTypes,
    dateRangeStartDate,
    dateRangeEndDate,
    campaignIds,
    queryByDay,
    respectAdAttributionDate
  ];

  return {
    queryKey,
    staleTime: staleTime || 10 * 60 * 1_000, // 10 minutes
    cacheTime: cacheTime || 11 * 60 * 1_000, // 11 minutes
    enabled: !!siteAlias && !!dateRangeStartDate && !!dateRangeEndDate,
    queryFn: () =>
      fetchDashboardTableMetrics({
        siteAlias,
        campaignPlatform,
        retailer,
        objectTypes,
        dateRangeStartDate,
        dateRangeEndDate,
        campaignIds,
        queryByDay,
        respectAdAttributionDate,
        cacheBehavior: cacheBehavior?.current
      })
  };
}

export const fetchDashboardTableMetrics = async ({
  siteAlias,
  campaignPlatform,
  retailer,
  objectTypes,
  dateRangeStartDate,
  dateRangeEndDate,
  campaignIds,
  queryByDay,
  respectAdAttributionDate = true,
  cacheBehavior
}: {
  siteAlias: string;
  campaignPlatform: CampaignPlatform.Option;
  retailer: Retailer.Option;
  objectTypes: Array<DashboardTableObjectType>;
  dateRangeStartDate: string | None;
  dateRangeEndDate: string | None;
  campaignIds: Array<string>;
  respectAdAttributionDate?: boolean;
  queryByDay?: boolean;
  cacheBehavior?: CacheBehavior.Option;
}): Promise<DashboardTableMetricsByCampaignIdByObjectType> => {
  const dashboardTableMetricsByCampaignIdByObjectType: DashboardTableMetricsByCampaignIdByObjectType = {};
  objectTypes.forEach(
    objectType =>
      (dashboardTableMetricsByCampaignIdByObjectType[objectType] = {})
  );

  if (!campaignIds?.length && !objectTypes?.length) {
    return dashboardTableMetricsByCampaignIdByObjectType;
  }

  const { dateRangeProto } = buildDateRangeV2Proto(
    dateRangeStartDate,
    dateRangeEndDate
  );

  const req = new GetSiteDashboardTableMetricsRequest();
  req.setSiteAlias(siteAlias);
  req.setCampaignPlatform(campaignPlatform);
  req.setRetailer(retailer);
  req.setDateRange(dateRangeProto);
  req.setObjectTypesList([...objectTypes]);
  req.setRespectAdAttributionDate(respectAdAttributionDate);

  const campaignsFilter = new DashboardTableProto.CampaignsFilter();
  campaignsFilter.setCampaignIdsList(campaignIds);

  const objectFilter = new DashboardTableProto.ObjectFilter();
  objectFilter.setCampaignsFilter(campaignsFilter);

  req.setFiltersList([objectFilter]);

  if (queryByDay) {
    req.setQueryByDay(true);
  }

  if (cacheBehavior) {
    req.setCacheBehavior(cacheBehavior);
  }

  await streamProcessor(
    GRPCWebCallbackClient.getSiteDashboardTableMetrics(req),
    (reply: GetSiteDashboardTableMetricsReply) => {
      reply.getDashboardTableMetricsList().forEach(dashboardTableMetrics => {
        const objectType = dashboardTableMetrics.getObjectType();
        const campaignId = dashboardTableMetrics.getCampaignId();
        const dashboardTableMetricsByCampaignId =
          dashboardTableMetricsByCampaignIdByObjectType[objectType] || {};

        const dashboardTableMetricsList =
          dashboardTableMetricsByCampaignId[campaignId] || [];

        dashboardTableMetricsList.push(dashboardTableMetrics.toObject());

        dashboardTableMetricsByCampaignId[
          campaignId
        ] = dashboardTableMetricsList;
      });
    }
  );

  return dashboardTableMetricsByCampaignIdByObjectType;
};
