import React, { useCallback, useState } from "react";
import { Checkbox, Icon, Modal, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { AmpdContentAreaModal } from "../layout/AmpdContentAreaModal";
import { Column } from "./column";
import { MetricsTableData } from "./MetricsTable";
import { backgroundPrimary, semanticBorder } from "../../styles/colors";

export const ColumnsManagerModal = <T extends MetricsTableData>({
  open,
  setOpen,
  columns,
  onUpdateColumns
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  columns: Map<Column<T>, boolean>;
  onUpdateColumns: (columns: Map<Column<T>, boolean> | null) => void;
}): JSX.Element => {
  return (
    <AmpdContentAreaModal open={open} onClose={() => setOpen(false)}>
      <ColumnsManager columns={columns} onUpdateColumns={onUpdateColumns} />
    </AmpdContentAreaModal>
  );
};

const ColumnsManager = <T extends MetricsTableData>({
  columns,
  onUpdateColumns
}: {
  columns: Map<Column<T>, boolean>;
  onUpdateColumns: (columns: Map<Column<T>, boolean> | null) => void;
}): JSX.Element => {
  /* 
    Start Draggable stuff 
  */
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);

  const handleDragStart = useCallback(
    (ev: React.DragEvent<HTMLDivElement>, index: number) => {
      setDraggingIndex(index);

      if (ev.dataTransfer) {
        // Set some arbitrary data otherwise some browsers might not recognize
        // a successful drag operation
        ev.dataTransfer.setData("text/plain", String(index));
        // Set the correct cursor effect
        ev.dataTransfer.effectAllowed = "move";
      }
    },
    []
  );

  // This is fired continuously while dragging over valid drop targets
  const handleDragOver = useCallback(
    (ev: React.DragEvent<HTMLDivElement>, index: number) => {
      ev.preventDefault();
      setDragOverIndex(index);
    },
    []
  );

  // Fired when dropped on a valid drop target
  const handleDrop = useCallback(
    (ev: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
      ev.preventDefault();
      const fromIndex = draggingIndex;
      if (fromIndex == null) {
        return;
      }

      // Reorder the list
      const reordered = reorderColumnsMap(columns, fromIndex, dropIndex);
      onUpdateColumns(reordered);

      // Reset indices
      setDraggingIndex(null);
      setDragOverIndex(null);
    },
    [draggingIndex, columns, onUpdateColumns]
  );

  // Reset the dragging state regardless if the drop was successful or not
  const handleDragEnd = useCallback(() => {
    setDraggingIndex(null);
    setDragOverIndex(null);
  }, []);
  /* 
    End draggable stuff 
  */

  const toggleColumnSelected = (column: Column<T>) => {
    const nextColumns = new Map(columns);
    nextColumns.set(column, !nextColumns.get(column));
    onUpdateColumns(nextColumns);
  };

  const toggleAllColumns = (on: boolean) => {
    const nextColumns = new Map(columns);
    const cols = [...columns.keys()];
    // Skipping the first column, which is the always visible Name column.
    for (let i = 1; i < columns.size; i += 1) {
      nextColumns.set(cols[i], on);
    }
    onUpdateColumns(nextColumns);
  };

  const handleResetColumns = () => {
    onUpdateColumns(null);
  };

  const allColumnsSelected = [...columns.values()].every(Boolean);

  return (
    <Modal.Content>
      <ColumnManagerHeader>
        <h4>Select Columns - Drag To Reorder</h4>
        <div>
          <Checkbox
            aria-label="select all"
            defaultChecked={allColumnsSelected}
            type="checkbox"
            onChange={(_ev, { checked }) => toggleAllColumns(checked ?? false)}
            label="All"
          />
          |
          <button
            aria-label="restore default columns"
            onClick={handleResetColumns}
          >
            Restore Default Columns
          </button>
        </div>
      </ColumnManagerHeader>
      <ColumnsList className="columns-list">
        {[...columns.entries()].map(([column, isSelected], index) => {
          const isDragging = index === draggingIndex;
          const isDragOver = index === dragOverIndex;

          // The first column is a special case, it's not draggable or selectable.
          if (index === 0) {
            return (
              <React.Fragment key={String(column.dataName)}></React.Fragment>
            );
          }

          return (
            <DraggableColumnListing
              aria-label={`drag ${column.displayName}`}
              key={String(column.dataName)}
              draggable
              onDragStart={e => handleDragStart(e, index)}
              onDragOver={e => handleDragOver(e, index)}
              onDrop={e => handleDrop(e, index)}
              onDragLeave={() => setDragOverIndex(null)}
              onDragEnd={handleDragEnd}
              isDragging={isDragging}
              isDragOver={isDragOver}
            >
              <Icon name="ellipsis vertical" className="drag-handle" />
              <Checkbox
                aria-label={`select ${column.displayName}`}
                type="checkbox"
                checked={isSelected}
                onChange={() => toggleColumnSelected(column)}
                label={column.displayName}
              />
            </DraggableColumnListing>
          );
        })}
      </ColumnsList>
    </Modal.Content>
  );
};

// reorder the columns map
const reorderColumnsMap = <T extends MetricsTableData>(
  columns: Map<Column<T>, boolean>,
  startIndex: number,
  endIndex: number
): Map<Column<T>, boolean> => {
  const nextColumnOrder = Array.from(columns.keys());
  const [removed] = nextColumnOrder.splice(startIndex, 1);
  nextColumnOrder.splice(endIndex, 0, removed);

  return new Map(nextColumnOrder.map(col => [col, columns.get(col) ?? false]));
};

const ColumnManagerHeader = styled.div`
  h4 {
    margin: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    > button {
      background: none;
      border: none;
      cursor: pointer;
      margin: 0;
      :hover {
        font-style: italic;
        text-decoration: underline;
      }
    }
  }
`;

// flex into two columns
const ColumnsList = styled(Segment)`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  flex-wrap: wrap;
  gap: 0.7rem;
  overflow-x: auto;
  width: 100%;
`;

const DraggableColumnListing = styled.div<{
  isDragging?: boolean;
  isDragOver?: boolean;
}>`
  gap: 0.5rem;
  cursor: move;

  > .drag-handle {
   cursor: move;
  }

  /* If the item is the one being dragged */
  ${({ isDragging }) =>
    isDragging &&
    `
      opacity: 0.2;
      border: 1px solid ${semanticBorder};
      background-color: white;
    `}

  /* If an item is currently hovered (dragged over), highlight it */
  ${({ isDragOver }) =>
    isDragOver &&
    `;
      outline: 2px dashed ${backgroundPrimary};
    `}
  
  &:last-child {
    margin-bottom: 0;
  }
`;
