import React, { useContext, useState } from "react";
import { toCapitalCase } from "Common/utils/strings";
import { ViewLevelContext } from "ExtensionV2/pages/FacebookCampaignsPage/FacebookPageV2";
import { toast } from "react-toastify";
import { Button, Checkbox, Icon, Popup } from "semantic-ui-react";
import { Column } from "./column";
import { PrimaryMetricsLoadingContext } from "./contexts";
import { MetricsTableData } from "./MetricsTable";

export type CSVExportOptions = {
  dailyData: boolean;
  allColumns: boolean;
};

export type CSVGeneratorFn<T extends MetricsTableData> = (
  columns: Array<Column<T>>,
  exportOptions: CSVExportOptions
) => Promise<[string, string]>; // csvName, csvContent

export function CSVExportManager<T extends MetricsTableData>({
  columns,
  csvGenerator
}: {
  columns: Array<Column<T>>;
  csvGenerator: CSVGeneratorFn<T>;
}): JSX.Element {
  const primaryMetricsLoading = useContext(PrimaryMetricsLoadingContext);
  const viewLevel = useContext(ViewLevelContext);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [dailyData, setDailyData] = useState(false);
  const [exportAllColumns, setExportAllColumns] = useState(true);
  const [activeDownload, setActiveDownload] = useState(false);

  const awaitingData = activeDownload || (primaryMetricsLoading && !dailyData);

  return (
    <Popup
      open={popoverOpen}
      onOpen={() => setPopoverOpen(true)}
      onClose={() => setPopoverOpen(false)}
      on="click"
      trigger={
        <Button icon="download" onClick={() => setPopoverOpen(!popoverOpen)} />
      }
      position="bottom right"
    >
      <div>
        <h3>{viewLevel ? toCapitalCase(viewLevel) : ""} CSV Export</h3>

        <h4>Data Type</h4>
        <Checkbox
          radio
          label="Aggregated data"
          checked={!dailyData}
          onChange={() => setDailyData(false)}
        />

        <Checkbox
          radio
          label="Daily data"
          checked={dailyData}
          onChange={() => setDailyData(true)}
        />

        <h4>Columns</h4>
        <Checkbox
          radio
          label="Export all columns"
          checked={exportAllColumns}
          onChange={() => setExportAllColumns(true)}
        />

        <Checkbox
          radio
          label="Export selected columns"
          checked={!exportAllColumns}
          onChange={() => setExportAllColumns(false)}
        />
        <p
          style={{
            fontSize: "0.8rem",
            fontStyle: "italic",
            marginTop: "1rem"
          }}
        >
          Editable attributes like Status or Budget will show their current
          value, not their historical value.
        </p>

        <Button
          disabled={awaitingData}
          loading={awaitingData}
          fluid
          size="small"
          color="green"
          onClick={async () => {
            toast.info("Exporting data", {
              position: "top-right",
              isLoading: true,
              toastId: "metrics-csv-export"
            });
            setActiveDownload(true);

            const [name, data] = await csvGenerator(columns, {
              dailyData,
              allColumns: exportAllColumns
            });

            if (!data) {
              toast.dismiss("metrics-csv-export");
              toast.error("Export failed", {
                position: "top-right"
              });
              setActiveDownload(false);
              return;
            }

            toast.dismiss("metrics-csv-export");
            toast.success("Export complete", {
              position: "top-right",
              autoClose: 5000
            });
            setActiveDownload(false);
            setPopoverOpen(false);

            downloadCSV(name, data);
          }}
        >
          Download
          <Icon style={{ marginLeft: "0.5rem" }} name="arrow circle down" />
        </Button>
      </div>
    </Popup>
  );
}

function downloadCSV(csvName: string, csvContent: string) {
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = csvName;
  a.click();
  URL.revokeObjectURL(url);
}
