import { useMemo } from "react";

import { Retailer } from "Common/proto/common/retailer_pb";
import { GetCampaignConfigurationsReply } from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { None } from "Common/utils/tsUtils";
import { useFacebookCampaignConfigurations } from "ExtensionV2/queries/useFacebookCampaignConfigurations";
import { FacebookConfiguration } from "Common/proto/ampdPb/facebookConfiguration_pb";
import { CONFIGURED_STATUS } from "./configuredStatus";
import { stringForEnum } from "Common/utils/proto";
import { extractTargetHREFFromAdConfiguration } from "Common/utils/facebook";
import { momentFromTimestampProtoJSON } from "Common/utils/DateUtilities";

// Fetched from our entity database.
export type FacebookTableRowAttributes = {
  rowID: string;
  accountID: string;
  adID: string;
  adName: string;
  adSetID: string;
  adSetName: string;
  campaignID: string;
  campaignName: string;
  name: string;
  retailer: Retailer.Option;
  status: CONFIGURED_STATUS;
  effectiveStatus: string;
  dailyBudgetCents: number;
  lifetimeBudgetCents: number;
  bidAmountCents: number;
  adTarget: string;
  attributionStartDate: number;
};

export const useFacebookTableRowAttributes = (
  siteAlias: string
): {
  data: Array<FacebookTableRowAttributes> | undefined;
  isLoading: boolean;
  isFetchingUpToDateConfigs: boolean;
  error: unknown;
} => {
  const configs = useFacebookCampaignConfigurations({ siteAlias: siteAlias });

  const metaDataRow = useMemo(
    () => mapCampaignConfigToAttributeRows(configs.data),
    [configs.data]
  );

  return {
    ...configs,
    data: metaDataRow
  };
};

export function mapCampaignConfigToAttributeRows(
  configs:
    | Array<GetCampaignConfigurationsReply.CampaignConfiguration.AsObject>
    | None
): Array<FacebookTableRowAttributes> {
  if (!configs || !configs.length) {
    return [];
  }

  const rows: Array<FacebookTableRowAttributes> = [];
  for (const config of configs) {
    const fbConfig = config.ampdResourceConfiguration?.facebook;
    const fbAccountID = fbConfig?.accountId;
    const campaign = fbConfig?.campaignDetails;
    if (!fbAccountID || !campaign) {
      continue;
    }

    let earliestCampaignTime = Infinity;
    const campaignRow: FacebookTableRowAttributes = {
      rowID: campaign.campaignId,
      accountID: fbAccountID,
      adID: "",
      adName: "",
      adSetID: "",
      adSetName: "",
      campaignID: campaign.campaignId,
      campaignName: campaign.name,
      name: campaign.name,
      retailer: Retailer.Option.AMAZON,
      status: campaignStatusToConfiguredStatus(campaign.status),
      effectiveStatus: stringForEnum(
        FacebookConfiguration.CampaignDetails.Status.Option,
        campaign.effectiveStatus
      ),
      dailyBudgetCents: campaign.dailyBudget,
      lifetimeBudgetCents: campaign.lifetimeBudget,
      bidAmountCents: 0,
      adTarget: "",
      attributionStartDate: 0
    };

    for (const adset of campaign.adSetDetailsList || []) {
      let earliestAdsetTime = Infinity;
      const adsetRow: FacebookTableRowAttributes = {
        rowID: `${campaign.campaignId}-${adset.adSetId}`,
        accountID: fbAccountID,
        adID: "",
        adName: "",
        adSetID: adset.adSetId,
        adSetName: adset.name,
        campaignID: campaign.campaignId,
        campaignName: campaign.name,
        name: adset.name,
        retailer: Retailer.Option.AMAZON,
        status: adSetStatusToConfiguredStatus(adset.status),
        effectiveStatus: stringForEnum(
          FacebookConfiguration.AdSetDetails.Status.Option,
          adset.effectiveStatus
        ),
        dailyBudgetCents: adset.dailyBudget,
        lifetimeBudgetCents: adset.lifetimeBudget,
        bidAmountCents: adset.bidAmount,
        adTarget: "",
        attributionStartDate: 0
      };

      for (const ad of adset.adDetailsList || []) {
        const configuredTimeMoment = momentFromTimestampProtoJSON(
          ad.configuredForAmpdTime
        );
        const configuredTimeTimestamp = configuredTimeMoment
          ? configuredTimeMoment.valueOf()
          : 0;

        if (configuredTimeTimestamp < earliestAdsetTime) {
          earliestAdsetTime = configuredTimeTimestamp;
        }

        if (configuredTimeTimestamp < earliestCampaignTime) {
          earliestCampaignTime = configuredTimeTimestamp;
        }

        const adRow = {
          rowID: `${campaign.campaignId}-${adset.adSetId}-${ad.adId}`,
          accountID: fbAccountID,
          adID: ad.adId,
          adName: ad.name,
          adSetID: adset.adSetId,
          adSetName: adset.name,
          campaignID: campaign.campaignId,
          campaignName: campaign.name,
          name: ad.name,
          retailer: Retailer.Option.AMAZON,
          status: adStatusToConfiguredStatus(ad.status),
          effectiveStatus: stringForEnum(
            FacebookConfiguration.AdDetails.Status.Option,
            ad.effectiveStatus
          ),
          // these amounts are set by the ad's adset
          dailyBudgetCents: adset.dailyBudget,
          lifetimeBudgetCents: adset.lifetimeBudget,
          bidAmountCents: adset.bidAmount,
          adTarget: extractTargetHREFFromAdConfiguration(ad),
          attributionStartDate: configuredTimeTimestamp
        };

        rows.push(adRow);
      }

      adsetRow.attributionStartDate = earliestAdsetTime;
      rows.push(adsetRow);
    }

    campaignRow.attributionStartDate = earliestCampaignTime;
    rows.push(campaignRow);
  }

  return rows;
}

function campaignStatusToConfiguredStatus(
  status: FacebookConfiguration.CampaignDetails.Status.Option
): CONFIGURED_STATUS {
  switch (status) {
    case FacebookConfiguration.CampaignDetails.Status.Option.ACTIVE:
      return CONFIGURED_STATUS.ACTIVE;
    case FacebookConfiguration.CampaignDetails.Status.Option.PAUSED:
      return CONFIGURED_STATUS.PAUSED;
    case FacebookConfiguration.CampaignDetails.Status.Option.DELETED:
      return CONFIGURED_STATUS.DELETED;
    case FacebookConfiguration.CampaignDetails.Status.Option.ARCHIVED:
      return CONFIGURED_STATUS.ARCHIVED;
    default:
      return CONFIGURED_STATUS.UNKNOWN;
  }
}

function adSetStatusToConfiguredStatus(
  status: FacebookConfiguration.AdSetDetails.Status.Option
): CONFIGURED_STATUS {
  switch (status) {
    case FacebookConfiguration.AdSetDetails.Status.Option.ACTIVE:
      return CONFIGURED_STATUS.ACTIVE;
    case FacebookConfiguration.AdSetDetails.Status.Option.PAUSED:
      return CONFIGURED_STATUS.PAUSED;
    case FacebookConfiguration.AdSetDetails.Status.Option.DELETED:
      return CONFIGURED_STATUS.DELETED;
    case FacebookConfiguration.AdSetDetails.Status.Option.ARCHIVED:
      return CONFIGURED_STATUS.ARCHIVED;
    default:
      return CONFIGURED_STATUS.UNKNOWN;
  }
}

function adStatusToConfiguredStatus(
  status: FacebookConfiguration.AdDetails.Status.Option
): CONFIGURED_STATUS {
  switch (status) {
    case FacebookConfiguration.AdDetails.Status.Option.ACTIVE:
      return CONFIGURED_STATUS.ACTIVE;
    case FacebookConfiguration.AdDetails.Status.Option.PAUSED:
      return CONFIGURED_STATUS.PAUSED;
    case FacebookConfiguration.AdDetails.Status.Option.DELETED:
      return CONFIGURED_STATUS.DELETED;
    case FacebookConfiguration.AdDetails.Status.Option.ARCHIVED:
      return CONFIGURED_STATUS.ARCHIVED;
    default:
      return CONFIGURED_STATUS.UNKNOWN;
  }
}
