import Immutable from "immutable";

import {
  AACOS_RANGE_MAX,
  AACOS_RANGE_MIN,
  ACCOUNTS_METRIC_COLUMNS,
  CAMPAIGNS_COL,
  CampaignStatusFilter,
  DAILY_BUDGET_COL
} from "ExtensionV2/pages/AccountsPage/AccountsTable";
import {
  calculateDerivedMetrics,
  COLUMN_DATA_KEYS,
  convertMetricColumnToUSD,
  DataObject,
  ENABLED_STATUS,
  MetricColumnKey,
  MetricColumnsMetrics,
  PAUSED_STATUS,
  STATUS_ENABLED_STR,
  STATUS_PAUSED_STR
} from "ExtensionV2/components/MetricColumns";
import { convertValueToUSD } from "Common/utils/googleAds";
import { defaultSortComparator } from "../../components/AmpdDataTable";
import { ClientSite } from "ExtensionV2/grpc/getClientSitesForManagerSite";
import { None, PartialWithNone } from "Common/utils/tsUtils";
import { ItemizedCampaignConfiguration } from "ExtensionV2/queries/useItemizedCampaignConfiguration";
import { DashboardTableMetrics } from "ExtensionV2/queries/useDashboardTableMetrics";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { Retailer } from "Common/proto/common/retailer_pb";

export const NO_CAMPAIGNS_FOR_ROW = "0";

type WithCompareMetrics = {
  compareMetricsLoading?: boolean;
  compareMetrics?: MetricColumnsMetrics;
};

type CampaignRowData = DataObject &
  WithCompareMetrics & {
    campaignId?: string;
    facebookAdSetAndAdIds?: Array<[string, string]>;
  };
type CampaignMetrics = {
  [K in typeof ACCOUNTS_METRIC_COLUMNS[number]]?: number | None;
} & {
  [AACOS_RANGE_MIN]?: number;
  [AACOS_RANGE_MAX]?: number;
} & PartialWithNone<
    // Strip off all non-metric fields and make all the metric
    // fields optional (because SiteMetrics doesn't have all of them)
    Omit<
      DashboardTableMetrics,
      | "objectType"
      | "campaignPlatform"
      | "retailer"
      | "platformAccountId"
      | "adGroupId"
      | "criteriaId"
      | "unconvertedRevenueCurrencyCode"
      | "unconvertedRevenue"
      | "unconvertedBrandReferralBonus"
      | "queryText"
      | "campaignId"
      | "facebookAdSetId"
      | "facebookAdId"
      | "productAsin"
      | "parentAsin"
      | "brandName"
      | "brandMembership"
      | "productName"
      | "productGroup"
      | "productCategory"
      | "productSubcategory"
      | "unconvertedNewToBrandRevenue"
    >
  > &
  WithCompareMetrics;

export type ClientSiteRowData = DataObject & {
  campaignRowDataList: Array<CampaignRowData>;
  subClientRowDataList: Array<ClientSite>;
};

function getClientSiteRowData({
  clientSite,
  clientSiteAliases = [],
  subClientSitesByClientSiteMap,
  itemizedConfigurationsByCampaignIdMap,
  campaignCompareMetricsMap,
  campaignIdsBySiteMap,
  campaignMetricsMap,
  campaignStatusFilter,
  doCompare,
  compareMetricsLoading,
  aliasToCurrency
}: {
  clientSite?: ClientSite;
  clientSiteAliases: Array<string>;
  subClientSitesByClientSiteMap?: Immutable.Map<string, Array<ClientSite>>;
  itemizedConfigurationsByCampaignIdMap: Immutable.Map<
    string,
    ItemizedCampaignConfiguration
  >;
  campaignIdsBySiteMap: Immutable.Map<string, Array<string>>;
  campaignMetricsMap: Immutable.Map<string, DashboardTableMetrics>;
  campaignCompareMetricsMap: Immutable.Map<string, DashboardTableMetrics>;
  campaignStatusFilter: CampaignStatusFilter;
  doCompare: boolean;
  compareMetricsLoading: boolean;
  aliasToCurrency?: Record<string, string>;
}): ClientSiteRowData {
  let subClientRowDataList: Array<ClientSite> = [];
  if (clientSite && subClientSitesByClientSiteMap) {
    const subClientSites = subClientSitesByClientSiteMap.get(
      clientSite.clientSiteAlias
    );
    if (subClientSites && subClientSites.length > 0) {
      subClientRowDataList = subClientSites
        .map(subClientSite => ({
          ...subClientSite
        }))
        .sort((a, b) =>
          defaultSortComparator(a, b, COLUMN_DATA_KEYS.clientSiteName, true)
        );
    }
  }

  // Count the number of enabled and paused campaigns
  const campaignsDesc = getCampaignCountDescription(
    clientSiteAliases,
    itemizedConfigurationsByCampaignIdMap,
    campaignIdsBySiteMap
  );

  // Initialize site metrics.
  const siteMetrics = initializeSiteMetrics(clientSite?.currencyCode || "USD");
  let siteCompareMetrics = null;
  if (doCompare) {
    siteCompareMetrics = initializeSiteMetrics(
      clientSite?.currencyCode || "USD"
    );
  }

  const siteAacosValues: Array<number> = [];
  const siteAacosCompareValues: Array<number> = [];
  let siteBudget = 0;

  const campaignIds = clientSiteAliases.reduce(
    (ids: Array<string>, alias: string) =>
      ids.concat(campaignIdsBySiteMap.get(alias) || []),
    []
  );
  let budgetIds = Immutable.Set();
  const campaignRowDataList = [];
  for (const campaignId of campaignIds) {
    const itemizedConfiguration = itemizedConfigurationsByCampaignIdMap.get(
      campaignId
    );
    if (!itemizedConfiguration) {
      continue;
    }
    const {
      campaignName,
      campaignStatus,
      campaignBudget,
      campaignBudgetId,
      currencyCode,
      isAmpdCampaignOnGoogleAds,
      isAmpdCampaignOnFacebook,
      isAmpdCampaign,
      isAmpdCampaignForWalmart,
      facebookAdSetAndAdIds
    } = itemizedConfiguration;

    if (
      campaignStatusFilter === ENABLED_STATUS &&
      campaignStatus != "ENABLED"
    ) {
      continue;
    } else if (
      campaignStatusFilter === PAUSED_STATUS &&
      campaignStatus != "PAUSED"
    ) {
      continue;
    }

    const metrics:
      | (DashboardTableMetrics & {
          [COLUMN_DATA_KEYS.aacos]?: number | None;
        })
      | None = campaignMetricsMap.get(String(campaignId));
    const compareMetrics:
      | (DashboardTableMetrics & {
          [COLUMN_DATA_KEYS.aacos]?: number | None;
        })
      | None = campaignCompareMetricsMap.get(String(campaignId));

    if (metrics) {
      calculateDerivedMetrics(metrics, [
        ...ACCOUNTS_METRIC_COLUMNS,
        COLUMN_DATA_KEYS.aacos
      ]);
      const value =
        metrics[COLUMN_DATA_KEYS.aacos as keyof DashboardTableMetrics];
      if (value) {
        siteAacosValues.push(Number(value));
      }
    }
    if (compareMetrics) {
      calculateDerivedMetrics(compareMetrics, [
        ...ACCOUNTS_METRIC_COLUMNS,
        COLUMN_DATA_KEYS.aacos
      ]);
      const value = compareMetrics[COLUMN_DATA_KEYS.aacos];
      if (value) {
        siteAacosCompareValues.push(value);
      }
    }

    // Only add the budget for enabled campaigns to the site budget value, because
    // those are the only ones that are active.
    if (campaignStatus === "ENABLED") {
      // Add the budget amount if there is no specific budget ID or if
      // we haven't encountered the budget ID already.
      if (!campaignBudgetId || !budgetIds.has(campaignBudgetId)) {
        let addAmount = campaignBudget;
        if (aliasToCurrency) {
          addAmount = convertValueToUSD({
            value: addAmount,
            currency: currencyCode || "USD"
          });
        }

        siteBudget += addAmount;
      }

      budgetIds = budgetIds.add(campaignBudgetId);
    }

    let platform = CampaignPlatform.Option.UNKNOWN;
    if (isAmpdCampaignOnGoogleAds) {
      platform = CampaignPlatform.Option.GOOGLE_ADS;
    } else if (isAmpdCampaignOnFacebook) {
      platform = CampaignPlatform.Option.FACEBOOK;
    }

    let retailer = Retailer.Option.UNKNOWN;
    if (isAmpdCampaignForWalmart) {
      retailer = Retailer.Option.WALMART;
    } else if (isAmpdCampaign) {
      // If it is an Ampd campaign, assume it is for amazon if it
      // is not for walmart.
      retailer = Retailer.Option.AMAZON;
    }

    const campaignRowData: CampaignRowData = {
      ...metrics,
      campaignId: campaignId,
      facebookAdSetAndAdIds: facebookAdSetAndAdIds,
      campaignPlatform: platform,
      retailer: retailer,
      name: campaignName,
      status: campaignStatus,
      [DAILY_BUDGET_COL]: campaignBudget,
      compareMetricsLoading,
      compareMetrics
    };

    campaignRowDataList.push(campaignRowData);

    // Update site metrics.
    if (metrics) {
      updateSiteMetrics(siteMetrics, metrics, !!aliasToCurrency);
    }
    if (siteCompareMetrics && compareMetrics) {
      updateSiteMetrics(siteCompareMetrics, compareMetrics, !!aliasToCurrency);
    }
  }

  // Finalize site metrics.
  finalizeSiteMetrics(siteMetrics, siteAacosValues, siteBudget);
  if (siteCompareMetrics) {
    finalizeSiteMetrics(siteCompareMetrics, siteAacosCompareValues, siteBudget);

    siteMetrics.compareMetricsLoading = compareMetricsLoading;
    siteMetrics.compareMetrics = siteCompareMetrics;
  }

  return {
    ...clientSite,
    ...siteMetrics,
    [CAMPAIGNS_COL]: campaignsDesc,
    campaignRowDataList,
    subClientRowDataList
  };
}

// Returns a string of the form "<enabled count> / <total count>".
function getCampaignCountDescription(
  siteAliases: Array<string>,
  itemizedConfigurationsByCampaignIdMap: Immutable.Map<
    string,
    ItemizedCampaignConfiguration
  >,
  campaignIdsBySiteMap: Immutable.Map<string, Array<string>>
): string | undefined {
  let enabledCount = 0;
  let pausedCount = 0;

  for (const [
    campaignId,
    itemizedCampaignConfiguration
  ] of itemizedConfigurationsByCampaignIdMap.entries()) {
    if (!siteAliases.includes(itemizedCampaignConfiguration.siteAlias)) {
      continue;
    }

    const campaignIds = campaignIdsBySiteMap.get(
      itemizedCampaignConfiguration.siteAlias
    );
    if (!campaignIds?.includes(campaignId)) {
      continue;
    }

    const status = itemizedCampaignConfiguration.campaignStatus;

    if (status === STATUS_ENABLED_STR) {
      enabledCount += 1;
    } else if (status === STATUS_PAUSED_STR) {
      pausedCount += 1;
    }
  }

  if (enabledCount + pausedCount === 0) {
    return NO_CAMPAIGNS_FOR_ROW;
  }

  return `${enabledCount} / ${enabledCount + pausedCount}`;
}

// Initialize site metric values.
function initializeSiteMetrics(currencyCode: string): CampaignMetrics {
  const siteMetrics = (ACCOUNTS_METRIC_COLUMNS as Array<
    MetricColumnKey
  >).reduce<MetricColumnsMetrics>(
    (
      data: MetricColumnsMetrics,
      column: MetricColumnKey
    ): MetricColumnsMetrics => {
      data[column] = 0;
      return data;
    },
    {
      currencyCode: currencyCode,
      costCurrencyCode: currencyCode,
      revenueCurrencyCode: currencyCode
    } as MetricColumnsMetrics
  );

  return siteMetrics;
}

// Update site metric values with a campaign's metric values.
function updateSiteMetrics(
  siteMetrics: CampaignMetrics,
  metrics: CampaignMetrics,
  convertToUSD: boolean
) {
  if (!metrics) {
    return;
  }

  const costCurrencyCode = metrics.costCurrencyCode;
  const revenueCurrencyCode = metrics.revenueCurrencyCode;
  if (convertToUSD) {
    siteMetrics.costCurrencyCode = "USD";
    siteMetrics.revenueCurrencyCode = "USD";

    // Sum all site metric columns for now.  The metrics that are not themselves
    // summable but are derived from summable metrics will be calculated after
    // the loop.  Treat all null and NaNs as zeros.
    ACCOUNTS_METRIC_COLUMNS.forEach(column => {
      let siteValue = siteMetrics[column] || 0;
      siteValue +=
        convertMetricColumnToUSD(
          column,
          metrics[column],
          costCurrencyCode || "USD",
          revenueCurrencyCode || "USD"
        ) || 0;
      siteMetrics[column] = siteValue;
    });
  } else {
    siteMetrics.costCurrencyCode = costCurrencyCode;
    siteMetrics.revenueCurrencyCode = revenueCurrencyCode;

    // Sum all site metric columns for now.  The metrics that are not themselves
    // summable but are derived from summable metrics will be calculated after
    // the loop.  Treat all null and NaNs as zeros.
    ACCOUNTS_METRIC_COLUMNS.forEach(column => {
      let siteValue = siteMetrics[column] || 0;
      siteValue += metrics[column] || 0;
      siteMetrics[column] = siteValue;
    });
  }
}

// Finalize site metric values
function finalizeSiteMetrics(
  siteMetrics: CampaignMetrics,
  aacosValues: Array<number>,
  siteBudget: number
) {
  // Now calculate all derived site metric values.
  calculateDerivedMetrics(siteMetrics, ACCOUNTS_METRIC_COLUMNS);

  // For AACOS, we will show a range of values instead of an aggregation.
  aacosValues.sort();
  siteMetrics[AACOS_RANGE_MIN] = aacosValues[0];
  siteMetrics[AACOS_RANGE_MAX] = aacosValues[aacosValues.length - 1];
  siteMetrics[DAILY_BUDGET_COL] = siteBudget;
}

export default getClientSiteRowData;
