import _ from "lodash";

import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Icon,
  Image,
  List,
  Message,
  Modal
} from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import OneClickButton from "Common/components/OneClickButton";
import SimpleTooltip from "../SimpleTooltip";

import { sendGAEvent } from "../GA";

import { extractErrorMessage } from "Common/errors/error";
import { ItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { useWalmartProduct } from "../../queries/useWalmartProduct";
import { useWalmartSearchPage } from "../../queries/useWalmartSearchPage";
import { isWalmartMarketplaceInfo } from "Common/utils/marketplace";
import { LoadingSpinner } from "Common/components/LoadingSpinner";

import {
  WalmartBrandsDropdown,
  WalmartFacetsDropdown,
  WalmartTargetSelectorDropdown
} from "ExtensionV2/components/WalmartTargetSelector";
import {
  extractWalmartURLInfoFromString,
  getWalmartDetailPageURLForItemId,
  getWalmartMarketplace,
  getWalmartSearchPageURL,
  getWalmartURLWithRedirect,
  replaceWalmartSearchPhraseInURL,
  WalmartURLInfo
} from "Common/utils/walmart";
import { fullyScrubHtml } from "ExtensionV2/util";
import { sendUpdateAdTracking } from "ExtensionV2/components/campaignEditor/AmazonAttributionEditButton";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  UpdateCampaignsRequest,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { ProductDetails } from "Common/proto/common/productDetails_pb";

const WalmartFinalURLEditButton: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    customerId,
    isCampaignRemoved,
    domain,
    marketplaceInfo
  } = itemizedCampaignConfiguration;

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let editable = true;
  if (
    !isWalmartMarketplaceInfo(marketplaceInfo) ||
    !domain ||
    !customerId ||
    isCampaignRemoved
  ) {
    editable = false;
  }

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={
          editable
            ? () => {
                sendGAEvent(gaCategory, "Click Edit Target URL", siteAlias);
                setModalOpen(true);
              }
            : undefined
        }
      >
        {editable && (
          <SimpleTooltip tooltip="Edit Target URL">
            <Icon name="pencil" style={{ cursor: "pointer" }} />
          </SimpleTooltip>
        )}
      </Flex>
      {modalOpen && (
        <FinalURLModal
          siteAlias={siteAlias}
          gaCategory={gaCategory}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

type PartialWalmartProductDetails = {
  targetUrl: string;
  itemIds: Array<string>;
  itemBrand: string;
  urlSearchTerm: string;
  brandNameFacets: Array<string>;
  otherSearchFacets: Array<[string, string]>;
  otherSearchParameters: Array<[string, string]>;
};

const partialWalmartProductDetailsFields: Array<[
  keyof PartialWalmartProductDetails,
  string
]> = [
  ["targetUrl", "Target URL has changed."],
  ["itemIds", "Item IDs have changed."],
  ["itemBrand", "Item Brand has changed."],
  ["urlSearchTerm", "Search Phrase has changed."],
  ["brandNameFacets", "Search Brand Names have changed."],
  ["otherSearchFacets", "Search Filters have changed."],
  ["otherSearchParameters", "Other URL Parameters have changed."]
];

const FinalURLModal: React.FC<{
  siteAlias: string;
  gaCategory: string;
  itemizedCampaignConfiguration: ItemizedCampaignConfiguration;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  onClose: () => void;
}> = ({
  siteAlias,
  gaCategory,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  onClose
}) => {
  const {
    fullAmpdConfiguration,
    customerId,
    campaignId,
    adGroupId,
    adId,
    actionId,
    marketplaceInfo,
    walmartProfileName,
    walmartItemIds,
    walmartFollowOnSearchPhrase,
    searchTerms,
    finalURL,
    trackingURLTemplate
  } = itemizedCampaignConfiguration;

  const initialPartialProductDetails: PartialWalmartProductDetails = useMemo(() => {
    const walmartProductDetails =
      fullAmpdConfiguration?.ampdProductDetails?.walmart;
    return {
      targetUrl: walmartProductDetails?.targetUrl || "",
      itemIds: walmartProductDetails?.itemIdsList || [],
      itemBrand: walmartProductDetails?.itemBrand || "",
      urlSearchTerm: walmartProductDetails?.urlSearchTerm || "",
      brandNameFacets: walmartProductDetails?.brandNameFacetsList || [],
      otherSearchFacets:
        walmartProductDetails?.otherSearchFacetsList.map(param => [
          param.name,
          param.value
        ]) || [],
      otherSearchParameters:
        walmartProductDetails?.otherSearchParametersList.map(param => [
          param.name,
          param.value
        ]) || []
    };
  }, [fullAmpdConfiguration]);

  const [modalOpen, setModalOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);

  const [
    editWalmartURLInfo,
    setEditWalmartURLInfo
  ] = useState<WalmartURLInfo | null>(null);
  const [editTargetURL, setEditTargetURL] = useState("");
  const [editFollowOn, setEditFollowOn] = useState("");

  const [initialTargetURL, initialRedirectURL] = useMemo(() => {
    const urlInfo = extractWalmartURLInfoFromString(finalURL);
    setEditWalmartURLInfo(urlInfo);
    setEditTargetURL(urlInfo.effectiveURL || "");
    setEditFollowOn(urlInfo.followOnSearchPhrase || "");
    return [urlInfo.effectiveURL || "", urlInfo.redirectURL || ""];
  }, [finalURL]);

  // Look for brands by searching for the initialBrand or any string typed in by the user.
  const {
    data: brandSearchData,
    isFetching: brandSearchIsFetching
  } = useWalmartSearchPage(
    siteAlias,
    "",
    editWalmartURLInfo?.itemId ? "" : editWalmartURLInfo?.searchPhrase,
    getWalmartMarketplace(marketplaceInfo)
  );

  const {
    data: productPage,
    isFetching: productPageIsFetching
  } = useWalmartProduct(
    siteAlias,
    editWalmartURLInfo?.itemId || "",
    getWalmartMarketplace(marketplaceInfo)
  );

  useEffect(() => {
    if (editWalmartURLInfo?.itemId) {
      const productItemId = productPage?.product?.itemId;
      let productLink = productPage?.product?.link || editTargetURL;
      if (productLink) {
        productLink = replaceWalmartSearchPhraseInURL(
          productLink,
          editFollowOn
        );
      }

      if (
        editWalmartURLInfo?.itemId === productItemId &&
        editTargetURL !== productLink
      ) {
        setEditTargetURL(productLink);
      }
    }
  }, [productPage, editWalmartURLInfo, editTargetURL, editFollowOn]);

  // Look for brands by searching for the initialBrand or any string typed in by the user.
  const {
    data: searchPageData,
    isFetching: searchPageIsFetching
  } = useWalmartSearchPage(
    siteAlias,
    editWalmartURLInfo?.itemId ? "" : editTargetURL,
    null,
    getWalmartMarketplace(marketplaceInfo)
  );

  const editPartialProductDetails: PartialWalmartProductDetails = useMemo(() => {
    if (editWalmartURLInfo?.itemId) {
      return {
        targetUrl: editTargetURL,
        itemIds: [editWalmartURLInfo.itemId],
        itemBrand:
          productPage?.product?.brand || initialPartialProductDetails.itemBrand,
        urlSearchTerm: "",
        brandNameFacets: [],
        otherSearchFacets: [],
        otherSearchParameters: []
      };
    } else if (editWalmartURLInfo?.searchPhrase) {
      return {
        targetUrl: editTargetURL,
        itemIds:
          searchPageData?.productsList
            ?.map(product => product?.itemId)
            .filter(Boolean) || initialPartialProductDetails.itemIds,
        itemBrand: "",
        urlSearchTerm: editWalmartURLInfo?.searchPhrase || "",
        brandNameFacets: editWalmartURLInfo?.searchBrands || [],
        otherSearchFacets: editWalmartURLInfo?.searchOtherFacets || [],
        otherSearchParameters: editWalmartURLInfo?.searchOtherParameters || []
      };
    } else {
      return {
        targetUrl: editTargetURL,
        itemIds: [],
        itemBrand: "",
        urlSearchTerm: "",
        brandNameFacets: [],
        otherSearchFacets: [],
        otherSearchParameters: []
      };
    }
  }, [
    editTargetURL,
    editWalmartURLInfo,
    productPage,
    searchPageData,
    initialPartialProductDetails
  ]);

  const handleUpdateTargetURLPath = async () => {
    setIsSubmitting(true);

    let newFinalURL = editTargetURL;
    if (initialRedirectURL) {
      newFinalURL =
        getWalmartURLWithRedirect(editTargetURL, initialRedirectURL) ||
        editTargetURL;
    }

    try {
      if (newFinalURL !== finalURL) {
        await sendUpdateAdTracking(
          siteAlias,
          gaCategory,
          String(customerId),
          String(campaignId),
          String(adGroupId),
          String(adId),
          [newFinalURL],
          trackingURLTemplate ? trackingURLTemplate : undefined
        );
      }

      if (!_.isEqual(editPartialProductDetails, initialPartialProductDetails)) {
        await sendUpdateWalmartProductDetails(
          siteAlias,
          gaCategory,
          String(customerId),
          actionId,
          initialPartialProductDetails,
          editPartialProductDetails
        );
      }

      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(String(campaignId));
      }

      setIsSubmitting(false);
      if (onClose) {
        onClose();
      }
    } catch (e) {
      const message = extractErrorMessage(e);

      console.error(e); // log to datadog

      setErrorMessage(message);

      // Since submitAttempts is used as the key of the OneClickButton, changing it
      // will create a new button instance and re-enable it.
      setSubmitAttempts(submitAttempts + 1);
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setErrorMessage("");
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSelectItemId = (
    newItemId: string,
    newFollowOnSearchPhrase: string
  ) => {
    let warningMessage = "";
    if (!newItemId) {
      // We are not showing an error message for this now
      warningMessage = "";
    } else if (_.toLower(newItemId) !== _.toLower(walmartItemIds[0])) {
      warningMessage =
        `The Walmart Item ID (${newItemId})` +
        ` does not match the one used to create the ad (${walmartItemIds[0]}).`;
    }
    setWarningMessage(warningMessage);

    const newTargetUrl =
      getWalmartDetailPageURLForItemId(
        marketplaceInfo,
        newItemId,
        newFollowOnSearchPhrase || editFollowOn
      ) || "";
    setEditTargetURL(newTargetUrl);
    setEditFollowOn(newFollowOnSearchPhrase || editFollowOn);
    setEditWalmartURLInfo(extractWalmartURLInfoFromString(newTargetUrl));
  };

  const handleSelectSearchPage = (newSearchPage: string) => {
    setEditTargetURL(newSearchPage);
    setEditWalmartURLInfo(extractWalmartURLInfoFromString(newSearchPage));
  };

  const handleSelectBrands = (newBrands: Array<string>) => {
    if (editWalmartURLInfo && editWalmartURLInfo.searchPhrase) {
      const newSearchPage = getWalmartSearchPageURL(
        editWalmartURLInfo.marketplaceInfo,
        editWalmartURLInfo.searchPhrase,
        newBrands,
        editWalmartURLInfo.searchOtherFacets,
        editWalmartURLInfo.searchOtherParameters,
        true
      );

      if (newSearchPage) {
        setEditTargetURL(newSearchPage);
        setEditWalmartURLInfo(extractWalmartURLInfoFromString(newSearchPage));
      }
    }
  };

  const handleSelectFacets = (newFacets: Array<[string, string]>) => {
    if (editWalmartURLInfo && editWalmartURLInfo.searchPhrase) {
      const newSearchPage = getWalmartSearchPageURL(
        editWalmartURLInfo.marketplaceInfo,
        editWalmartURLInfo.searchPhrase,
        editWalmartURLInfo.searchBrands,
        newFacets,
        editWalmartURLInfo.searchOtherParameters,
        true
      );

      if (newSearchPage) {
        setEditTargetURL(newSearchPage);
        setEditWalmartURLInfo(extractWalmartURLInfoFromString(newSearchPage));
      }
    }
  };

  const searchImages = searchPageData?.productsList?.filter(
    product => !!product.mainImage
  );
  const productTitle = productPage?.product?.title;
  const productImage = productPage?.product?.mainImage?.link;

  const updateNeeded =
    editTargetURL !== initialTargetURL ||
    !_.isEqual(initialPartialProductDetails, editPartialProductDetails);

  const followOnOptions = searchTerms.map(searchTerm => ({
    key: searchTerm,
    value: searchTerm,
    text: searchTerm
  }));
  followOnOptions.unshift({ key: "__NONE__", value: "", text: "(None)" });
  if (
    walmartFollowOnSearchPhrase &&
    !searchTerms.includes(walmartFollowOnSearchPhrase)
  ) {
    followOnOptions.push({
      key: walmartFollowOnSearchPhrase,
      value: walmartFollowOnSearchPhrase,
      text: walmartFollowOnSearchPhrase
    });
  }
  if (
    editFollowOn &&
    editFollowOn !== walmartFollowOnSearchPhrase &&
    !searchTerms.includes(editFollowOn)
  ) {
    followOnOptions.push({
      key: editFollowOn,
      value: editFollowOn,
      text: editFollowOn
    });
  }

  const handleFollowOnChange = (
    _e: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    setEditFollowOn(String(value));
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      closeIcon={<Icon name="close" color="black" />}
      centered={false}
      dimmer="inverted"
      size="large"
    >
      <Modal.Header>Configure Target URL for Ad</Modal.Header>
      <Modal.Content style={{ minHeight: "35em" }} scrolling>
        <Form>
          <Form.Field>
            <label>Enter Walmart item ID, search phrase, or Walmart URL:</label>
            <WalmartTargetSelectorDropdown
              marketplaceInfo={marketplaceInfo}
              profileName={walmartProfileName}
              itemId={editWalmartURLInfo?.itemId || ""}
              searchPage={editWalmartURLInfo?.itemId ? "" : editTargetURL}
              brands={editWalmartURLInfo?.searchBrands || []}
              onSelectItemId={handleSelectItemId}
              onSelectSearchPage={handleSelectSearchPage}
              searchData={
                editWalmartURLInfo?.searchPhrase
                  ? searchPageIsFetching
                    ? undefined
                    : searchPageData
                  : undefined
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Select brand(s) to target:</label>
            <WalmartBrandsDropdown
              brandHint={productPage?.product?.brand || ""}
              setBrandHint={() => undefined}
              brands={editWalmartURLInfo?.searchBrands || []}
              defaultPlaceholder={
                editWalmartURLInfo?.searchPhrase
                  ? "Select brand"
                  : "Enter or select brand"
              }
              onSelectBrands={handleSelectBrands}
              brandSearchData={
                brandSearchIsFetching ? undefined : brandSearchData
              }
              brandSearchIsFetching={brandSearchIsFetching}
            />
          </Form.Field>
          {!editWalmartURLInfo?.searchPhrase && (
            <Form.Field>
              <label>Follow-on search phrase for organic ranking boost:</label>
              <Dropdown
                search
                selection
                allowAdditions={true}
                options={followOnOptions}
                value={editFollowOn}
                onChange={handleFollowOnChange}
              />
            </Form.Field>
          )}
          {!!editWalmartURLInfo?.searchPhrase && (
            <Form.Field>
              <label>Select other search filters:</label>
              <WalmartFacetsDropdown
                facets={editWalmartURLInfo.searchOtherFacets}
                onSelectFacets={handleSelectFacets}
                brandSearchData={
                  brandSearchIsFetching ? undefined : brandSearchData
                }
                brandSearchIsFetching={brandSearchIsFetching}
              />
            </Form.Field>
          )}
        </Form>
        <br />
        <a href={editTargetURL} target="_blank" rel="noopener noreferrer">
          <i style={{ overflowWrap: "anywhere" }}>{editTargetURL}</i>
        </a>
        <br />
        <br />
        {(productPageIsFetching || searchPageIsFetching) && <LoadingSpinner />}
        {editWalmartURLInfo?.itemId ? (
          <div>
            <SimpleTooltip
              tooltip={fullyScrubHtml(productPage?.product?.description)}
              hoverable={true}
              wide="very"
            >
              <span>
                {!!productImage && (
                  <Image
                    src={productImage}
                    alt={productTitle}
                    style={{ cursor: "pointer" }}
                    inline
                    size="tiny"
                  />
                )}
                {productTitle}
              </span>
            </SimpleTooltip>
          </div>
        ) : (
          !!searchImages && (
            <div>
              {searchImages.slice(0, 10).map((product, index) => (
                <SimpleTooltip
                  key={index}
                  hoverable={true}
                  wide="very"
                  tooltip={
                    <div>
                      ({product.itemId}) {product.title}
                      <hr />
                      {fullyScrubHtml(product.description)}
                    </div>
                  }
                >
                  <Image
                    src={product.mainImage}
                    alt={product.title}
                    style={{ cursor: "pointer" }}
                    inline
                    size="tiny"
                  />
                </SimpleTooltip>
              ))}
            </div>
          )
        )}
        {!!warningMessage && (
          <Message warning>
            <p>{warningMessage}</p>
            <p>
              If you use this value, there may be problems attributing traffic
              to the correct product.
            </p>
          </Message>
        )}
        {!!errorMessage && <Message error>{errorMessage}</Message>}
        {!_.isEqual(
          initialPartialProductDetails,
          editPartialProductDetails
        ) && (
          <Message info>
            <List bulleted>
              {partialWalmartProductDetailsFields.map(([key, note]) => {
                if (
                  _.isEqual(
                    initialPartialProductDetails[key],
                    editPartialProductDetails[key]
                  )
                ) {
                  return <React.Fragment key={key}></React.Fragment>;
                }
                return <List.Item key={key}>{note}</List.Item>;
              })}
            </List>
            <div>Update will save changes.</div>
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <OneClickButton
          key={submitAttempts}
          primary
          onClick={handleUpdateTargetURLPath}
          loading={isSubmitting}
          disabled={!updateNeeded}
        >
          Update
        </OneClickButton>
      </Modal.Actions>
    </Modal>
  );
};

// Updates the Walmart product details based on partial differences.
async function sendUpdateWalmartProductDetails(
  siteAlias: string,
  gaCategory: string,
  customerId: string,
  actionId: string,
  currentPartialProductDetails: PartialWalmartProductDetails,
  newPartialProductDetails: PartialWalmartProductDetails
) {
  if (
    !siteAlias ||
    !customerId ||
    !actionId ||
    _.isEqual(newPartialProductDetails, currentPartialProductDetails)
  ) {
    return;
  }

  const action = new UpdateCampaignAction();

  const updateProductDetails = new UpdateCampaignAction.UpdateProductDetailsAction();
  updateProductDetails.setActionId(actionId);
  updateProductDetails.setCompareProductDetails(
    partialWalmartProductDetailsToProto(currentPartialProductDetails)
  );
  updateProductDetails.setUpdateProductDetails(
    partialWalmartProductDetailsToProto(newPartialProductDetails)
  );

  action.setUpdateProductDetails(updateProductDetails);

  sendGAEvent(gaCategory, "Update Walmart Product Details", siteAlias);

  const updateReq = new UpdateCampaignsRequest();
  updateReq.setSiteAlias(siteAlias);
  updateReq.setCustomerId(customerId);
  updateReq.setActionsList([action]);

  await GRPCWebClient.updateCampaigns(updateReq, null);
}

function partialWalmartProductDetailsToProto(
  partialProductDetails: PartialWalmartProductDetails
): ProductDetails {
  const productDetails = new ProductDetails();
  const walmartProduct = new ProductDetails.Walmart();

  walmartProduct.setTargetUrl(partialProductDetails.targetUrl);
  walmartProduct.setItemIdsList(partialProductDetails.itemIds);
  walmartProduct.setItemBrand(partialProductDetails.itemBrand);
  walmartProduct.setItemBrand(partialProductDetails.itemBrand);
  walmartProduct.setUrlSearchTerm(partialProductDetails.urlSearchTerm);
  walmartProduct.setBrandNameFacetsList(partialProductDetails.brandNameFacets);
  walmartProduct.setOtherSearchFacetsList(
    convertToParameterProtos(partialProductDetails.otherSearchFacets)
  );
  walmartProduct.setOtherSearchParametersList(
    convertToParameterProtos(partialProductDetails.otherSearchParameters)
  );

  productDetails.setWalmart(walmartProduct);

  return productDetails;
}

function convertToParameterProtos(
  nameValueList: Array<[string, string]>
): Array<ProductDetails.Parameter> {
  return nameValueList.map(([name, value]) => {
    const parameter = new ProductDetails.Parameter();
    parameter.setName(name);
    parameter.setValue(value);
    return parameter;
  });
}

export default WalmartFinalURLEditButton;
