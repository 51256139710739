import React, { useContext } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { ExpandedRowContext, MetricsTableData } from "../MetricsTable";
import { DepthAwareTableCell } from "./DepthAwareTableCell";

export const TitleCell = <T extends MetricsTableData>({
  rowID,
  val,
  depth,
  childRows
}: {
  rowID: string;
  val: unknown;
  depth?: number;
  childRows?: Array<T>;
}): JSX.Element => {
  const [expandedRows, setExpandedRows] = useContext(ExpandedRowContext);
  const isExpanded = expandedRows.has(rowID);

  return (
    <StyledTitleCell
      key={rowID}
      depth={depth}
      _isExpanded={isExpanded}
      _clickable={childRows && childRows.length > 0}
    >
      <div
        onClick={() => {
          const nextExpandedRows = new Set(expandedRows);
          if (isExpanded) {
            nextExpandedRows.delete(rowID);
          } else {
            nextExpandedRows.add(rowID);
          }

          // Expand/collapse any child rows too
          for (const childRow of childRows ?? []) {
            if (isExpanded) {
              nextExpandedRows.delete(childRow.rowID);
            } else {
              nextExpandedRows.add(childRow.rowID);
            }
          }

          setExpandedRows(nextExpandedRows);
        }}
      >
        <p>{val}</p>
        {childRows && childRows.length > 0 && (
          <Icon
            aria-label="Expand row"
            name="arrows alternate vertical"
            color="blue"
          />
        )}
      </div>
    </StyledTitleCell>
  );
};

// This wrapper is necessary to prevent a "react does not recognize the
// `isExpanded` prop on a DOM element" warning until we upgrade to
// styled-components v5
const StyledTitleCell = styled(
  ({
    _isExpanded,
    _clickable,
    ...props
  }: {
    _isExpanded?: boolean;
    depth?: number;
    _clickable?: boolean;
    children: React.ReactNode;
  }) => <DepthAwareTableCell {...props} />
)`
  > div {
    display: grid;
    grid-template-columns: 1fr 1rem;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "title toggle";
    align-items: center;

    p:first-of-type {
      padding: 0.5rem 0;
      gird-area: title;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0 0 ${({ depth }) => depth ?? 0}rem;
    }

    > i {
      grid-area: toggle;
      display: ${props => (props?._isExpanded ? "block" : "none")};
      position: absolute;
      right: 0;
      cursor: pointer;
      background-color: inherit;
    }
  }

  :hover {
    i {
      display: block;
    }
    cursor: ${props => (props._clickable ? "pointer" : "default")};
  }
`;
