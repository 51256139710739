import React from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import styled from "styled-components";
import { DepthAwareTableCell } from "./DepthAwareTableCell";

export const DropdownEditCell = <T extends DropdownProps["value"]>({
  val,
  dropdownOptions,
  onEdit,
  isLoading,
  depth
}: {
  val: T;
  dropdownOptions: Array<{
    key: string;
    text: string;
    value: T;
  }>;
  onEdit: (newVal: DropdownProps["value"]) => void;
  isLoading: boolean;
  depth?: number;
}): JSX.Element => {
  const handleOnChange = async (
    event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    event.preventDefault();
    onEdit(value);
  };

  return (
    <StyledDropdownEditCell depth={depth}>
      <div>
        <StyledEditDropdown
          disabled={isLoading}
          defaultValue={val}
          onChange={handleOnChange}
          options={dropdownOptions}
          icon="pencil"
        />
      </div>
    </StyledDropdownEditCell>
  );
};

const StyledEditDropdown = styled(Dropdown)`
  &.visible {
    z-index: 1;
  }
  > i {
    margin-left: 0.5em;
  }
`;

const StyledDropdownEditCell = styled(DepthAwareTableCell)`
  overflow: visible !important;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
