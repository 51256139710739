import { NumberInputNoSpinners } from "ExtensionV2/pages/FacebookPage/EditFacebookBudget";
import React, { useState, useRef } from "react";
import { Icon, InputOnChangeData } from "semantic-ui-react";
import styled from "styled-components";
import { DepthAwareTableCell } from "./DepthAwareTableCell";

export const EnterFloatCell = ({
  rowID,
  val,
  formatFn,
  onEdit,
  isLoading,
  depth
}: {
  rowID: string;
  val: string;
  formatFn?: (rawVal: unknown, showFraction: boolean) => string;
  onEdit: (rowID: string, newBudget: string) => void;
  isLoading: boolean;
  depth?: number;
}): JSX.Element => {
  const [inputValue, setInputValue] = useState(val);
  const [isEditing, setIsEditing] = useState(false);

  // Ref to the input so we can focus it when editing
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCancel = () => {
    setInputValue(val);
    setIsEditing(false);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (inputValue === "") {
      return;
    }

    if (inputValue === val) {
      return;
    }

    onEdit(rowID, inputValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleBlur();
    } else if (event.key === "Escape") {
      handleCancel();
    }
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: InputOnChangeData
  ) => {
    event.preventDefault();

    let value = data.value;
    // don't allow more than 2 decimal places
    if (value.split(".")[1]?.length > 2) {
      value = Number(value).toFixed(2);
    }
    setInputValue(value);
  };

  return (
    <StyledEnterFloatCell depth={depth}>
      <div>
        {isEditing ? (
          <NumberInputNoSpinners
            ref={inputRef}
            icon={
              <Icon
                aria-label="Cancel edit"
                name="cancel"
                color="grey"
                onClick={handleCancel}
                link
              />
            }
            type="number"
            min="0"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            value={inputValue}
          />
        ) : (
          <>
            <p>{formatFn ? formatFn(val, true) : val}</p>
            <Icon
              loading={isLoading}
              name={isLoading ? "spinner" : "pencil"}
              onClick={() => {
                setIsEditing(true);
                // run this in the next tick so the input is rendered
                // and inputRef.current is set
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 0);
              }}
            />
          </>
        )}
      </div>
    </StyledEnterFloatCell>
  );
};

export const StyledCenteredMetricCell = styled(DepthAwareTableCell)`
  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledEnterFloatCell = styled(StyledCenteredMetricCell)`
  &&& {
    p {
      margin-top: 0.25rem;
    }
  }

  i {
    margin-left: 0.5em;
    cursor: pointer;
  }

  && input {
    max-width: 8rem;
  }

  .disabled {
    opacity: 0.6;
  }
`;
