import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetGoogleAdsLinkableAccountsRequest,
  GoogleAdsCustomerAccount
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { useSessionUser } from "ExtensionV2/queries/useSessionUser";

export const useGoogleAdsLinkableAccounts = ({
  customerIDOrPrefix,
  customerNameFragment
}: {
  customerIDOrPrefix: string;
  customerNameFragment: string;
}): UseQueryResult<Array<GoogleAdsCustomerAccount.AsObject>, unknown> => {
  const { isAmpdOperator } = useSessionUser();

  return useQuery({
    enabled:
      isAmpdOperator &&
      (customerIDOrPrefix.length >= 3 || customerNameFragment.length >= 3),
    queryKey: [
      "googleAdsLinkableAccounts",
      customerIDOrPrefix,
      customerNameFragment
    ],
    staleTime: 10 * 60 * 1_000, // 10 minutes
    queryFn: async () => {
      const request = new GetGoogleAdsLinkableAccountsRequest();
      request.setCustomerIdOrPrefix(customerIDOrPrefix);
      request.setCustomerNameFragment(customerNameFragment);

      const reply = await GRPCWebClient.getGoogleAdsLinkableAccounts(
        request,
        {}
      );
      return reply.toObject().accountsList;
    }
  });
};
