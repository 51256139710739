import _ from "lodash";
import moment from "moment";

import { useMemo } from "react";

import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import {
  AMPD_ATTRIBUTION_BEHAVIOR,
  AttributionAdvertiser,
  CUSTOM_ATTRIBUTION_BEHAVIOR,
  extractAmazonURLInfoFromString,
  findAttributionAdvertiser,
  getAmazonMarketplaceInfo,
  getRelevantAttributionAdvertisers,
  NO_ATTRIBUTION_BEHAVIOR,
  UNSPECIFIED_ATTRIBUTION_BEHAVIOR
} from "Common/utils/amazon";
import { MarketplaceInfo } from "Common/utils/marketplace";
import { GetCampaignConfigurationsReply } from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { googleAdsGeotargetNameMap } from "Common/utils/googleAds";
import { stringForEnum } from "Common/utils/proto";
import {
  GoogleAdsConfiguration,
  GoogleAdsResourceStatus
} from "Common/proto/ampdPb/googleAdsConfiguration_pb";
import { Amazon } from "Common/proto/common/amazon_pb";
import {
  CampaignSettings,
  InternalCampaignDetails
} from "Common/proto/entity/campaign_pb";
import { convertMicrosToCurrencyUnit } from "Common/utils/money";
import { Retailer } from "Common/proto/common/retailer_pb";
import { extractWalmartURLInfoFromString } from "Common/utils/walmart";
import { toUpperCase } from "Common/utils/strings";
import { FacebookConfiguration } from "Common/proto/ampdPb/facebookConfiguration_pb";
import {
  STATUS_ENABLED_STR,
  STATUS_PAUSED_STR,
  STATUS_REMOVED_STR
} from "ExtensionV2/components/MetricColumns";
import { USD_OFFSET } from "ExtensionV2/pages/FacebookPage/EditFacebookBudget";
import { ProductDetails } from "Common/proto/common/productDetails_pb";

export enum AttributionBehavior {
  UNSPECIFIED = UNSPECIFIED_ATTRIBUTION_BEHAVIOR,
  AMPD_ATTRIBUTION = AMPD_ATTRIBUTION_BEHAVIOR,
  CUSTOM_ATTRIBUTION = CUSTOM_ATTRIBUTION_BEHAVIOR,
  NO_ATTRIBUTION = NO_ATTRIBUTION_BEHAVIOR,

  AMPD_WALMART_PARALLEL_ATTRIBUTION = AMPD_ATTRIBUTION_BEHAVIOR,
  AMPD_WALMART_REDIRECT_ATTRIBUTION = CUSTOM_ATTRIBUTION_BEHAVIOR
}

export const AutomationStatus = CampaignSettings.AutomationStatus.Option;
export type AutomationStatus = CampaignSettings.AutomationStatus.Option;

export type PositiveKeywordResource = GoogleAdsConfiguration.KeywordConfiguration.AsObject & {
  campaignId: number;
  adGroupId: number;
};
export type MinimalKeywordResource = Pick<
  PositiveKeywordResource,
  "campaignId" | "adGroupId" | "criteriaId" | "text" | "matchType"
>;
export type NegativeKeywordResource = MinimalKeywordResource;

export const BidAutomationTrainingStatusCode =
  InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode
    .Option;
export type BidAutomationTrainingStatusCode = InternalCampaignDetails.BidAutomationInternalDetails.BidAutomationStatusCode.Option;

export interface ItemizedCampaignConfiguration {
  // The original configuration object that was itemized in case
  // some component needs more specific details.
  fullAmpdConfiguration: GetCampaignConfigurationsReply.CampaignConfiguration.AsObject | null;

  siteAlias: string;

  actionId: string;
  currencyCode: string;
  campaignName: string;
  campaignId: number;
  campaignStatus: string;
  campaignStartDate: string;
  campaignServingStatus: string;
  campaignBiddingStrategy: string;
  isCampaignEnabled: boolean;
  isCampaignPaused: boolean;
  isCampaignRemoved: boolean;
  isAmpdCampaign: boolean;
  isAmpdCampaignOnGoogleAds: boolean;
  isAmpdCampaignOnFacebook: boolean;
  isAmpdCampaignForAmazon: boolean;
  isAmpdCampaignForWalmart: boolean;
  campaignBudget: number;

  // Google Ads only fields
  customerId: number;
  campaignMaxCPCMicros: number;
  adGroupMaxCPCMicros: number;
  geotargets: Array<number>;
  locations: string;
  adGroupId: number;
  adGroupName: string;
  adId: number;
  finalURL: string;
  allFinalURLs: Array<string>;
  finalURLSuffix: string;
  trackingURLTemplate: string;
  usesAmpdAttribution: boolean;
  marketplaceInfo: MarketplaceInfo;
  domain: string;
  campaignBudgetId: number;
  campaignBudgetIsShared: boolean;

  trackOrganicRankings: boolean;
  searchTerms: Array<string>;
  historicalSearchTerms: Array<string>;
  headlines: Array<GoogleAdsConfiguration.TextAsset.AsObject>;
  descriptions: Array<GoogleAdsConfiguration.TextAsset.AsObject>;
  keywords: Array<PositiveKeywordResource>;
  negativeKeywords: Array<NegativeKeywordResource>;
  pausingAutomationEnabled: boolean;
  pausingAutomationCostThresholdMicros: number;
  pausingAutomationAACOSThresholdPoints: number;
  pausingAutomationReevaluatePausedKeywords: boolean;
  pausingAutomationMinNumActiveKeywords: number;
  bidAutomationStatus: AutomationStatus;
  bidAutomationVoltageMin: number;
  bidAutomationVoltageMax: number;
  bidAutomationVoltageStep: number;
  bidAutomationTargetVoltage: number;
  bidAutomationInitialRun: moment.Moment | null;
  bidAutomationLastRun: moment.Moment | null;
  bidAutomationNextScheduledRun: moment.Moment | null;
  bidAutomationRunFrequencyDays: number;
  bidAutomationTrainingStatusCode: BidAutomationTrainingStatusCode;
  bidAutomationTrainingStatusMessage: string;
  bidAutomationTrainingProgress: number;
  bidAutomationTrainingEstimatedCompletionDate: moment.Moment | null;
  bidAutomationRecommendedMinimumBudget: number;
  bidAutomationTargetedVoltage: number;

  // Facebook only fields
  facebookAccountId: string;
  facebookCampaignId: string;
  facebookAdSetAndAdIds: Array<[string, string]>;

  // Amazon only fields
  marketplaceAdvertisers: Array<AttributionAdvertiser>;
  attributionAdvertiserId: string;
  attributionAdvertiser: AttributionAdvertiser | null;
  attributionBehavior: AttributionBehavior;
  attributionCustomTags: Array<[string, string]>;
  asin: string;
  variantASINs: Array<string>;
  detailPagePath: string;
  customTargetURLPathAndQuery: string;

  // Walmart only fields
  walmartSearchPhrase: string;
  walmartProfileName: string;
  walmartSearchBrands: Array<string>;
  walmartSearchOtherFacets: Array<[string, string]>;
  walmartSearchOtherParameters: Array<[string, string]>;
  walmartItemIds: Array<string>;
  walmartFollowOnSearchPhrase: string;
  walmartTrackingURL: string;
  walmartAttributionCategoriesToInfer: Array<string>;
  walmartAttributionCategoriesToIgnore: Array<string>;
}

// Converts a grpcwebService.CampaignConfiguration object into a collection of
// fields that are more convenient to used in the campaign editor.  All fields
// are initialized to a default values even if the ampdCampaignedConfiguration is
// undefined or is missing parts.
export const getItemizedCampaignConfiguration = (
  ampdCampaignConfiguration:
    | GetCampaignConfigurationsReply.CampaignConfiguration.AsObject
    | undefined
    | null,
  advertisingProfiles: Array<Amazon.AdvertisingProfile.AsObject>
): ItemizedCampaignConfiguration => {
  const itemized: ItemizedCampaignConfiguration = {
    fullAmpdConfiguration: ampdCampaignConfiguration || null,
    siteAlias: "",
    actionId: "",
    customerId: 0,
    currencyCode: "",
    campaignName: "",
    campaignId: 0,
    campaignStatus: "",
    isCampaignEnabled: false,
    isCampaignPaused: false,
    isCampaignRemoved: false,
    isAmpdCampaign: false,
    isAmpdCampaignOnGoogleAds: false,
    isAmpdCampaignOnFacebook: false,
    isAmpdCampaignForAmazon: false,
    isAmpdCampaignForWalmart: false,
    campaignStartDate: "",
    campaignServingStatus: "",
    campaignBiddingStrategy: "",
    campaignMaxCPCMicros: 0,
    adGroupMaxCPCMicros: 0,
    campaignBudgetId: 0,
    campaignBudget: 0,
    campaignBudgetIsShared: false,
    geotargets: [],
    locations: "",
    adGroupId: 0,
    adGroupName: "",
    adId: 0,
    finalURL: "",
    allFinalURLs: [],
    finalURLSuffix: "",
    trackingURLTemplate: "",
    usesAmpdAttribution: false,
    marketplaceInfo: {
      retailer: Retailer.Option.UNKNOWN,
      marketplace: 0,
      name: "",
      domain: "",
      geotargets: [],
      currencyCode: ""
    },
    domain: "",

    // Facebook only fields
    facebookAccountId: "",
    facebookCampaignId: "",
    facebookAdSetAndAdIds: [],

    // Amazon only fields
    marketplaceAdvertisers: [],
    attributionAdvertiserId: "",
    attributionAdvertiser: null,
    attributionBehavior: AttributionBehavior.UNSPECIFIED,
    attributionCustomTags: [],
    asin: "",
    variantASINs: [],
    detailPagePath: "",
    customTargetURLPathAndQuery: "",

    // Walmart only fields
    walmartSearchPhrase: "",
    walmartProfileName: "",
    walmartSearchBrands: [],
    walmartSearchOtherFacets: [],
    walmartSearchOtherParameters: [],
    walmartItemIds: [],
    walmartFollowOnSearchPhrase: "",
    walmartTrackingURL: "",
    walmartAttributionCategoriesToInfer: [],
    walmartAttributionCategoriesToIgnore: [],

    trackOrganicRankings: false,
    searchTerms: [],
    historicalSearchTerms: [],
    headlines: [],
    descriptions: [],
    keywords: [],
    negativeKeywords: [],
    pausingAutomationEnabled: false,
    pausingAutomationCostThresholdMicros: 0,
    pausingAutomationAACOSThresholdPoints: 0,
    pausingAutomationReevaluatePausedKeywords: false,
    pausingAutomationMinNumActiveKeywords: 0,
    bidAutomationStatus: AutomationStatus.UNSPECIFIED,
    bidAutomationVoltageMin: 0,
    bidAutomationVoltageMax: 0,
    bidAutomationVoltageStep: 0,
    bidAutomationTargetVoltage: 0,
    bidAutomationInitialRun: null,
    bidAutomationLastRun: null,
    bidAutomationNextScheduledRun: null,
    bidAutomationRunFrequencyDays: 0,
    bidAutomationTrainingStatusCode:
      BidAutomationTrainingStatusCode.UNSPECIFIED,
    bidAutomationTrainingStatusMessage: "",
    bidAutomationTrainingProgress: 0,
    bidAutomationTrainingEstimatedCompletionDate: null,
    bidAutomationRecommendedMinimumBudget: 0,
    bidAutomationTargetedVoltage: 0
  };

  if (!ampdCampaignConfiguration) {
    return itemized;
  }

  itemized.siteAlias = ampdCampaignConfiguration.siteAlias || "";
  itemized.actionId = ampdCampaignConfiguration.creationActionId?.uuid || "";

  const googleAdsConfiguration =
    ampdCampaignConfiguration.ampdResourceConfiguration?.googleAds;
  if (googleAdsConfiguration) {
    itemized.isAmpdCampaignOnGoogleAds = true;
    itemized.customerId = googleAdsConfiguration.customerId;
    itemized.currencyCode = googleAdsConfiguration.currencyCode;

    const campaignConfiguration = googleAdsConfiguration?.campaignConfiguration;
    if (campaignConfiguration) {
      itemizeFromGoogleAdsCampaignConfiguration(
        itemized,
        campaignConfiguration
      );
    }

    const budgetConfiguration = googleAdsConfiguration?.budgetConfiguration;
    if (budgetConfiguration) {
      itemizeFromGoogleAdsBudgetConfiguration(itemized, budgetConfiguration);
    }

    // NOTE: Only considering the first ad in the first ad group to be part of
    // the Ampd configuration.
    const adGroupConfiguration =
      googleAdsConfiguration?.adGroupConfigurationsList[0];
    if (adGroupConfiguration) {
      itemizeFromAdGroupConfiguration(
        itemized,
        campaignConfiguration?.campaignId || 0,
        adGroupConfiguration
      );
    }
  }

  const facebookConfiguration =
    ampdCampaignConfiguration.ampdResourceConfiguration?.facebook;
  if (facebookConfiguration) {
    itemized.isAmpdCampaignOnFacebook = true;
    itemized.facebookAccountId = facebookConfiguration.accountId;
    itemized.currencyCode = "USD"; /* TODO:  */

    const campaignDetails = facebookConfiguration.campaignDetails;
    if (campaignDetails) {
      itemizeFromFacebookCampaignDetails(itemized, campaignDetails);
    }
  }

  const campaignProductDetails = ampdCampaignConfiguration.ampdProductDetails;
  if (campaignProductDetails) {
    itemizeFromProductDetails(
      itemized,
      campaignProductDetails,
      advertisingProfiles
    );
  } else if (ampdCampaignConfiguration.adProductDetailsList.length > 0) {
    // Facebook campaigns can advertise different products and target different retailers, but let's just take the first
    // well-formed one for now.  TODO: Include more ads if needed.
    for (const adProductDetails of ampdCampaignConfiguration.adProductDetailsList) {
      if (
        adProductDetails.productDetails?.amazon ||
        adProductDetails.productDetails?.walmart
      ) {
        itemizeFromProductDetails(
          itemized,
          adProductDetails.productDetails,
          advertisingProfiles
        );
        break;
      }
    }
  }

  const pausingAutomationSettings =
    ampdCampaignConfiguration.settings?.googleAds?.pausingAutomationSettings;
  if (pausingAutomationSettings) {
    itemizeFromPausingAutomationSettings(itemized, pausingAutomationSettings);
  }

  const bidAutomationSettings =
    ampdCampaignConfiguration.settings?.googleAds?.bidAutomationSettings;
  let trainingStatus = ampdCampaignConfiguration.bidAutomationTrainingStatus;
  if (bidAutomationSettings) {
    if (!trainingStatus) {
      trainingStatus = new GetCampaignConfigurationsReply.BidAutomationTrainingStatus().toObject();
    }

    itemizeFromBidAutomationSettings(
      itemized,
      bidAutomationSettings,
      trainingStatus
    );
  }

  return itemized;
};

export function useItemizedCampaignConfiguration(
  ampdCampaignConfiguration:
    | GetCampaignConfigurationsReply.CampaignConfiguration.AsObject
    | undefined
    | null
): ItemizedCampaignConfiguration {
  const {
    amazonInfo: { advertisingProfiles }
  } = useSessionSite();

  return useMemo(
    () =>
      getItemizedCampaignConfiguration(
        ampdCampaignConfiguration,
        advertisingProfiles
      ),
    [ampdCampaignConfiguration, advertisingProfiles]
  );
}

// Populates the itemized configuration information for the campaign itself.
function itemizeFromGoogleAdsCampaignConfiguration(
  itemized: ItemizedCampaignConfiguration,
  campaignConfiguration: GoogleAdsConfiguration.CampaignConfiguration.AsObject
) {
  const campaignId = campaignConfiguration.campaignId;

  itemized.campaignName = campaignConfiguration.campaignName || "";
  itemized.campaignId = campaignId;

  // Stringify the status, but don't prettify it, so it can be compared
  // against standard STATUS_*_STR strings.
  itemized.campaignStatus = stringForEnum(
    GoogleAdsResourceStatus.Option,
    campaignConfiguration.status
  );
  itemized.isCampaignEnabled =
    campaignConfiguration.status === GoogleAdsResourceStatus.Option.ENABLED;
  itemized.isCampaignPaused =
    campaignConfiguration.status === GoogleAdsResourceStatus.Option.PAUSED;
  itemized.isCampaignRemoved =
    campaignConfiguration.status === GoogleAdsResourceStatus.Option.REMOVED;
  itemized.campaignStartDate = campaignConfiguration.startDate || "";

  itemized.campaignMaxCPCMicros = campaignConfiguration.maxCpcMicros || 0;

  itemized.geotargets = campaignConfiguration.geoTargetLocationIdsList || [];
  itemized.locations =
    itemized.geotargets == null || itemized.geotargets.length === 0
      ? "N/A"
      : itemized.geotargets
          .map(x => googleAdsGeotargetNameMap.get(x))
          .filter(Boolean)
          .join(", ");

  itemized.campaignServingStatus = stringForEnum(
    GoogleAdsConfiguration.CampaignServingStatus.Option,
    campaignConfiguration.servingStatus
  );

  itemized.campaignBiddingStrategy =
    campaignConfiguration.otherBiddingStrategy ||
    stringForEnum(
      GoogleAdsConfiguration.CampaignBiddingStrategy.Option,
      campaignConfiguration.biddingStrategy
    );

  if (campaignConfiguration.negativeKeywordConfigurationsList.length > 0) {
    itemized.negativeKeywords = itemized.negativeKeywords.concat(
      campaignConfiguration.negativeKeywordConfigurationsList.map(keyword => ({
        ...keyword,
        campaignId: campaignId,
        adGroupId: 0
      }))
    );
  }
}

// Populates the itemized configuration information for the campaign budget.
function itemizeFromGoogleAdsBudgetConfiguration(
  itemized: ItemizedCampaignConfiguration,
  budgetConfiguration: GoogleAdsConfiguration.BudgetConfiguration.AsObject
) {
  itemized.campaignBudgetId = budgetConfiguration.budgetId;

  itemized.campaignBudget = convertMicrosToCurrencyUnit(
    budgetConfiguration.budgetAmountMicros
  );
  itemized.campaignBudgetIsShared =
    budgetConfiguration.explicitlyShared ||
    budgetConfiguration.campaignCount > 1;
}

// Populates the itemized configuration information for the campaign itself.
function itemizeFromFacebookCampaignDetails(
  itemized: ItemizedCampaignConfiguration,
  campaignDetails: FacebookConfiguration.CampaignDetails.AsObject
) {
  itemized.campaignName = campaignDetails.name || "";

  itemized.isCampaignEnabled =
    campaignDetails.status ===
      FacebookConfiguration.CampaignDetails.Status.Option.ACTIVE ||
    campaignDetails.status ===
      FacebookConfiguration.CampaignDetails.Status.Option.IN_PROCESS ||
    campaignDetails.status ===
      FacebookConfiguration.CampaignDetails.Status.Option.WITH_ISSUES;
  itemized.isCampaignPaused =
    campaignDetails.status ===
    FacebookConfiguration.CampaignDetails.Status.Option.PAUSED;
  itemized.isCampaignRemoved =
    campaignDetails.status ===
      FacebookConfiguration.CampaignDetails.Status.Option.DELETED ||
    campaignDetails.status ===
      FacebookConfiguration.CampaignDetails.Status.Option.ARCHIVED;

  // Uses the standard STATUS_*_STR strings here.
  itemized.campaignStatus = itemized.isCampaignEnabled
    ? STATUS_ENABLED_STR
    : itemized.isCampaignPaused
    ? STATUS_PAUSED_STR
    : STATUS_REMOVED_STR;

  itemized.campaignStartDate = campaignDetails.startTime || ""; // TODO: Should use date that the first ad was tagged by Ampd.

  itemized.campaignServingStatus = stringForEnum(
    FacebookConfiguration.CampaignDetails.Status.Option,
    campaignDetails.status
  );

  itemized.campaignBiddingStrategy = stringForEnum(
    FacebookConfiguration.CampaignDetails.BidStrategy.Option,
    campaignDetails.bidStrategy
  );

  itemized.facebookAdSetAndAdIds = [];

  for (const adSetDetails of campaignDetails.adSetDetailsList) {
    for (const adDetails of adSetDetails.adDetailsList) {
      itemized.facebookAdSetAndAdIds.push([
        adSetDetails.adSetId,
        adDetails.adId
      ]);
    }
  }

  // TODO handle currencies with different offsets (e.g. JPY offset is 1)
  itemized.campaignBudget = campaignDetails.dailyBudget / USD_OFFSET;
}

// Populates the itemized configuration information for the ad group object.
function itemizeFromAdGroupConfiguration(
  itemized: ItemizedCampaignConfiguration,
  campaignId: number,
  adGroupConfiguration: GoogleAdsConfiguration.AdGroupConfiguration.AsObject
) {
  const adGroupId = adGroupConfiguration.adGroupId;

  itemized.adGroupId = adGroupId;
  itemized.adGroupName = adGroupConfiguration.adGroupName;
  itemized.adGroupMaxCPCMicros = adGroupConfiguration.cpcBidMicros || 0;

  const adConfiguration = adGroupConfiguration?.adConfigurationsList[0];
  if (adConfiguration) {
    itemizeFromAdConfiguration(itemized, adConfiguration);
  }

  itemized.keywords = adGroupConfiguration.keywordConfigurationsList.map(
    keyword => ({
      ...keyword,
      campaignId: campaignId,
      adGroupId: adGroupId
    })
  );

  if (adGroupConfiguration.negativeKeywordConfigurationsList.length > 0) {
    itemized.negativeKeywords = itemized.negativeKeywords.concat(
      adGroupConfiguration.negativeKeywordConfigurationsList.map(keyword => ({
        ...keyword,
        campaignId: campaignId,
        adGroupId: adGroupId
      }))
    );
  }
}

// Populates the itemized configuration information for the ad object.
function itemizeFromAdConfiguration(
  itemized: ItemizedCampaignConfiguration,
  adConfiguration: GoogleAdsConfiguration.AdConfiguration.AsObject
) {
  itemized.headlines = adConfiguration.headlinesList;
  itemized.descriptions = adConfiguration.descriptionsList;
  itemized.adId = adConfiguration.adId;
  itemized.finalURL = adConfiguration.finalUrlsList[0];
  itemized.allFinalURLs = adConfiguration.finalUrlsList;
  itemized.finalURLSuffix = adConfiguration.finalUrlSuffix;
  itemized.trackingURLTemplate = adConfiguration.trackingUrlTemplate;
  itemized.usesAmpdAttribution = adConfiguration.usesAmpdAttribution;
  itemized.attributionAdvertiserId = adConfiguration.advertiserIdStr;
}

// Populates the itemized configuration information that is derived from the
// product details
function itemizeFromProductDetails(
  itemized: ItemizedCampaignConfiguration,
  productDetails: ProductDetails.AsObject,
  advertisingProfiles: Array<Amazon.AdvertisingProfile.AsObject>
) {
  const amazonConfiguration = productDetails?.amazon;
  if (amazonConfiguration) {
    // This is a proper "Ampd" campaign if we have product details.
    itemized.isAmpdCampaign = true;
    itemized.isAmpdCampaignForAmazon = true;

    itemized.trackOrganicRankings = amazonConfiguration?.trackOrganicRankings;
    itemized.searchTerms = amazonConfiguration?.searchTermsToTrackList;
    itemized.historicalSearchTerms =
      amazonConfiguration?.searchTermsHistoryList;

    // NOTE: This is the original ASIN and marketplace for which the campaign was
    // created.  If the current finalURL for the ad has an embedded ASIN and/or
    // marketplace in the URL, then these values will be overwritten by
    // itemizeFromAmazonFinalURL below.
    itemized.asin = amazonConfiguration?.asin;
    const marketplaceInfo = getAmazonMarketplaceInfo(
      amazonConfiguration?.marketplace
    );
    if (marketplaceInfo) {
      itemized.marketplaceInfo = marketplaceInfo;
      itemized.domain = marketplaceInfo.domain;
    }

    // TODO (robert): populate 'itemized.variantASINs' */

    // Let the actual final URL in the ad override any fields.
    if (itemized.finalURL) {
      itemizeFromAmazonURL(itemized, itemized.finalURL, advertisingProfiles);
    }
  }

  const walmartConfiguration = productDetails?.walmart;
  if (walmartConfiguration) {
    itemized.isAmpdCampaign = true;
    itemized.isAmpdCampaignForWalmart = true;

    itemized.searchTerms = [];
    itemized.historicalSearchTerms = [];
    walmartConfiguration.searchTermTrackingMap.forEach(([term, track]) => {
      if (track) {
        itemized.searchTerms.push(term);
      } else {
        itemized.historicalSearchTerms.push(term);
      }
    });

    itemized.walmartSearchPhrase = walmartConfiguration.urlSearchTerm;
    itemized.walmartProfileName =
      walmartConfiguration.attribution?.profileName || "";
    itemized.walmartSearchBrands = walmartConfiguration.brandNameFacetsList;
    itemized.walmartSearchOtherFacets = walmartConfiguration.otherSearchFacetsList.map(
      param => [param.name, param.value]
    );
    itemized.walmartSearchOtherParameters = walmartConfiguration.otherSearchParametersList.map(
      param => [param.name, param.value]
    );

    itemized.walmartItemIds = walmartConfiguration.itemIdsList;

    // Let the actual final URL in the ad override any fields.
    if (itemized.finalURL) {
      itemizeFromWalmartURL(itemized, itemized.finalURL);
    }

    if (itemized.searchTerms.length > 0) {
      itemized.trackOrganicRankings = true;
    }

    itemized.walmartAttributionCategoriesToInfer =
      walmartConfiguration.attribution?.categoriesList?.map(toUpperCase) || [];
    itemized.walmartAttributionCategoriesToIgnore =
      walmartConfiguration.attribution?.excludedCategoriesList?.map(
        toUpperCase
      ) || [];
  }
}

// Populates the itemized configuration information that is derived from the
// final url of the ad.
function itemizeFromAmazonURL(
  itemized: ItemizedCampaignConfiguration,
  urlString: string,
  advertisingProfiles: Array<Amazon.AdvertisingProfile.AsObject>
) {
  const {
    domain,
    marketplaceInfo,
    asin,
    detailPagePath,
    pathAndQuery
  } = extractAmazonURLInfoFromString(urlString);

  if (marketplaceInfo) {
    itemized.marketplaceInfo = marketplaceInfo;
  }

  if (domain) {
    itemized.domain = domain;
  }

  if (asin) {
    itemized.asin = asin;
  }

  itemized.detailPagePath = detailPagePath || "";
  itemized.customTargetURLPathAndQuery =
    _.toLower(pathAndQuery || "") === _.toLower(detailPagePath || "")
      ? ""
      : pathAndQuery || "";

  itemized.attributionBehavior = AttributionBehavior.UNSPECIFIED;
  if (marketplaceInfo) {
    itemized.attributionBehavior = AttributionBehavior.NO_ATTRIBUTION;
    if (itemized.usesAmpdAttribution) {
      itemized.attributionBehavior = AttributionBehavior.AMPD_ATTRIBUTION;
    }

    const marketplace = _.get(
      marketplaceInfo,
      "marketplace",
      Amazon.Marketplace.Option.UNKNOWN
    );

    if (advertisingProfiles) {
      itemized.marketplaceAdvertisers = getRelevantAttributionAdvertisers(
        advertisingProfiles,
        marketplace,
        false
      );

      if (itemized.attributionAdvertiserId) {
        itemized.attributionAdvertiser = findAttributionAdvertiser(
          itemized.marketplaceAdvertisers,
          itemized.attributionAdvertiserId
        );
      }
    }

    try {
      const url = new URL(urlString);

      if (!_.isEmpty(url.searchParams.toString())) {
        itemized.attributionCustomTags = Array.from(url.searchParams.entries());
        if (
          !itemized.usesAmpdAttribution &&
          (url.searchParams.get("maas") ||
            url.searchParams.get("ref_") === "aa_maas")
        ) {
          itemized.attributionBehavior = AttributionBehavior.CUSTOM_ATTRIBUTION;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
}

// Populates the itemized configuration information that is derived from the
// final url of the ad.
function itemizeFromWalmartURL(
  itemized: ItemizedCampaignConfiguration,
  urlString: string
) {
  const {
    domain,
    marketplaceInfo,
    itemId,
    followOnSearchPhrase,
    searchPhrase,
    searchBrands,
    searchOtherFacets,
    searchOtherParameters,
    redirectURL
  } = extractWalmartURLInfoFromString(urlString);

  if (marketplaceInfo) {
    itemized.marketplaceInfo = marketplaceInfo;
  }

  if (domain) {
    itemized.domain = domain;
    itemized.walmartTrackingURL = redirectURL || "";
  }

  if (itemId) {
    itemized.walmartItemIds = [itemId];
  }

  if (followOnSearchPhrase) {
    itemized.walmartFollowOnSearchPhrase = followOnSearchPhrase;
  }

  if (searchPhrase) {
    itemized.walmartSearchPhrase = searchPhrase;
    itemized.walmartSearchBrands = searchBrands || [];
    itemized.walmartSearchOtherFacets = searchOtherFacets || [];
    itemized.walmartSearchOtherParameters = searchOtherParameters || [];
  }

  if (itemized.usesAmpdAttribution) {
    if (itemized.trackingURLTemplate) {
      itemized.attributionBehavior =
        AttributionBehavior.AMPD_WALMART_PARALLEL_ATTRIBUTION;
    } else {
      itemized.attributionBehavior =
        AttributionBehavior.AMPD_WALMART_REDIRECT_ATTRIBUTION;
    }
  } else {
    itemized.attributionBehavior = AttributionBehavior.NO_ATTRIBUTION;
  }
}

// Populates the itemized configuration information for the pausing automation.
function itemizeFromPausingAutomationSettings(
  itemized: ItemizedCampaignConfiguration,
  pausingAutomationSettings: CampaignSettings.GoogleAds.PausingAutomationSettings.AsObject
) {
  itemized.pausingAutomationEnabled =
    pausingAutomationSettings.status ===
    CampaignSettings.AutomationStatus.Option.ENABLED;
  itemized.pausingAutomationCostThresholdMicros =
    pausingAutomationSettings.cost28DayThresholdMicros;
  itemized.pausingAutomationAACOSThresholdPoints =
    pausingAutomationSettings.aacos28DayThresholdPoints;
  itemized.pausingAutomationReevaluatePausedKeywords =
    pausingAutomationSettings.reevaluatePausedKeywords;
  itemized.pausingAutomationMinNumActiveKeywords =
    pausingAutomationSettings.minimumNumberOfActiveKeywords;
}

// Populates the itemized configuration information for the bid automation.
function itemizeFromBidAutomationSettings(
  itemized: ItemizedCampaignConfiguration,
  bidAutomationSettings: CampaignSettings.GoogleAds.BidAutomationSettings.AsObject,
  trainingStatus: GetCampaignConfigurationsReply.BidAutomationTrainingStatus.AsObject
) {
  itemized.bidAutomationStatus = bidAutomationSettings.status;
  const initialRunTime = bidAutomationSettings.dateInfo?.initialRunTime;
  itemized.bidAutomationInitialRun = initialRunTime
    ? moment(initialRunTime)
    : null;
  const nextScheduledRunTime =
    bidAutomationSettings.dateInfo?.nextScheduledRunTime;
  itemized.bidAutomationNextScheduledRun = nextScheduledRunTime
    ? moment(nextScheduledRunTime)
    : null;
  itemized.bidAutomationTargetVoltage = bidAutomationSettings.targetVoltage;
  itemized.bidAutomationRunFrequencyDays =
    bidAutomationSettings.dateInfo?.runFrequencyDays || 0;

  const lastRunTime = trainingStatus.lastRunTime;
  itemized.bidAutomationLastRun = lastRunTime ? moment(lastRunTime) : null;
  itemized.bidAutomationTrainingStatusCode = trainingStatus.statusCode;
  itemized.bidAutomationTrainingStatusMessage = trainingStatus.statusMessage;
  itemized.bidAutomationTrainingProgress = trainingStatus.trainingProgress;
  const estimatedCompletionDate = trainingStatus.estimatedCompletionDate;
  itemized.bidAutomationTrainingEstimatedCompletionDate = estimatedCompletionDate
    ? moment(estimatedCompletionDate)
    : null;
  itemized.bidAutomationVoltageMin = trainingStatus.voltageMin;
  itemized.bidAutomationVoltageMax = trainingStatus.voltageMax;
  itemized.bidAutomationVoltageStep = trainingStatus.voltageStep;
  itemized.bidAutomationRecommendedMinimumBudget =
    trainingStatus.recommendedMinimumBudget;
  itemized.bidAutomationTargetedVoltage = trainingStatus.targetedVoltage;
}
