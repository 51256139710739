import _ from "lodash";
import {
  ExecutionResult,
  useMutation as useGraphQLMutation
} from "react-apollo";

import { Amazon } from "Common/proto/common/amazon_pb";
import {
  LinkDataSourceReply,
  UnlinkDataSourceReply,
  UnlinkDataSourceRequest,
  UpdateSiteManagerLinkClientLabelsReply
} from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { UpdateSiteContactEmailReply } from "Common/proto/edge/grpcwebPb/grpcweb_Site_pb";
import {
  DashboardSite,
  GetDashboardSessionReply
} from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";
import {
  CreateGoogleAdsAccountReply,
  CreateGoogleAdsAccountRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import {
  AmpdSubscription,
  BillingAccount
} from "Common/proto/entity/billingAccount_pb";
import {
  AccountBudgetTarget,
  InternalSiteDetails,
  SiteDetails
} from "Common/proto/entity/site_pb";
import {
  SiteManagerLinkClient,
  SiteManagerLinkManager
} from "Common/proto/entity/siteManagerLink_pb";
import { GlobalRole, UISettings } from "Common/proto/entity/user_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import * as proto from "Common/utils/proto";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "@tanstack/react-query";

import addSiteDataSourceLink from "ExtensionV2/grpc/addSiteDataSourceLink";
import { getSession } from "ExtensionV2/grpc/getSession";
import removeSiteDataSourceLink from "ExtensionV2/grpc/removeSiteDataSourceLink";
import { updateSiteContactEmail } from "ExtensionV2/grpc/updateSiteContactEmail";
import { updateSiteManagerLinkClientLabels } from "ExtensionV2/grpc/updateSiteManagerLinkClientLabels";

import getSiteAliasFromURL from "Common/utils/getSiteAliasFromURL";
import { LinkGoogleAdsMutation } from "ExtensionV2/graphql";
import { updateSiteUIBehavior } from "ExtensionV2/grpc/updateSiteUIBehavior";

export interface Site {
  accountBudgetTargets: Array<AccountBudgetTarget.AsObject>;
  adwordsAccounts: Array<DashboardSite.DashboardAdwordsAccountInfo.AsObject>;
  adwordsCustomerIds: Array<string>;
  amazonInfo: {
    advertisingProfiles: Array<Amazon.AdvertisingProfile.AsObject>;
    advertisingAccounts: Array<
      DashboardSite.DashboardAmazonAdvertisingAccountInfo.AsObject
    >;
    sellerAccounts: Array<
      DashboardSite.DashboardAmazonSellerAccountInfo.AsObject
    >;
    hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount: boolean;
  };
  walmartInfo: {
    walmartProfiles: Array<DashboardSite.DashboardWalmartProfileInfo.AsObject>;
  };
  billingAccountStatus: BillingAccount.Status.Option;
  billingAccountType: DashboardSite.BillingAccountType.Option;
  clientBillingAccountCount: number;
  clientBillingAccountMax: number;
  facebookAccount: DashboardSite.DashboardFacebookAccountInfo.AsObject;
  hasAdwordsAccount: boolean;
  siteAlias: string;
  siteContactEmail: string;
  siteCurrencyCode: string;
  siteDomainName: string;
  siteFeatures: DashboardSite.DashboardFeatures.AsObject;
  siteUIBehavior: SiteDetails.UIBehavior.AsObject;
  siteId: string;
  siteName: string;
  subscription: AmpdSubscription.AsObject | null | undefined;
  hubSpotCompanyId: string;
  minGmv: number;
  organizationType: InternalSiteDetails.OrganizationType.Option;
}

export interface User {
  authorizedSitesList: Array<
    GetDashboardSessionReply.SiteAuthorization.AsObject
  >;
  familyName: string;
  givenName: string;
  userEmail: string;
  userId: string;
  isCurrentSiteAdmin: boolean;
  isAmpdOperator: boolean;
  uiSettings: UISettings.AsObject;
  userName: string;
}

interface DashboardSessionData {
  currentSite: Site | undefined;
  clientSitesList: Array<SiteManagerLinkClient.AsObject> | undefined;
  managerSitesList: Array<SiteManagerLinkManager.AsObject> | undefined;
  user: User | undefined;
}

type DashboardSession = DashboardSessionData &
  UseQueryResult<DashboardSessionData, Error> & {
    updateSiteContactEmailMutation: UseMutationResult<
      UpdateSiteContactEmailReply.AsObject | undefined,
      Error,
      {
        email: string;
      },
      unknown
    >;
    updateOnlyAdminUsersCanEditCampaignsMutation: UseMutationResult<
      SiteDetails.UIBehavior.AsObject | undefined,
      Error,
      {
        onlyAdminUsersCanEditCampaigns: boolean;
      },
      unknown
    >;
    updateSiteManagerLinkClientLabelsMutation: UseMutationResult<
      UpdateSiteManagerLinkClientLabelsReply.AsObject | undefined,
      Error,
      {
        managerSiteAlias: string;
        clientSiteAlias: string;
        addLabels: Array<string>;
        removeLabels: Array<string>;
      },
      unknown
    >;
    updateLinkedGoogleAdsAccount: UseMutationResult<
      ExecutionResult,
      Error,
      {
        accessToken: string;
        siteAlias: string;
        newGoogleAdsCustomerId: string;
        newGoogleAdsManagerId: string;
      },
      unknown
    >;
    createAndLinkGoogleAdsAccount: UseMutationResult<
      CreateGoogleAdsAccountReply.AsObject,
      Error,
      {
        siteAlias: string;
        googleAccountEmail: string;
        googleAccountName: string;
        timezone: string;
        currencyCode: string;
      },
      unknown
    >;
    invalidateSessionQuery: () => void;
    forceActivateBilling: () => void;
    disconnectDataSourceMutation: UseMutationResult<
      UnlinkDataSourceReply.AsObject | undefined,
      Error,
      {
        removeDataSourceReq: UnlinkDataSourceRequest.AsObject;
      },
      unknown
    >;
    connectDataSourceMutation: UseMutationResult<
      LinkDataSourceReply.AsObject | undefined,
      unknown,
      {
        siteAlias: string;
        // TODO: figure out  type for dataSource
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataSource: any;
      },
      unknown
    >;
  };

export const useSession = (): DashboardSession => {
  const queryClient = useQueryClient();

  const siteAlias = getSiteAliasFromURL("/t");
  const queryKey = ["session", siteAlias];
  const sessionQuery = buildSessionQuery(queryKey, siteAlias);
  const sessionQueryResponse = useQuery<DashboardSessionData, Error>(
    sessionQuery
  );

  // This is helpful if something other than React Query updates the session state and
  // you want React Query to refetch to keep things in sync. This shouldn't be necessary after
  // we get everything off redux.
  const invalidateSessionQuery = async () =>
    queryClient.invalidateQueries({ queryKey });

  // When setting up a new account, the dashboard receives confirmation directly
  // from Stripe that the payment is complete. This can lead to a race condition where the dashboard
  // knows the payment is complete before our backend has processed the webhook event from
  // Stripe. Use this method after receiving signal from Stipe that the payment has been processed
  // so we don't need to wait for our backend to become consistent.
  const forceActivateBilling = () => {
    queryClient.setQueryData(queryKey, (oldData?: DashboardSessionData):
      | DashboardSessionData
      | undefined => {
      const newData = _.cloneDeep(oldData);
      if (!newData?.currentSite) {
        return;
      }

      newData.currentSite.billingAccountStatus =
        BillingAccount.Status.Option.ACTIVE;

      return newData;
    });
  };

  const updateSiteContactEmailMutation = useMutation({
    mutationFn: ({ email }: { email: string }) => {
      return updateSiteContactEmail(siteAlias, email);
    },
    onSuccess: updateEmailReply => {
      queryClient.setQueryData(queryKey, (oldData?: DashboardSessionData):
        | DashboardSessionData
        | undefined => {
        if (!oldData || !updateEmailReply) {
          return;
        }

        const newData = _.cloneDeep(oldData);
        if (!newData?.currentSite) {
          return;
        }

        newData.currentSite.siteContactEmail =
          updateEmailReply.siteContactEmail;
        return newData;
      });
    },
    onError: (e: Error) => {
      console.error(e.message);
    }
  });

  const updateOnlyAdminUsersCanEditCampaignsMutation = useMutation({
    mutationFn: ({
      onlyAdminUsersCanEditCampaigns
    }: {
      onlyAdminUsersCanEditCampaigns: boolean;
    }) => {
      return updateSiteUIBehavior({
        siteAlias,
        onlyAdminUsersCanEditStatuses: onlyAdminUsersCanEditCampaigns,
        onlyAdminUsersCanEditBids: onlyAdminUsersCanEditCampaigns,
        onlyAdminUsersCanEditBudgets: onlyAdminUsersCanEditCampaigns,
        onlyAdminUsersCanEditDetails: onlyAdminUsersCanEditCampaigns,
        onlyAdminUsersCanAddKeywords: onlyAdminUsersCanEditCampaigns,
        onlyAdminUsersCanAddCampaigns: onlyAdminUsersCanEditCampaigns
      });
    },
    onSuccess: updatedSiteUIBehavior => {
      queryClient.setQueryData(queryKey, (oldData?: DashboardSessionData):
        | DashboardSessionData
        | undefined => {
        if (!oldData || !updatedSiteUIBehavior) {
          return;
        }

        const newData = _.cloneDeep(oldData);
        if (!newData?.currentSite) {
          return;
        }

        newData.currentSite.siteUIBehavior = updatedSiteUIBehavior;
        return newData;
      });
    },
    onError: (e: Error) => {
      console.error(e.message);
    }
  });

  const updateSiteManagerLinkClientLabelsMutation = useMutation({
    mutationFn: ({
      managerSiteAlias,
      clientSiteAlias,
      addLabels,
      removeLabels
    }: {
      managerSiteAlias: string;
      clientSiteAlias: string;
      addLabels: Array<string>;
      removeLabels: Array<string>;
    }) => {
      return updateSiteManagerLinkClientLabels(
        managerSiteAlias,
        clientSiteAlias,
        addLabels,
        removeLabels
      );
    },
    onSuccess: updateLabelsReply => {
      queryClient.setQueryData(queryKey, (oldData?: DashboardSessionData):
        | DashboardSessionData
        | undefined => {
        if (!oldData || !updateLabelsReply) {
          return;
        }

        const newData = _.cloneDeep(oldData);

        newData.clientSitesList?.forEach(clientSite => {
          if (
            oldData.currentSite?.siteAlias ===
              updateLabelsReply.managerSiteAlias &&
            clientSite.clientSiteAlias === updateLabelsReply.clientSiteAlias
          ) {
            clientSite.detailsAboutClient =
              updateLabelsReply.detailsAboutClient;
          }
        });
        return newData;
      });
    },
    onError: (e: Error) => {
      console.error(e.message);
    }
  });

  const [linkGoogleAdsAccounts] = useGraphQLMutation(LinkGoogleAdsMutation);
  const updateLinkedGoogleAdsAccount = useMutation({
    //TODO (jake): Move this to an RPC and return the new adwords accounts in the response. Then
    // update the onSuccess callback to modify the currentSite object with the response instead
    // of refetching everything.
    mutationFn: async ({
      accessToken,
      siteAlias,
      newGoogleAdsCustomerId,
      newGoogleAdsManagerId
    }: {
      accessToken: string;
      siteAlias: string;
      newGoogleAdsCustomerId: string;
      newGoogleAdsManagerId: string;
    }) => {
      // If a manager ID is specified, then we need an access token to connect to it.
      if (!accessToken && newGoogleAdsManagerId) {
        throw new Error("Missing access token.");
      }

      // If a manager ID is not specified, then we should not have an accessToken because
      // the account should already be connected to MetricstoryRootManagerCustomerID.
      if (accessToken && !newGoogleAdsManagerId) {
        throw new Error("Missing manager id.");
      }

      if (!newGoogleAdsCustomerId) {
        throw new Error("Missing customer id.");
      }

      return await linkGoogleAdsAccounts({
        variables: {
          site: {
            siteAlias
          },
          accessToken,
          customerIds: [newGoogleAdsCustomerId],
          managerIds: newGoogleAdsManagerId ? [newGoogleAdsManagerId] : [],
          skipBilling: true,
          attribution: ""
        }
      });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (e: Error) => {
      console.error(e.message);
    }
  });

  const createAndLinkGoogleAdsAccount = useMutation({
    mutationFn: async ({
      siteAlias,
      googleAccountEmail,
      googleAccountName,
      timezone,
      currencyCode
    }: {
      siteAlias: string;
      googleAccountEmail: string;
      googleAccountName: string;
      timezone: string;
      currencyCode: string;
    }) => {
      const createAccountReq = proto.set(new CreateGoogleAdsAccountRequest(), {
        siteAlias,
        userEmail: googleAccountEmail,
        accountName: googleAccountName,
        timezone,
        currencyCode
      });

      const response = await GRPCWebClient.createGoogleAdsAccount(
        createAccountReq,
        {}
      );
      return response.toObject();
    },
    onSuccess: createGoogleAdsAccountReply => {
      queryClient.setQueryData(queryKey, (oldData?: DashboardSessionData):
        | DashboardSessionData
        | undefined => {
        if (!createGoogleAdsAccountReply.adwordsAccount) {
          return;
        }

        const newData = _.cloneDeep(oldData);
        if (!newData?.currentSite) {
          return;
        }

        newData.currentSite.adwordsAccounts = [
          ...newData.currentSite.adwordsAccounts,
          createGoogleAdsAccountReply.adwordsAccount
        ];

        return newData;
      });
    },
    onError: (e: Error) => {
      console.error(e.message);
    }
  });

  const disconnectDataSourceMutation = useMutation({
    mutationFn: async ({
      removeDataSourceReq
    }: {
      removeDataSourceReq: UnlinkDataSourceRequest.AsObject;
    }) => {
      return await removeSiteDataSourceLink(removeDataSourceReq);
    },
    onSuccess: async dataSourceReply => {
      queryClient.setQueryData(queryKey, (oldData?: DashboardSessionData):
        | DashboardSessionData
        | undefined => {
        if (!dataSourceReply) {
          return;
        }

        const newData = _.cloneDeep(oldData);
        if (!newData?.currentSite) {
          return;
        }

        newData.currentSite.adwordsAccounts =
          dataSourceReply.adwordsAccountsList;

        newData.currentSite.amazonInfo.advertisingAccounts =
          dataSourceReply.amazonAdvertisingAccountsList;

        newData.currentSite.amazonInfo.advertisingProfiles =
          dataSourceReply.amazonAdvertisingProfilesList;

        newData.currentSite.amazonInfo.sellerAccounts =
          dataSourceReply.amazonSellerAccountsList;

        newData.currentSite.amazonInfo.hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount = hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount(
          newData.currentSite.amazonInfo.advertisingAccounts,
          newData.currentSite.amazonInfo.advertisingProfiles
        );

        return newData;
      });
    },
    onError: (e: Error) => {
      console.error(e.message);
    }
  });

  const connectDataSourceMutation = useMutation({
    mutationFn: async ({
      siteAlias,
      dataSource
    }: {
      siteAlias: string;
      // TODO: figure out add type for dataSource
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataSource: any;
    }) => {
      return await addSiteDataSourceLink(siteAlias, dataSource);
    },
    onSuccess: dataSourceReply => {
      queryClient.setQueryData(queryKey, (oldData?: DashboardSessionData):
        | DashboardSessionData
        | undefined => {
        if (!dataSourceReply) {
          return;
        }

        const newData = _.cloneDeep(oldData);
        if (!newData?.currentSite) {
          return;
        }

        newData.currentSite.adwordsAccounts =
          dataSourceReply.adwordsAccountsList;
        newData.currentSite.amazonInfo.advertisingAccounts =
          dataSourceReply.amazonAdvertisingAccountsList;
        newData.currentSite.amazonInfo.advertisingProfiles =
          dataSourceReply.amazonAdvertisingProfilesList;
        newData.currentSite.amazonInfo.sellerAccounts =
          dataSourceReply.amazonSellerAccountsList;
        // TODO (jake): This derived value should not be held in the query cache, but rather derived fresh when used.
        newData.currentSite.amazonInfo.hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount = hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount(
          newData.currentSite.amazonInfo.advertisingAccounts,
          newData.currentSite.amazonInfo.advertisingProfiles
        );
        newData.currentSite.facebookAccount =
          dataSourceReply.facebookAccount ??
          new DashboardSite.DashboardFacebookAccountInfo().toObject();

        return newData;
      });
    }
  });

  return {
    ...sessionQueryResponse,
    currentSite: sessionQueryResponse.data?.currentSite,
    user: sessionQueryResponse.data?.user,
    managerSitesList: sessionQueryResponse.data?.managerSitesList,
    clientSitesList: sessionQueryResponse.data?.clientSitesList,
    updateSiteContactEmailMutation,
    updateOnlyAdminUsersCanEditCampaignsMutation,
    updateSiteManagerLinkClientLabelsMutation,
    updateLinkedGoogleAdsAccount,
    createAndLinkGoogleAdsAccount,
    invalidateSessionQuery,
    forceActivateBilling,
    disconnectDataSourceMutation,
    connectDataSourceMutation
  };
};

const buildSessionQuery = (queryKey: Array<string>, siteAlias: string) => {
  return {
    queryKey,
    staleTime: 5 * 60 * 1_000, // 5 minutes
    // This means that returned data should be largely stable even when parts change.
    structuralSharing: true,
    queryFn: async () => {
      const response = await getSession(siteAlias);
      const {
        user,
        currentSite,
        authorizedSitesList,
        clientSitesList,
        managerSitesList
      } = response;

      const isSuperUser =
        user?.globalRoleEnumOption === GlobalRole.Option.SUPERUSER;

      let maxClientBillingAccounts = currentSite?.clientBillingAccountMax || 0;
      if (maxClientBillingAccounts === -1) {
        maxClientBillingAccounts = Infinity;
      }

      const session: DashboardSessionData = {
        currentSite: {
          accountBudgetTargets: currentSite?.accountBudgetTargetsList || [],
          adwordsAccounts: currentSite?.adwordsAccountsList || [],
          adwordsCustomerIds: getAdwordsCustomerIds(currentSite),
          amazonInfo: {
            advertisingProfiles:
              currentSite?.amazonAdvertisingProfilesList || [],
            advertisingAccounts:
              currentSite?.amazonAdvertisingAccountsList || [],
            sellerAccounts: currentSite?.amazonSellerAccountsList || [],
            hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount: hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount(
              currentSite?.amazonAdvertisingAccountsList || [],
              currentSite?.amazonAdvertisingProfilesList || []
            )
          },
          walmartInfo: {
            walmartProfiles: currentSite?.walmartProfilesList || []
          },
          billingAccountStatus:
            currentSite?.billingAccountStatusEnumOption ||
            BillingAccount.Status.Option.UNKNOWN,
          billingAccountType:
            currentSite?.billingAccountType ||
            DashboardSite.BillingAccountType.Option.UNSPECIFIED,
          clientBillingAccountCount:
            currentSite?.clientBillingAccountCount || 0,
          clientBillingAccountMax: maxClientBillingAccounts,
          facebookAccount:
            currentSite?.facebookAccount ||
            new DashboardSite.DashboardFacebookAccountInfo().toObject(),
          hasAdwordsAccount: getHasAdwordsAccount(currentSite),

          siteAlias: currentSite?.alias ?? "",
          siteContactEmail: currentSite?.contactEmail ?? "",
          siteCurrencyCode: currentSite?.currencyCode ?? "",
          siteDomainName: currentSite?.domainName ?? "",
          siteFeatures:
            currentSite?.features ||
            new DashboardSite.DashboardFeatures().toObject(),
          siteUIBehavior:
            currentSite?.uiBehavior || new SiteDetails.UIBehavior().toObject(),
          siteId: currentSite?.id ?? "",
          siteName: currentSite?.name ?? "",
          subscription: currentSite?.ampdSubscription,
          hubSpotCompanyId: currentSite?.hubSpotCompanyId ?? "",
          minGmv: currentSite?.minGmvUsd ?? -1,
          organizationType:
            currentSite?.organizationType ??
            InternalSiteDetails.OrganizationType.Option.UNKNOWN
        },
        clientSitesList,
        managerSitesList,
        user: {
          authorizedSitesList,
          familyName: user?.familyName ?? "",
          givenName: user?.givenName ?? "",
          userEmail: user?.email ?? "",
          userId: user?.id ?? "",
          isCurrentSiteAdmin:
            getIsSiteAdmin(authorizedSitesList, currentSite?.alias ?? "") ||
            isSuperUser,
          isAmpdOperator: isSuperUser,
          uiSettings: user?.uiSettings ?? {
            dashboardIntroVideoDismissed: 0,
            ampdDashboardV2OptIn: false
          },
          userName: user?.name ?? ""
        }
      };

      return session;
    }
  };
};

const getAdwordsCustomerIds = (
  currentSite: DashboardSite.AsObject | undefined
) => {
  const accountList = currentSite?.adwordsAccountsList || [];
  return accountList.map(account => account.customerId);
};

const getHasAdwordsAccount = (
  currentSite: DashboardSite.AsObject | undefined
) => {
  if (!currentSite) {
    return false;
  }
  return currentSite.adwordsAccountsList.length > 0;
};

const getIsSiteAdmin = (
  authorizedSitesList: Array<
    GetDashboardSessionReply.SiteAuthorization.AsObject
  >,
  siteAlias: string
) => {
  const currentSiteAuthorization = (authorizedSitesList ?? []).find(
    site => site.siteAlias === siteAlias
  );
  if (!currentSiteAuthorization) {
    return false;
  }
  return currentSiteAuthorization.isAdmin;
};

// NOTE: For the time being, we are considering suitable amazon-enabled sites are
// those with profiles that have a list advertisers that we can use for
// attribution or those with all accounts in the FAR_EAST.
// We have had scammers creating NORTH_AMERICA accounts just so they could
// create Google Ads accounts.  However, advertisers in the FAR_EAST do not
// have the option to use attribution at all.  If the scammers realize they
// can create a FAR_EAST account, then we may have to close that door, too.
export const hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount = (
  amazonAdvertisingAccounts: Array<
    DashboardSite.DashboardAmazonAdvertisingAccountInfo.AsObject
  >,
  amazonAdvertisingProfiles: Array<Amazon.AdvertisingProfile.AsObject>
): boolean => {
  if (amazonAdvertisingAccounts.length === 0) {
    return false;
  }

  const hasAmazonAttributionAvailable = amazonAdvertisingProfiles?.some(
    profile => {
      return profile.advertisersList.length > 0;
    }
  );
  if (hasAmazonAttributionAvailable) {
    return true;
  }

  for (const account of amazonAdvertisingAccounts) {
    if (account.regionEnumOption != Amazon.Region.Option.FAR_EAST) {
      return false;
    }
  }

  return true;
};
