import { AMAZON_ATTRIBUTION_DELAY_DAYS } from "Common/utils/amazon";
import moment from "moment";
import React from "react";
import { Button, Checkbox, Popup } from "semantic-ui-react";

export const OptionsManagerModal = ({
  open,
  setOpen,
  showFractions,
  setShowFractions,
  excludeAmazonLagPeriod,
  setExcludeAmazonLagPeriod,
  respectAdAttributionDate,
  setRespectAdAttributionDate
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  showFractions: boolean;
  setShowFractions: (showFractions: boolean) => void;
  excludeAmazonLagPeriod: boolean;
  setExcludeAmazonLagPeriod?: (excludeAmazonLagPeriod: boolean) => void;
  respectAdAttributionDate?: boolean;
  setRespectAdAttributionDate?: (respectAdAttributionDate: boolean) => void;
}): JSX.Element => {
  const amazonIncompleteDataDate = moment()
    .startOf("day")
    .subtract(AMAZON_ATTRIBUTION_DELAY_DAYS + 1, "days");

  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      on="click"
      position="bottom right"
      trigger={<Button icon="sliders horizontal" />}
    >
      <div
        style={{
          width: "35rem"
        }}
      >
        <div>
          <Checkbox
            id="exclude-incomplete-data"
            toggle
            label={`Exclude incomplete data (after ${amazonIncompleteDataDate.format(
              "MMM Do YYYY"
            )}) *`}
            onChange={(_e, data) => {
              setExcludeAmazonLagPeriod &&
                setExcludeAmazonLagPeriod(data.checked ?? false);
            }}
            checked={excludeAmazonLagPeriod}
          />
        </div>
        <p style={{ margin: "0.25rem 0.5rem 1.5rem" }}>
          <i>
            Amazon can take up to 2 days to make sales data available. Data from
            today and yesterday should be considered incomplete.
          </i>
        </p>
        <div>
          {setRespectAdAttributionDate && (
            <Checkbox
              toggle
              label="Exclude data before ad attribution date"
              onChange={(_e, data) =>
                setRespectAdAttributionDate(data?.checked ?? false)
              }
              checked={respectAdAttributionDate}
            />
          )}
        </div>
        <p style={{ margin: "0.25rem 0.5rem 1.5rem" }}>
          <i>
            Excludes metrics that were collected before attribution was enabled
            for your ad. This will give you a more accurate representation of
            the performance of your ads.
          </i>
        </p>
        <Checkbox
          toggle
          label="Show fractions"
          onChange={(_e, data) => setShowFractions(data?.checked ?? false)}
          checked={showFractions}
        />
      </div>
    </Popup>
  );
};
