import moment from "moment";
import google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
import * as proto from "Common/utils/proto";
import * as datetime_pb from "Common/proto/common/datetime_pb";

/**
 * Returns the number of days that the dateDurationMillis represents.
 * @param {moment.DurationInputArg1}  dateDurationMillis
 * @returns {number}
 */
export const durationToDays = dateDurationMillis =>
  moment.duration(dateDurationMillis).asDays();

/**
 * Returns the number of weeks that the dateDurationMillis represents.
 * @param {moment.DurationInputArg1}  dateDurationMillis
 * @returns {number}
 */
export const durationToWeeks = dateDurationMillis =>
  Math.floor(moment.duration(dateDurationMillis).asWeeks());

/**
 * Returns the input moment formatted as an ISO8601 date string.
 * @param {moment.Moment}  input
 * @returns {string}
 */
export const asISO8601Date = input => input.format("YYYY-MM-DD");

/**
 * Returns the input moment formatted to be more presentable in UI.
 * @param {moment.Moment}  date
 * @returns {string}
 */
export const asPrettyDate = date => date.format("ddd, MMM Do YYYY");

/**
 * Returns the input moment formatted to show like: "Apr 10th 2022"
 * @param {moment.Moment}  date
 * @returns {string}
 */
export const asPrettyDateNoDay = date => date.format("MMM Do YYYY");

/**
 * Returns date from ISO8601 state string.
 * @param {string}  string
 * @returns {moment.Moment}
 */
export const fromISO8601Date = string => moment.utc(string);

// Returns a moment based on a UTC GraphQL DateTime object.
export const momentFromUTCGraphQLDateTime = ({
  date: { year, month, day },
  time: { hour, minute, second }
}) =>
  moment.utc(
    `${year}-${month}-${day} ${hour}:${minute}:${second}`,
    "YYYY-M-D HH:m:s"
  );

// Returns a moment based on a non-null timestamp proto.  Returns
// null for null.
// NOTE: Javascript only keep times down to the millisecond,
// so finer grain time units are lost.
export const momentFromTimestampProto = proto => {
  if (!proto) {
    return null;
  }
  return moment.utc(proto.getSeconds() * 1000 + proto.getNanos() * 1e-6);
};

// Returns a moment based on a non-null timestamp proto that has already been
// turned into a JSON object (usually with the proto's .toObject()).  Returns
// null for null.
// NOTE: Javascript only keep times down to the millisecond,
// so finer grain time units are lost.
export const momentFromTimestampProtoJSON = proto => {
  if (!proto) {
    return null;
  }
  return moment.utc(proto.seconds * 1000 + proto.nanos * 1e-6);
};

// Returns a timestamp proto message based on the specified moment object,
// if non-null.  Returns null for null.
// NOTE: Javascript only keep times down to the millisecond,
// so finer grain time units are lost.
export const momentToTimestampProto = time => {
  if (!time) {
    return null;
  }
  const milliseconds = time.valueOf();
  const seconds = Math.floor(milliseconds / 1000);
  const nanos = (milliseconds - seconds * 1000) * 1e6;

  return proto.set(new google_protobuf_timestamp_pb.Timestamp(), {
    seconds,
    nanos
  });
};

// Returns a moment based on a non-null common.Date proto.  Returns
// null for null.
export const momentFromCommonDateProto = proto => {
  if (!proto) {
    return null;
  }
  return moment(new Date(proto.year, proto.month - 1, proto.day));
};

export function buildDateRangeV2Proto(startDate, endDate) {
  if (!startDate || !endDate) {
    return {};
  }

  const start = moment(startDate);
  const end = moment(endDate);

  const dateRangeProto = proto.set(new datetime_pb.DateRangeV2(), {
    startDate: proto.set(new datetime_pb.Date(), {
      year: start.year(),
      month: start.month() + 1, // month() is zero-based
      day: start.date()
    }),
    endDate: proto.set(new datetime_pb.Date(), {
      year: end.year(),
      month: end.month() + 1, // month() is zero-based
      day: end.date()
    })
  });

  return { dateRangeProto };
}

const dateRangeFormatter = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "short",
  day: "numeric",
  timezone: "UTC"
});

const monthRangeFormatter = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "short",
  timezone: "UTC"
});

/**
 * Returns a formatted string for the specified date range.
 * @param {string|moment.Moment}  startDate
 * @param {string|moment.Moment}  endDate
 * @returns {string}
 */
export function formatDateRange(startDate, endDate) {
  const startMoment = moment(startDate).startOf("day");
  const endMoment = moment(endDate).endOf("day");

  const start = new Date(
    startMoment.year(),
    startMoment.month(),
    startMoment.date()
  );
  const end = new Date(endMoment.year(), endMoment.month(), endMoment.date());

  let formatter = dateRangeFormatter;
  if (
    startMoment.isSame(moment(startDate).startOf("month")) &&
    endMoment.isSame(moment(endDate).endOf("month"))
  ) {
    formatter = monthRangeFormatter;
  }

  return formatter.formatRange(start, end);
}
