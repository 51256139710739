import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetGoogleAdsAccessibleAccountsForUserRequest,
  GoogleAdsCustomerAccount
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";

export const useGoogleAdsAccessibleAccountsForUser = ({
  siteAlias,
  accessToken
}: {
  siteAlias: string;
  accessToken: string;
}): UseQueryResult<Array<GoogleAdsCustomerAccount.AsObject>, unknown> => {
  return useQuery({
    enabled: !!siteAlias && !!accessToken,
    queryKey: ["googleAdsAccessibleAccountsForUser", siteAlias, accessToken],
    staleTime: 10 * 60 * 1_000, // 10 minutes
    queryFn: async () => {
      const request = new GetGoogleAdsAccessibleAccountsForUserRequest();
      request.setSiteAlias(siteAlias);
      request.setAccessToken(accessToken);

      const reply = await GRPCWebClient.getGoogleAdsAccessibleAccountsForUser(
        request,
        {}
      );
      return reply.toObject().accountsList;
    }
  });
};
