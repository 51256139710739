import React, { useRef, useState } from "react";
import {
  MutateFacebookBudgetProps,
  useUpdateFacebookBidAmount,
  useUpdateFacebookDailyBudget,
  useUpdateFacebookLifetimeBudget
} from "ExtensionV2/queries/useUpdateFacebookStatus";
import { extractErrorMessage } from "Common/errors/error";
import { toast } from "react-toastify";
import { Icon, Input, InputOnChangeData } from "semantic-ui-react";
import styled from "styled-components";
import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";
import { UseMutationResult } from "@tanstack/react-query";
import { DashboardTable } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";

// TODO handle currencies with different offsets (e.g. JPY offset is 1)
export const USD_OFFSET = 100;

export const NumberInputNoSpinners = styled(Input)`
  &&& {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const EditFacebookBidAmount = ({
  currency,
  dailyBudgetCents,
  resourceId,
  rollupLevel
}: {
  currency: string;
  dailyBudgetCents: number;
  resourceId: string;
  rollupLevel: DashboardTable.ObjectType.Option;
}): JSX.Element => {
  const updateBidAmountMutation = useUpdateFacebookBidAmount(rollupLevel);

  if (rollupLevel === DashboardTable.ObjectType.Option.FACEBOOK_AD) {
    return (
      <>
        {formatMetric(
          getCurrencyMetricDef(currency, true),
          dailyBudgetCents / USD_OFFSET
        )}
      </>
    );
  }

  return (
    <EditFacebookBudget
      currency={currency}
      dailyBudgetCents={dailyBudgetCents}
      resourceId={resourceId}
      updateBudgetMutation={updateBidAmountMutation}
    />
  );
};

export const EditFacebookLifetimeBudget = ({
  currency,
  dailyBudgetCents,
  resourceId,
  rollupLevel
}: {
  currency: string;
  dailyBudgetCents: number;
  resourceId: string;
  rollupLevel: DashboardTable.ObjectType.Option;
}): JSX.Element => {
  const updateDailyBudgetMutation = useUpdateFacebookLifetimeBudget(
    rollupLevel
  );

  return (
    <EditFacebookBudget
      currency={currency}
      dailyBudgetCents={dailyBudgetCents}
      resourceId={resourceId}
      updateBudgetMutation={updateDailyBudgetMutation}
    />
  );
};

export const EditFacebookDailyBudget = ({
  currency,
  dailyBudgetCents,
  resourceId,
  rollupLevel
}: {
  currency: string;
  dailyBudgetCents: number;
  resourceId: string;
  rollupLevel: DashboardTable.ObjectType.Option;
}): JSX.Element => {
  const updateDailyBudgetMutation = useUpdateFacebookDailyBudget(rollupLevel);

  return (
    <EditFacebookBudget
      currency={currency}
      dailyBudgetCents={dailyBudgetCents}
      resourceId={resourceId}
      updateBudgetMutation={updateDailyBudgetMutation}
    />
  );
};

const EditFacebookBudget = ({
  currency,
  dailyBudgetCents,
  resourceId,
  updateBudgetMutation
}: {
  currency: string;
  dailyBudgetCents: number;
  resourceId: string;
  updateBudgetMutation: UseMutationResult<
    void,
    unknown,
    MutateFacebookBudgetProps,
    unknown
  >;
}) => {
  // Use a sting here so 110 will be 1.10 instead of 1.1
  const initialOffsetDailyBudget = (
    Math.round(dailyBudgetCents) / USD_OFFSET
  ).toFixed(2);
  const [inputValue, setInputValue] = useState(initialOffsetDailyBudget);
  const [isEditing, setIsEditing] = useState(false);
  // Ref to the input so we can focus it when editing
  const budgetInputRef = useRef<HTMLInputElement>(null);

  const handleCancel = () => {
    setInputValue(initialOffsetDailyBudget);
    setIsEditing(false);
  };

  const handleBlur = () => {
    if (inputValue === "") {
      setIsEditing(false);
      return;
    }

    if (inputValue === initialOffsetDailyBudget) {
      setIsEditing(false);
      return;
    }

    updateBudgetMutation.mutate(
      {
        resourceId,
        offsetDailyBudget: inputValue
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
        onError: err => {
          console.error(extractErrorMessage(err));
          toast.error(extractErrorMessage(err));
          handleCancel();
        }
      }
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleBlur();
    } else if (event.key === "Escape") {
      handleCancel();
    }
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: InputOnChangeData
  ) => {
    event.preventDefault();
    // don't allow more than 2 decimal places
    if (data.value.split(".")[1]?.length > 2) {
      return;
    }
    setInputValue(data.value);
  };

  return isEditing ? (
    <NumberInputNoSpinners
      ref={budgetInputRef}
      icon={<Icon name="cancel" color="grey" onClick={handleCancel} link />}
      style={{
        maxWidth: "7em"
      }}
      type="number"
      min="0"
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      value={inputValue}
      loading={updateBudgetMutation.isLoading}
    />
  ) : (
    <div
      style={{
        minWidth: "7em"
      }}
    >
      {formatMetric(
        getCurrencyMetricDef(currency, true),
        initialOffsetDailyBudget
      )}
      <Icon
        name="pencil"
        style={{ marginLeft: "0.5em" }}
        onClick={() => {
          setIsEditing(true);
          // run this in the next tick so the input is rendered
          // and inputRef.current is set
          setTimeout(() => {
            budgetInputRef.current?.focus();
          }, 0);
        }}
      />
    </div>
  );
};
