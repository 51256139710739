import React, { useState } from "react";
import { Message } from "semantic-ui-react";
import { CampaignReviewSection } from "./ReviewCampaignsStage";
import {
  CampaignSetupAction,
  CampaignSetupState,
  FormField,
  getCurrentCampaignAndSelectors
} from "./CampaignSetupPageState";
import { isValidItemID, walmartUSMarketplaceInfo } from "Common/utils/walmart";
import { WalmartTargetSelectorDropdown } from "ExtensionV2/components/WalmartTargetSelector";

const walmartItemIdValidator = (itemId: string): Set<string> => {
  const errors = new Set<string>();
  if (!itemId) {
    return errors;
  }

  if (!isValidItemID(itemId)) {
    errors.add("Please enter a valid item id");
  }

  return errors;
};

export const walmartItemIdField = (
  initialValue: string
): FormField<string> => ({
  _value: initialValue,
  errors: walmartItemIdValidator(initialValue),
  validator: walmartItemIdValidator
});

export const WalmartTargetReviewSection = ({
  state,
  dispatch,
  onEdit
}: {
  state: CampaignSetupState;
  dispatch: React.Dispatch<CampaignSetupAction>;
  onEdit: (dataName: string | null) => void;
}): JSX.Element => {
  const { currentReviewCampaignIndex } = state;
  const [
    campaign,
    {
      selectSeedKeywords,
      selectWalmartItemId,
      selectMarketplaceInfo,
      selectWalmartProfileName,
      selectWalmartBrands,
      selectWalmartSearchPage,
      selectWalmartSearchPhrase
    }
  ] = getCurrentCampaignAndSelectors(state);

  const marketplaceInfo = selectMarketplaceInfo(campaign, state);
  const itemId = selectWalmartItemId(campaign);
  const walmartProfileName = selectWalmartProfileName(campaign);
  const brands = selectWalmartBrands(campaign);
  const searchPhrase = selectWalmartSearchPhrase(campaign);
  const searchPage = selectWalmartSearchPage(campaign);
  const seedKeywords = selectSeedKeywords(campaign);

  const [tempItemId, setTempItemId] = useState(itemId);
  const [tempFollowOnSearchPhrase, setTempFollowOnSearchPhrase] = useState(
    seedKeywords[0] || ""
  );
  const [tempSearchPage, setTempSearchPage] = useState(searchPage);
  const [localErrors, setLocalErrors] = useState<Set<string>>(
    new Set([
      ...campaign.walmartItemIdField.errors,
      ...campaign.walmartSearchPageField.errors
    ])
  );

  const dataComponent = <p>{itemId || searchPhrase || "CUSTOM"}</p>;

  const editComponent = (
    <>
      <p>
        <i>Enter an item ID or search phrase</i>
      </p>
      <WalmartTargetSelectorDropdown
        marketplaceInfo={marketplaceInfo || walmartUSMarketplaceInfo}
        profileName={walmartProfileName}
        itemId={tempItemId}
        searchPage={tempSearchPage}
        brands={brands}
        onSelectItemId={(newItemId: string, newFollowOnSearchPhrase) => {
          setLocalErrors(campaign.walmartItemIdField.validator(newItemId));
          setTempItemId(newItemId);
          setTempFollowOnSearchPhrase(newFollowOnSearchPhrase);
          setTempSearchPage("");
        }}
        onSelectSearchPage={newSearchPage => {
          setLocalErrors(
            campaign.walmartSearchPageField.validator(newSearchPage)
          );
          setTempItemId("");
          setTempSearchPage(newSearchPage);
        }}
        searchData={undefined}
      />
      {localErrors.size > 0 && (
        <Message negative>
          {Array.from(localErrors).map(error => (
            <p key={error}>{error}</p>
          ))}
        </Message>
      )}
    </>
  );

  const handleSave = () => {
    dispatch({
      name: "UpdateWalmartItemId",
      data: {
        index: currentReviewCampaignIndex,
        itemId: tempItemId,
        followOnSearchPhrase: tempFollowOnSearchPhrase
      }
    });
    dispatch({
      name: "UpdateWalmartSearchPage",
      data: {
        index: currentReviewCampaignIndex,
        searchPage: tempSearchPage
      }
    });
  };

  const handleCancel = () => {
    setTempItemId(itemId);
    setTempSearchPage(searchPage);
  };

  return (
    <CampaignReviewSection
      dataName={itemId ? "Walmart Item ID" : "Walmart Search Phrase"}
      dataComponent={dataComponent}
      editComponent={editComponent}
      disableSave={localErrors.size > 0}
      errors={
        new Set([
          ...campaign.walmartItemIdField.errors,
          ...campaign.walmartSearchPageField.errors
        ])
      }
      onEdit={onEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};
