import { None, removeNullAndUndefined } from "Common/utils/tsUtils";
import { ampdRed, semanticBorder } from "ExtensionV2/styles/colors";
import _ from "lodash";
import moment from "moment";
import React, { JSX, useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownMenu,
  Icon,
  Input,
  Popup
} from "semantic-ui-react";
import styled from "styled-components";
import { Column } from "./column";
import { FilterArguments, MatchOperator } from "./filters";
import { MetricsFilter, MetricsTableData } from "./MetricsTable";

export const FILTER_PILL_BORDER_RADIUS_REMS = 0.75;

export const StyledFilterManager = styled.div`
  padding: 0.25rem;
  &.expanded {
    border: 1px solid ${semanticBorder};
    border-radius: ${FILTER_PILL_BORDER_RADIUS_REMS + 0.25}rem;

    .accordion.ui {
      .title {
        background-color: white;
        width: fit-content;
      }
      .content.active {
        padding: 0;
      }
    }
  }

  &.collapsed {
    border: none;
  }

  .accordion.ui {
    .title {
      margin: -1rem 0 0 0.5rem;
      padding: 0;
      font-style: italic;
      cursor: pointer;
    }
  }
`;

const StyledFilterPillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

const StyledFilterPill = styled.div`
  margin-top: 0.5rem;
  position: relative;
  border-radius: ${FILTER_PILL_BORDER_RADIUS_REMS}rem;
  padding: 0.5rem;
  height: 2lh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0 1rem !important;
  border: 1px solid ${ampdRed};
  :hover {
    border: 1px dashed ${ampdRed};
  }

  > p {
    font-style: italic;
    margin: 0;
  }

  :hover {
    .filter-pill-close {
      display: block;
      opacity: 1;
    }

    p {
      opacity: 0.5;
    }
  }

  .filter-pill-close {
    opacity: 0.7;
    cursor: pointer;
    margin-bottom: 0.25rem;
  }
`;

// This window is absolutely positioned so it can float above the table and
// mimic a dropdown. The width must be calculated based on the length of the column
// name.
const StyledCreateFilterWindow = styled.div<{ labelChars: number }>`
  width: ${props => props.labelChars + 55}ch;
  z-index: 2;
  background-color: white;

  > div {
    gap: 0.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;

    p {
      margin: 0;
      font-size: 1.2rem;
    }
  }

  .number-filter-value-input {
    width: 8rem;
  }
`;

export const FilterManager = <T extends MetricsTableData>({
  columns,
  filters,
  onSetFilters,
  initialSelectedColumn
}: {
  columns: Array<Column<T>>;
  filters: Array<MetricsFilter<T>>;
  onSetFilters: (filters: Array<MetricsFilter<T>>) => void;
  initialSelectedColumn?: Column<T> | None;
}): JSX.Element => {
  const [selectedColumn, setSelectedColumn] = useState<Column<T> | None>(
    initialSelectedColumn
  );

  const handleSaveFilter = (column: Column<T>, args: FilterArguments) => {
    onSetFilters([...filters, { column, args: args }]);
  };

  return (
    <Popup
      open={selectedColumn != null}
      onClose={() => setSelectedColumn(null)}
      on="click"
      position="bottom right"
      trigger={
        <FilterColumnsDropdown
          columns={columns}
          selectedColumn={selectedColumn}
          onSelectColumn={setSelectedColumn}
        />
      }
    >
      <NewFilterWindow
        column={selectedColumn}
        onClose={() => setSelectedColumn(null)}
        onSaveFilter={handleSaveFilter}
      />
    </Popup>
  );
};

export const FilterColumnsDropdown = <T extends MetricsTableData>({
  columns,
  selectedColumn,
  onSelectColumn
}: {
  columns: Array<Column<T>>;
  selectedColumn?: Column<T> | None;
  onSelectColumn: (column: Column<T>) => void;
}): JSX.Element => {
  const [optionsFilterVal, setOptionsFilterVal] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Focus the search input when the dropdown is opened
  const filterSearchRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (dropdownOpen && filterSearchRef.current) {
      filterSearchRef.current.focus();
    }
  }, [dropdownOpen]);

  const dropdownOptions = columns
    .map(column => {
      if (column.FilterUI == undefined) {
        return;
      }

      return {
        key: String(column.dataName),
        text: column.displayName,
        value: String(column.dataName)
      };
    })
    .filter(column =>
      column?.text.toLowerCase().includes(optionsFilterVal.toLowerCase())
    )
    .filter(removeNullAndUndefined);

  return (
    <div>
      <Dropdown
        disabled={selectedColumn != null}
        value={String(selectedColumn?.dataName)}
        button
        className="icon"
        floating
        icon="filter"
        text={" "}
        open={dropdownOpen}
        onClick={_ev => {
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <DropdownMenu>
          <Input
            placeholder="Search Columns"
            onClick={(ev: React.MouseEvent<HTMLInputElement>) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onChange={ev => {
              setOptionsFilterVal(ev.currentTarget.value);
            }}
            value={optionsFilterVal}
          >
            <input ref={filterSearchRef} />
          </Input>
          <DropdownMenu scrolling>
            {dropdownOptions.map(option => (
              <Dropdown.Item
                key={option.key}
                text={option.text}
                value={option.value}
                onClick={() => {
                  const column: Column<T> | undefined = columns.find(
                    c => String(c.dataName) === option.value
                  );
                  if (column) {
                    setOptionsFilterVal("");
                    onSelectColumn(column);
                  }
                }}
              />
            ))}
          </DropdownMenu>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const NewFilterWindow = <T extends MetricsTableData>({
  column,
  onClose,
  onSaveFilter
}: {
  column: Column<T> | None;
  onClose: () => void;
  onSaveFilter: (column: Column<T>, args: FilterArguments) => void;
}) => {
  const [filterArgs, setFilterArgs] = useState<FilterArguments>({});

  const handleSaveFilterArgs = useCallback(() => {
    if (_.isEmpty(filterArgs)) {
      return;
    }

    if (!column) {
      return;
    }

    onSaveFilter(column, filterArgs);
    onClose();
  }, [column, filterArgs, onClose, onSaveFilter]);

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (ev.key === "Enter") {
        handleSaveFilterArgs();
      }
      if (ev.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, handleSaveFilterArgs]);

  if (!column) {
    return <></>;
  }

  return (
    <StyledCreateFilterWindow labelChars={column.displayName.length}>
      <p style={{ fontSize: "1rem", fontStyle: "italic" }}>New filter:</p>
      <div>
        <p style={{ display: "inline" }}>{column.displayName}</p>

        {column.FilterUI != undefined &&
          column.FilterUI(filterArgs, setFilterArgs)}

        <div>
          <Button
            aria-label="Save filter"
            icon="check"
            color="green"
            onClick={() => {
              handleSaveFilterArgs();
            }}
          />
          <Button
            aria-label="Close filter window"
            icon="close"
            onClick={onClose}
          />
        </div>
      </div>
    </StyledCreateFilterWindow>
  );
};

const timestampRegexp = /^\d{13}$/;

const FilterPill = <T extends MetricsTableData>({
  filter,
  onRemoveFilter
}: {
  filter: MetricsFilter<T>;
  onRemoveFilter: (filter: MetricsFilter<T>) => void;
}): JSX.Element => {
  let filterArgsString = "";
  if (filter.args.text != undefined) {
    switch (filter.args.textMatch) {
      case MatchOperator.CONTAINS:
        filterArgsString = `contains "${filter.args.text}"`;
        break;
      case MatchOperator.DOES_NOT_CONTAIN:
        filterArgsString = `does not contain "${filter.args.text}"`;
        break;
      case MatchOperator.STARTS_WITH:
        filterArgsString = `starts with "${filter.args.text}"`;
        break;
      case MatchOperator.ENDS_WITH:
        filterArgsString = `ends with "${filter.args.text}"`;
        break;
      case MatchOperator.EQUALS:
        filterArgsString = `is "${filter.args.text}"`;
        break;
      case MatchOperator.NOT_EQUALS:
        filterArgsString = `is not "${filter.args.text}"`;
        break;
      case MatchOperator.REGEXP:
        filterArgsString = `matches regex "${filter.args.text}"`;
        break;
      default:
        filterArgsString = `contains "${filter.args.text}"`;
    }
  } else if (filter.args.min != undefined && filter.args.max != undefined) {
    if (filter.args.min === filter.args.max) {
      filterArgsString = `= ${filter.args.min}`;
    } else {
      filterArgsString = ` > ${filter.args.min} and < ${filter.args.max}`;
    }
  } else if (filter.args.min != undefined) {
    // If the min is a timestamp, convert it to a date string
    if (timestampRegexp.test(String(filter.args.min))) {
      filterArgsString = ` after ${moment(Number(filter.args.min)).format(
        "YYYY-MM-DD"
      )}`;
    } else {
      filterArgsString = ` > ${filter.args.min}`;
    }
  } else if (filter.args.max != undefined) {
    // If the max is a timestamp, convert it to a date string
    if (timestampRegexp.test(String(filter.args.max))) {
      filterArgsString = ` before ${moment(Number(filter.args.max)).format(
        "YYYY-MM-DD"
      )}`;
    } else {
      filterArgsString = ` < ${filter.args.max}`;
    }
  } else {
    return <></>;
  }

  return (
    <>
      <StyledFilterPill>
        <p style={{ display: "inline" }}>
          {filter.column.displayName} {filterArgsString}
        </p>
        <Icon
          aria-label="Remove filter"
          className="filter-pill-close"
          name="close"
          onClick={() => {
            onRemoveFilter(filter);
          }}
        />
      </StyledFilterPill>
    </>
  );
};

export const FilterContainer = <T extends MetricsTableData>({
  filters,
  onSetFilters
}: {
  filters: Array<MetricsFilter<T>>;
  onSetFilters: (filters: Array<MetricsFilter<T>>) => void;
}): JSX.Element => {
  if (!filters.length) {
    return <></>;
  }

  return (
    <StyledFilterPillContainer>
      {filters.map((filter, i) => {
        return (
          <React.Fragment key={i}>
            <FilterPill
              filter={filter}
              onRemoveFilter={removedFilter => {
                const newFilters = filters.filter(
                  activeFilter => activeFilter !== removedFilter
                );
                onSetFilters(newFilters);
              }}
              key={String(filter.column.dataName)}
            />{" "}
            {i < filters.length - 1 && "and"}
          </React.Fragment>
        );
      })}
    </StyledFilterPillContainer>
  );
};
