import { useMemo } from "react";

import { useQueries, UseQueryOptions } from "@tanstack/react-query";
import { None } from "Common/utils/tsUtils";
import Immutable from "immutable";
import {
  DashboardTableMetrics,
  DashboardTableMetricsByCampaignIdByObjectType,
  DashboardTableObjectType,
  getDashboardTableMetricsQueryArgs
} from "ExtensionV2/queries/useDashboardTableMetrics";
import { Retailer } from "Common/proto/common/retailer_pb";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import useCancelInactiveQueries from "ExtensionV2/state/useCancelInactiveQueries";
import useMakeQueriesStable from "ExtensionV2/state/useMakeQueriesStable";

function useMultiSiteAmpdCampaignMetrics({
  siteAliasesWithGoogleAds,
  siteAliasesWithFacebook,
  queryGroup,
  retailer = Retailer.Option.UNKNOWN,
  campaignIdsBySiteMap,
  dateRangeStartDate,
  dateRangeEndDate,
  queryByDay = false
}: {
  siteAliasesWithGoogleAds: Array<string>;
  siteAliasesWithFacebook: Array<string>;
  queryGroup: string;
  retailer?: Retailer.Option;
  campaignIdsBySiteMap: Immutable.Map<string, string[]>;
  dateRangeStartDate: string | None;
  dateRangeEndDate: string | None;
  queryByDay?: boolean;
}): {
  sitesLoadingSet: Immutable.Set<string>;
  sitesErrorMap: Immutable.Map<string, unknown>;
  campaignMetricsMap: Immutable.Map<string, DashboardTableMetrics>;
} {
  const oneHour = 1000 * 60 * 60; //one hour

  const siteAliasesWithGoogleAdsAndCampaigns = useMemo(
    () =>
      siteAliasesWithGoogleAds.filter(
        siteAlias => !!campaignIdsBySiteMap.get(siteAlias)?.length
      ),
    [siteAliasesWithGoogleAds, campaignIdsBySiteMap]
  );
  const siteAliasesWithFacebookAndCampaigns = useMemo(
    () =>
      siteAliasesWithFacebook.filter(
        siteAlias => !!campaignIdsBySiteMap.get(siteAlias)?.length
      ),
    [siteAliasesWithFacebook, campaignIdsBySiteMap]
  );

  const [metricQueries, siteAliasesOfQueries] = useMemo(() => {
    const objectTypes = [DashboardTableObjectType.CAMPAIGN];
    const aliasesOfQueries: Array<string> = [];
    const queries: Array<UseQueryOptions<
      DashboardTableMetricsByCampaignIdByObjectType,
      unknown
    >> = [];
    for (const siteAlias of siteAliasesWithGoogleAdsAndCampaigns) {
      if (
        retailer === Retailer.Option.AMAZON ||
        retailer === Retailer.Option.UNKNOWN
      ) {
        const queryArgs = getDashboardTableMetricsQueryArgs({
          queryName: `${queryGroup}:googleAds:amazon:multiCampaignConfigurations`,
          siteAlias,
          campaignPlatform: CampaignPlatform.Option.GOOGLE_ADS,
          retailer: Retailer.Option.AMAZON,
          objectTypes,
          dateRangeStartDate,
          dateRangeEndDate,
          campaignIds: campaignIdsBySiteMap.get(siteAlias, []),
          queryByDay,
          staleTime: oneHour,
          cacheTime: oneHour
        });

        if (queryArgs.enabled) {
          aliasesOfQueries.push(siteAlias);
          queries.push(queryArgs);
        }
      }
      if (
        retailer === Retailer.Option.WALMART ||
        retailer === Retailer.Option.UNKNOWN
      ) {
        const queryArgs = getDashboardTableMetricsQueryArgs({
          queryName: `${queryGroup}:googleAds:walmart:multiCampaignConfigurations`,
          siteAlias,
          campaignPlatform: CampaignPlatform.Option.GOOGLE_ADS,
          retailer: Retailer.Option.WALMART,
          objectTypes,
          dateRangeStartDate,
          dateRangeEndDate,
          campaignIds: campaignIdsBySiteMap.get(siteAlias, []),
          queryByDay,
          staleTime: oneHour,
          cacheTime: oneHour
        });

        if (queryArgs.enabled) {
          aliasesOfQueries.push(siteAlias);
          queries.push(queryArgs);
        }
      }
    }
    for (const siteAlias of siteAliasesWithFacebookAndCampaigns) {
      if (
        retailer === Retailer.Option.AMAZON ||
        retailer === Retailer.Option.UNKNOWN
      ) {
        const queryArgs = getDashboardTableMetricsQueryArgs({
          queryName: `${queryGroup}:facebook:amazon:multiCampaignConfigurations`,
          siteAlias,
          campaignPlatform: CampaignPlatform.Option.FACEBOOK,
          retailer: Retailer.Option.AMAZON,
          objectTypes,
          dateRangeStartDate,
          dateRangeEndDate,
          campaignIds: campaignIdsBySiteMap.get(siteAlias, []),
          queryByDay,
          staleTime: oneHour,
          cacheTime: oneHour
        });

        if (queryArgs.enabled) {
          aliasesOfQueries.push(siteAlias);
          queries.push(queryArgs);
        }
      }
      // TODO: Facebook to Walmart not yet supported.
    }

    return [queries, aliasesOfQueries];
  }, [
    siteAliasesWithGoogleAdsAndCampaigns,
    siteAliasesWithFacebookAndCampaigns,
    campaignIdsBySiteMap,
    dateRangeEndDate,
    dateRangeStartDate,
    oneHour,
    queryByDay,
    queryGroup,
    retailer
  ]);

  const results = useMakeQueriesStable(useQueries({ queries: metricQueries }));
  useCancelInactiveQueries(metricQueries);

  const returnValue = useMemo(() => {
    let sitesLoadingSet = Immutable.Set<string>();
    let sitesErrorMap = Immutable.Map<string, unknown>();
    let campaignMetricsMap = Immutable.Map<string, DashboardTableMetrics>();

    results.forEach((result, resultIndex) => {
      const siteAlias = siteAliasesOfQueries[resultIndex];

      if (result.isLoading) {
        sitesLoadingSet = sitesLoadingSet.add(siteAlias);
      }
      if (result.error) {
        sitesErrorMap = sitesErrorMap.set(siteAlias, result.error);
      }
      if (result.data) {
        const campaignData =
          result.data[DashboardTableObjectType.CAMPAIGN] || {};
        for (const [campaignId, metricsList] of Object.entries(campaignData)) {
          campaignMetricsMap = campaignMetricsMap.set(
            campaignId,
            metricsList[0]
          );
        }
      }
    });

    return {
      sitesLoadingSet,
      sitesErrorMap,
      campaignMetricsMap
    };
  }, [results, siteAliasesOfQueries]);

  return returnValue;
}

export default useMultiSiteAmpdCampaignMetrics;
