import moment from "moment";

import React from "react";

import {
  CHECKED_COL_WIDTH,
  CHECKED_COL,
  ACCOUNT_NAME_COL,
  ACCOUNT_ID_COL,
  BILLING_STATUS_COL,
  CAMPAIGNS_COL,
  getClientSiteLabels
} from "./AccountsTable";

import {
  DataTableFreezeLeftCell,
  DataTableRow,
  DataTableRowCell
} from "../../components/AmpdDataTable";

import { isValidEmail } from "Common/utils/email";
import { LinkToClient } from "./AccountsTableCampaignRow";
import { SelectedPhrase } from "../../components/PhraseSelector";
import HubspotLink from "./HubspotLink";
import { AccountIDsTableCellContent } from "./AccountsTableRow";

function AccountsTableSubClientRow({
  subClientSiteAlias,
  subClientRowData,
  rowIndex,
  columns,
  campaignTypeFilter,
  isLastRow,
  showHubspotLinks
}) {
  const subClientColumns = [
    CHECKED_COL,
    ACCOUNT_NAME_COL,
    ACCOUNT_ID_COL,
    BILLING_STATUS_COL
  ];

  return (
    <DataTableRow
      key={rowIndex}
      style={{ fontSize: "small" }}
      isLastKeywordRow={isLastRow}
    >
      {columns.map(columnName => {
        switch (columnName) {
          case CHECKED_COL:
            return (
              <DataTableFreezeLeftCell
                style={{ fontSize: "16px" }}
                key={columnName}
                rowIndex={rowIndex}
              />
            );
          case ACCOUNT_NAME_COL:
            return (
              <DataTableFreezeLeftCell
                style={{ left: CHECKED_COL_WIDTH }}
                key={columnName}
                rowIndex={rowIndex}
              >
                <LinkToClient
                  clientSiteAlias={subClientSiteAlias}
                  campaignTypeFilter={campaignTypeFilter}
                >
                  {subClientRowData[columnName]}
                </LinkToClient>
                {showHubspotLinks && (
                  <HubspotLink
                    siteName={subClientRowData[columnName]}
                    hubspotCompanyId={subClientRowData.hubSpotCompanyId}
                  />
                )}
              </DataTableFreezeLeftCell>
            );
          case ACCOUNT_ID_COL:
            return (
              <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                <AccountIDsTableCellContent
                  campaignTypeFilter={campaignTypeFilter}
                  rowData={subClientRowData}
                />
              </DataTableRowCell>
            );
          case BILLING_STATUS_COL: {
            let status = subClientRowData[BILLING_STATUS_COL];
            if (status === "active" && subClientRowData.billingCanceledTime) {
              if (subClientRowData.billingActiveUntilTime) {
                status = `${status} until ${moment
                  .unix(subClientRowData.billingActiveUntilTime.seconds)
                  .format("MMM Do YYYY")}`;
              }
              status = `${status} (canceled ${moment
                .unix(subClientRowData.billingCanceledTime.seconds)
                .format("MMM Do YYYY")})`;
            }

            return (
              <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                {status}
              </DataTableRowCell>
            );
          }
          case CAMPAIGNS_COL: {
            const siteLabels = getClientSiteLabels(subClientRowData);
            const userSiteLabels = siteLabels
              .filter(label => isValidEmail(label))
              .sort();
            const nonUserSiteLabels = siteLabels
              .filter(label => !isValidEmail(label))
              .sort();

            return (
              <DataTableRowCell
                key={columnName}
                rowIndex={rowIndex}
                colSpan={columns.length - subClientColumns.length}
              >
                {userSiteLabels.map(label => (
                  <SelectedPhrase
                    key={label}
                    phrase={label}
                    canEdit={false}
                    canRemove={false}
                  />
                ))}
                {nonUserSiteLabels.map(label => (
                  <SelectedPhrase
                    key={label}
                    phrase={label}
                    canEdit={false}
                    canRemove={false}
                  />
                ))}
              </DataTableRowCell>
            );
          }
          default:
            return null;
        }
      })}
    </DataTableRow>
  );
}

export default AccountsTableSubClientRow;
