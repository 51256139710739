import React from "react";
import { generatePath } from "react-router-dom";

import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { Icon } from "semantic-ui-react";

import {
  ACCOUNTS_PATH,
  AMPD_ROOT,
  AUTOMATION_PATH,
  CAMPAIGN_DETAILS_DEFAULT_CHARTS_PATH,
  CAMPAIGNS_PATH,
  EXPORTS_PATH,
  GOOGLE_ADS_HISTORY_PATH,
  LEARN_PATH,
  SETTINGS_PATH,
  PRODUCTS_PATH,
  CREATE_CAMPAIGN_PATH,
  BRAND_OVERVIEW_PATH,
  FACEBOOK_PAGE_V2_PATH,
  FACEBOOK_ADD_ATTRIBUTION_PATH
} from "ExtensionV2/ExtensionV2";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { backgroundDark, backgroundLight } from "ExtensionV2/styles/colors";

import googleAdsSVG from "../../../assets/icons/google-ads.svg";
import metaPNG from "../../../assets/icons/meta.png";
import metaWithTextSVG from "../../../assets/icons/metaWithText.svg";
import { logout } from "Common/utils/auth";

export const NavItemIcon = styled.div``;

export const NavBarItem = styled(NavLink)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.25rem;
  text-decoration: none;
  background-color: ${backgroundLight};
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1.75rem;

  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "initial")};

  &.active {
    background-color: ${backgroundDark};
  }

  &:hover {
    background-color: ${backgroundDark};
    text-decoration: none;
  }
`;

export const NavSectionHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 0.25rem;
`;

export const NavItemLabel = styled.p<{ disabled?: boolean }>`
  align-self: center;
  opacity: 0;
  white-space: nowrap;
  color: ${props => (props.disabled ? "gray" : "initial")};
  font-size: 0.9rem;
`;

export const NavBarIconBlock = styled.div`
  margin-bottom: 2em;
  :last-child {
    bottom: 0;
  }
  ${NavBarItem} {
    padding-left: 0.4em;
  }
`;

export const SideWideNavBarIconsBlock = styled.div`
  margin-bottom: 2em;
  :last-child {
    bottom: 0;
  }
  ${NavBarItem} {
    padding: 0.25em 0 0.25em 0;
  }
`;

const AmpdNavBarItem = ({
  name,
  icon,
  onClick,
  to,
  end,
  disabled
}: {
  name: string;
  icon: React.ReactNode;
  tooltipText?: string;
  onClick?: () => void;
  to: string;
  end?: boolean;
  disabled?: boolean;
}) => {
  return (
    <NavBarItem disabled={disabled} to={to} onClick={onClick} end={end}>
      <NavItemIcon>{icon}</NavItemIcon>
      <NavItemLabel>{name}</NavItemLabel>
    </NavBarItem>
  );
};

export const BrandOverviewIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Overview"
    icon={<Icon name="dashboard" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${BRAND_OVERVIEW_PATH}`, {
      siteAlias
    })}
  />
);

export const CampaignsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    end
    name="Campaigns"
    icon={<Icon name="home" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${CAMPAIGNS_PATH}`, {
      siteAlias
    })}
  />
);

export const CreateCampaignsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Create Campaigns"
    icon={<Icon name="plus" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${CREATE_CAMPAIGN_PATH}`, {
      siteAlias
    })}
  />
);

export const ImpressionsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Impressions"
    icon={<Icon name="area graph" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${CAMPAIGN_DETAILS_DEFAULT_CHARTS_PATH}`, {
      siteAlias
    })}
  />
);

export const ProductsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Products"
    icon={<Icon name="cart" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${PRODUCTS_PATH}`, {
      siteAlias
    })}
  />
);

export const AutomationIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Automation"
    icon={<Icon name="bolt" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${AUTOMATION_PATH}`, {
      siteAlias
    })}
  />
);

export const ExportsIcon = ({
  siteAlias,
  isSiteAdmin
}: {
  siteAlias: string;
  isSiteAdmin: boolean;
}): JSX.Element => (
  <SimpleTooltip
    tooltip="Only available for Admin users"
    position="right center"
    disabled={isSiteAdmin}
  >
    {isSiteAdmin ? (
      <AmpdNavBarItem
        name="Exports"
        icon={<Icon name="file excel outline" size="small" color="black" />}
        to={EXPORTS_PATH}
      />
    ) : (
      <NavBarItem
        to={generatePath(`${AMPD_ROOT}/${EXPORTS_PATH}`, {
          siteAlias
        })}
      >
        <NavItemIcon>
          <Icon
            disabled={!isSiteAdmin}
            name="file excel outline"
            size="small"
            color="black"
          />
        </NavItemIcon>
        <NavItemLabel disabled={!isSiteAdmin}>Exports</NavItemLabel>
      </NavBarItem>
    )}
  </SimpleTooltip>
);

export const HistoryIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="History"
    icon={<Icon name="history" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${GOOGLE_ADS_HISTORY_PATH}`, {
      siteAlias
    })}
  />
);

export const LearnIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Learn"
    icon={
      <Icon
        name="book"
        size="small"
        color="black"
        style={{ fontSize: "1.75rem", marginLeft: "0.25rem" }}
      />
    }
    to={generatePath(`${AMPD_ROOT}/${LEARN_PATH}`, { siteAlias })}
  />
);

export const SettingsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Settings"
    icon={
      <Icon
        name="sun outline"
        size="small"
        color="black"
        style={{ fontSize: "1.75rem", marginLeft: "0.25rem" }}
      />
    }
    to={generatePath(`${AMPD_ROOT}/${SETTINGS_PATH}`, { siteAlias })}
  />
);

export const AccountsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Accounts"
    icon={
      <Icon
        name="sitemap"
        size="small"
        color="black"
        style={{ fontSize: "1.75rem", marginLeft: "0.25rem" }}
      />
    }
    to={generatePath(`${AMPD_ROOT}/${ACCOUNTS_PATH}`, {
      siteAlias
    })}
  />
);

export const FacebookCampaignsIcon = ({
  siteAlias,
  disabled
}: {
  siteAlias: string;
  disabled: boolean;
}): JSX.Element => (
  <AmpdNavBarItem
    disabled={disabled}
    name="Campaigns"
    icon={<Icon name="home" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${FACEBOOK_PAGE_V2_PATH}`, { siteAlias })}
  />
);

export const CreateFacebookCampaignsIcon = ({
  siteAlias,
  disabled
}: {
  siteAlias: string;
  disabled: boolean;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Add Attribution"
    disabled={disabled}
    icon={<Icon name="plus" size="small" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${FACEBOOK_ADD_ATTRIBUTION_PATH}`, {
      siteAlias
    })}
  />
);

export const GoogleAdsIcon = (): JSX.Element => (
  <NavSectionHeader>
    <NavItemIcon style={{ marginBottom: "1em" }}>
      <img
        src={googleAdsSVG}
        alt="Google Ads Logo"
        width={25}
        style={{ marginLeft: "0.25rem" }}
      />
    </NavItemIcon>
    <div
      style={{
        display: "flex",
        marginBottom: "1em",
        paddingLeft: "0.25em"
      }}
    >
      <NavItemIcon>
        <img src={googleAdsSVG} alt="Large Google Ads Logo" width={30} />
      </NavItemIcon>
      <NavItemLabel
        style={{ paddingLeft: "0.5rem", fontWeight: "500", fontSize: "1rem" }}
      >
        Google Ads
      </NavItemLabel>
    </div>
  </NavSectionHeader>
);

export const MetaIcon = (): JSX.Element => {
  return (
    <NavSectionHeader>
      <NavItemIcon>
        <img
          src={metaPNG}
          alt="Meta Logo"
          width={25}
          style={{ marginLeft: "0.25rem" }}
        />
      </NavItemIcon>
      <NavItemIcon>
        <img src={metaWithTextSVG} alt="Large Meta Logo" width={118} />
      </NavItemIcon>
    </NavSectionHeader>
  );
};

export const LogoutIcon = (): JSX.Element => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      cursor: "pointer",
      fontSize: "1.75rem",
      marginLeft: "0.25rem"
    }}
    onClick={() => logout("")}
  >
    <NavItemIcon>
      {<Icon name="sign out" size="small" color="black" />}
    </NavItemIcon>
    <NavItemLabel>Log Out</NavItemLabel>
  </div>
);
