import { generatePath, useNavigate } from "react-router-dom";
import { Segment, Icon, Button } from "semantic-ui-react";
import React, { useMemo } from "react";
import styled from "styled-components";

import { useItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { COPY_CAMPAIGN_PATH } from "ExtensionV2/ExtensionV2";
import { backgroundDark, ampdRed } from "ExtensionV2/styles/colors";
import { popover } from "ExtensionV2/styles/zIndexes";
import { getDetailPageURLForASIN } from "Common/utils/amazon";
import EditableCampaignAttributes, {
  PAGE_GA_CATEGORY
} from "./EditableCampaignConfiguration";
import CampaignNameEditButton from "./CampaignNameEditButton";
import { useSession } from "ExtensionV2/queries/useSession";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { GetCampaignConfigurationsReply } from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { formatCustomerId } from "Common/utils/googleAds";
import { RefetchCampaignConfigurationsResult } from "../../queries/useCampaignConfigurationsByCampaignId";
import { prettyStringForIdentifier } from "../../../Common/utils/proto";
import { BIDDING_STRATEGY_TARGET_SPEND_STR } from "../../queries/useUpdateCampaignBiddingStrategy";
import {
  useCanUserAddCampaigns,
  useCanUserEditDetails
} from "Common/utils/siteUIBehavior";

const CampaignEditorTray = styled.div`
  background-color: ${backgroundDark};
  border-radius: 5px;
  bottom: 0;
  box-shadow: 0px 0px 8px #000000;
  height: 95vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  padding: 0.5em;
  position: absolute;
  right: 0;
  width: 40vw;
  z-index: ${popover};
`;

const RedBottomSegment = styled(Segment)`
  border-bottom: 2px solid ${ampdRed} !important;
`;

const RedTopSegment = styled(Segment)`
  border-top: 2px solid ${ampdRed} !important;
`;

export const CampaignAttributeGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const NonEditableSection = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  h5 {
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  p {
    text-wrap: nowrap;
  }
`;

const CampaignEditor: React.FC<{
  campaignConfigurationsByCampaignId: Map<
    string,
    GetCampaignConfigurationsReply.CampaignConfiguration.AsObject
  >;
  campaignConfigurationsLoading: boolean;
  refetchCampaignConfiguration: (
    campaignId: string | null
  ) => RefetchCampaignConfigurationsResult;
  campaignId: string | undefined | null;
  setExpandedCampaignId: (expandedCampaignId: string | null) => void;
}> = ({
  campaignConfigurationsByCampaignId,
  campaignConfigurationsLoading,
  refetchCampaignConfiguration,
  campaignId,
  setExpandedCampaignId
}) => {
  const { isLoading } = useSession();
  const currentSite = useSessionSite();
  const { siteAlias } = currentSite;
  const canEditDetails = useCanUserEditDetails();
  const canAddCampaigns = useCanUserAddCampaigns();

  const navigate = useNavigate();

  const campaignConfiguration =
    campaignConfigurationsByCampaignId == null
      ? undefined
      : campaignConfigurationsByCampaignId.get(campaignId || "");

  const itemizedCampaignConfiguration = useItemizedCampaignConfiguration(
    campaignConfiguration
  );

  const {
    customerId,
    campaignName,
    campaignStatus,
    campaignStartDate,
    campaignServingStatus,
    campaignBiddingStrategy,
    adId,
    locations,
    marketplaceInfo,
    asin,
    variantASINs,
    walmartItemIds,
    walmartSearchPhrase
  } = itemizedCampaignConfiguration;

  const asinLink = useMemo(() => {
    return (
      <a
        href={getDetailPageURLForASIN(marketplaceInfo, asin) || ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        {asin}
      </a>
    );
  }, [asin, marketplaceInfo]);

  const variantASINLinks = useMemo(() => {
    if (!variantASINs || variantASINs.length < 1 || variantASINs[0] === asin) {
      return;
    }

    const variants = variantASINs.map((variantASIN, i) =>
      variantASIN === asin ? null : (
        <a
          key={variantASIN}
          href={
            getDetailPageURLForASIN(marketplaceInfo, variantASIN) || undefined
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {variantASIN}
          {i < variantASINs.length - 1 && ", "}
        </a>
      )
    );
    return variants.filter(Boolean);
  }, [asin, marketplaceInfo, variantASINs]);

  if (isLoading || campaignConfigurationsLoading) {
    return (
      <CampaignEditorTray>
        <LoadingSpinner />
      </CampaignEditorTray>
    );
  }

  if (!siteAlias || !customerId || !campaignId || !campaignConfiguration) {
    return null;
  }

  return (
    <CampaignEditorTray>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <RedBottomSegment
          style={{ marginBottom: 5, marginRight: 10, flexGrow: 1 }}
        >
          {canEditDetails && (
            <div style={{ display: "inline-block" }}>
              <CampaignNameEditButton
                siteAlias={siteAlias}
                gaCategory={PAGE_GA_CATEGORY}
                itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                refetchCampaignConfiguration={refetchCampaignConfiguration}
              />
            </div>
          )}
          <strong style={{ display: "inline", marginLeft: "0.5em" }}>
            {campaignName}
          </strong>
        </RedBottomSegment>
        <Icon
          name="angle double right"
          circular
          style={{ cursor: "pointer", float: "right" }}
          onClick={() => setExpandedCampaignId(null)}
        />
      </div>
      <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
        <Segment>
          <RedTopSegment>
            <h4>Campaign Information</h4>
            <NonEditableSection>
              <div>
                <h5>Google Ads Account ID: </h5>
                <p>{formatCustomerId(String(customerId))}</p>
              </div>
              <div>
                <h5>Campaign ID </h5>
                <p>{campaignId}</p>
              </div>
              <div>
                <h5>Locations: </h5>
                <p>{locations}</p>
              </div>
              <div>
                <h5>Campaign Start Date: </h5>
                <p>{campaignStartDate}</p>
              </div>
              <div>
                <h5>Campaign Status: </h5>
                <p>{prettyStringForIdentifier(campaignStatus)}</p>
              </div>
              <div>
                <h5>Campaign Serving Status: </h5>
                <p>{prettyStringForIdentifier(campaignServingStatus)}</p>
              </div>
              <div>
                <h5>Bidding Strategy: </h5>
                <p>
                  {campaignBiddingStrategy == BIDDING_STRATEGY_TARGET_SPEND_STR
                    ? "Maximize clicks"
                    : prettyStringForIdentifier(campaignBiddingStrategy)}
                </p>
              </div>
              {!!asin && (
                <div>
                  <h5>ASIN: </h5>
                  <p>{asinLink}</p>
                </div>
              )}
              {!!walmartSearchPhrase && (
                <div>
                  <h5>Walmart Search: </h5>
                  <p>'{walmartSearchPhrase}'</p>
                </div>
              )}
              {!walmartSearchPhrase && !!walmartItemIds?.length && (
                <div>
                  <h5>Walmart Item ID: </h5>
                  <p>{walmartItemIds.join(", ")}</p>
                </div>
              )}
              {variantASINLinks && (
                <div>
                  <h5>ASIN Variants: </h5>
                  <div>{variantASINLinks}</div>
                </div>
              )}
            </NonEditableSection>
          </RedTopSegment>

          {campaignConfiguration &&
          !!adId && ( // ie: An "Ampd Campaign"
              <RedTopSegment>
                <EditableCampaignAttributes
                  key={itemizedCampaignConfiguration.campaignId}
                  siteAlias={siteAlias}
                  itemizedCampaignConfiguration={itemizedCampaignConfiguration}
                  refetchCampaignConfiguration={refetchCampaignConfiguration}
                />
              </RedTopSegment>
            )}

          {canAddCampaigns &&
          campaignConfiguration &&
          !!adId && ( // ie: An "Ampd Campaign"
              <RedTopSegment>
                <h4>Copy Campaign</h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1em"
                  }}
                >
                  <div style={{ alignSelf: "center" }}>
                    <Button
                      basic
                      primary
                      size="small"
                      onClick={() => {
                        const copyCampaignPath = generatePath(
                          `../${COPY_CAMPAIGN_PATH}`,
                          {
                            campaignId
                          }
                        );

                        navigate(generatePath(copyCampaignPath));
                      }}
                    >
                      Copy
                    </Button>
                  </div>
                  <p>
                    Create a new campaign using this one as a template. You will
                    be able to create a new campaign for the same ASIN or for a
                    store-front page that contains this ASIN.
                  </p>
                </div>
              </RedTopSegment>
            )}
        </Segment>
      </div>
    </CampaignEditorTray>
  );
};

export default CampaignEditor;
