import { CSV_FLOAT_PRECISION } from "Common/utils/csv";
import { FormattedValue } from "../MetricColumns";
import { CompareStyles } from "./cells/MetricCell";
import { FilterArguments } from "./filters";
import { MetricsTableCell, MetricsTableData } from "./MetricsTable";
import { SortOption } from "./sorting";

export class Column<T extends MetricsTableData> {
  // The key of the data in the row object, ex: "addToCartClicks"
  readonly dataName;
  // The name of the column to display in the table, ex: "Add to Cart Clicks"
  readonly displayName;
  // A short explainer about the column the will be displayed in the column header under the display name.
  readonly displaySubtitle;
  // A longer explainer that shows up when the user hovers over the column.
  readonly tooltip;
  // The default sort direction.
  readonly defaultSortDirection;
  // When to the previous date range is a positive or negative change good or bad?
  readonly compareStyle;
  // how to calculate the value of the total row
  // the UI to collect the filter arguments
  readonly FilterUI:
    | ((
        args: FilterArguments,
        setArgs: (args: FilterArguments) => void
      ) => JSX.Element)
    | undefined;
  // The function that will calculate the total value for the column.
  readonly totalFn;

  private _sortFn;
  private _filterFn;
  private _formatFn;
  private _tableCell;
  private _formatCSVFn;

  constructor(args: {
    dataName: keyof T;
    displayName: string;
    displaySubtitle: string;
    tooltip: string;
    defaultSortDirection: SortOption;
    compareStyle?: CompareStyles;
    totalFn?: (rows: T[]) => number;
    formatFn: (rawVal: unknown, showFraction: boolean) => string;
    formatCSVFn?: (rawVal: unknown) => string;
    sortFn: (a: unknown, b: unknown, direction: SortOption) => number;
    filterFn: <T>(row: T, dataName: keyof T, args: FilterArguments) => boolean;
    filterUI?: (
      args: FilterArguments,
      setArgs: (args: FilterArguments) => void
    ) => JSX.Element;
    tableCell: MetricsTableCell<T>;
  }) {
    this.dataName = args.dataName;
    this.displayName = args.displayName;
    this.displaySubtitle = args.displaySubtitle;
    this.tooltip = args.tooltip;
    this.defaultSortDirection = args.defaultSortDirection;
    this.compareStyle = args.compareStyle;
    this.FilterUI = args.filterUI;
    this.totalFn = args.totalFn;

    this._tableCell = args.tableCell;
    this._sortFn = args.sortFn;
    this._filterFn = args.filterFn;
    this._formatFn = args.formatFn;
    this._formatCSVFn = args.formatCSVFn ?? defaultFormatCSVFn;
  }

  compare(rowA: T, rowB: T, direction: SortOption): number {
    return this._sortFn(rowA[this.dataName], rowB[this.dataName], direction);
  }

  filter(row: T, filterArgs: FilterArguments): boolean {
    return this._filterFn(row, this.dataName, filterArgs);
  }

  format(row: T, showFractions: boolean): FormattedValue {
    return this._formatFn(row[this.dataName], showFractions);
  }

  formatCSV(row: T): string {
    return this._formatCSVFn(row[this.dataName]);
  }

  RenderCell = ({
    rowID,
    val,
    rowData,
    prev,
    depth,
    childRows
  }: {
    rowID: string;
    val: unknown;
    rowData: T;
    prev?: unknown;
    depth?: number;
    childRows?: Array<T>;
  }): JSX.Element => {
    return this._tableCell({
      rowID,
      val,
      rowData,
      prev,
      depth,
      formatFn: this._formatFn,
      compareStyles: this.compareStyle,
      childRows
    });
  };
}

function defaultFormatCSVFn(rawVal: unknown): string {
  if (typeof rawVal === "undefined" || rawVal === null) {
    return "";
  }

  if (typeof rawVal !== "number") {
    return String(rawVal);
  }

  if (!Number.isFinite(rawVal)) {
    return "";
  }

  if (Number.isNaN(rawVal)) {
    return "";
  }

  if (!Number.isInteger(rawVal)) {
    return rawVal.toFixed(CSV_FLOAT_PRECISION);
  }

  return String(rawVal);
}

export const calculateSumTotal = <TData,>(
  dataName: keyof TData
): ((rows: TData[]) => number) => {
  return rows => {
    return rows.reduce((acc, row) => acc + Number(row[dataName]), 0);
  };
};

export const calculateRatioTotal = <TData,>(
  numerator: keyof TData,
  denominator: keyof TData,
  factor?: number
): ((rows: TData[]) => number) => {
  return rows => {
    return (
      ((factor ?? 1) *
        rows.reduce((acc, row) => acc + Number(row[numerator]), 0)) /
      rows.reduce((acc, row) => acc + Number(row[denominator]), 0)
    );
  };
};
