import { Divider, Loader, Message } from "semantic-ui-react";
import { Flex } from "@rebass/grid";
import React, { JSX, useEffect, useState } from "react";

import { sendGAEvent, useGAEvent } from "./GA";
import GoogleAdsConnectAccountContent, {
  GoogleAdsCustomerClientAccount
} from "ExtensionV2/components/GoogleAdsConnectAccountContent";
import { Site, useSession } from "ExtensionV2/queries/useSession";
import AuditSegment from "./AuditSegment";
import CreateGoogleAdsAccount from "ExtensionV2/pages/CreateGoogleAdsAccountPage";
import GoogleAdsLoginButton from "./GoogleAdsLoginButton";
import { AUDIT_PAGE_GA_CATEGORY } from "ExtensionV2/ExtensionV2";
import { AdWordsAccount } from "Common/proto/common/dataSourceInfo_pb";
import { extractErrorMessage } from "Common/errors/error";
import { GoogleLoginResponse } from "react-google-login";
import { useQuery } from "@tanstack/react-query";
import { UnlinkDataSourceRequest } from "Common/proto/edge/grpcwebPb/grpcweb_Admin_pb";
import { DashboardSite } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardSession_pb";
import {
  GoogleAdsAuditReply,
  GoogleAdsAuditRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import { formatAccount } from "Common/utils/googleAds";
import { useWantsProOperatorFeatures } from "Common/utils/featureFlags";
import { None } from "Common/utils/tsUtils";
import { useGoogleAdsAccessibleAccountsForUser } from "ExtensionV2/queries/useGoogleAdsAccessibleAccountsForUser";
import { useGoogleAdsLinkableAccounts } from "ExtensionV2/queries/useGoogleAdsLinkableAccounts";

function CouponPromotion({ siteAlias }: { siteAlias: string }): JSX.Element {
  useGAEvent(AUDIT_PAGE_GA_CATEGORY, "Showed coupon promotion", siteAlias);

  const handleTermsAndConditionsLink = () => {
    sendGAEvent(
      AUDIT_PAGE_GA_CATEGORY,
      "Clicked T&C Link",
      siteAlias,
      "create account card"
    );
  };

  return (
    <Message success>
      <p>
        Start today and get up to{" "}
        <b>$500 in Google Ad credit for new accounts!</b>
      </p>
      <p>
        Value varies by territory outside of the United States. See{" "}
        <a
          href="https://www.google.com/ads/coupons/terms/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleTermsAndConditionsLink}
        >
          terms & conditions
        </a>
        .
      </p>
    </Message>
  );
}

const genericErrorMessage =
  "There was an internal error linking your Google Ads account. Please contact us for assistance.";

const GoogleAdsAccountIsLinkedContent = ({
  siteAlias,
  googleAdsAccount
}: {
  siteAlias: string;
  googleAdsAccount: DashboardSite.DashboardAdwordsAccountInfo.AsObject;
}) => {
  const googleAdsAudit = useGoogleAdsAudit(
    siteAlias,
    googleAdsAccount.customerId
  );

  const { disconnectDataSourceMutation } = useSession();

  const {
    isLoading: disconnectDataSourceLoading,
    error: disconnectDataSourceError,
    mutate: disconnectDataSource
  } = disconnectDataSourceMutation;

  const handleDisconnectGoogle = () => {
    const req: UnlinkDataSourceRequest.AsObject = {
      siteAlias,
      adwords: {
        customerId: googleAdsAccount.customerId
      }
    };
    disconnectDataSource({ removeDataSourceReq: req });
  };

  const isOperator = useWantsProOperatorFeatures();

  // Has billing been set up for the linked Google Ads account?
  const hasGoogleBilling =
    googleAdsAudit.data?.googleAdsAudit?.hasBilling || false;

  useEffect(() => {
    if (googleAdsAudit.error) {
      console.error(googleAdsAudit.error);
    }
  }, [googleAdsAudit.error]);

  const invitation = googleAdsAccount.invitation;
  return (
    <>
      {!!googleAdsAudit.error && (
        <Message negative>
          <p key="error">{genericErrorMessage}</p>
        </Message>
      )}

      <AuditSegment
        siteAlias={siteAlias}
        icon="check circle"
        color="green"
        title="Connected to your Google Ads account"
        details={formatAccount(googleAdsAccount, false)}
      >
        {isOperator && (
          <a onClick={handleDisconnectGoogle}>
            {disconnectDataSourceLoading
              ? "Disconnecting..."
              : "Disconnect this Google Ads account"}
          </a>
        )}
      </AuditSegment>
      {googleAdsAudit.isLoading ? (
        <AuditSegment
          siteAlias={siteAlias}
          key="BILLING_STATUS_LOADING"
          loading={true}
          icon={true}
          title={"Checking the billing status of your Google Ads account"}
        />
      ) : (
        <AuditSegment
          key="BILLING_STATUS"
          siteAlias={siteAlias}
          icon={hasGoogleBilling ? "check circle" : "warning circle"}
          color={hasGoogleBilling ? "green" : "red"}
          title={
            hasGoogleBilling
              ? "Google Ads billing enabled"
              : "Google Ads billing not enabled"
          }
        >
          {!hasGoogleBilling && (
            <p>
              In order to set up campaigns with Ampd, you must go to Google Ads
              and provide payment information for your Google Ads account (
              {googleAdsAccount.name})
            </p>
          )}

          {invitation &&
            invitation.statusEnumOption !==
              AdWordsAccount.Invitation.Status.EXPIRED &&
            invitation.link && (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={invitation.link}
              >
                Visit Google Ads
              </a>
            )}
        </AuditSegment>
      )}
      {disconnectDataSourceError && (
        <Message error>
          There was an error when trying to disconnect this Google Ads Account
        </Message>
      )}
    </>
  );
};

const LoginWithGoogleContent = ({
  siteAlias,
  setGoogleLogin,
  googleLoginEmail
}: {
  siteAlias: string;
  setGoogleLogin: (googleLogin: GoogleLoginResponse) => void;
  googleLoginEmail: string;
}) => {
  return (
    <AuditSegment
      siteAlias={siteAlias}
      icon={"warning circle"}
      color={"red"}
      title="Sign into your Google user account"
    >
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <GoogleAdsLoginButton
          siteAlias={siteAlias}
          gaCategory={AUDIT_PAGE_GA_CATEGORY}
          email={googleLoginEmail || ""}
          onGoogleLogin={setGoogleLogin}
          disabled={false}
        />
        {!googleLoginEmail && (
          <a
            style={{ float: "right" }}
            href="https://support.google.com/accounts/answer/27441"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendGAEvent(
                AUDIT_PAGE_GA_CATEGORY,
                "Clicked Don't have Google account Link",
                siteAlias,
                "sign in card"
              );
            }}
          >
            <small>Don't have a Google account?</small>
          </a>
        )}
      </Flex>
    </AuditSegment>
  );
};

// For external users, the user must log into Google Ads (to get an access token)
// and either select an existing account or create a new account.  When selected,
// the account will be connected to our root MCC and referenced in the site details.
const SelectOrCreateGoogleAdsAccountContent = ({
  accessToken,
  canCreateGoogleAdsAccount,
  googleLoginEmail,
  setGoogleLogin,
  siteAlias
}: {
  accessToken: string;
  canCreateGoogleAdsAccount: boolean;
  googleLoginEmail: string;
  setGoogleLogin: (googleLogin: GoogleLoginResponse | null) => void;
  siteAlias: string;
}) => {
  const {
    isFetching: refetchingSession,
    updateLinkedGoogleAdsAccount: updateLinkedGoogleAdsAccountMutation
  } = useSession();

  const {
    isLoading: updateLinkedGoogleAdsAccountLoading,
    error: updateLinkedGoogleAdsAccountError,
    mutate: updateLinkedGoogleAdsAccount
  } = updateLinkedGoogleAdsAccountMutation;

  const [selectedAccount, setSelectedAccount] = useState<
    GoogleAdsCustomerClientAccount | None
  >(null);

  const {
    data: accounts,
    isLoading: accountsLoading,
    error: accountsError
  } = useGoogleAdsAccessibleAccountsForUser({ siteAlias, accessToken });

  if (updateLinkedGoogleAdsAccountError) {
    return (
      <Message negative>
        There was a problem linking your Google account:{" "}
        {extractErrorMessage(updateLinkedGoogleAdsAccountError)}
      </Message>
    );
  }

  if (!accounts || accountsLoading || refetchingSession) {
    return (
      <AuditSegment
        siteAlias={siteAlias}
        icon="info circle"
        color="blue"
        title="Loading Google Ads accounts"
      >
        <div style={{ minHeight: "60px" }}>
          <Loader active />
        </div>
      </AuditSegment>
    );
  }

  if (accounts.length === 0 && !canCreateGoogleAdsAccount) {
    return (
      <AuditSegment
        icon="info circle"
        color="blue"
        title="Connect to your Google Ads account"
      >
        <div style={{ minHeight: "60px" }}>
          <p>
            You must first log into your Amazon Advertising account and your
            Amazon Advertising account must be associated with at least 1 active
            attribution profile before you can create your Google Ads account.
          </p>
        </div>
      </AuditSegment>
    );
  }

  return (
    <>
      {!!accountsError && (
        <Message negative>
          <p key="error">{extractErrorMessage(accountsError)}</p>
        </Message>
      )}
      {accounts.length === 0 ? (
        <>
          <AuditSegment
            siteAlias={siteAlias}
            disabled={!accessToken}
            icon="arrow circle right"
            color="yellow"
            title="Create a new Google Ads account"
          >
            <CouponPromotion siteAlias={siteAlias} />
            <CreateGoogleAdsAccount
              canCreateGoogleAdsAccount={canCreateGoogleAdsAccount}
              setGoogleLogin={setGoogleLogin}
            />

            <Divider horizontal>Or</Divider>
            <p>
              <b>{googleLoginEmail}</b> doesn’t have access to any Google Ads
              accounts.
            </p>
            <p>
              If you already have a Google Ads account, try signing out then
              signing in with different Google account. &nbsp;
              <a onClick={() => setGoogleLogin(null)}>Log out</a>
            </p>
          </AuditSegment>
        </>
      ) : (
        <AuditSegment
          siteAlias={siteAlias}
          disabled={!accessToken}
          icon="arrow circle right"
          color="yellow"
          title="Connect to your Google Ads account"
          buttonLabel="Connect"
          buttonLoading={updateLinkedGoogleAdsAccountLoading}
          buttonDisabled={
            updateLinkedGoogleAdsAccountLoading || !selectedAccount
          }
          onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            ev.stopPropagation();
            updateLinkedGoogleAdsAccount({
              accessToken,
              siteAlias,
              newGoogleAdsCustomerId: selectedAccount?.customerId || "",
              newGoogleAdsManagerId: selectedAccount?.managerId || ""
            });
          }}
        >
          {!!accessToken && (
            <GoogleAdsConnectAccountContent
              siteAlias={siteAlias}
              email={googleLoginEmail}
              accounts={accounts}
              linkingAccount={updateLinkedGoogleAdsAccountLoading}
              accountsLoading={accountsLoading}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
            />
          )}
          <p>
            Logged in with <b>{googleLoginEmail}</b>{" "}
            <a onClick={() => setGoogleLogin(null)}>(Log out)</a>
          </p>
        </AuditSegment>
      )}
    </>
  );
};

// For internal users, the user just needs to type in the first 3 digits of a customer id
// or a 3-character fragment of the account name to get a list of accounts already connected
// to our MCC.  Once an account is selected, a reference to it is just added to the site's
// details.
const SelectLinkableGoogleAdsAccountContent = ({
  siteAlias
}: {
  siteAlias: string;
}) => {
  const {
    isFetching: refetchingSession,
    updateLinkedGoogleAdsAccount: updateLinkedGoogleAdsAccountMutation
  } = useSession();

  const {
    isLoading: updateLinkedGoogleAdsAccountLoading,
    error: updateLinkedGoogleAdsAccountError,
    mutate: updateLinkedGoogleAdsAccount
  } = updateLinkedGoogleAdsAccountMutation;

  const [selectedAccount, setSelectedAccount] = useState<
    GoogleAdsCustomerClientAccount | None
  >(null);

  const [editSearch, setEditSearch] = useState("");

  const editSearchScrubbed = editSearch.replaceAll("-", "");
  // Only query accounts if search string is at least three digits (after removing '-'s)
  // or any 3 characters.  However, in either case, only query with 3 characters and let
  // the Dropdown do any further filtering.  Using 3 characters means the result won't be
  // too long, and using only that many means we won't be doing new queries on every
  // subsequent character.
  const { data: accounts, error: accountsError } = useGoogleAdsLinkableAccounts(
    {
      customerIDOrPrefix:
        editSearchScrubbed.length >= 3 &&
        Number.isInteger(Number(editSearchScrubbed))
          ? editSearchScrubbed.slice(0, 3)
          : "",
      customerNameFragment:
        editSearch.length >= 3 && !Number.isInteger(Number(editSearchScrubbed))
          ? editSearch.slice(0, 3)
          : ""
    }
  );

  if (updateLinkedGoogleAdsAccountError) {
    return (
      <Message negative>
        There was a problem linking the Google account:{" "}
        {extractErrorMessage(updateLinkedGoogleAdsAccountError)}
      </Message>
    );
  }

  if (refetchingSession) {
    return (
      <AuditSegment
        siteAlias={siteAlias}
        icon="info circle"
        color="blue"
        title="Loading Google Ads accounts"
      >
        <div style={{ minHeight: "60px" }}>
          <Loader active />
        </div>
      </AuditSegment>
    );
  }

  return (
    <>
      {!!accountsError && (
        <Message negative>
          <p key="error">{extractErrorMessage(accountsError)}</p>
        </Message>
      )}
      <AuditSegment
        siteAlias={siteAlias}
        icon="arrow circle right"
        color="yellow"
        title="Connect to an already connected Google Ads account"
        buttonLabel="Connect"
        buttonLoading={updateLinkedGoogleAdsAccountLoading}
        buttonDisabled={updateLinkedGoogleAdsAccountLoading || !selectedAccount}
        onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          ev.stopPropagation();
          updateLinkedGoogleAdsAccount({
            accessToken: "",
            siteAlias,
            newGoogleAdsCustomerId: selectedAccount?.customerId || "",
            newGoogleAdsManagerId: selectedAccount?.managerId || ""
          });
        }}
      >
        <GoogleAdsConnectAccountContent
          siteAlias={siteAlias}
          searchString={editSearch}
          setSearchString={setEditSearch}
          accounts={accounts}
          email={""}
          linkingAccount={updateLinkedGoogleAdsAccountLoading}
          accountsLoading={false}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
      </AuditSegment>
    </>
  );
};

const useGoogleAdsAudit = (siteAlias: string, customerId: string) => {
  return useQuery({
    queryKey: ["googleAdsAudit", siteAlias, customerId],
    staleTime: 60 * 60 * 1_000, // 1 hour
    enabled: !!siteAlias && !!customerId,
    queryFn: async (): Promise<GoogleAdsAuditReply.AsObject> => {
      const auditReq = new GoogleAdsAuditRequest();
      auditReq.setSiteAlias(siteAlias);
      auditReq.setCustomerId(customerId);

      const auditReply = await GRPCWebClient.googleAdsAudit(auditReq, {});
      return auditReply.toObject();
    }
  });
};

/* 
  The account linking process can exist in 4 states:

  * linked: We have a Google Ads account ID stored with the current site. The only thing to do is
      display a success message.
  
    Superusers can disconnect Google Ads account, but users themselves can't.
  
  * unlinked and not signed in: We don't have a Google Ads account for the site, and we don't have
      an access token to fetch Google Ads accounts for the Google user. We need to display the Google
      login button to get a token.

  * unlinked and signed in: The user has clicked the sign-in button, and we have a Google access
      token. We use this token to fetch a list of their Google Ads accounts. If they have at least
      1 account we ask them to select an existing account form a dropdown. If they don't have any 
      Google Ads accounts, we display a form that allows them to create a new account.
  
  * new Google Ads account submitted: The user has used our form to create their first Google Ads account.
      In order for them to complete the setup, we need them to follow the deep link provided by 
      Google to finish setting up their account in the Google Ads dashboard (this is where they 
      grant us control over their account and provide payment details to Google.)
                
        The invitation has 3 states that need to be handled:

        VALID - The user has not used the link to finalize their Google Ads account and the link is
          still active. We should show the user the link and inform them that they need to use it.

        IS_MEMBER - The user has used the link and their Google Ads account has been linked to
          the metricstory manager account. The linking process is complete and a success message
          should be displayed.

        EXPIRED - The use has not used the link and the link has expired. We need to generate
          a new link for the user. The link typically expires after 14 days.
*/
function ManageGoogleAdsAccountPanel({
  currentSite
}: {
  currentSite: Site;
}): JSX.Element {
  const { adwordsAccounts, siteAlias, siteFeatures, amazonInfo } = currentSite;

  const [googleLogin, setGoogleLogin] = useState<GoogleLoginResponse | null>(
    null
  );

  const isOperator = useWantsProOperatorFeatures();

  const canCreateGoogleAdsAccount =
    amazonInfo?.hasSuitableAmazonConfigurationForCreatingGoogleAdsAccount ||
    siteFeatures?.canCreateGoogleAdsAccount;
  const primaryGoogleAdsAccount = adwordsAccounts?.[0];

  const accessToken = googleLogin?.accessToken;
  const googleLoginEmail = googleLogin?.profileObj?.email || "";
  const adWordsAccountIsLinked = primaryGoogleAdsAccount?.customerId != null;
  const signedIn = accessToken != null && accessToken != "";

  let content;
  if (adWordsAccountIsLinked) {
    // There is a Google Ads account ID associated with the site and the invitation has
    // been accepted (or there is no invitation because the Google Ads account wasn't new)
    content = (
      <>
        {adwordsAccounts.map(account => (
          <GoogleAdsAccountIsLinkedContent
            key={account.customerId}
            siteAlias={siteAlias}
            googleAdsAccount={account}
          />
        ))}
      </>
    );
  } else if (isOperator) {
    // There is no linked Google Ads account and the user is an AmpdProOperator/superuser,
    // so either select an already linkable Google Ads account.
    content = <SelectLinkableGoogleAdsAccountContent siteAlias={siteAlias} />;
  } else if (!signedIn) {
    // There is no linked Google Ads account and user has not requested a Google access token yet.
    content = (
      <LoginWithGoogleContent
        siteAlias={siteAlias}
        setGoogleLogin={setGoogleLogin}
        googleLoginEmail={googleLoginEmail}
      />
    );
  } else if (signedIn) {
    // There is no linked Google Ads account and the user has signed in with Google,
    // so either select an existing Google Ads account or create a new one.
    content = (
      <SelectOrCreateGoogleAdsAccountContent
        accessToken={accessToken}
        canCreateGoogleAdsAccount={canCreateGoogleAdsAccount}
        googleLoginEmail={googleLoginEmail}
        setGoogleLogin={setGoogleLogin}
        siteAlias={siteAlias}
      />
    );
  } else {
    console.error(
      `Unexpected state in ManageGoogleAdsAccountPanel for site ${siteAlias}`
    );
    content = <p>Something went wrong. Please contact us for assistance</p>;
  }

  return content;
}

export default ManageGoogleAdsAccountPanel;
