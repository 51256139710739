import _ from "lodash";
import { useRef } from "react";

import {
  QueryKey,
  useQueryClient,
  UseQueryOptions
} from "@tanstack/react-query";

// Keeps track of a set of query keys from the specified queries and cancels (by queryKey) any
// that are no longer in the set.
export function useCancelInactiveQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queries: Array<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>>
): void {
  const previousQueryKeys = useRef<Array<QueryKey>>([]);
  const queryClient = useQueryClient();

  const activeQueryKeys = queries
    .filter(query => query.enabled)
    .map(query => query.queryKey || []);
  if (!_.isEqual(activeQueryKeys, previousQueryKeys.current)) {
    for (const inFlightQueryKey of previousQueryKeys.current) {
      let stillActive = false;
      for (const activeQueryKey of activeQueryKeys) {
        if (_.isEqual(inFlightQueryKey, activeQueryKey)) {
          stillActive = true;
          break;
        }
      }

      if (!stillActive) {
        // This will remove the query from the queue if it is still there.
        queryClient.cancelQueries(inFlightQueryKey);
      }
    }
    previousQueryKeys.current = activeQueryKeys;
  }
}

export default useCancelInactiveQueries;
