import { ampdRed, backgroundDark } from "ExtensionV2/styles/colors";
import React from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import { Column } from "../column";
import { MetricsTableData } from "../MetricsTable";

export const MetricsTableBodyRow = <T extends MetricsTableData>({
  columns,
  data,
  depth,
  childRows
}: {
  data: T;
  columns: Array<Column<T>>;
  depth: number;
  childRows?: Array<T>;
}): JSX.Element => {
  const columnCells = columns.map(c => {
    switch (c.dataName) {
      case "rowID":
        return null;
      default:
        return (
          <c.RenderCell
            key={String(c.dataName)}
            rowID={data.rowID}
            rowData={data}
            val={data[c.dataName]}
            prev={data.prev?.[String(c.dataName)]}
            depth={depth}
            childRows={childRows}
          />
        );
    }
  });

  return (
    <StyledMetricsTableBodyRow>
      <td>
        <input type="checkbox" />{" "}
      </td>
      {columnCells}
    </StyledMetricsTableBodyRow>
  );
};

export const StyledMetricsTableBodyRow = styled(Table.Row)`
  td {
    padding: 0 !important;

    > div {
      height: 2.5rem;
      margin: 0.25rem 0.5rem;

      p {
        line-height: 1rem;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
`;

export const StyledMetricsTableFooterRow = styled(StyledMetricsTableBodyRow)`
  td {
    box-shadow: 0px -2px 0px 0px ${ampdRed} !important;
    background-color: white;
    font-weight: bold;

    // Name Column
    :nth-child(2) {
      left: 3rem;
      position: sticky;
      border-left: none;
      border-right: none;
      box-shadow: inset 1px 0px 0px 0px ${backgroundDark},
        inset -1px 0px 0px 0px ${backgroundDark}, 0px -2px 0px 0px ${ampdRed} !important;
    }
  }
`;
