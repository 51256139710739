import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from "@tanstack/react-query";
import { Facebook } from "Common/proto/common/facebook_pb";
import { DashboardTable } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";
import {
  UpdateFacebookAdRequest,
  UpdateFacebookAdSetRequest,
  UpdateFacebookCampaignRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Facebook_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import { USD_OFFSET } from "ExtensionV2/pages/FacebookPage/EditFacebookBudget";

import { useSessionSite } from "./useSessionSite";

export type MutateFacebookBudgetProps = {
  resourceId: string;
  offsetDailyBudget: string;
};

function dollarStringToCentsString(dollarString: string): string {
  const offsetAmount = parseFloat(dollarString);
  const amountCents = Math.round(offsetAmount * USD_OFFSET);
  if (isNaN(amountCents) || amountCents < 0) {
    throw new Error("Invalid daily budget value");
  }
  return amountCents.toFixed(0);
}

export function useUpdateFacebookBidAmount(
  level: DashboardTable.ObjectType.Option
): UseMutationResult<void, unknown, MutateFacebookBudgetProps, unknown> {
  const { siteAlias } = useSessionSite();

  const mutationFn = async ({
    resourceId,
    offsetDailyBudget
  }: MutateFacebookBudgetProps) => {
    const updateAdSetReq = new UpdateFacebookAdSetRequest();

    // convert offsetDailyBudget to cents
    const amountCents = dollarStringToCentsString(offsetDailyBudget);

    switch (level) {
      case DashboardTable.ObjectType.Option.FACEBOOK_AD:
        throw new Error("Facebook Ads bid amounts are set at the ad set level");

      case DashboardTable.ObjectType.Option.FACEBOOK_AD_SET:
        updateAdSetReq
          .setSiteAlias(siteAlias)
          .setAdSetId(resourceId)
          .setUpdateValues(
            new Facebook.API.AdSetEditFields().setBidAmount(amountCents)
          )
          .setUpdateMask(new Facebook.API.AdSetEditMask().setBidAmount(true));
        await GRPCWebClient.updateFacebookAdSet(updateAdSetReq, {});
        return;

      case DashboardTable.ObjectType.Option.CAMPAIGN:
        throw new Error("Facebook Campaigns do not support bid amounts");

      default:
        throw new Error("Invalid updateValues type");
    }
  };

  return useUpdateFacebookResource(mutationFn);
}

export function useUpdateFacebookLifetimeBudget(
  level: DashboardTable.ObjectType.Option
): UseMutationResult<void, unknown, MutateFacebookBudgetProps, unknown> {
  const { siteAlias } = useSessionSite();

  const mutationFn = async ({
    resourceId,
    offsetDailyBudget
  }: MutateFacebookBudgetProps) => {
    const updateAdSetReq = new UpdateFacebookAdSetRequest();
    const updateCampaignReq = new UpdateFacebookCampaignRequest();

    // convert offsetDailyBudget to cents
    const amountCents = dollarStringToCentsString(offsetDailyBudget);

    switch (level) {
      case DashboardTable.ObjectType.Option.FACEBOOK_AD:
        throw new Error("Facebook Ads do not support lifetime budget updates");

      case DashboardTable.ObjectType.Option.FACEBOOK_AD_SET:
        updateAdSetReq
          .setSiteAlias(siteAlias)
          .setAdSetId(resourceId)
          .setUpdateValues(
            new Facebook.API.AdSetEditFields().setLifetimeBudget(amountCents)
          )
          .setUpdateMask(
            new Facebook.API.AdSetEditMask().setLifetimeBudget(true)
          );
        await GRPCWebClient.updateFacebookAdSet(updateAdSetReq, {});
        return;

      case DashboardTable.ObjectType.Option.CAMPAIGN:
        updateCampaignReq
          .setSiteAlias(siteAlias)
          .setCampaignId(resourceId)
          .setUpdateValues(
            new Facebook.API.CampaignEditFields().setLifetimeBudget(amountCents)
          )
          .setUpdateMask(
            new Facebook.API.CampaignEditMask().setLifetimeBudget(true)
          );
        await GRPCWebClient.updateFacebookCampaign(updateCampaignReq, {});
        return;

      default:
        throw new Error("Invalid updateValues type");
    }
  };

  return useUpdateFacebookResource(mutationFn);
}

export function useUpdateFacebookDailyBudget(
  level: DashboardTable.ObjectType.Option
): UseMutationResult<void, unknown, MutateFacebookBudgetProps, unknown> {
  const { siteAlias } = useSessionSite();

  const mutationFn = async ({
    resourceId,
    offsetDailyBudget
  }: MutateFacebookBudgetProps) => {
    const updateAdSetReq = new UpdateFacebookAdSetRequest();
    const updateCampaignReq = new UpdateFacebookCampaignRequest();

    // convert offsetDailyBudget to cents
    const amountCents = dollarStringToCentsString(offsetDailyBudget);

    switch (level) {
      case DashboardTable.ObjectType.Option.FACEBOOK_AD:
        throw new Error("Facebook ads do not support daily budget updates");

      case DashboardTable.ObjectType.Option.FACEBOOK_AD_SET:
        updateAdSetReq
          .setSiteAlias(siteAlias)
          .setAdSetId(resourceId)
          .setUpdateValues(
            new Facebook.API.AdSetEditFields().setDailyBudget(amountCents)
          )
          .setUpdateMask(new Facebook.API.AdSetEditMask().setDailyBudget(true));
        await GRPCWebClient.updateFacebookAdSet(updateAdSetReq, {});
        return;

      case DashboardTable.ObjectType.Option.CAMPAIGN:
        updateCampaignReq
          .setSiteAlias(siteAlias)
          .setCampaignId(resourceId)
          .setUpdateValues(
            new Facebook.API.CampaignEditFields().setDailyBudget(amountCents)
          )
          .setUpdateMask(
            new Facebook.API.CampaignEditMask().setDailyBudget(true)
          );
        await GRPCWebClient.updateFacebookCampaign(updateCampaignReq, {});
        return;

      default:
        throw new Error("Unable to update this type of object");
    }
  };

  return useUpdateFacebookResource(mutationFn);
}

type MutateFacebookStatusProps = {
  resourceId: string;
  newStatus: Facebook.API.UpdateStatus.Option;
};

export function useUpdateFacebookStatus(
  level: DashboardTable.ObjectType.Option
): UseMutationResult<void, unknown, MutateFacebookStatusProps, unknown> {
  const { siteAlias } = useSessionSite();

  const mutationFn = async ({
    resourceId,
    newStatus
  }: MutateFacebookStatusProps): Promise<undefined> => {
    let updateAdReq: UpdateFacebookAdRequest;
    let updateAdSetReq: UpdateFacebookAdSetRequest;
    let updateCampaignReq: UpdateFacebookCampaignRequest;
    switch (level) {
      case DashboardTable.ObjectType.Option.FACEBOOK_AD:
        updateAdReq = new UpdateFacebookAdRequest()
          .setSiteAlias(siteAlias)
          .setAdId(resourceId)
          .setUpdateValues(new Facebook.API.AdEditFields().setStatus(newStatus))
          .setUpdateMask(new Facebook.API.AdEditMask().setStatus(true));
        await GRPCWebClient.updateFacebookAd(updateAdReq, {});
        return;

      case DashboardTable.ObjectType.Option.FACEBOOK_AD_SET:
        updateAdSetReq = new UpdateFacebookAdSetRequest()
          .setSiteAlias(siteAlias)
          .setAdSetId(resourceId)
          .setUpdateValues(
            new Facebook.API.AdSetEditFields().setStatus(newStatus)
          )
          .setUpdateMask(new Facebook.API.AdSetEditMask().setStatus(true));
        await GRPCWebClient.updateFacebookAdSet(updateAdSetReq, {});
        return;

      case DashboardTable.ObjectType.Option.CAMPAIGN:
        updateCampaignReq = new UpdateFacebookCampaignRequest()
          .setSiteAlias(siteAlias)
          .setCampaignId(resourceId)
          .setUpdateValues(
            new Facebook.API.CampaignEditFields().setStatus(newStatus)
          )
          .setUpdateMask(new Facebook.API.CampaignEditMask().setStatus(true));
        await GRPCWebClient.updateFacebookCampaign(updateCampaignReq, {});
        return;

      default:
        return Promise.reject("Invalid rollup level");
    }
  };

  return useUpdateFacebookResource(mutationFn);
}

function useUpdateFacebookResource<T>(mutationFn: (props: T) => Promise<void>) {
  const { siteAlias } = useSessionSite();
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries([
      "upToDateFacebookCampaignConfigurations",
      siteAlias
    ]);
  };

  return useMutation({
    mutationFn,
    onSuccess
  });
}
