// source: proto/edge/grpcwebPb/grpcweb_Site.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_entity_site_pb = require('../../../proto/entity/site_pb.js');
goog.object.extend(proto, proto_entity_site_pb);
goog.exportSymbol('proto.grpcwebPb.GetSiteBrandInfoReply', null, global);
goog.exportSymbol('proto.grpcwebPb.GetSiteBrandInfoRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.MarkSiteReply', null, global);
goog.exportSymbol('proto.grpcwebPb.MarkSiteRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteBrandInfoReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteBrandInfoRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteContactEmailReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteContactEmailRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteFeatureFlagReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteFeatureFlagRequest', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteUIBehaviorReply', null, global);
goog.exportSymbol('proto.grpcwebPb.UpdateSiteUIBehaviorRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.MarkSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.MarkSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.MarkSiteRequest.displayName = 'proto.grpcwebPb.MarkSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.MarkSiteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.MarkSiteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.MarkSiteReply.displayName = 'proto.grpcwebPb.MarkSiteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteContactEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteContactEmailRequest.displayName = 'proto.grpcwebPb.UpdateSiteContactEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteContactEmailReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteContactEmailReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteContactEmailReply.displayName = 'proto.grpcwebPb.UpdateSiteContactEmailReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteBrandInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteBrandInfoRequest.displayName = 'proto.grpcwebPb.UpdateSiteBrandInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteBrandInfoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteBrandInfoReply.displayName = 'proto.grpcwebPb.UpdateSiteBrandInfoReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSiteBrandInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetSiteBrandInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSiteBrandInfoRequest.displayName = 'proto.grpcwebPb.GetSiteBrandInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.GetSiteBrandInfoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.GetSiteBrandInfoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.GetSiteBrandInfoReply.displayName = 'proto.grpcwebPb.GetSiteBrandInfoReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.displayName = 'proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.repeatedFields_, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.displayName = 'proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteUIBehaviorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteUIBehaviorRequest.displayName = 'proto.grpcwebPb.UpdateSiteUIBehaviorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteUIBehaviorReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteUIBehaviorReply.displayName = 'proto.grpcwebPb.UpdateSiteUIBehaviorReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteFeatureFlagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteFeatureFlagRequest.displayName = 'proto.grpcwebPb.UpdateSiteFeatureFlagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpcwebPb.UpdateSiteFeatureFlagReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpcwebPb.UpdateSiteFeatureFlagReply.displayName = 'proto.grpcwebPb.UpdateSiteFeatureFlagReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.MarkSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.MarkSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.MarkSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.MarkSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    application: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.MarkSiteRequest}
 */
proto.grpcwebPb.MarkSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.MarkSiteRequest;
  return proto.grpcwebPb.MarkSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.MarkSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.MarkSiteRequest}
 */
proto.grpcwebPb.MarkSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplication(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.MarkSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.MarkSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.MarkSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.MarkSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApplication();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.MarkSiteRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.MarkSiteRequest} returns this
 */
proto.grpcwebPb.MarkSiteRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string application = 2;
 * @return {string}
 */
proto.grpcwebPb.MarkSiteRequest.prototype.getApplication = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.MarkSiteRequest} returns this
 */
proto.grpcwebPb.MarkSiteRequest.prototype.setApplication = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.MarkSiteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.MarkSiteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.MarkSiteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.MarkSiteReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.MarkSiteReply}
 */
proto.grpcwebPb.MarkSiteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.MarkSiteReply;
  return proto.grpcwebPb.MarkSiteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.MarkSiteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.MarkSiteReply}
 */
proto.grpcwebPb.MarkSiteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.MarkSiteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.MarkSiteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.MarkSiteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.MarkSiteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteContactEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteContactEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteContactEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteContactEmailRequest}
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteContactEmailRequest;
  return proto.grpcwebPb.UpdateSiteContactEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteContactEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteContactEmailRequest}
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteContactEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteContactEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteContactEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteContactEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteContactEmailRequest} returns this
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_contact_email = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.prototype.getSiteContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteContactEmailRequest} returns this
 */
proto.grpcwebPb.UpdateSiteContactEmailRequest.prototype.setSiteContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteContactEmailReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteContactEmailReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteContactEmailReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteContactEmailReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteContactEmail: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteContactEmailReply}
 */
proto.grpcwebPb.UpdateSiteContactEmailReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteContactEmailReply;
  return proto.grpcwebPb.UpdateSiteContactEmailReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteContactEmailReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteContactEmailReply}
 */
proto.grpcwebPb.UpdateSiteContactEmailReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteContactEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteContactEmailReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteContactEmailReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteContactEmailReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteContactEmailReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteContactEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_contact_email = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteContactEmailReply.prototype.getSiteContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteContactEmailReply} returns this
 */
proto.grpcwebPb.UpdateSiteContactEmailReply.prototype.setSiteContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteBrandInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteBrandInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newBrandInfo: (f = msg.getNewBrandInfo()) && proto_entity_site_pb.SiteDetails.BrandInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoRequest}
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteBrandInfoRequest;
  return proto.grpcwebPb.UpdateSiteBrandInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteBrandInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoRequest}
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto_entity_site_pb.SiteDetails.BrandInfo;
      reader.readMessage(value,proto_entity_site_pb.SiteDetails.BrandInfo.deserializeBinaryFromReader);
      msg.setNewBrandInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteBrandInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteBrandInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewBrandInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entity_site_pb.SiteDetails.BrandInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoRequest} returns this
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.SiteDetails.BrandInfo new_brand_info = 2;
 * @return {?proto.entity.SiteDetails.BrandInfo}
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.prototype.getNewBrandInfo = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.SiteDetails.BrandInfo, 2));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo|undefined} value
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoRequest} returns this
*/
proto.grpcwebPb.UpdateSiteBrandInfoRequest.prototype.setNewBrandInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoRequest} returns this
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.prototype.clearNewBrandInfo = function() {
  return this.setNewBrandInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteBrandInfoRequest.prototype.hasNewBrandInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteBrandInfoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteBrandInfoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    brandInfo: (f = msg.getBrandInfo()) && proto_entity_site_pb.SiteDetails.BrandInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoReply}
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteBrandInfoReply;
  return proto.grpcwebPb.UpdateSiteBrandInfoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteBrandInfoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoReply}
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_site_pb.SiteDetails.BrandInfo;
      reader.readMessage(value,proto_entity_site_pb.SiteDetails.BrandInfo.deserializeBinaryFromReader);
      msg.setBrandInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteBrandInfoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteBrandInfoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrandInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_site_pb.SiteDetails.BrandInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.SiteDetails.BrandInfo brand_info = 1;
 * @return {?proto.entity.SiteDetails.BrandInfo}
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.prototype.getBrandInfo = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.SiteDetails.BrandInfo, 1));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo|undefined} value
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoReply} returns this
*/
proto.grpcwebPb.UpdateSiteBrandInfoReply.prototype.setBrandInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteBrandInfoReply} returns this
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.prototype.clearBrandInfo = function() {
  return this.setBrandInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteBrandInfoReply.prototype.hasBrandInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSiteBrandInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSiteBrandInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSiteBrandInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteBrandInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSiteBrandInfoRequest}
 */
proto.grpcwebPb.GetSiteBrandInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSiteBrandInfoRequest;
  return proto.grpcwebPb.GetSiteBrandInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSiteBrandInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSiteBrandInfoRequest}
 */
proto.grpcwebPb.GetSiteBrandInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSiteBrandInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSiteBrandInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSiteBrandInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteBrandInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.GetSiteBrandInfoRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.GetSiteBrandInfoRequest} returns this
 */
proto.grpcwebPb.GetSiteBrandInfoRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.GetSiteBrandInfoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.GetSiteBrandInfoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.GetSiteBrandInfoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteBrandInfoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    brandInfo: (f = msg.getBrandInfo()) && proto_entity_site_pb.SiteDetails.BrandInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.GetSiteBrandInfoReply}
 */
proto.grpcwebPb.GetSiteBrandInfoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.GetSiteBrandInfoReply;
  return proto.grpcwebPb.GetSiteBrandInfoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.GetSiteBrandInfoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.GetSiteBrandInfoReply}
 */
proto.grpcwebPb.GetSiteBrandInfoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_site_pb.SiteDetails.BrandInfo;
      reader.readMessage(value,proto_entity_site_pb.SiteDetails.BrandInfo.deserializeBinaryFromReader);
      msg.setBrandInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.GetSiteBrandInfoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.GetSiteBrandInfoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.GetSiteBrandInfoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.GetSiteBrandInfoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrandInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_site_pb.SiteDetails.BrandInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.SiteDetails.BrandInfo brand_info = 1;
 * @return {?proto.entity.SiteDetails.BrandInfo}
 */
proto.grpcwebPb.GetSiteBrandInfoReply.prototype.getBrandInfo = function() {
  return /** @type{?proto.entity.SiteDetails.BrandInfo} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.SiteDetails.BrandInfo, 1));
};


/**
 * @param {?proto.entity.SiteDetails.BrandInfo|undefined} value
 * @return {!proto.grpcwebPb.GetSiteBrandInfoReply} returns this
*/
proto.grpcwebPb.GetSiteBrandInfoReply.prototype.setBrandInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.GetSiteBrandInfoReply} returns this
 */
proto.grpcwebPb.GetSiteBrandInfoReply.prototype.clearBrandInfo = function() {
  return this.setBrandInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.GetSiteBrandInfoReply.prototype.hasBrandInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    applyAccountBudgetTargetsList: jspb.Message.toObjectList(msg.getApplyAccountBudgetTargetsList(),
    proto_entity_site_pb.AccountBudgetTarget.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest;
  return proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = new proto_entity_site_pb.AccountBudgetTarget;
      reader.readMessage(value,proto_entity_site_pb.AccountBudgetTarget.deserializeBinaryFromReader);
      msg.addApplyAccountBudgetTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApplyAccountBudgetTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entity_site_pb.AccountBudgetTarget.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated entity.AccountBudgetTarget apply_account_budget_targets = 2;
 * @return {!Array<!proto.entity.AccountBudgetTarget>}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.prototype.getApplyAccountBudgetTargetsList = function() {
  return /** @type{!Array<!proto.entity.AccountBudgetTarget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entity_site_pb.AccountBudgetTarget, 2));
};


/**
 * @param {!Array<!proto.entity.AccountBudgetTarget>} value
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest} returns this
*/
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.prototype.setApplyAccountBudgetTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.AccountBudgetTarget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.AccountBudgetTarget}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.prototype.addApplyAccountBudgetTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.AccountBudgetTarget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest} returns this
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsRequest.prototype.clearApplyAccountBudgetTargetsList = function() {
  return this.setApplyAccountBudgetTargetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountBudgetTargetsList: jspb.Message.toObjectList(msg.getAccountBudgetTargetsList(),
    proto_entity_site_pb.AccountBudgetTarget.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply;
  return proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_site_pb.AccountBudgetTarget;
      reader.readMessage(value,proto_entity_site_pb.AccountBudgetTarget.deserializeBinaryFromReader);
      msg.addAccountBudgetTargets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountBudgetTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_entity_site_pb.AccountBudgetTarget.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.AccountBudgetTarget account_budget_targets = 1;
 * @return {!Array<!proto.entity.AccountBudgetTarget>}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.prototype.getAccountBudgetTargetsList = function() {
  return /** @type{!Array<!proto.entity.AccountBudgetTarget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entity_site_pb.AccountBudgetTarget, 1));
};


/**
 * @param {!Array<!proto.entity.AccountBudgetTarget>} value
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply} returns this
*/
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.prototype.setAccountBudgetTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.AccountBudgetTarget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.AccountBudgetTarget}
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.prototype.addAccountBudgetTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.AccountBudgetTarget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply} returns this
 */
proto.grpcwebPb.UpdateSiteAccountBudgetTargetsReply.prototype.clearAccountBudgetTargetsList = function() {
  return this.setAccountBudgetTargetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteUIBehaviorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    onlyAdminsCanEditStatuses: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    onlyAdminsCanEditBids: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    onlyAdminsCanEditBudgets: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    onlyAdminsCanEditDetails: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    onlyAdminsCanAddKeywords: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    onlyAdminsCanAddCampaigns: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteUIBehaviorRequest;
  return proto.grpcwebPb.UpdateSiteUIBehaviorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanEditStatuses(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanEditBids(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanEditBudgets(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanEditDetails(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanAddKeywords(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyAdminsCanAddCampaigns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteUIBehaviorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool only_admins_can_edit_statuses = 2;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.getOnlyAdminsCanEditStatuses = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.setOnlyAdminsCanEditStatuses = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.clearOnlyAdminsCanEditStatuses = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.hasOnlyAdminsCanEditStatuses = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool only_admins_can_edit_bids = 3;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.getOnlyAdminsCanEditBids = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.setOnlyAdminsCanEditBids = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.clearOnlyAdminsCanEditBids = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.hasOnlyAdminsCanEditBids = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool only_admins_can_edit_budgets = 4;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.getOnlyAdminsCanEditBudgets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.setOnlyAdminsCanEditBudgets = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.clearOnlyAdminsCanEditBudgets = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.hasOnlyAdminsCanEditBudgets = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool only_admins_can_edit_details = 5;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.getOnlyAdminsCanEditDetails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.setOnlyAdminsCanEditDetails = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.clearOnlyAdminsCanEditDetails = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.hasOnlyAdminsCanEditDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool only_admins_can_add_keywords = 6;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.getOnlyAdminsCanAddKeywords = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.setOnlyAdminsCanAddKeywords = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.clearOnlyAdminsCanAddKeywords = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.hasOnlyAdminsCanAddKeywords = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool only_admins_can_add_campaigns = 7;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.getOnlyAdminsCanAddCampaigns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.setOnlyAdminsCanAddCampaigns = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorRequest} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.clearOnlyAdminsCanAddCampaigns = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorRequest.prototype.hasOnlyAdminsCanAddCampaigns = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteUIBehaviorReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteUIBehaviorReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_entity_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorReply}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteUIBehaviorReply;
  return proto.grpcwebPb.UpdateSiteUIBehaviorReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteUIBehaviorReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorReply}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_site_pb.Site;
      reader.readMessage(value,proto_entity_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteUIBehaviorReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteUIBehaviorReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.Site site = 1;
 * @return {?proto.entity.Site}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.prototype.getSite = function() {
  return /** @type{?proto.entity.Site} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.Site, 1));
};


/**
 * @param {?proto.entity.Site|undefined} value
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorReply} returns this
*/
proto.grpcwebPb.UpdateSiteUIBehaviorReply.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteUIBehaviorReply} returns this
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteUIBehaviorReply.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteFeatureFlagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    featureFlagName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    removeFlag: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    newValue: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteFeatureFlagRequest;
  return proto.grpcwebPb.UpdateSiteFeatureFlagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeatureFlagName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoveFlag(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteFeatureFlagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeatureFlagName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRemoveFlag();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getNewValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string site_alias = 1;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.getSiteAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest} returns this
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.setSiteAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string feature_flag_name = 2;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.getFeatureFlagName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest} returns this
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.setFeatureFlagName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool remove_flag = 3;
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.getRemoveFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest} returns this
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.setRemoveFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string new_value = 4;
 * @return {string}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.getNewValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagRequest} returns this
 */
proto.grpcwebPb.UpdateSiteFeatureFlagRequest.prototype.setNewValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.prototype.toObject = function(opt_includeInstance) {
  return proto.grpcwebPb.UpdateSiteFeatureFlagReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpcwebPb.UpdateSiteFeatureFlagReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_entity_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagReply}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpcwebPb.UpdateSiteFeatureFlagReply;
  return proto.grpcwebPb.UpdateSiteFeatureFlagReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpcwebPb.UpdateSiteFeatureFlagReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagReply}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entity_site_pb.Site;
      reader.readMessage(value,proto_entity_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpcwebPb.UpdateSiteFeatureFlagReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpcwebPb.UpdateSiteFeatureFlagReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entity_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.Site site = 1;
 * @return {?proto.entity.Site}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.prototype.getSite = function() {
  return /** @type{?proto.entity.Site} */ (
    jspb.Message.getWrapperField(this, proto_entity_site_pb.Site, 1));
};


/**
 * @param {?proto.entity.Site|undefined} value
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagReply} returns this
*/
proto.grpcwebPb.UpdateSiteFeatureFlagReply.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpcwebPb.UpdateSiteFeatureFlagReply} returns this
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpcwebPb.UpdateSiteFeatureFlagReply.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.grpcwebPb);
