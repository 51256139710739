import React from "react";

import styled from "styled-components/macro";

import {
  AccountsIcon,
  AutomationIcon,
  BrandOverviewIcon,
  CampaignsIcon,
  CreateCampaignsIcon,
  CreateFacebookCampaignsIcon,
  ExportsIcon,
  FacebookCampaignsIcon,
  GoogleAdsIcon,
  HistoryIcon,
  ImpressionsIcon,
  LearnIcon,
  LogoutIcon,
  MetaIcon,
  NavBarIconBlock,
  NavBarItem,
  NavItemLabel,
  NavSectionHeader,
  ProductsIcon,
  SettingsIcon,
  SideWideNavBarIconsBlock
} from "./AmpdNavBarIcons";

import { backgroundDark, backgroundLight } from "ExtensionV2/styles/colors";

import { Site, User } from "ExtensionV2/queries/useSession";
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";
import { isManagerSite } from "Common/utils/site";
import {
  useShouldShowFacebookFeatures,
  useShouldShowFacebookPage
} from "Common/utils/featureFlags";
import { useCanUserAddCampaigns } from "Common/utils/siteUIBehavior";
import SimpleTooltip from "../SimpleTooltip";
import { CONTENT_AREA_HEIGHT, SIDEBAR_WIDTH } from "./AmpdWrapper";
import { sidebar } from "ExtensionV2/styles/zIndexes";

const StyledNavBarLinks = styled.div`
  grid-area: navbar-links;
`;

const StyledNavBarUserInfo = styled.div`
  grid-area: navbar-user-info;
  p {
    font-size: small;
    margin: 0;
    white-space: nowrap;
    opacity: 0;
  }
`;

const StyledNavBarChat = styled.div`
  grid-area: navbar-chat;
`;

const StyledAmpdNavBar = styled.nav<{ restingWidth: string }>`
  grid-area: navbar;
  width: ${props => `${props.restingWidth}`};
  transition: width 0.3s ease;
  height: ${CONTENT_AREA_HEIGHT};

  background-color: ${backgroundLight};

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 4em 9em;
  grid-template-areas:
    "navbar-links"
    "navbar-user-info"
    "navbar-chat";

  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  padding: 1em 0.25em 0 0.25em;
  border-right: 1px solid ${backgroundDark};
  z-index: ${sidebar};
  padding-left: 0.5rem;

  ${SideWideNavBarIconsBlock}:only-child {
    ${NavBarItem} {
      gap: 0.5em;
      margin-bottom: 0.5em;
    }
  }

  ${NavSectionHeader} {
    > div:first-child {
      // This margin renders the second icon somewhere not visible (instead of
      // making it display:none or visibility:hidden.) This makes the slide out
      // effect much smoother because the browser won't wait the load/render the
      // icon before starting the transition.
      margin-right: 25rem;
    }

    > div:nth-child(2) {
      opacity: 0;
    }
  }

  &:hover {
    width: 14em;
    transition: width 0.3s ease;

    ${NavSectionHeader} {
      > div:first-child {
        border: 1px solid red;
        opacity: 0;
        margin-right: 0rem;
      }

      > div:nth-child(2) {
        opacity: 1;
        transition: opacity 0.3s ease;
      }
    }

    ${NavItemLabel} {
      opacity: 1;
      transition: opacity 0.3s ease;
    }

    ${StyledNavBarUserInfo} {
      p {
        opacity: 1;
        transition: opacity 0.3s ease;
      }
    }
  }
`;

export const AmpdNavBar = ({
  automationPageEnabled,
  googleAdsHistoryEnabled,
  currentSite,
  user,
  clientSitesList,
  brandOverviewPageEnabled
}: {
  automationPageEnabled: boolean;
  googleAdsHistoryEnabled: boolean;
  currentSite: Site;
  user: User;
  clientSitesList: Array<SiteManagerLinkClient.AsObject>;
  brandOverviewPageEnabled: boolean;
}): JSX.Element => {
  const { hasAdwordsAccount, siteAlias } = currentSite;
  const { isCurrentSiteAdmin: isSiteAdmin } = user;
  const canAddCampaigns = useCanUserAddCampaigns();

  // A manager site is one that has client sites or ends with -mgr.  Manager sites can navigate to the
  // AccountsPage.
  const isManager = isManagerSite(currentSite, clientSitesList);

  // Any non-manager site is expected to hook up to a Google Ads account, so even
  // if it isn't currently linked to one, show the campaign related pages.  If
  // a manager site happens to be linked to Google Ads itself, show the campaign
  // related pages, too.  Otherwise, don't expect or encourage manager sites to
  // link to Google Ads directly.
  const isSiteForGoogleAdsCampaigns = !isManager || hasAdwordsAccount;

  const facebookEnabled = useShouldShowFacebookFeatures();
  const facebookActive = useShouldShowFacebookPage();

  return (
    <StyledAmpdNavBar restingWidth={SIDEBAR_WIDTH}>
      <StyledNavBarLinks>
        {/* Google Ads */}
        {isSiteForGoogleAdsCampaigns && (
          <NavBarIconBlock>
            <GoogleAdsIcon />
            {/* general icons */}
            {brandOverviewPageEnabled && (
              <BrandOverviewIcon siteAlias={siteAlias} />
            )}
            <CampaignsIcon siteAlias={siteAlias} />
            {canAddCampaigns && <CreateCampaignsIcon siteAlias={siteAlias} />}
            <ImpressionsIcon siteAlias={siteAlias} />
            <ProductsIcon siteAlias={siteAlias} />
            {automationPageEnabled && <AutomationIcon siteAlias={siteAlias} />}
            <ExportsIcon siteAlias={siteAlias} isSiteAdmin={isSiteAdmin} />
            {googleAdsHistoryEnabled && <HistoryIcon siteAlias={siteAlias} />}
          </NavBarIconBlock>
        )}

        {/* Facebook */}
        {!isManager && (
          <NavBarIconBlock>
            <SimpleTooltip
              position="right center"
              disabled={facebookActive}
              tooltip={
                facebookEnabled
                  ? "Login into your Meta Account on the Settings page to access the Meta dashboard"
                  : "Contact us about getting access to Meta"
              }
            >
              <div>
                <MetaIcon />
                <FacebookCampaignsIcon
                  disabled={!facebookActive}
                  siteAlias={siteAlias}
                />
                <CreateFacebookCampaignsIcon
                  disabled={!facebookActive}
                  siteAlias={siteAlias}
                />
              </div>
            </SimpleTooltip>
          </NavBarIconBlock>
        )}

        {/* Site Wide */}
        <SideWideNavBarIconsBlock>
          {isManager && <AccountsIcon siteAlias={siteAlias} />}
          <LearnIcon siteAlias={siteAlias} />
          <SettingsIcon siteAlias={siteAlias} />
        </SideWideNavBarIconsBlock>
      </StyledNavBarLinks>

      <StyledNavBarUserInfo>
        <p>Logged in as {`${user.userName}`}</p>
        <p>({user.isCurrentSiteAdmin ? "Admin user" : "Non-admin user"})</p>
        <LogoutIcon />
      </StyledNavBarUserInfo>

      <StyledNavBarChat />
    </StyledAmpdNavBar>
  );
};
