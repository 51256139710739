import moment, { Moment } from "moment";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { GRPCWebClient } from "Common/utils/grpc";
import {
  GetGoogleAdsChangeHistoryReply,
  GetGoogleAdsChangeHistoryRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import {
  GoogleAdsChangeEvent,
  GoogleAdsChangeEventConstraints
} from "Common/proto/warehousePb/googleAds_pb";
import { momentFromTimestampProto } from "Common/utils/DateUtilities";
import { CampaignStatusEnum } from "Common/google/ads/googleads/v18/enums/campaign_status_pb";
import { convertMicrosToCurrencyUnit } from "Common/utils/money";
import { None } from "Common/utils/tsUtils";
import { useMemo } from "react";

export type DateString = string;

export const DATE_STRING_FORMAT = "YYYY-MM-DD";

const Status = CampaignStatusEnum.CampaignStatus;
type Status = CampaignStatusEnum.CampaignStatus;

const fetchCampaignsChangeHistory = async (
  siteAlias: string,
  campaignIds: Array<string>
): Promise<Array<GetGoogleAdsChangeHistoryReply.Campaign>> => {
  const req = new GetGoogleAdsChangeHistoryRequest();
  req.setSiteAlias(siteAlias);
  req.setMaxEvents(1000);
  req.setUpdateChangeHistoryFirst(false);

  const constraints = new GoogleAdsChangeEventConstraints();
  const campaignConstraints = new GoogleAdsChangeEventConstraints.HierarchyConstraints();
  campaignConstraints.setIdsList(campaignIds.map(id => Number(id)));
  constraints.setCampaignConstraints(campaignConstraints);

  req.setConstraints(constraints);

  const reply = await GRPCWebClient.getGoogleAdsChangeHistory(req, {});

  return reply.getCampaignsList();
};

const useCampaignsChangeHistoryProto = (
  siteAlias: string,
  campaignIds: Array<string>
): UseQueryResult<Array<GetGoogleAdsChangeHistoryReply.Campaign>, unknown> => {
  const halfHour = 1000 * 60 * 5; // 5 minutes

  return useQuery({
    queryKey: ["campaignChangeHistory", siteAlias, campaignIds],
    staleTime: halfHour,
    cacheTime: halfHour,
    enabled: !!siteAlias && campaignIds.length > 0,
    queryFn: async () => fetchCampaignsChangeHistory(siteAlias, campaignIds)
  });
};

export const useBudgetHistoryDayByDay = (
  siteAlias: string,
  campaignIds: Array<string>,
  startDate: string,
  endDate: string,
  startDateByCampaignId: Map<string, string>,
  currentStatusByCampaignId: Map<string, Status>,
  currentBudgetByCampaignId: Map<string, number>
): Map<string, Map<DateString, [Status, number]>> => {
  const {
    data: campaignsChangeHistory,
    error: campaignsChangeHistoryError
  } = useCampaignsChangeHistoryProto(siteAlias, campaignIds);

  const budgetHistoryByCampaignId = useMemo(() => {
    const _budgetHistoryByCampaignId = new Map();

    const startDateMoment = moment(startDate).startOf("day");
    const endDateMoment = moment(endDate).startOf("day");

    // Set a default history based on the current state for currently
    // enabled campaigns.
    for (const campaignId of campaignIds) {
      const campaignStartDate = startDateByCampaignId.get(campaignId) || "";
      const campaignStartDateMoment = campaignStartDate
        ? moment(campaignStartDate)
        : startDateMoment;

      const currentStatus =
        currentStatusByCampaignId.get(campaignId) || Status.PAUSED;

      if (currentStatus === Status.ENABLED) {
        const currentBudget = currentBudgetByCampaignId.get(campaignId) || 0;

        const defaultBudgetHistory = determineCampaignBudgetHistory(
          startDateMoment,
          endDateMoment,
          campaignStartDateMoment,
          currentStatus,
          currentBudget,
          null
        );

        _budgetHistoryByCampaignId.set(campaignId, defaultBudgetHistory);
      }
    }

    if (campaignsChangeHistoryError || !campaignsChangeHistory) {
      return _budgetHistoryByCampaignId;
    }

    for (const campaignChangeHistory of campaignsChangeHistory) {
      const campaignId = String(campaignChangeHistory.getCampaignId());

      const campaignStartDate = startDateByCampaignId.get(campaignId) || "";
      const campaignStartDateMoment = campaignStartDate
        ? moment(campaignStartDate)
        : startDateMoment;
      const currentStatus =
        currentStatusByCampaignId.get(campaignId) || Status.PAUSED;
      const currentBudget = currentBudgetByCampaignId.get(campaignId) || 0;

      const budgetHistory = determineCampaignBudgetHistory(
        startDateMoment,
        endDateMoment,
        campaignStartDateMoment,
        currentStatus,
        currentBudget,
        campaignChangeHistory
      );

      if (budgetHistory) {
        _budgetHistoryByCampaignId.set(campaignId, budgetHistory);
      }
    }

    return _budgetHistoryByCampaignId;
  }, [
    campaignIds,
    campaignsChangeHistory,
    campaignsChangeHistoryError,
    currentBudgetByCampaignId,
    currentStatusByCampaignId,
    startDateByCampaignId,
    startDate,
    endDate
  ]);

  return budgetHistoryByCampaignId;
};

export function determineCampaignBudgetHistory(
  startDateMoment: Moment,
  endDateMoment: Moment,
  campaignStartDateMoment: Moment,
  currentStatus: Status,
  currentBudget: number,
  campaignChangeHistory: GetGoogleAdsChangeHistoryReply.Campaign | None
): Map<DateString, [Status, number]> {
  const budgetHistory: Map<DateString, [Status, number]> = new Map();

  let status = currentStatus;
  let budget = currentBudget;
  let eventIndex = 0;

  let events: Array<GoogleAdsChangeEvent> = [];
  if (campaignChangeHistory) {
    // Events should already be in reverse chronological order, but
    // let's resort in order to ensure that.
    events = campaignChangeHistory.getEventsList().sort((a, b) => {
      const atime = momentFromTimestampProto(a.getChangeTime());
      const btime = momentFromTimestampProto(b.getChangeTime());
      if (!atime) {
        return -1;
      }
      if (!btime) {
        return 1;
      }
      // Reverse chronological order.
      return atime.isAfter(btime) ? -1 : 0;
    });
  }

  for (
    let day = moment(endDateMoment);
    day.isSameOrAfter(startDateMoment);
    day = day.subtract(1, "day")
  ) {
    if (day.isBefore(campaignStartDateMoment)) {
      // If the current day in the date range is before the start date of the
      // campaign, let's just say the campaign is paused with a zero budget
      // for this day.
      budgetHistory.set(day.format(DATE_STRING_FORMAT), [Status.PAUSED, 0]);
      continue;
    }

    budgetHistory.set(day.format(DATE_STRING_FORMAT), [
      status,
      status === Status.ENABLED ? budget : 0
    ]);

    // Events are in reverse chronological order.
    let event = events[eventIndex];
    while (event && !event.getChangeTime()) {
      eventIndex++;
      event = events[eventIndex];
    }

    if (!event) {
      // If no more events, fill out the remaining days of the range with
      // the present values.
      continue;
    }

    const eventMoment = momentFromTimestampProto(event.getChangeTime());
    if (eventMoment && eventMoment.isBefore(day)) {
      // If the next event is before the current day of the loop, move back
      // to the next day.
      continue;
    }

    // Process and then move past this event.
    eventIndex++;

    if (
      event.getResourceType() == "CAMPAIGN" &&
      event.getChangedFieldsList().includes("status")
    ) {
      const oldStatus = event
        .getDetails()
        ?.getOldResource()
        ?.getCampaign()
        ?.getStatus();

      if (oldStatus === CampaignStatusEnum.CampaignStatus.ENABLED) {
        status = Status.ENABLED;
      } else {
        status = Status.PAUSED;
      }
    }

    if (
      event.getResourceType() == "CAMPAIGN_BUDGET" &&
      event.getChangedFieldsList().includes("amount_micros")
    ) {
      const oldBudgetMicros =
        event
          .getDetails()
          ?.getOldResource()
          ?.getCampaignBudget()
          ?.getAmountMicros() || 0;

      budget = convertMicrosToCurrencyUnit(oldBudgetMicros);
    }

    // Increase the loop variable, so the for loop will keep processing the same day.
    day = day.add(1, "day");
  }

  return budgetHistory;
}
