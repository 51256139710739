import { DashboardTable } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";
import {
  getStoredFacebookTableDataColumns,
  resetAllFacebookTableOptions,
  setStoredFacebookTableDataColumns
} from "Common/utils/savedTablePreferences";
import { GlobalDateContext } from "ExtensionV2";
import AmpdDataTableOptions, {
  useAmpdDataTableOptions
} from "ExtensionV2/components/AmpdDataTableOptions";
import { GlobalDatePicker } from "ExtensionV2/components/GlobalDatePicker";
import { popover } from "ExtensionV2/styles/zIndexes";
import React, { useContext, useMemo } from "react";
import { toast } from "react-toastify";
import { Button, Dropdown, Icon, Label, Popup } from "semantic-ui-react";
import styled from "styled-components";

import {
  allFacebookColumns,
  defaultFacebookColumns,
  FacebookAdsPerformanceTable,
  facebookPerformanceColumns,
  unhideableFacebookColumns
} from "./FacebookPerformanceTable";
import { useFacebookPerformanceRowData } from "./useFacebookPerformanceRowData";

const FilterLabel = styled.p`
  display: block;
  margin: 0 0 0.3em 0.5em;
  font-weight: bold;
  font-size: small;
`;

export function AdPerformanceTab(): JSX.Element {
  const [selectedRollup, setSelectedRollup] = React.useState<
    DashboardTable.ObjectType.Option
  >(DashboardTable.ObjectType.Option.FACEBOOK_AD);

  const { startDate, endDate } = useContext(GlobalDateContext);

  const {
    showFractions,
    showUnconvertedRevenue,
    selectableOptionsMap,
    selectedColumns,
    handleSelectColumnOption,
    handleResetTableOptions,
    handleToggleShowFraction,
    handleToggleShowUnconvertedRevenue
  } = useAmpdDataTableOptions({
    allColumns: allFacebookColumns,
    defaultColumns: defaultFacebookColumns,
    unhideableColumns: unhideableFacebookColumns,
    getStoredDataTableColumns: getStoredFacebookTableDataColumns,
    setStoredDataTableColumns: setStoredFacebookTableDataColumns,
    resetAllDataTableOptions: resetAllFacebookTableOptions
  });

  const {
    rowData,
    isLoading: rowsLoading,
    awaitingUpdatedConfigs,
    fetchConfigurationsError,
    fetchFacebookDataError
  } = useFacebookPerformanceRowData(startDate, endDate, selectedRollup);

  if (fetchFacebookDataError) {
    console.error(fetchFacebookDataError);
    toast.error(
      <div>
        <p>
          There was an error when retrieving your data from Meta. You may see
          incomplete or missing data in the table.
        </p>

        <div>
          <p>Additional Info: {fetchFacebookDataError}</p>
        </div>
      </div>,
      {
        toastId: fetchFacebookDataError
      }
    );
  }

  const displayColumns = useMemo(() => {
    if (selectedRollup === DashboardTable.ObjectType.Option.FACEBOOK_AD_SET) {
      return selectedColumns.filter(col => col !== "adSetName");
    } else if (selectedRollup === DashboardTable.ObjectType.Option.CAMPAIGN) {
      return selectedColumns.filter(
        col => col !== "adSetName" && col !== "campaignName"
      );
    }

    return selectedColumns;
  }, [selectedColumns, selectedRollup]);

  return (
    <>
      <div style={{ flexShrink: 0, zIndex: popover }}>
        <GlobalDatePicker />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <FacebookTableFilters onSelectRollupLevel={setSelectedRollup} />
          {awaitingUpdatedConfigs && (
            <Label>
              <Icon loading name="spinner" size="large" />
              Refreshing
            </Label>
          )}
          <Popup
            trigger={
              <Button style={{ height: "3em" }} size="medium">
                Table Options
              </Button>
            }
            on="click"
            position="bottom right"
            content={
              <AmpdDataTableOptions
                columnOptionsMap={selectableOptionsMap}
                columnTitles={facebookPerformanceColumns.reduce((acc, curr) => {
                  return { ...acc, [curr.name]: curr.displayName };
                }, {})}
                onToggleColumn={handleSelectColumnOption}
                onToggleShowFractions={handleToggleShowFraction}
                onToggleShowUnconvertedRevenue={
                  handleToggleShowUnconvertedRevenue
                }
                onResetTableOptions={handleResetTableOptions}
                showFractions={showFractions}
                showUnconvertedRevenue={showUnconvertedRevenue}
                showAmazonLagPeriodToggle={false}
                showFractionsToggle={true}
                showUnconvertedRevenueToggle={false}
              />
            }
          />
        </div>
      </div>

      <div
        style={{
          overflow: "auto"
        }}
      >
        <FacebookAdsPerformanceTable
          selectedColumns={displayColumns}
          showFractions={showFractions}
          rows={rowData}
          dataLoading={rowsLoading}
          dataError={fetchConfigurationsError}
        />
      </div>
    </>
  );
}

function FacebookTableFilters({
  onSelectRollupLevel
}: {
  onSelectRollupLevel: (level: DashboardTable.ObjectType.Option) => void;
}): JSX.Element {
  return (
    <div style={{ marginTop: "0.5em" }}>
      <FilterLabel>Select Level</FilterLabel>
      <Dropdown
        selection
        defaultValue={DashboardTable.ObjectType.Option.FACEBOOK_AD}
        options={[
          {
            key: "adId",
            text: "Ad",
            value: DashboardTable.ObjectType.Option.FACEBOOK_AD
          },
          {
            key: "adSetId",
            text: "Ad Set",
            value: DashboardTable.ObjectType.Option.FACEBOOK_AD_SET
          },
          {
            key: "campaignId",
            text: "Campaign",
            value: DashboardTable.ObjectType.Option.CAMPAIGN
          }
        ]}
        onChange={(_, { value }) => {
          onSelectRollupLevel(value as DashboardTable.ObjectType.Option);
        }}
      />
    </div>
  );
}
