import moment from "moment";

import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import { DateInput, MonthInput, YearInput } from "semantic-ui-calendar-react";

import { DATE_STRING_FORMAT } from "../queries/useChangeHistoryForDayByDayBudgets";

const MONTH_STRING_FORMAT = "MMM";
const YEAR_STRING_FORMAT = "YYYY";

export const ControlLabel = styled.p`
  display: block;
  margin: 0 0 0.3em 0.5em;
  font-weight: bold;
  font-size: small;
`;

const BudgetPlanningDateRangePicker: React.FC<{
  startDate: string;
  endDate: string;
  setDatesAndSelectTarget: (startDate: string, endDate: string) => void;
}> = ({ startDate, endDate, setDatesAndSelectTarget }) => {
  const [lockDateChange, setLockDateChange] = useState(false);

  // NOTE: The DateInput component returns the initial date if the user opens the
  // popup, edits the text, and hits enter.  To prevent that and keep the value
  // that the user typed in, let's lock the date change during an ENTER keypress.
  // However, let's not respect the lock if the typed in value (current value)
  // is badly formatted, because the DateInput will at least give us a valid
  // date string.
  const handleDateKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setLockDateChange(true);
    } else {
      setLockDateChange(false);
    }
  };

  const handleDateKeyUp = () => {
    setLockDateChange(false);
  };

  const handleStartDateChange = (value: string) => {
    if (
      !lockDateChange ||
      !moment(startDate, DATE_STRING_FORMAT, true).isValid()
    ) {
      setDatesAndSelectTarget(value, endDate);
    }
    setLockDateChange(false);
  };

  const handleEndDateChange = (value: string) => {
    if (
      !lockDateChange ||
      !moment(endDate, DATE_STRING_FORMAT, true).isValid()
    ) {
      setDatesAndSelectTarget(startDate, value);
    }
    setLockDateChange(false);
  };

  const handleMonthChange = (value: string) => {
    if (
      !lockDateChange ||
      !moment(startDate, DATE_STRING_FORMAT, true).isValid()
    ) {
      const year = moment(endDate).year();
      setDatesAndSelectTarget(
        moment(value, MONTH_STRING_FORMAT)
          .year(year)
          .startOf("month")
          .format(DATE_STRING_FORMAT),
        moment(value, MONTH_STRING_FORMAT)
          .year(year)
          .endOf("month")
          .format(DATE_STRING_FORMAT)
      );
    }
    setLockDateChange(false);
  };

  const handleYearChange = (value: string) => {
    if (
      !lockDateChange ||
      !moment(startDate, DATE_STRING_FORMAT, true).isValid()
    ) {
      if (Number(value) > moment(endDate).year()) {
        setDatesAndSelectTarget(
          moment()
            .year(Number(value))
            .startOf("year")
            .startOf("month")
            .format(DATE_STRING_FORMAT),
          moment()
            .year(Number(value))
            .startOf("year")
            .endOf("month")
            .format(DATE_STRING_FORMAT)
        );
      } else {
        setDatesAndSelectTarget(
          moment()
            .year(Number(value))
            .endOf("year")
            .startOf("month")
            .format(DATE_STRING_FORMAT),
          moment()
            .year(Number(value))
            .endOf("year")
            .endOf("month")
            .format(DATE_STRING_FORMAT)
        );
      }
    }
    setLockDateChange(false);
  };

  return (
    <Flex
      style={{ gap: "1em" }}
      alignItems="center"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="flex-start"
    >
      <div>
        <ControlLabel>Start Date</ControlLabel>
        <DateInput
          style={{ width: "10em" }}
          name="startDate"
          placeholder="Pacing Start Date"
          dateFormat={DATE_STRING_FORMAT}
          value={startDate}
          iconPosition="left"
          closable={true}
          onChange={(_e, { value }) => handleStartDateChange(value)}
          onKeyDown={handleDateKeyDown}
          onKeyUp={handleDateKeyUp}
        />
      </div>
      <div>
        <ControlLabel>End Date</ControlLabel>
        <DateInput
          style={{ width: "10em" }}
          name="endDate"
          placeholder="End Date"
          dateFormat={DATE_STRING_FORMAT}
          value={endDate}
          iconPosition="left"
          closable={true}
          onChange={(_e, { value }) => handleEndDateChange(value)}
          onKeyDown={handleDateKeyDown}
          onKeyUp={handleDateKeyUp}
        />
      </div>
      <div>
        <ControlLabel>Month</ControlLabel>
        <MonthInput
          style={{ width: "7em" }}
          name="month"
          placeholder="Month"
          dateFormat={MONTH_STRING_FORMAT}
          value={
            moment(endDate).isSame(
              moment(startDate)
                .endOf("month")
                .startOf("day")
            ) &&
            moment(startDate).isSame(
              moment(endDate)
                .startOf("month")
                .startOf("day")
            )
              ? moment(startDate).format(MONTH_STRING_FORMAT)
              : ""
          }
          iconPosition="left"
          closable={true}
          onChange={(_e, { value }) => handleMonthChange(value)}
          onKeyDown={handleDateKeyDown}
          onKeyUp={handleDateKeyUp}
        />
      </div>
      <div>
        <ControlLabel>Year</ControlLabel>
        <YearInput
          style={{ width: "6.5em" }}
          name="year"
          placeholder="Year"
          dateFormat={YEAR_STRING_FORMAT}
          value={moment(endDate).format(YEAR_STRING_FORMAT)}
          iconPosition="left"
          closable={true}
          onChange={(_e, { value }) => handleYearChange(value)}
          onKeyDown={handleDateKeyDown}
          onKeyUp={handleDateKeyUp}
        />
      </div>
    </Flex>
  );
};

export default BudgetPlanningDateRangePicker;
