// The know values of FacebookTableData.status are a common subset of
// FacebookConfiguration.<Campaign | AdSet | Ad>Details.Status.Option
// This is sometime called the "configured status" in the FB API.
// *This is in its own file to resolve a circular dependency issue with jest*
export enum CONFIGURED_STATUS {
  UNKNOWN = "UNKNOWN",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  DELETED = "DELETED",
  ARCHIVED = "ARCHIVED"
}
