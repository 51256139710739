import { asISO8601Date } from "Common/utils/DateUtilities";
import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";
import {
  fractionalNumberMetricDef,
  oneDecimalPercentageMetricDef,
  roundedNumberMetricDef,
  roundedPercentageMetricDef
} from "ExtensionV2/components/MetricColumns";
import moment from "moment";
import {
  MetricCell,
  lessIsBetter,
  moreIsBetter,
  trueNeutral
} from "./cells/MetricCell";
import { SimpleCell } from "./cells/SimpleCell";
import { TitleCell } from "./cells/TitleCell";
import { calculateRatioTotal, calculateSumTotal, Column } from "./column";
import { NumberFilterUI, StringFilterUI } from "./filters";
import { numberFilterFn, stringFilterFn } from "./filters";
import { MetricsTableData } from "./MetricsTable";
import { numberSort, stringSort } from "./sorting";

export const iso8601DateFormatter = getTimestampFormatter("YYYY-MM-DD");

export const COL_ATC = new Column<
  { addToCartClicks: number } & MetricsTableData
>({
  dataName: "addToCartClicks",
  displayName: "Carts",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("addToCartClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetricNoFractions,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_ATC_RATE = new Column<
  {
    addToCartRate: number;
    addToCartClicks: number;
    clicks: number;
  } & MetricsTableData
>({
  dataName: "addToCartRate",
  displayName: "Cart Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("addToCartClicks", "clicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_BRAND_REFERRAL_BONUS = new Column<
  { brandReferralBonus: number } & MetricsTableData
>({
  dataName: "brandReferralBonus",
  displayName: "Brand Referral Bonus",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("brandReferralBonus"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_CONVERSIONS = new Column<
  { conversions: number } & MetricsTableData
>({
  dataName: "conversions",
  displayName: "Conversions",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("conversions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_CONVERSION_RATE = new Column<
  {
    conversionRate: number;
    conversions: number;
    clicks: number;
  } & MetricsTableData
>({
  dataName: "conversionRate",
  displayName: "Conversion Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("conversions", "clicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_CONVERSION_VALUE = new Column<
  { conversionValue: number } & MetricsTableData
>({
  dataName: "conversionValue",
  displayName: "Revenue",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("conversionValue"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_CONVERSION = new Column<
  {
    costPerConversion: number;
  } & MetricsTableData
>({
  dataName: "costPerConversion",
  displayName: "Cost Per Conversion",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateRatioTotal("spend", "conversions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_ADD_TO_CART = new Column<
  {
    costPerAddToCart: number;
  } & MetricsTableData
>({
  dataName: "costPerAddToCart",
  displayName: "Cost Per Add To Cart",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateRatioTotal("spend", "addToCartClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_DETAIL_PAGE_VIEW = new Column<
  {
    costPerDetailPageView: number;
  } & MetricsTableData
>({
  dataName: "costPerDetailPageView",
  displayName: "Cost Per Detail Page View",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateRatioTotal("spend", "detailPageViews"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_MILLE = new Column<
  { costPerMille: number } & MetricsTableData
>({
  dataName: "costPerMille",
  displayName: "CPM",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateRatioTotal("spend", "impressions", 1000),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_DETAIL_PAGE_VIEW_RATE = new Column<
  {
    detailPageViewRate: number;
    detailPageViews: number;
    clicks: number;
  } & MetricsTableData
>({
  dataName: "detailPageViewRate",
  displayName: "Detail Page View Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("detailPageViews", "clicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_DETAIL_PAGE_VIEWS = new Column<
  { detailPageViews: number } & MetricsTableData
>({
  dataName: "detailPageViews",
  displayName: "Detail Page Views",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("detailPageViews"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetricNoFractions,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_CLICK = new Column<
  {
    costPerClick: number;
  } & MetricsTableData
>({
  dataName: "costPerClick",
  displayName: "CPC",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateRatioTotal("spend", "clicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSmallCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_CLICK_RATE = new Column<
  {
    clickRate: number;
    clicks: number;
    impressions: number;
  } & MetricsTableData
>({
  dataName: "clickRate",
  displayName: "Click Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("clicks", "impressions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_CLICKS = new Column<
  {
    clicks: number;
  } & MetricsTableData
>({
  dataName: "clicks",
  displayName: "Clicks",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("clicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetricNoFractions,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_IMPRESSIONS = new Column<
  { impressions: number } & MetricsTableData
>({
  dataName: "impressions",
  displayName: "Impressions",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("impressions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetricNoFractions,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_MARKETPLACE_CLICK_RATE = new Column<
  {
    marketplaceClickRate: number;
    marketplaceClicks: number;
    impressions: number;
  } & MetricsTableData
>({
  dataName: "marketplaceClickRate",
  displayName: "Marketplace Click Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("marketplaceClicks", "impressions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_MARKETPLACE_CLICKS = new Column<
  { marketplaceClicks: number } & MetricsTableData
>({
  dataName: "marketplaceClicks",
  displayName: "Marketplace Clicks",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("marketplaceClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetricNoFractions,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_MARKETPLACE_COST_PER_CLICK = new Column<
  {
    marketplaceCostPerClick: number;
  } & MetricsTableData
>({
  dataName: "marketplaceCostPerClick",
  displayName: "Marketplace Cost Per Click",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateRatioTotal("spend", "marketplaceClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSmallCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_CONVERSION_RATE = new Column<
  {
    ntbConversionRate: number;
    ntbConversions: number;
    conversions: number;
  } & MetricsTableData
>({
  dataName: "ntbConversionRate",
  displayName: "NTB Conversion Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("ntbConversions", "conversions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_CONVERSIONS = new Column<
  { ntbConversions: number } & MetricsTableData
>({
  dataName: "ntbConversions",
  displayName: "NTB Conversions",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("ntbConversions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetricNoFractions,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_NTB_CONVERSION = new Column<
  { costPerNTBConversion: number } & MetricsTableData
>({
  dataName: "costPerNTBConversion",
  displayName: "Cost Per NTB Conversion",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateRatioTotal("spend", "ntbConversions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_REVENUE = new Column<
  { ntbRevenue: number } & MetricsTableData
>({
  dataName: "ntbRevenue",
  displayName: "NTB Revenue",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("ntbRevenue"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_REVENUE_RATE = new Column<
  {
    ntbRevenueRate: number;
    ntbRevenue: number;
    conversionValue: number;
  } & MetricsTableData
>({
  dataName: "ntbRevenueRate",
  displayName: "NTB Revenue Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("ntbRevenue", "conversionValue"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_ROAS = new Column<{ ntbROAS: number } & MetricsTableData>({
  dataName: "ntbROAS",
  displayName: "NTB ROAS",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("ntbRevenue", "spend"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_UNITS_SOLD = new Column<
  { ntbUnitsSold: number } & MetricsTableData
>({
  dataName: "ntbUnitsSold",
  displayName: "NTB Units Sold",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("ntbUnitsSold"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetricNoFractions,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_UNITS_SOLD_RATE = new Column<
  {
    ntbUnitsSoldRate: number;
    ntbUnitsSold: number;
    unitsSold: number;
  } & MetricsTableData
>({
  dataName: "ntbUnitsSoldRate",
  displayName: "NTB Units Sold Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("ntbUnitsSold", "unitsSold"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_ROAS = new Column<
  {
    roas: number;
    conversionValue: number;
    spend: number;
  } & MetricsTableData
>({
  dataName: "roas",
  displayName: "ROAS",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("conversionValue", "spend"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_SPEND = new Column<{ spend: number } & MetricsTableData>({
  dataName: "spend",
  displayName: "Cost",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  totalFn: calculateSumTotal("spend"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  formatCSVFn: formatCSVCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_UNITS_SOLD = new Column<
  { unitsSold: number } & MetricsTableData
>({
  dataName: "unitsSold",
  displayName: "Units Sold",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("unitsSold"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetricNoFractions,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_ACCOUNT_ID = new Column<
  { accountID: string } & MetricsTableData
>({
  dataName: "accountID",
  displayName: "Account ID",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const COL_AD_ID = new Column<{ adID: string } & MetricsTableData>({
  dataName: "adID",
  displayName: "Ad ID",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const COL_AD_SET_ID = new Column<{ adSetID: string } & MetricsTableData>(
  {
    dataName: "adSetID",
    displayName: "Ad Set ID",
    displaySubtitle: "",
    tooltip: "",
    defaultSortDirection: "asc",
    compareStyle: trueNeutral,
    sortFn: stringSort,
    filterFn: stringFilterFn,
    formatFn: n => String(n),
    tableCell: SimpleCell,
    filterUI: StringFilterUI
  }
);

export const COL_CAMPAIGN_ID = new Column<
  { campaignID: string } & MetricsTableData
>({
  dataName: "campaignID",
  displayName: "Campaign ID",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const COL_NAME = new Column<
  { name: string } & MetricsTableData & MetricsTableData
>({
  dataName: "name",
  displayName: "Name",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: TitleCell,
  filterUI: StringFilterUI
});

export const COL_METRICS_START = new Column<
  { metricsStart?: number } & MetricsTableData
>({
  dataName: "metricsStart",
  displayName: "Metrics Start",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: iso8601DateFormatter,
  tableCell: SimpleCell,
  filterUI: NumberFilterUI,
  formatCSVFn: iso8601DateFormatter
});

export const COL_METRICS_END = new Column<
  { metricsEnd?: number } & MetricsTableData
>({
  dataName: "metricsEnd",
  displayName: "Metrics End",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: iso8601DateFormatter,
  tableCell: SimpleCell,
  filterUI: NumberFilterUI,
  formatCSVFn: iso8601DateFormatter
});

function formatSimpleMetricNoFractions(
  rawVal: unknown,
  _showFractions: boolean
): string {
  return formatMetric(roundedNumberMetricDef, rawVal).toString();
}

export function formatSimpleMetric(
  rawVal: unknown,
  showFractions: boolean
): string {
  return formatMetric(
    showFractions ? fractionalNumberMetricDef : roundedNumberMetricDef,
    rawVal
  ).toString();
}

function formatCurrency(rawVal: unknown, showFractions?: boolean): string {
  return formatMetric(
    getCurrencyMetricDef("USD", showFractions),
    rawVal
  ).toString();
}

function formatCSVCurrency(rawVal: unknown, showFractions?: boolean): string {
  if (typeof rawVal !== "number" || isNaN(rawVal) || !isFinite(rawVal)) {
    return "";
  }

  return formatMetric(
    getCurrencyMetricDef("USD", showFractions),
    rawVal
  ).toString();
}

function formatSmallCurrency(rawVal: unknown, _showFractions: boolean): string {
  return formatMetric(getCurrencyMetricDef("USD", true), rawVal).toString();
}

function formatPercent(rawVal: unknown, showFractions: boolean): string {
  return formatMetric(
    showFractions ? oneDecimalPercentageMetricDef : roundedPercentageMetricDef,
    rawVal
  ).toString();
}

export function getTimestampFormatter(
  format?: string // moment.js format string ex "YYYY-MM-DD"
): (rawVal: unknown, showFractions?: boolean) => string {
  return (rawVal: unknown) => {
    if (!rawVal || typeof rawVal !== "number") {
      return "";
    }

    if (!format) {
      return asISO8601Date(moment(rawVal));
    }
    return moment(rawVal).format(format);
  };
}
