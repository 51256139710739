import React from "react";

import {
  CHECKED_COL_WIDTH,
  CHECKED_COL,
  ACCOUNT_NAME_COL,
  ACCOUNT_ID_COL,
  BILLING_STATUS_COL,
  CAMPAIGNS_COL,
  AACOS_RANGE_COL,
  AACOS_RANGE_MIN,
  AACOS_RANGE_MAX,
  DAILY_BUDGET_COL
} from "./AccountsTable";

import { NotApplicableCell } from "../../components/CampaignsTableRow";

import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnCompareInfo,
  renderFormattedValue,
  InlineLoadingSpinner
} from "../../components/MetricColumns";
import {
  DataTableRow,
  DataTableFreezeLeftCell,
  DataTableMetricCell,
  DataTableRowCell
} from "../../components/AmpdDataTable";

function AccountsTableTotalRow({
  clientSitesLoading,
  metricsLoading,
  totalRowData,
  rowIndex,
  columns,
  showFractions
}) {
  return (
    <>
      <DataTableRow>
        {columns.map(columnName => {
          switch (columnName) {
            case CHECKED_COL:
              return (
                <DataTableFreezeLeftCell
                  key={columnName}
                  as="th"
                  style={{ bottom: 0 }}
                  rowIndex={rowIndex}
                />
              );
            case ACCOUNT_NAME_COL:
              return (
                <DataTableFreezeLeftCell
                  key={columnName}
                  as="th"
                  style={{ bottom: 0, left: CHECKED_COL_WIDTH }}
                  rowIndex={rowIndex}
                >
                  {totalRowData[columnName] || "Total"}
                </DataTableFreezeLeftCell>
              );
            case BILLING_STATUS_COL:
            case ACCOUNT_ID_COL:
              return (
                <NotApplicableCell
                  key={columnName}
                  as="th"
                  rowIndex={rowIndex}
                />
              );
            case CAMPAIGNS_COL: {
              return (
                <DataTableRowCell as="th" key={columnName} rowIndex={rowIndex}>
                  {totalRowData[columnName]}{" "}
                  {clientSitesLoading && <InlineLoadingSpinner size="mini" />}
                </DataTableRowCell>
              );
            }
            case DAILY_BUDGET_COL: {
              const costCurrencyCode = totalRowData.costCurrencyCode;
              const revenueCurrencyCode = totalRowData.revenueCurrencyCode;

              const formattedValue = formatMetricColumnValue(
                columnName,
                totalRowData[columnName],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              return (
                <DataTableMetricCell
                  as="th"
                  key={columnName}
                  rowIndex={rowIndex}
                >
                  {renderFormattedValue(formattedValue)}{" "}
                  {clientSitesLoading && <InlineLoadingSpinner size="mini" />}
                </DataTableMetricCell>
              );
            }
            case AACOS_RANGE_COL: {
              const costCurrencyCode = totalRowData.costCurrencyCode;
              const revenueCurrencyCode = totalRowData.revenueCurrencyCode;

              const formattedMinValue = formatMetricColumnValue(
                COLUMN_DATA_KEYS.aacos,
                totalRowData[AACOS_RANGE_MIN],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );
              const formattedMaxValue = formatMetricColumnValue(
                COLUMN_DATA_KEYS.aacos,
                totalRowData[AACOS_RANGE_MAX],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              return (
                <DataTableRowCell as="th" key={columnName} rowIndex={rowIndex}>
                  {String(formattedMinValue) === String(formattedMaxValue) ? (
                    formattedMinValue
                  ) : (
                    <span>
                      {formattedMinValue}&nbsp;-&nbsp;{formattedMaxValue}
                    </span>
                  )}{" "}
                  {(clientSitesLoading || metricsLoading) && (
                    <InlineLoadingSpinner size="mini" />
                  )}
                </DataTableRowCell>
              );
            }

            default: {
              const costCurrencyCode = totalRowData.costCurrencyCode;
              const revenueCurrencyCode = totalRowData.revenueCurrencyCode;

              const formattedValue = formatMetricColumnValue(
                columnName,
                totalRowData[columnName],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              // If we want to show comparison annotations, we need to compile
              // some information based on any compareMetrics value stored in
              // the data object.
              const compareInfo = getMetricColumnCompareInfo(
                columnName,
                totalRowData,
                costCurrencyCode,
                revenueCurrencyCode
              );

              return (
                <DataTableMetricCell
                  as="th"
                  key={columnName}
                  rowIndex={rowIndex}
                >
                  {renderFormattedValue(formattedValue, undefined, compareInfo)}{" "}
                  {(clientSitesLoading || metricsLoading) && (
                    <InlineLoadingSpinner size="mini" />
                  )}
                </DataTableMetricCell>
              );
            }
          }
        })}
      </DataTableRow>
    </>
  );
}

export default AccountsTableTotalRow;
