import { GRPCWebClient } from "Common/utils/grpc";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { sendGAEvent } from "../components/GA";
import { AccountBudgetTarget } from "Common/proto/entity/site_pb";
import {
  UpdateSiteAccountBudgetTargetsReply,
  UpdateSiteAccountBudgetTargetsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Site_pb";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { Retailer } from "Common/proto/common/retailer_pb";
import { roundMicrosToCurrencyMinimumUnit } from "Common/utils/googleAds";
import { convertToMicros } from "Common/utils/money";

const ACTION_GA_CATEGORY = "Ampd: Update Account Budget Targets";

interface UpdateSiteAccountBudgetTargetArgs {
  siteAlias: string;
  budgetAmount: number;
  currencyCode: string;
  campaignPlatform: CampaignPlatform.Option;
  retailer: Retailer.Option;
  startDate: string;
  endDate: string;
  campaignNameFilter: string;
}

// Updates the BrandInfo in the site's SiteDetails
export const useUpdateSiteAccountBudgetTarget = (): UseMutationResult<
  UpdateSiteAccountBudgetTargetsReply.AsObject,
  unknown,
  UpdateSiteAccountBudgetTargetArgs,
  unknown
> => {
  const mutationFn = async (
    updateSiteAccountBudgetTargetArgs: UpdateSiteAccountBudgetTargetArgs
  ): Promise<UpdateSiteAccountBudgetTargetsReply.AsObject> => {
    for (const [key, value] of Object.entries(
      updateSiteAccountBudgetTargetArgs
    )) {
      if (key === "campaignNameFilter") {
        // Can be empty.
        continue;
      }

      if (value == null || value?.length === 0) {
        throw new Error(`Update SiteBrandInfo: Missing argument: ${key}`);
      }
    }

    const {
      siteAlias,
      budgetAmount,
      currencyCode,
      campaignPlatform,
      retailer,
      startDate,
      endDate,
      campaignNameFilter
    } = updateSiteAccountBudgetTargetArgs;

    // TODO (FACEBOOK): This needs to be updated if Facebook supports
    // a different set of currency codes.
    const budgetAmountMicros = budgetAmount
      ? roundMicrosToCurrencyMinimumUnit(
          convertToMicros(budgetAmount),
          currencyCode,
          false
        )
      : 0;

    const budgetTargetProto = new AccountBudgetTarget();
    budgetTargetProto.setBudgetAmountMicros(budgetAmountMicros);
    budgetTargetProto.setCampaignPlatform(campaignPlatform);
    budgetTargetProto.setRetailer(retailer);
    budgetTargetProto.setCampaignNameFilter(campaignNameFilter);
    budgetTargetProto.setStartDate(startDate);
    budgetTargetProto.setEndDate(endDate);

    const updateRequest = new UpdateSiteAccountBudgetTargetsRequest();
    updateRequest.setSiteAlias(siteAlias);
    updateRequest.setApplyAccountBudgetTargetsList([budgetTargetProto]);

    const response = await GRPCWebClient.updateSiteAccountBudgetTargets(
      updateRequest,
      null
    );

    sendGAEvent(
      ACTION_GA_CATEGORY,
      "Update Brand Info Customer Behavior Settings",
      siteAlias
    );

    return response?.toObject();
  };

  // Log any update errors
  const onError = (err: Error) => console.error(err);

  return useMutation({ mutationFn, onError });
};
