import _ from "lodash";
import moment from "moment";

import React, { useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";

import googleAdsSVG from "../../../assets/icons/google-ads.svg";
import metaPNG from "../../../assets/icons/meta.png";
import amazonWithText from "../../../assets/amazon-with-text.svg";
import walmartWithText from "../../../assets/walmart-with-text.svg";

import {
  CHECKED_COL_WIDTH,
  CHECKED_COL,
  ACCOUNT_NAME_COL,
  ACCOUNT_ID_COL,
  BILLING_STATUS_COL,
  CAMPAIGNS_COL,
  AACOS_RANGE_COL,
  AACOS_RANGE_MIN,
  AACOS_RANGE_MAX,
  GOOGLE_ADS_CAMPAIGNS,
  FACEBOOK_CAMPAIGNS,
  DAILY_BUDGET_COL
} from "./AccountsTable";

import {
  defaultSortComparator,
  DataTableFreezeLeftCell,
  DataTableMetricCell,
  DataTableRowCell,
  SelectableDataTableRow
} from "../../components/AmpdDataTable";

import { pluralize } from "Common/utils/strings";
import { formatCustomerId } from "Common/utils/googleAds";
import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnCompareInfo,
  renderFormattedValue
} from "../../components/MetricColumns";

import { getClientSiteLabels } from "./AccountsTable";
import AccountsTableCampaignRow from "./AccountsTableCampaignRow";
import AccountsTableCommandRow from "./AccountsTableCommandRow";
import AccountsTableSubClientRow from "./AccountsTableSubClientRow";
import HubspotLink from "./HubspotLink";
import { TableRowErrorContainer } from "../../components/CampaignsTableRowIssues";
import SimpleTooltip from "../../components/SimpleTooltip";
import { isValidEmail } from "Common/utils/email";
import { SelectedPhrase } from "../../components/PhraseSelector";
import { CampaignPlatform } from "../../../Common/proto/common/campaignPlatform_pb";
import styled from "styled-components";
import { Retailer } from "../../../Common/proto/common/retailer_pb";
import { NO_CAMPAIGNS_FOR_ROW } from "./getClientSiteRowData";

export const PlatformLabelImage = styled.img`
  margin-right: 0.5em;
  margin-top: 0.2em;
`;

const InactiveSpan = styled.span`
  font-style: italic;
  opacity: 0.5;
`;

export const InlineLoadingSpinnerStatic = ({ size = "small" }) => (
  <Icon style={{ zIndex: 0 }} name="spinner" size={size} color="blue" />
);

function AccountsTableRow({
  clientSiteAlias,
  clientSiteLoading,
  clientSiteErrors,
  siteRowData,
  isSelectedSite,
  setSelectedSite,
  rowIndex,
  columns,
  metricsLoading,
  campaignTypeFilter,
  sortColumn,
  sortIsAscending,
  showFractions,
  showHubspotLinks,
  onCheckClick
}) {
  const [mouseDownTime, setMouseDownTime] = useState(null);

  const subClientRowDataList = siteRowData.subClientRowDataList;
  const subClientCount = _.size(subClientRowDataList);

  const subRowComponents = useMemo(() => {
    if (!isSelectedSite) {
      return null;
    }

    const sortedRowDataList = getSortedCampaigns(
      siteRowData.campaignRowDataList || [],
      sortColumn,
      sortIsAscending
    );

    const campaignCount = _.size(sortedRowDataList);
    const lastRowIndex = subClientCount + campaignCount - 1;

    return (
      <>
        <AccountsTableCommandRow
          clientSiteAlias={clientSiteAlias}
          clientSiteLoading={clientSiteLoading}
          subClientCount={subClientCount}
          campaignCount={campaignCount}
          rowIndex={rowIndex}
          columns={columns}
          campaignTypeFilter={campaignTypeFilter}
          isLastRow={lastRowIndex < 0}
        />
        {subClientRowDataList &&
          subClientRowDataList.map((subClientRowData, index) => {
            return (
              <AccountsTableSubClientRow
                key={subClientRowData.clientSiteAlias}
                subClientSiteAlias={subClientRowData.clientSiteAlias}
                subClientRowData={subClientRowData}
                rowIndex={rowIndex}
                columns={columns}
                campaignTypeFilter={campaignTypeFilter}
                isLastRow={index === lastRowIndex}
                showHubspotLinks={showHubspotLinks}
              />
            );
          })}
        {sortedRowDataList.map((campaignRowData, index) => {
          return (
            <AccountsTableCampaignRow
              key={campaignRowData.campaignId}
              clientSiteAlias={clientSiteAlias}
              clientSiteLoading={clientSiteLoading}
              campaignRowData={campaignRowData}
              rowIndex={rowIndex}
              columns={columns}
              metricsLoading={metricsLoading}
              showFractions={showFractions}
              isLastRow={index + subClientCount === lastRowIndex}
            />
          );
        })}
      </>
    );
  }, [
    isSelectedSite,
    siteRowData,
    clientSiteAlias,
    clientSiteLoading,
    campaignTypeFilter,
    metricsLoading,
    columns,
    rowIndex,
    sortColumn,
    sortIsAscending,
    showFractions,
    showHubspotLinks,
    subClientCount,
    subClientRowDataList
  ]);

  let hideMetrics =
    clientSiteErrors?.length ||
    (!siteRowData.googleAdsCustomerIdsList?.length &&
      !siteRowData.facebookAccountIdsList?.length);

  let subClientDescription;
  if (subClientCount > 0) {
    subClientDescription = pluralize(subClientCount, "sub-client");
  } else if (siteRowData[CAMPAIGNS_COL] === NO_CAMPAIGNS_FOR_ROW) {
    hideMetrics = true;
  }

  const handleMouseDown = () => {
    setMouseDownTime(Date.now());
  };
  const handleMouseUp = () => {
    // Customers may try to drag on the site name so they can copy it to their
    // clipboard. This mouseup/mousedown tries to account for that by not navigating
    // on when a long click is detected.
    if (Date.now() - mouseDownTime > 500) {
      return;
    }
    setSelectedSite(isSelectedSite ? null : clientSiteAlias);
  };

  return (
    <>
      <SelectableDataTableRow
        key={rowIndex}
        isSelected={isSelectedSite}
        keywordsOpen={isSelectedSite}
      >
        {columns.map(columnName => {
          switch (columnName) {
            case CHECKED_COL: {
              const siteLabels = getClientSiteLabels(siteRowData);
              const userSiteLabels = siteLabels.filter(label =>
                isValidEmail(label)
              );
              const nonUserSiteLabels = siteLabels.filter(
                label => !isValidEmail(label)
              );
              let nonUserTooltipContent = "No labels";
              if (nonUserSiteLabels.length > 0) {
                nonUserTooltipContent = (
                  <span>
                    Labels:{" "}
                    {nonUserSiteLabels.map(label => (
                      <SelectedPhrase
                        key={label}
                        phrase={label}
                        canEdit={false}
                        canRemove={false}
                      />
                    ))}
                  </span>
                );
              }
              let userTooltipContent = "Unassigned";
              if (userSiteLabels.length > 0) {
                userTooltipContent = (
                  <span>
                    Assigned:{" "}
                    {userSiteLabels.map(label => (
                      <SelectedPhrase
                        key={label}
                        phrase={label}
                        canEdit={false}
                        canRemove={false}
                      />
                    ))}
                  </span>
                );
              }
              const tooltipContent = (
                <span>
                  {nonUserTooltipContent}
                  <br />
                  {userTooltipContent}
                </span>
              );

              return (
                <DataTableFreezeLeftCell
                  style={{ textAlign: "center" }}
                  key={columnName}
                  rowIndex={rowIndex}
                >
                  {onCheckClick && (
                    <SimpleTooltip
                      tooltip={tooltipContent}
                      position="right center"
                    >
                      <Icon
                        style={{ margin: "-0.3em" }}
                        name={siteRowData[columnName]}
                        onClick={onCheckClick}
                      />
                    </SimpleTooltip>
                  )}
                </DataTableFreezeLeftCell>
              );
            }
            case ACCOUNT_NAME_COL:
              return (
                <DataTableFreezeLeftCell
                  style={{ left: CHECKED_COL_WIDTH }}
                  key={columnName}
                  rowIndex={rowIndex}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                >
                  <Icon
                    name={isSelectedSite ? "angle down" : "angle right"}
                    style={{ marginRight: "0.5em" }}
                  />
                  {siteRowData[columnName]}
                  {showHubspotLinks && (
                    <HubspotLink
                      siteName={siteRowData[columnName]}
                      hubspotCompanyId={siteRowData.hubSpotCompanyId}
                    />
                  )}
                </DataTableFreezeLeftCell>
              );
            case ACCOUNT_ID_COL:
              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  <AccountIDsTableCellContent
                    campaignTypeFilter={campaignTypeFilter}
                    rowData={siteRowData}
                    clientSiteErrors={clientSiteErrors}
                  />
                </DataTableRowCell>
              );
            case BILLING_STATUS_COL: {
              let status = siteRowData[BILLING_STATUS_COL];
              if (status === "active" && siteRowData.billingCanceledTime) {
                if (siteRowData.billingActiveUntilTime) {
                  status = `${status} until ${moment
                    .unix(siteRowData.billingActiveUntilTime.seconds)
                    .format("MMM Do YYYY")}`;
                }
                status = `${status} (canceled ${moment
                  .unix(siteRowData.billingCanceledTime.seconds)
                  .format("MMM Do YYYY")})`;
              }

              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  {status}
                </DataTableRowCell>
              );
            }
            case CAMPAIGNS_COL: {
              let contents = null;
              if (subClientDescription) {
                contents = (
                  <small>
                    <em>{subClientDescription}</em>
                  </small>
                );
              } else if (clientSiteLoading) {
                contents = <InlineLoadingSpinnerStatic />;
              } else if (siteRowData[columnName] !== NO_CAMPAIGNS_FOR_ROW) {
                contents = siteRowData[columnName];
              }

              return (
                <DataTableRowCell
                  style={{ textAlign: "center" }}
                  key={columnName}
                  rowIndex={rowIndex}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                >
                  {contents}
                </DataTableRowCell>
              );
            }
            case AACOS_RANGE_COL: {
              if (hideMetrics) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex} />
                );
              }

              if (clientSiteLoading || metricsLoading) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                    <InlineLoadingSpinnerStatic />
                  </DataTableRowCell>
                );
              }

              const costCurrencyCode = siteRowData.costCurrencyCode;
              const revenueCurrencyCode = siteRowData.revenueCurrencyCode;

              const formattedMinValue = formatMetricColumnValue(
                COLUMN_DATA_KEYS.aacos,
                siteRowData[AACOS_RANGE_MIN],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );
              const formattedMaxValue = formatMetricColumnValue(
                COLUMN_DATA_KEYS.aacos,
                siteRowData[AACOS_RANGE_MAX],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  {String(formattedMinValue) === String(formattedMaxValue) ? (
                    formattedMinValue
                  ) : (
                    <span>
                      {formattedMinValue}&nbsp;-&nbsp;{formattedMaxValue}
                    </span>
                  )}
                </DataTableRowCell>
              );
            }
            case DAILY_BUDGET_COL: {
              if (clientSiteLoading) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                    <InlineLoadingSpinnerStatic />
                  </DataTableRowCell>
                );
              }

              const costCurrencyCode = siteRowData.costCurrencyCode;
              const revenueCurrencyCode = siteRowData.revenueCurrencyCode;

              const formattedValue = formatMetricColumnValue(
                columnName,
                siteRowData[columnName],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              return (
                <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                  {!!siteRowData[columnName] &&
                    renderFormattedValue(formattedValue)}
                </DataTableMetricCell>
              );
            }

            default: {
              if (hideMetrics) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex} />
                );
              }

              if (clientSiteLoading || metricsLoading) {
                return (
                  <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                    <InlineLoadingSpinnerStatic />
                  </DataTableRowCell>
                );
              }

              const costCurrencyCode = siteRowData.costCurrencyCode;
              const revenueCurrencyCode = siteRowData.revenueCurrencyCode;

              const formattedValue = formatMetricColumnValue(
                columnName,
                siteRowData[columnName],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              // If we want to show comparison annotations, we need to compile
              // some information based on any compareMetrics value stored in
              // the data object.
              const compareInfo = getMetricColumnCompareInfo(
                columnName,
                siteRowData,
                costCurrencyCode,
                revenueCurrencyCode
              );

              return (
                <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                  {renderFormattedValue(formattedValue, undefined, compareInfo)}
                </DataTableMetricCell>
              );
            }
          }
        })}
      </SelectableDataTableRow>

      {subRowComponents}
    </>
  );
}

export const PlatformLabel = ({ campaignPlatform, text, inactive }) => {
  return (
    <p style={{ marginBottom: "0.2em", whiteSpace: "nowrap" }}>
      {campaignPlatform === CampaignPlatform.Option.GOOGLE_ADS && (
        <PlatformLabelImage src={googleAdsSVG} width={15} />
      )}
      {campaignPlatform === CampaignPlatform.Option.FACEBOOK && (
        <PlatformLabelImage src={metaPNG} width={15} />
      )}
      {inactive ? <InactiveSpan>{text}</InactiveSpan> : text}
    </p>
  );
};

export const RetailerLabel = ({ retailer, text, inactive }) => {
  return (
    <p style={{ marginBottom: "0.2em", whiteSpace: "nowrap" }}>
      {retailer === Retailer.Option.AMAZON && (
        <PlatformLabelImage src={amazonWithText} height={15} />
      )}
      {retailer === Retailer.Option.WALMART && (
        <PlatformLabelImage src={walmartWithText} height={15} />
      )}
      {inactive ? <InactiveSpan>{text}</InactiveSpan> : text}
    </p>
  );
};

export const AccountIDsTableCellContent = ({
  campaignTypeFilter,
  rowData,
  clientSiteErrors
}) => {
  return (
    <>
      {clientSiteErrors?.length && (
        <TableRowErrorContainer>
          <small>No Account Access</small>
        </TableRowErrorContainer>
      )}
      {campaignTypeFilter.includes(GOOGLE_ADS_CAMPAIGNS) && (
        <>
          {rowData.googleAdsCustomerIdsList?.map(customerId => (
            <PlatformLabel
              key={customerId}
              campaignPlatform={CampaignPlatform.Option.GOOGLE_ADS}
              text={formatCustomerId(customerId, "\u2011")}
            />
          ))}
          {rowData.googleAdsErrorDescription && (
            <TableRowErrorContainer>
              <small>{rowData.googleAdsErrorDescription}</small>
            </TableRowErrorContainer>
          )}
        </>
      )}
      {campaignTypeFilter.includes(FACEBOOK_CAMPAIGNS) && (
        <>
          {rowData.facebookAccountIdsList?.map(accountId => (
            <PlatformLabel
              key={accountId}
              campaignPlatform={CampaignPlatform.Option.FACEBOOK}
              text={accountId}
            />
          ))}
          {rowData.facebookErrorDescription && (
            <TableRowErrorContainer>
              <small>{rowData.facebookErrorDescription}</small>
            </TableRowErrorContainer>
          )}
        </>
      )}
    </>
  );
};

const getSortedCampaigns = (campaignRows, sortColumn, sortIsAscending) => {
  let useSortColumn = sortColumn;
  // Since we are sorting the campaigns within the accounts table, using the account
  // column names, change target column name as appropriate.
  if (sortColumn === ACCOUNT_NAME_COL) {
    useSortColumn = COLUMN_DATA_KEYS.campaignName;
  } else if (sortColumn === AACOS_RANGE_COL) {
    useSortColumn = COLUMN_DATA_KEYS.aacos;
  } else if (sortColumn === ACCOUNT_ID_COL) {
    useSortColumn = COLUMN_DATA_KEYS.status;
  }

  const sortedCampaignRows = campaignRows.sort((a, b) => {
    let compare = defaultSortComparator(a, b, useSortColumn, sortIsAscending);

    // If the sort column values compare equal, further sort by campaignName
    // (always ascending if not the primary sort column).
    if (sortColumn !== ACCOUNT_NAME_COL && compare === 0) {
      compare = defaultSortComparator(
        a,
        b,
        COLUMN_DATA_KEYS.campaignName,
        true
      );
    }

    return compare;
  });

  return sortedCampaignRows;
};

export default AccountsTableRow;
