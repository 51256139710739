import _ from "lodash";
import React, { useContext } from "react";
import { formatMetric } from "Common/utils/metrics";
import { diffPercentageMetricDef } from "ExtensionV2/components/MetricColumns";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { comparisonGreen, comparisonRed } from "ExtensionV2/styles/colors";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import {
  CompareMetricsLoadingContext,
  PrimaryMetricsLoadingContext,
  ShowFractionsContext
} from "../contexts";
import { DepthAwareTableCell } from "./DepthAwareTableCell";

const CompareText = styled.p<{ isLoading: boolean }>`
  font-style: italic;
  opacity: ${props => (props.isLoading ? 0.3 : 1)};
  font-size: small;
`;

const CompareNeutral = styled(CompareText)`
  opacity: 0.6;
`;

const CompareGood = styled(CompareText)`
  font-weight: 500;
  color: ${comparisonGreen};
`;

const CompareBad = styled(CompareText)`
  font-weight: 500;
  color: ${comparisonRed};
`;

// [when current < previous, when current == previous, when current > previous]
export type CompareStyles = [CompareStyle, CompareStyle, CompareStyle];

export type CompareStyle =
  | typeof CompareGood
  | typeof CompareNeutral
  | typeof CompareBad;

export const lessIsBetter: CompareStyles = [
  CompareGood,
  CompareNeutral,
  CompareBad
];
export const moreIsBetter: CompareStyles = [
  CompareBad,
  CompareNeutral,
  CompareGood
];
export const trueNeutral: CompareStyles = [
  CompareNeutral,
  CompareNeutral,
  CompareNeutral
];

const StyledMetricCell = styled(DepthAwareTableCell)`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;

    p.previous {
      font-style: italic;
    }
  }
`;

export const MetricCell = ({
  rowID,
  val,
  prev,
  formatFn,
  compareStyles,
  depth
}: {
  rowID: string;
  val: unknown;
  prev?: unknown;
  formatFn?: (rawVal: unknown, showFraction: boolean) => string;
  compareStyles?: CompareStyles;
  depth?: number;
}): JSX.Element => {
  const [showFractions] = useContext(ShowFractionsContext);
  const primaryMetricsLoading = useContext(PrimaryMetricsLoadingContext);

  const formattedVal = formatFn
    ? formatFn(val, showFractions ?? false)
    : String(val);

  return (
    <StyledMetricCell key={rowID} depth={depth}>
      <div>
        {primaryMetricsLoading ? (
          <Loader
            size="tiny"
            inline={true}
            active={true}
            style={{ zIndex: 0 }}
          />
        ) : (
          <p>{formattedVal}</p>
        )}
        <CompareAnnotation
          val={val}
          prev={prev}
          showFractions={showFractions}
          formatFn={formatFn}
          compareStyles={compareStyles}
        />
      </div>
    </StyledMetricCell>
  );
};

const CompareAnnotation = ({
  val,
  prev,
  showFractions,
  formatFn,
  compareStyles
}: {
  val: unknown;
  prev: unknown;
  showFractions: boolean;
  formatFn?: (rawVal: unknown, showFraction: boolean) => string;
  compareStyles?: CompareStyles;
}) => {
  const compareMetricsLoading = useContext(CompareMetricsLoadingContext);

  if (!compareStyles || typeof prev !== "number" || typeof val !== "number") {
    return <></>;
  }

  const diff = val - prev;
  const percentChange = diff / prev;
  const formattedDiff = formatFn ? formatFn(diff, showFractions) : diff;
  const formattedPrev = formatFn ? formatFn(prev, showFractions) : prev;

  let diffString = "";
  let CompareComponent = CompareNeutral;
  if (_.isFinite(percentChange)) {
    diffString = `(${formatMetric(diffPercentageMetricDef, percentChange)})`;
    CompareComponent = compareStyles[Math.sign(percentChange) + 1];
  } else if (_.isNaN(percentChange)) {
    diffString = "(+0%)";
  } else {
    diffString = "(+)";
  }

  return (
    <SimpleTooltip
      tooltip={
        <div>
          Difference: {formattedDiff}
          <br />
          Previous: {formattedPrev}
        </div>
      }
    >
      {compareMetricsLoading ? (
        <Loader
          aria-label="compare metrics loading"
          size="mini"
          inline={true}
          active={true}
          style={{ zIndex: 0 }}
        />
      ) : (
        <CompareComponent isLoading={compareMetricsLoading}>
          {diffString}
        </CompareComponent>
      )}
    </SimpleTooltip>
  );
};
