import { useState } from "react";

import {
  QueryObserverResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "@tanstack/react-query";

import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { streamProcessor } from "Common/utils/grpcStreams";
import {
  GetCampaignConfigurationsReply,
  GetCampaignConfigurationsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";

export type CampaignConfiguration = GetCampaignConfigurationsReply.CampaignConfiguration.AsObject;

export type CampaignConfigurationsByCampaignIdResult = Map<
  string,
  CampaignConfiguration
>;

// Type of the promise returned by the 'refetch' of useCampaignConfigurationsByCampaignId.
export type RefetchCampaignConfigurationsResult = Promise<
  QueryObserverResult<CampaignConfigurationsByCampaignIdResult, unknown>
>;

// Queries for Ampd campaign configurations for a site and returns a mapping from
// campaignId strings to CampaignConfiguration message-as-object.  The initial
// query for a site returns configurations stored in the database, but all
// subsequent refetch queries return freshly updated up-to-date configurations.
// This means the first query is fast and the subsequent queries will be a little
// slower.
export const useCampaignConfigurationsByCampaignId = (
  siteAlias: string,
  onlyAmpdCampaigns?: boolean
): UseQueryResult<CampaignConfigurationsByCampaignIdResult, unknown> => {
  const queryKey = ["campaignConfigurations", siteAlias, onlyAmpdCampaigns];
  const queryClient = useQueryClient();
  const [queriedSiteAlias, setQueriedSiteAlias] = useState<string | null>(null);

  return useQuery({
    queryKey,
    staleTime: 10 * 60 * 1_000, // 10 minutes
    cacheTime: 11 * 60 * 1_000, // 11 minutes
    enabled: !!siteAlias,
    queryFn: async () => {
      const req = new GetCampaignConfigurationsRequest();
      req.setSiteAlias(siteAlias);
      req.setCampaignPlatform(CampaignPlatform.Option.GOOGLE_ADS);

      // In addition to checking our queriedSiteAlias state, check if the query
      // is already in the cache.  If so, that means this is a refetch call, so
      // get the most up-to-date configurations.
      if (
        queryClient.getQueryData(queryKey) ||
        queriedSiteAlias === siteAlias
      ) {
        // On subsequent queries for the same site, return up-to-date Ampd
        // configurations and then partial configurations for all campaigns.
        req.setDoUpdateStoredAmpdCampaigns(true);
        req.setReturnUpToDateAmpdCampaignConfigurations(true);
        req.setReturnAllPartialCampaignConfigurations(!onlyAmpdCampaigns);
      } else {
        // On the first query for the site, only return stored configurations.
        req.setReturnStoredAmpdCampaignConfigurations(true);
      }

      const campaignConfigurationsByCampaignId = new Map<
        string,
        CampaignConfiguration
      >();

      setQueriedSiteAlias(siteAlias);

      await streamProcessor(
        GRPCWebCallbackClient.getCampaignConfigurations(req),
        (reply: GetCampaignConfigurationsReply) => {
          const arePartialConfigurations =
            reply.getType() ===
            GetCampaignConfigurationsReply.CampaignConfigurationType.Option
              .PARTIAL;

          reply
            .getCampaignConfigurationsList()
            .forEach(campaignConfiguration => {
              const campaignId = campaignConfiguration
                ?.getAmpdResourceConfiguration()
                ?.getGoogleAds()
                ?.getCampaignConfiguration()
                ?.getCampaignId();
              if (campaignId) {
                const campaignIdStr = String(campaignId);

                // Put the configuration in the map if these are complete (that is,
                // Ampd campaigns) or if they are partial and not already in the
                // map (so we don't override a complete configuration with a
                // partial one).
                if (
                  !arePartialConfigurations ||
                  !campaignConfigurationsByCampaignId.has(campaignIdStr)
                ) {
                  campaignConfigurationsByCampaignId.set(
                    campaignIdStr,
                    campaignConfiguration.toObject()
                  );
                }
              }
            });
        }
      );

      return campaignConfigurationsByCampaignId;
    }
  });
};
