import { CacheBehavior } from "Common/proto/common/cache_pb";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { Retailer } from "Common/proto/common/retailer_pb";
import { DashboardTable } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";
import { removeNullAndUndefined } from "Common/utils/tsUtils";
import { fetchDashboardTableMetrics } from "ExtensionV2/queries/useDashboardTableMetrics";
import { fetchStoredFacebookCampaignConfigurations } from "ExtensionV2/queries/useFacebookCampaignConfigurations";
import moment from "moment";
import { rowDataToResourceType } from "./cells";
import {
  emptyAttributes,
  emptyMetrics,
  FacebookTableData
} from "./useFacebookTableData";
import { mapCampaignConfigToAttributeRows } from "./useFacebookTableRowAttributes";
import { mapCampaignMetricsToRowMetrics } from "./useFacebookTableRowMetrics";

export async function fetchDailyFacebookTableData({
  siteAlias,
  startDate,
  endDate,
  objectType,
  respectAdAttributionDate
}: {
  siteAlias: string;
  startDate: string;
  endDate: string;
  objectType: DashboardTable.ObjectType.Option;
  respectAdAttributionDate: boolean;
}): Promise<Array<FacebookTableData>> {
  try {
    const campaignConfigs = await fetchStoredFacebookCampaignConfigurations(
      siteAlias
    );

    const campaignIDs = campaignConfigs
      .map(
        config =>
          config.ampdResourceConfiguration?.facebook?.campaignDetails
            ?.campaignId
      )
      .filter(removeNullAndUndefined);

    const dailyMetrics = await fetchDashboardTableMetrics({
      siteAlias,
      campaignPlatform: CampaignPlatform.Option.FACEBOOK,
      retailer: Retailer.Option.AMAZON,
      objectTypes: [objectType],
      dateRangeStartDate: startDate,
      dateRangeEndDate: endDate,
      campaignIds: campaignIDs,
      queryByDay: true,
      respectAdAttributionDate,
      cacheBehavior: CacheBehavior.Option.CACHE_FIRST
    });

    const attributesData = mapCampaignConfigToAttributeRows(
      campaignConfigs
    ).filter(row => objectType === rowDataToResourceType(row)[0]);

    const metricsRows = mapCampaignMetricsToRowMetrics(dailyMetrics);

    // Generate all possible date combinations
    const allDates = generateDateRange(startDate, endDate);
    const allRowIDs = [...new Set(attributesData.map(attr => attr.rowID))];

    const completeRows: Array<FacebookTableData> = allRowIDs.flatMap(rowID =>
      allDates.map(date => {
        const metricRowForDate = metricsRows.find(
          row =>
            row.rowID === rowID &&
            moment(row.metricsStart).format("YYYY-MM-DD") === date
        );

        const attributes =
          attributesData.find(attributesRow => attributesRow.rowID === rowID) ??
          emptyAttributes();

        // If no existing metrics, create an empty row
        if (!metricRowForDate) {
          return {
            ...emptyMetrics(date, date),
            ...attributes
          };
        }

        return {
          ...metricRowForDate,
          ...attributes
        };
      })
    );

    return completeRows;
  } catch (e) {
    console.error(e);
  }

  return [];
}

function generateDateRange(startDate: string, endDate: string): string[] {
  const start = moment(startDate);
  const end = moment(endDate);
  const daysDifference = end.diff(start, "days");

  const dates = [];
  for (let i = 0; i <= daysDifference; i += 1) {
    dates.push(
      start
        .clone()
        .add(i, "days")
        .format("YYYY-MM-DD")
    );
  }
  return dates;
}
