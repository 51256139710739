import { useMemo } from "react";
import { Retailer } from "Common/proto/common/retailer_pb";
import getCompareToDates from "ExtensionV2/state/getCompareToDates";
import {
  FacebookTableRowAttributes,
  useFacebookTableRowAttributes
} from "./useFacebookTableRowAttributes";
import {
  FacebookTableRowMetrics,
  useFacebookTableRowMetrics
} from "./useFacebookTableRowMetrics";
import { CONFIGURED_STATUS } from "./configuredStatus";
import moment from "moment";

export type FacebookTableData = FacebookTableRowMetrics &
  FacebookTableRowAttributes & {
    prev?: Omit<FacebookTableData, "rowID" | "prev">;
  };

export const useFacebookTableData = (
  siteAlias: string,
  startDate: string,
  endDate: string,
  respectAdAttributionDate: boolean,
  compareTo?: string
): {
  data: Array<FacebookTableData> | undefined;
  primaryMetricsLoading: boolean;
  prevMetricsFetching: boolean;
  error: unknown;
} => {
  const attributesQuery = useFacebookTableRowAttributes(siteAlias);

  const campaignIds = useMemo(() => {
    return (
      attributesQuery.data
        ?.filter(row => row.campaignID && !row.adSetID && !row.adID)
        .map(row => row.campaignID) || []
    );
  }, [attributesQuery.data]);

  const metricsQuery = useFacebookTableRowMetrics(
    siteAlias,
    campaignIds,
    startDate,
    endDate,
    respectAdAttributionDate
  );

  const [compareStart, compareEnd] = getCompareToDates(
    startDate,
    endDate,
    compareTo
  );

  const wantsCompare = compareStart?.length > 0 && compareEnd?.length > 0;

  const prevMetricsQuery = useFacebookTableRowMetrics(
    wantsCompare ? siteAlias : "",
    campaignIds,
    compareStart,
    compareEnd,
    respectAdAttributionDate
  );

  const data = useMemo(() => {
    if (!metricsQuery.data || !attributesQuery.data) {
      return;
    }

    const data: Array<FacebookTableData> = [];
    for (const attrs of attributesQuery.data) {
      const metric = metricsQuery.data.find(m => m.rowID === attrs.rowID) ?? {
        ...emptyMetrics(startDate, endDate),
        rowID: attrs.rowID
      };

      const row: FacebookTableData = {
        ...attrs,
        ...metric,
        rowID: metric.rowID
      };

      if (wantsCompare) {
        const prevMetric = prevMetricsQuery.data?.find(
          m => m.rowID === attrs.rowID
        ) ?? {
          ...emptyMetrics(compareStart, compareEnd),
          rowID: attrs.rowID
        };

        row.prev = {
          ...attrs,
          ...prevMetric
        };
      }

      data.push(row);
    }

    return data;
  }, [
    metricsQuery.data,
    attributesQuery.data,
    startDate,
    endDate,
    wantsCompare,
    prevMetricsQuery.data,
    compareStart,
    compareEnd
  ]);

  return {
    data,
    primaryMetricsLoading: metricsQuery.isLoading || attributesQuery.isLoading,
    // we want isFetching instead of isLoading because the query isLoading state
    // will be always true if the query is never enabled.
    prevMetricsFetching: prevMetricsQuery.isFetching,
    error: metricsQuery.error || attributesQuery.error || prevMetricsQuery.error
  };
};

export const emptyRow = (
  startDate?: string,
  endDate?: string
): FacebookTableData => ({
  ...emptyAttributes(),
  ...emptyMetrics(startDate, endDate)
});

export const emptyAttributes: () => FacebookTableRowAttributes = () => ({
  rowID: "",
  accountID: "",
  adID: "",
  adName: "",
  adSetID: "",
  adSetName: "",
  campaignID: "",
  campaignName: "",
  name: "",
  retailer: Retailer.Option.UNKNOWN,
  status: CONFIGURED_STATUS.UNKNOWN,
  effectiveStatus: "",
  dailyBudgetCents: 0,
  lifetimeBudgetCents: 0,
  bidAmountCents: 0,
  adTarget: "",
  attributionStartDate: 0
});

export const emptyMetrics = (
  startDate?: string,
  endDate?: string
): FacebookTableRowMetrics => {
  const emptyMetric = {
    rowID: "",
    addToCartRate: NaN,
    addToCartClicks: 0,
    brandReferralBonus: 0,
    conversionRate: NaN,
    conversions: 0,
    conversionValue: 0,
    costPerAddToCart: NaN,
    costPerConversion: NaN,
    costPerDetailPageView: NaN,
    costPerMille: NaN,
    costPerNTBConversion: NaN,
    detailPageViewRate: NaN,
    detailPageViews: 0,
    costPerClick: NaN,
    clickRate: NaN,
    clicks: 0,
    impressions: 0,
    marketplaceClickRate: NaN,
    marketplaceClicks: 0,
    marketplaceCostPerClick: NaN,
    ntbConversionRate: NaN,
    ntbConversions: 0,
    ntbRevenue: 0,
    ntbRevenueRate: NaN,
    ntbROAS: NaN,
    ntbUnitsSold: 0,
    ntbUnitsSoldRate: NaN,
    roas: NaN,
    spend: 0,
    unitsSold: 0
  };

  let metricsStart = 0;
  if (startDate) {
    metricsStart =
      moment(startDate)
        .startOf("day")
        .valueOf() ?? 0;
  }

  let metricsEnd = 0;
  if (endDate) {
    metricsEnd =
      moment(endDate)
        .endOf("day")
        .valueOf() ?? 0;
  }

  return {
    ...emptyMetric,
    metricsStart,
    metricsEnd
  };
};
