import React from "react";
import { generatePath } from "react-router-dom";

import {
  CHECKED_COL_WIDTH,
  CHECKED_COL,
  ACCOUNT_NAME_COL,
  ACCOUNT_ID_COL,
  BILLING_STATUS_COL,
  CAMPAIGNS_COL,
  AACOS_RANGE_COL,
  DAILY_BUDGET_COL,
  FACEBOOK_CAMPAIGNS,
  GOOGLE_ADS_CAMPAIGNS
} from "./AccountsTable";

import { CampaignName } from "../../components/CampaignsTableRow";

import {
  DATE_RANGE_CUSTOM_START_URL_PARAM,
  DATE_RANGE_CUSTOM_END_URL_PARAM,
  DATE_RANGE_NAME_URL_PARAM,
  DATE_RANGE_COMPARE_TO_URL_PARAM
} from "ExtensionV2/components/GlobalDatePicker";

import {
  CAMPAIGN_ID_QUERY_PARAM,
  CAMPAIGNS_PATH,
  DASHBOARD_PATH,
  AMPD_ROOT,
  FACEBOOK_PAGE_V2_PATH
} from "ExtensionV2/ExtensionV2";
import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnCompareInfo,
  renderFormattedValue
} from "../../components/MetricColumns";
import {
  DataTableRow,
  DataTableFreezeLeftCell,
  DataTableMetricCell,
  DataTableRowCell
} from "../../components/AmpdDataTable";
import {
  InlineLoadingSpinnerStatic,
  PlatformLabel,
  RetailerLabel
} from "./AccountsTableRow";
import { CampaignPlatform } from "../../../Common/proto/common/campaignPlatform_pb";

function AccountsTableCampaignRow({
  clientSiteAlias,
  clientSiteLoading,
  campaignRowData,
  columns,
  rowIndex,
  metricsLoading,
  showFractions,
  isLastRow
}) {
  const campaignLink = (
    <LinkToClient
      clientSiteAlias={clientSiteAlias}
      campaignPlatform={campaignRowData.campaignPlatform}
      campaignId={campaignRowData.campaignId}
      facebookAdSetAndAdIds={campaignRowData.facebookAdSetAndAdIds}
    >
      {campaignRowData.name}
    </LinkToClient>
  );

  const isInactive = campaignRowData.status !== "ENABLED";

  return (
    <DataTableRow
      key={rowIndex}
      style={{ fontSize: "small" }}
      isLastKeywordRow={isLastRow}
      isInactive={isInactive}
    >
      {columns.map(columnName => {
        switch (columnName) {
          case CHECKED_COL:
            return <DataTableFreezeLeftCell key={columnName} />;
          case ACCOUNT_NAME_COL:
            return (
              <DataTableFreezeLeftCell
                style={{ left: CHECKED_COL_WIDTH }}
                key={columnName}
                rowIndex={rowIndex}
              >
                <CampaignName>{campaignLink}</CampaignName>
              </DataTableFreezeLeftCell>
            );
          case ACCOUNT_ID_COL:
            return (
              <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                <PlatformLabel
                  campaignPlatform={campaignRowData.campaignPlatform}
                  text={campaignRowData.status}
                  inactive={campaignRowData.status !== "ENABLED"}
                />
              </DataTableRowCell>
            );
          case BILLING_STATUS_COL:
            return <DataTableRowCell key={columnName} rowIndex={rowIndex} />;
          case CAMPAIGNS_COL:
            return (
              <DataTableRowCell
                style={{ textAlign: "center" }}
                key={columnName}
                rowIndex={rowIndex}
              >
                <RetailerLabel retailer={campaignRowData.retailer} />
              </DataTableRowCell>
            );
          case DAILY_BUDGET_COL: {
            const costCurrencyCode = campaignRowData.costCurrencyCode;
            const revenueCurrencyCode = campaignRowData.revenueCurrencyCode;

            const formattedValue = formatMetricColumnValue(
              columnName,
              campaignRowData[columnName],
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            );

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                {renderFormattedValue(formattedValue)}
              </DataTableMetricCell>
            );
          }

          case AACOS_RANGE_COL: {
            if (clientSiteLoading || metricsLoading) {
              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  <InlineLoadingSpinnerStatic />
                </DataTableRowCell>
              );
            }

            const costCurrencyCode = campaignRowData.costCurrencyCode;
            const revenueCurrencyCode = campaignRowData.revenueCurrencyCode;

            const formattedValue = formatMetricColumnValue(
              COLUMN_DATA_KEYS.aacos,
              campaignRowData[COLUMN_DATA_KEYS.aacos],
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            );

            // If we want to show comparison annotations, we need to compile
            // some information based on any compareMetrics value stored in
            // the data object.
            const compareInfo = getMetricColumnCompareInfo(
              COLUMN_DATA_KEYS.aacos,
              campaignRowData,
              costCurrencyCode,
              revenueCurrencyCode
            );

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                {renderFormattedValue(formattedValue, undefined, compareInfo)}
              </DataTableMetricCell>
            );
          }

          default: {
            if (clientSiteLoading || metricsLoading) {
              return (
                <DataTableRowCell key={columnName} rowIndex={rowIndex}>
                  <InlineLoadingSpinnerStatic />
                </DataTableRowCell>
              );
            }

            const costCurrencyCode = campaignRowData.costCurrencyCode;
            const revenueCurrencyCode = campaignRowData.revenueCurrencyCode;

            const formattedValue = formatMetricColumnValue(
              columnName,
              campaignRowData[columnName],
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            );

            // If we want to show comparison annotations, we need to compile
            // some information based on any compareMetrics value stored in
            // the data object.
            const compareInfo = getMetricColumnCompareInfo(
              columnName,
              campaignRowData,
              costCurrencyCode,
              revenueCurrencyCode
            );

            return (
              <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                {renderFormattedValue(formattedValue, undefined, compareInfo)}
              </DataTableMetricCell>
            );
          }
        }
      })}
    </DataTableRow>
  );
}

export function LinkToClient({
  clientSiteAlias,
  campaignId,
  facebookAdSetAndAdIds,
  campaignPlatform,
  campaignTypeFilter,
  children
}) {
  const searchParams = new URLSearchParams(window.location.search);

  let clientSiteUrl;
  if (
    campaignPlatform === CampaignPlatform.Option.FACEBOOK ||
    (campaignTypeFilter?.includes(FACEBOOK_CAMPAIGNS) &&
      !campaignTypeFilter?.includes(GOOGLE_ADS_CAMPAIGNS))
  ) {
    clientSiteUrl = new URL(
      generatePath(`${AMPD_ROOT}/${FACEBOOK_PAGE_V2_PATH}`, {
        siteAlias: clientSiteAlias
      }),
      window.location.origin
    );

    if (campaignId) {
      const expandedRows = [campaignId];
      if (facebookAdSetAndAdIds) {
        const adSetIds = new Set();
        for (const [adSetId] of facebookAdSetAndAdIds) {
          if (!adSetIds.has(adSetId)) {
            expandedRows.push(`${campaignId}-${adSetId}`);
            adSetIds.add(adSetId);
          }
        }
      }

      clientSiteUrl.searchParams.set(
        "fb",
        JSON.stringify({
          level: "campaign",
          expandedRows: expandedRows
        })
      );
    }
  } else {
    if (campaignId) {
      clientSiteUrl = new URL(
        generatePath(`${AMPD_ROOT}/${CAMPAIGNS_PATH}`, {
          siteAlias: clientSiteAlias
        }),
        window.location.origin
      );

      clientSiteUrl.searchParams.set(CAMPAIGN_ID_QUERY_PARAM, campaignId);
    } else {
      // Since there is no specific campaignId, link to the default path and let
      // the router decide whether to go to the Campaigns page or the Accounts page.
      clientSiteUrl = new URL(
        generatePath(`/t/${DASHBOARD_PATH}`, {
          siteAlias: clientSiteAlias
        }),
        window.location.origin
      );
    }
  }

  const customStart = searchParams.get(DATE_RANGE_CUSTOM_START_URL_PARAM);
  if (customStart) {
    clientSiteUrl.searchParams.set(
      DATE_RANGE_CUSTOM_START_URL_PARAM,
      customStart
    );
  }
  const customEnd = searchParams.get(DATE_RANGE_CUSTOM_END_URL_PARAM);
  if (customEnd) {
    clientSiteUrl.searchParams.set(DATE_RANGE_CUSTOM_END_URL_PARAM, customEnd);
  }
  const dateRange = searchParams.get(DATE_RANGE_NAME_URL_PARAM);
  if (dateRange) {
    clientSiteUrl.searchParams.set(DATE_RANGE_NAME_URL_PARAM, dateRange);
  }
  const compareTo = searchParams.get(DATE_RANGE_COMPARE_TO_URL_PARAM);
  if (compareTo) {
    clientSiteUrl.searchParams.set(DATE_RANGE_COMPARE_TO_URL_PARAM, compareTo);
  }

  return (
    <a href={clientSiteUrl} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
}

export default AccountsTableCampaignRow;
