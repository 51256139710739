import React from "react";

import { DropdownEditCell } from "ExtensionV2/components/metricsTable/cells/DropdownEditCell";
import { Facebook } from "Common/proto/common/facebook_pb";
import {
  useUpdateFacebookDailyBudget,
  useUpdateFacebookLifetimeBudget,
  useUpdateFacebookStatus,
  useUpdateFacebookBidAmount
} from "ExtensionV2/queries/useUpdateFacebookStatus";
import { extractErrorMessage } from "Common/errors/error";
import { toast } from "react-toastify";
import { USD_OFFSET } from "../FacebookPage/EditFacebookBudget";
import {
  EnterFloatCell,
  StyledEnterFloatCell
} from "ExtensionV2/components/metricsTable/cells/EnterFloatCell";
import { DropdownProps, Icon } from "semantic-ui-react";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { SimpleCell } from "ExtensionV2/components/metricsTable/cells/SimpleCell";
import _ from "lodash";
import { stringForEnum } from "Common/utils/proto";
import { CONFIGURED_STATUS } from "./configuredStatus";
import { FacebookTableData } from "./useFacebookTableData";
import { TitleCell } from "ExtensionV2/components/metricsTable/cells/TitleCell";
import { MetricsTableData } from "ExtensionV2/components/metricsTable/MetricsTable";
import { backgroundMedium, semanticBorder } from "ExtensionV2/styles/colors";
import styled from "styled-components";
import { DashboardTable } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";

const editStatusDropdownOptions = [
  {
    key: "Active",
    text: "Active",
    value: CONFIGURED_STATUS.ACTIVE
  },
  {
    key: "Paused",
    text: "Paused",
    value: CONFIGURED_STATUS.PAUSED
  }
];

// If the status is DELETED or ARCHIVED, the cell should be read-only.
export const EditFacebookStatusCell = ({
  rowData,
  depth
}: {
  rowData: FacebookTableData;
  depth?: number;
}): JSX.Element => {
  const [rowType, resourceId] = rowDataToResourceType(rowData);
  const {
    mutate: doUpdateStatus,
    isLoading: updateLoading
  } = useUpdateFacebookStatus(rowType);

  if (
    rowType === DashboardTable.ObjectType.Option.UNSPECIFIED ||
    resourceId === ""
  ) {
    return <td></td>;
  }

  const status = rowData.status;
  if (status == CONFIGURED_STATUS.UNKNOWN) {
    return <td></td>;
  }

  const onEdit = (nextStatus: DropdownProps["value"]) => {
    let nextStatusOption: Facebook.API.UpdateStatus.Option;
    if (nextStatus === CONFIGURED_STATUS.ACTIVE) {
      nextStatusOption = Facebook.API.UpdateStatus.Option.ACTIVE;
    } else if (nextStatus === CONFIGURED_STATUS.PAUSED) {
      nextStatusOption = Facebook.API.UpdateStatus.Option.PAUSED;
    } else {
      return;
    }

    doUpdateStatus(
      {
        newStatus: nextStatusOption,
        resourceId
      },
      {
        onError: error => {
          console.error(extractErrorMessage(error));
          toast.error(extractErrorMessage(error), {
            toastId: "edit-facebook-status-error"
          });
        },
        onSuccess: () => {
          toast.success(
            `Status updated to ${_.startCase(
              _.toLower(
                stringForEnum(
                  Facebook.API.UpdateStatus.Option,
                  nextStatusOption
                )
              )
            )}`
          );
        }
      }
    );
  };

  if (
    status === CONFIGURED_STATUS.DELETED ||
    status === CONFIGURED_STATUS.ARCHIVED
  ) {
    return (
      <SimpleCell
        depth={depth}
        rowID={rowData.rowID}
        val={_.startCase(_.toLower(status))}
      />
    );
  }

  return (
    <DropdownEditCell<CONFIGURED_STATUS>
      depth={depth}
      val={status}
      onEdit={onEdit}
      dropdownOptions={editStatusDropdownOptions}
      isLoading={updateLoading}
    />
  );
};

export const EditFacebookDailyBudgetCell = ({
  val,
  rowData,
  depth
}: {
  val: unknown;
  rowData: FacebookTableData;
  depth?: number;
}): JSX.Element => {
  const [rowType, resourceId] = rowDataToResourceType(rowData);
  const { mutate: doUpdateBudget, isLoading } = useUpdateFacebookDailyBudget(
    rowType
  );

  if (!(typeof val === "number") || resourceId === "") {
    return <td></td>;
  }

  const onEdit = (_rowId: string, nextBudget: string) => {
    if (!nextBudget) {
      return;
    }

    doUpdateBudget(
      {
        offsetDailyBudget: nextBudget,
        resourceId
      },
      {
        onError: error => {
          console.error(extractErrorMessage(error));
          toast.error(extractErrorMessage(error), {
            toastId: "edit-facebook-daily-budget-error"
          });
        },
        onSuccess: () => {
          toast.success(`Budget updated to ${nextBudget}`, {
            toastId: "edit-facebook-daily-budget-success"
          });
        }
      }
    );
  };

  // Use a sting here so 110 will be 1.10 instead of 1.1
  const initialOffsetDailyBudget = (Math.round(val) / USD_OFFSET).toFixed(2);

  if (rowType === DashboardTable.ObjectType.Option.FACEBOOK_AD) {
    return (
      <StyledEnterFloatCell depth={depth}>
        <SimpleTooltip tooltip="Ad budgets are set at the Ad Set or Campaign level">
          <div className="disabled">
            <p>{initialOffsetDailyBudget}</p>
            {/* To make alignment with the editable cells easier... */}
            <Icon name="pencil" style={{ visibility: "hidden" }} />
          </div>
        </SimpleTooltip>
      </StyledEnterFloatCell>
    );
  }

  return (
    <EnterFloatCell
      rowID={rowData.rowID}
      val={initialOffsetDailyBudget}
      formatFn={undefined}
      onEdit={onEdit}
      isLoading={isLoading}
      depth={depth}
    />
  );
};

export const EditFacebookLifetimeBudgetCell = ({
  val,
  rowData,
  depth
}: {
  val: unknown;
  rowData: FacebookTableData;
  depth?: number;
}): JSX.Element => {
  const [rowType, resourceId] = rowDataToResourceType(rowData);
  const { mutate: doUpdateBudget, isLoading } = useUpdateFacebookLifetimeBudget(
    rowType
  );

  if (!(typeof val === "number") || resourceId === "") {
    return <td></td>;
  }

  const onEdit = (_rowId: string, nextBudget: string) => {
    if (!nextBudget) {
      return;
    }

    doUpdateBudget(
      {
        offsetDailyBudget: nextBudget,
        resourceId
      },
      {
        onError: error => {
          console.error(extractErrorMessage(error));
          toast.error(extractErrorMessage(error), {
            toastId: "edit-facebook-lifetime-budget-error"
          });
        },
        onSuccess: () => {
          toast.success(`Budget updated to ${nextBudget}`, {
            toastId: "edit-facebook-lifetime-budget-success"
          });
        }
      }
    );
  };

  // Use a sting here so 110 will be 1.10 instead of 1.1
  const initialOffsetLifetimeBudget = (Math.round(val) / USD_OFFSET).toFixed(2);

  if (rowType === DashboardTable.ObjectType.Option.FACEBOOK_AD) {
    return (
      <StyledEnterFloatCell depth={depth}>
        <SimpleTooltip tooltip="Ad budgets are set at the Ad Set or Campaign level">
          <div className="disabled">
            <p>{initialOffsetLifetimeBudget}</p>
            {/* To make alignment with the editable cells easier... */}
            <Icon name="pencil" style={{ visibility: "hidden" }} />
          </div>
        </SimpleTooltip>
      </StyledEnterFloatCell>
    );
  }

  return (
    <EnterFloatCell
      rowID={rowData.rowID}
      val={initialOffsetLifetimeBudget}
      formatFn={undefined}
      onEdit={onEdit}
      isLoading={isLoading}
      depth={depth}
    />
  );
};

export const EditFacebookBidAmountCell = ({
  val,
  rowData,
  depth
}: {
  val: unknown;
  rowData: FacebookTableData;
  depth?: number;
}): JSX.Element => {
  const [rowType, resourceId] = rowDataToResourceType(rowData);
  const { mutate: doUpdateBidAmount, isLoading } = useUpdateFacebookBidAmount(
    rowType
  );

  if (!(typeof val === "number")) {
    return <td></td>;
  }

  const onEdit = (_rowId: string, nextBidAmount: string) => {
    if (!nextBidAmount) {
      return;
    }

    doUpdateBidAmount(
      {
        offsetDailyBudget: nextBidAmount,
        resourceId: resourceId
      },
      {
        onError: error => {
          console.error(extractErrorMessage(error));
          toast.error(extractErrorMessage(error), {
            toastId: "edit-facebook-bid-amount-error"
          });
        },
        onSuccess: () => {
          toast.success(`Bid amount updated to ${nextBidAmount}`, {
            toastId: "edit-facebook-bid-amount-success"
          });
        }
      }
    );
  };

  // Use a sting here so 110 will be 1.10 instead of 1.1
  const initialOffsetBidAmount = (Math.round(val) / USD_OFFSET).toFixed(2);

  if (rowType === DashboardTable.ObjectType.Option.FACEBOOK_AD) {
    return (
      <StyledEnterFloatCell depth={depth}>
        <SimpleTooltip tooltip="The bid amount is set at the Ad Set level">
          <div className="disabled">
            <p>{initialOffsetBidAmount}</p>
            {/* To make alignment with the editable cells easier... */}
            <Icon name="pencil" style={{ visibility: "hidden" }} />
          </div>
        </SimpleTooltip>
      </StyledEnterFloatCell>
    );
  } else if (rowType === DashboardTable.ObjectType.Option.CAMPAIGN) {
    return (
      <StyledEnterFloatCell depth={depth}>
        <SimpleTooltip tooltip="The bid amount is set at the Ad Set level">
          <div>
            <div className="disabled">
              <p>--</p>
              <Icon name="pencil" style={{ visibility: "hidden" }} />
            </div>
          </div>
        </SimpleTooltip>
      </StyledEnterFloatCell>
    );
  }

  return (
    <EnterFloatCell
      rowID={rowData.rowID}
      val={initialOffsetBidAmount}
      formatFn={undefined}
      onEdit={onEdit}
      isLoading={isLoading}
      depth={depth}
    />
  );
};

const ResourceLabelPill = styled.span`
  background-color: ${backgroundMedium};
  border: 1px solid ${semanticBorder};
  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  margin-right: 0.5rem;
`;

export const FacebookTitleCell = <T extends MetricsTableData>({
  rowID,
  val,
  depth,
  childRows,
  rowData
}: {
  rowID: string;
  val: unknown;
  depth?: number;
  childRows?: Array<T>;
  rowData: FacebookTableData;
}): JSX.Element => {
  const [rowType] = rowDataToResourceType(rowData);
  if (depth === 0 || rowType === DashboardTable.ObjectType.Option.UNSPECIFIED) {
    return (
      <TitleCell rowID={rowID} val={val} depth={depth} childRows={childRows} />
    );
  }

  let labeledVal = val;
  if (rowType === DashboardTable.ObjectType.Option.FACEBOOK_AD) {
    labeledVal = (
      <>
        <ResourceLabelPill>Ad</ResourceLabelPill>
        {val}
      </>
    );
  } else if (rowType === DashboardTable.ObjectType.Option.FACEBOOK_AD_SET) {
    labeledVal = (
      <>
        <ResourceLabelPill>Ad Set</ResourceLabelPill>
        {val}
      </>
    );
  } else if (rowType === DashboardTable.ObjectType.Option.CAMPAIGN) {
    labeledVal = (
      <>
        <ResourceLabelPill>Campaign</ResourceLabelPill>
        {val}
      </>
    );
  }
  return (
    <TitleCell
      rowID={rowID}
      val={labeledVal}
      depth={depth}
      childRows={childRows}
    />
  );
};
``;

type FacebookResource = {
  campaignID: string;
  adSetID: string;
  adID: string;
};

export function rowDataToResourceType(
  rowData: FacebookResource
): [DashboardTable.ObjectType.Option, string] {
  if (rowData.campaignID && rowData.adSetID && rowData.adID) {
    return [DashboardTable.ObjectType.Option.FACEBOOK_AD, rowData.adID];
  }

  if (rowData.campaignID && rowData.adSetID) {
    return [DashboardTable.ObjectType.Option.FACEBOOK_AD_SET, rowData.adSetID];
  }

  if (rowData.campaignID) {
    return [DashboardTable.ObjectType.Option.CAMPAIGN, rowData.campaignID];
  }

  return [DashboardTable.ObjectType.Option.UNSPECIFIED, ""];
}
