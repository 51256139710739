import { useQuery } from "@tanstack/react-query";
import {
  GetMultiSiteCampaignConfigurationsReply,
  GetMultiSiteCampaignConfigurationsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { streamProcessor } from "Common/utils/grpcStreams";
import { GRPCWebCallbackClient } from "Common/utils/grpc";
import { CampaignConfiguration } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import { Error } from "Common/errors/error";
import Immutable from "immutable";
import { useMemo, useState } from "react";
import {
  getItemizedCampaignConfiguration,
  ItemizedCampaignConfiguration
} from "ExtensionV2/queries/useItemizedCampaignConfiguration";

export type ItemizedConfigurationsByCampaignId = Immutable.Map<
  string,
  ItemizedCampaignConfiguration
>;

export type CampaignConfigurationsById = Immutable.Map<
  string,
  CampaignConfiguration
>;
export type CampaignConfigurationsByIdByPlatform = Immutable.Map<
  CampaignPlatform.Option,
  CampaignConfigurationsById
>;
export type CampaignConfigurationsByIdByPlatformBySite = Immutable.Map<
  string,
  CampaignConfigurationsByIdByPlatform
>;

class MultiSiteAmpdCampaignConfigurations {
  private byCampaignIdByPlatformBySite: CampaignConfigurationsByIdByPlatformBySite;

  constructor(map?: CampaignConfigurationsByIdByPlatformBySite) {
    this.byCampaignIdByPlatformBySite = map || Immutable.Map();
  }

  addConfiguration(
    siteAlias: string,
    platform: CampaignPlatform.Option,
    campaignId: string,
    campaign: CampaignConfiguration
  ): MultiSiteAmpdCampaignConfigurations {
    let siteMap = this.byCampaignIdByPlatformBySite.get(siteAlias);
    if (!siteMap) {
      siteMap = Immutable.Map();
    }

    let platformMap = siteMap.get(platform);
    if (!platformMap) {
      platformMap = Immutable.Map();
    }

    platformMap = platformMap.set(campaignId, campaign);
    siteMap = siteMap.set(platform, platformMap);

    return new MultiSiteAmpdCampaignConfigurations(
      this.byCampaignIdByPlatformBySite.set(siteAlias, siteMap)
    );
  }

  getItemizedConfigurationsByCampaignId(): ItemizedConfigurationsByCampaignId {
    let byId = Immutable.Map<string, ItemizedCampaignConfiguration>();

    for (const siteMap of this.byCampaignIdByPlatformBySite.values()) {
      for (const platformMap of siteMap.values()) {
        for (const [
          campaignId,
          campaignConfiguration
        ] of platformMap.entries()) {
          byId = byId.set(
            campaignId,
            getItemizedCampaignConfiguration(campaignConfiguration, [])
          );
        }
      }
    }

    return byId;
  }

  getCampaignIdsBySite(
    targetPlatform: CampaignPlatform.Option,
    filter?: (campaign: CampaignConfiguration) => boolean
  ): Immutable.Map<string, Array<string>> {
    let bySite = Immutable.Map<string, Array<string>>();

    for (const [
      siteAlias,
      siteMap
    ] of this.byCampaignIdByPlatformBySite.entries()) {
      const campaignIds: Array<string> = [];
      for (const [platform, platformMap] of siteMap.entries()) {
        if (
          platform === targetPlatform ||
          targetPlatform === CampaignPlatform.Option.UNSPECIFIED
        ) {
          if (!filter) {
            campaignIds.push(...platformMap.keys());
          } else {
            for (const [
              campaignId,
              campaignConfiguration
            ] of platformMap.entries()) {
              if (filter(campaignConfiguration)) {
                campaignIds.push(campaignId);
              }
            }
          }
        }
      }

      bySite = bySite.set(siteAlias, campaignIds);
    }

    return bySite;
  }

  hasSiteLoaded(
    siteAlias: string,
    targetPlatform: CampaignPlatform.Option
  ): boolean {
    const byPlatform = this.byCampaignIdByPlatformBySite.get(siteAlias);
    if (!byPlatform) {
      return false;
    }

    if (targetPlatform !== CampaignPlatform.Option.UNSPECIFIED) {
      const byCampaignId = byPlatform.get(targetPlatform);
      if (!byCampaignId) {
        return false;
      }
    }

    return true;
  }
}

function useMultiSiteAmpdCampaignConfigurations({
  siteAliasesWithGoogleAds,
  siteAliasesWithFacebook
}: {
  siteAliasesWithGoogleAds: Array<string>;
  siteAliasesWithFacebook: Array<string>;
}): {
  isLoading: boolean;
  isLoadingBySite: { [siteAlias: string]: boolean };
  errorsBySite: {
    [siteAlias: string]: Array<Error>;
  };
  multiSiteAmpdCampaignConfigurations: MultiSiteAmpdCampaignConfigurations;
} {
  // The full state of the hook map be updated asynchronously as different
  // sites return from their query.  It is important that all inner loop
  // calls to 'set' pass a callback instead of a value based on this
  // state variable, because otherwise some results will be lost.
  // The state variable will be returned on each render but any number of
  // updates could happen between renders.
  const [
    multiSiteAmpdCampaignConfigurations,
    setMultiSiteAmpdCampaignConfigurations
  ] = useState(new MultiSiteAmpdCampaignConfigurations());
  const [errorsBySite, setErrorsBySite] = useState<{
    [siteAlias: string]: Array<Error>;
  }>({});

  const googleAdsQueryKey = ["multiCampaignConfigurations:googleAds"].concat(
    siteAliasesWithGoogleAds
  );
  const googleAdsResult = useQuery({
    queryKey: googleAdsQueryKey,
    staleTime: 60 * 60 * 1_000, // 1 hour
    cacheTime: 60 * 60 * 1_000, // 1 hour
    enabled: siteAliasesWithGoogleAds.length > 0,
    queryFn: async () => {
      const req = new GetMultiSiteCampaignConfigurationsRequest();
      req.setSiteAliasesList(siteAliasesWithGoogleAds);
      req.setCampaignPlatform(CampaignPlatform.Option.GOOGLE_ADS);

      await streamProcessor(
        GRPCWebCallbackClient.getMultiSiteCampaignConfigurations(req),
        (reply: GetMultiSiteCampaignConfigurationsReply) => {
          const siteAlias = reply.getSiteAliasForReply();
          const siteReply = reply.getSiteReply();
          if (reply.getSiteError()) {
            setErrorsBySite(bySite => ({
              ...bySite,
              [siteAlias]: [Error.fromProto(reply.getSiteError())]
            }));
          } else if (siteReply?.getCampaignConfigurationsList().length) {
            // Defer the state update so the UI won't freeze up when there are a lot of sites.
            setTimeout(() => {
              setMultiSiteAmpdCampaignConfigurations(
                multiSiteAmpdCampaignConfigurations => {
                  let newMultiSite = multiSiteAmpdCampaignConfigurations;
                  siteReply
                    .getCampaignConfigurationsList()
                    .forEach(campaignConfiguration => {
                      const campaignId = campaignConfiguration
                        ?.getAmpdResourceConfiguration()
                        ?.getGoogleAds()
                        ?.getCampaignConfiguration()
                        ?.getCampaignId();
                      if (campaignId) {
                        newMultiSite = newMultiSite.addConfiguration(
                          siteAlias,
                          CampaignPlatform.Option.GOOGLE_ADS,
                          String(campaignId),
                          campaignConfiguration.toObject()
                        );
                      }
                    });

                  return newMultiSite;
                }
              );
            });
          }
        }
      );

      return true;
    }
  });

  const facebookQueryKey = ["multiCampaignConfigurations:facebook"].concat(
    siteAliasesWithFacebook
  );
  const facebookResult = useQuery({
    queryKey: facebookQueryKey,
    staleTime: 60 * 60 * 1_000, // 1 hour
    cacheTime: 60 * 60 * 1_000, // 1 hour
    enabled: siteAliasesWithFacebook.length > 0,
    queryFn: async () => {
      const req = new GetMultiSiteCampaignConfigurationsRequest();
      req.setSiteAliasesList(siteAliasesWithFacebook);
      req.setCampaignPlatform(CampaignPlatform.Option.FACEBOOK);

      await streamProcessor(
        GRPCWebCallbackClient.getMultiSiteCampaignConfigurations(req),
        (reply: GetMultiSiteCampaignConfigurationsReply) => {
          const siteAlias = reply.getSiteAliasForReply();
          const siteReply = reply.getSiteReply();
          if (reply.getSiteError()) {
            setErrorsBySite(bySite => ({
              ...bySite,
              [siteAlias]: [Error.fromProto(reply.getSiteError())]
            }));
          } else if (siteReply?.getCampaignConfigurationsList().length) {
            // Defer the state update so the UI won't freeze up when there are a lot of sites.
            setTimeout(() => {
              setMultiSiteAmpdCampaignConfigurations(
                multiSiteAmpdCampaignConfigurations => {
                  let newMultiSite = multiSiteAmpdCampaignConfigurations;
                  siteReply
                    .getCampaignConfigurationsList()
                    .forEach(campaignConfiguration => {
                      const campaignId = campaignConfiguration
                        ?.getAmpdResourceConfiguration()
                        ?.getFacebook()
                        ?.getCampaignDetails()
                        ?.getCampaignId();
                      if (campaignId) {
                        newMultiSite = newMultiSite.addConfiguration(
                          siteAlias,
                          CampaignPlatform.Option.FACEBOOK,
                          String(campaignId),
                          campaignConfiguration.toObject()
                        );
                      }
                    });

                  return newMultiSite;
                }
              );
            });
          }
        }
      );

      return true;
    }
  });

  const [isLoading, isLoadingBySite] = useMemo(() => {
    let loading = false;
    const loadingBySite: { [siteAlias: string]: boolean } = {};
    if (siteAliasesWithGoogleAds.length && googleAdsResult.isLoading) {
      loading = true;
      siteAliasesWithGoogleAds.forEach(siteAlias => {
        if (
          !multiSiteAmpdCampaignConfigurations.hasSiteLoaded(
            siteAlias,
            CampaignPlatform.Option.GOOGLE_ADS
          )
        ) {
          loadingBySite[siteAlias] = true;
        }
      });
    }
    if (siteAliasesWithFacebook.length && facebookResult.isLoading) {
      loading = true;
      siteAliasesWithFacebook.forEach(siteAlias => {
        if (
          !multiSiteAmpdCampaignConfigurations.hasSiteLoaded(
            siteAlias,
            CampaignPlatform.Option.FACEBOOK
          )
        ) {
          loadingBySite[siteAlias] = true;
        }
      });
    }

    return [loading, loadingBySite];
  }, [
    siteAliasesWithGoogleAds,
    siteAliasesWithFacebook,
    multiSiteAmpdCampaignConfigurations,
    googleAdsResult,
    facebookResult
  ]);

  return {
    isLoading,
    isLoadingBySite,
    errorsBySite,
    multiSiteAmpdCampaignConfigurations
  };
}

export default useMultiSiteAmpdCampaignConfigurations;
