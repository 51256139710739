import React, { lazy, Suspense, useState } from "react";
import { Button, ButtonProps, Popup } from "semantic-ui-react";

// Lazy load the feature flag editor so the feature descriptions are
// not discoverable in the main javascript chunks.
const FeatureFlagEditor = lazy(() =>
  import("ExtensionV2/components/FeatureFlagEditor")
);

const FeatureFlagButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    _data: ButtonProps
  ) => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  return (
    <Popup
      trigger={
        <div>
          <Button
            color={isOpen ? "black" : undefined}
            onClick={handleButtonClick}
          >
            Advanced Feature Flags
          </Button>
        </div>
      }
      position="top right"
      wide="very"
      on="click"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      closeOnDocumentClick={true}
    >
      <Suspense fallback={<span>Loading</span>}>
        <FeatureFlagEditor />
      </Suspense>
    </Popup>
  );
};

export default FeatureFlagButton;
