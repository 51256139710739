import _ from "lodash";
import Immutable from "immutable";
import moment from "moment";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useQuery } from "react-apollo";

import getCompareToDates from "./getCompareToDates";

import {
  AdwordsObjectType,
  BasicMetrics,
  Keyword,
  Query,
  QueryAdwordsMetricsArgs,
  QueryAdwordsObjectsArgs,
  Status
} from "../../graphql/graphql";
import {
  CAMPAIGN_KEYWORD_METRICS_QUERY,
  CAMPAIGN_KEYWORDS_QUERY
} from "../graphql";

import {
  METRIC_COLUMNS,
  calculateDerivedMetrics
} from "../components/MetricColumns";

const CAMPAIGN_KEYWORDS_QUERY_ID = "keywords";
const CAMPAIGN_KEYWORDS_METRICS_QUERY_ID = "keyword metrics";
const CAMPAIGN_SEARCH_TERMS_METRICS_QUERY_ID = "search term metrics";

interface KeywordWithCampaignId extends Keyword {
  campaignId?: string;
  defaultCpcBid?: number | null;
  effectiveDefaultCpcBid?: number | null;
}

function useKeywordObjects({
  refreshCount,
  siteAlias,
  campaignIds,
  hasAmazonAccount,
  dateRangeStartDate,
  dateRangeEndDate,
  compareTo,
  collectSearchTermMetrics
}: {
  refreshCount: number;
  siteAlias: string;
  campaignIds: Array<string>;
  hasAmazonAccount: boolean;
  dateRangeStartDate: string;
  dateRangeEndDate: string;
  compareTo: string;
  collectSearchTermMetrics?: boolean;
}): {
  keywordsLoading: boolean;
  keywordCompareMetricsLoading: boolean;
  searchTermMetricsLoading: boolean;
  allKeywords: Array<KeywordWithCampaignId> | null;
  keywordMetricsMap: Immutable.Map<string, BasicMetrics> | null;
  keywordCompareMetricsMap: Immutable.Map<string, BasicMetrics> | null;
  searchTermMetricsMap: Immutable.Map<string, Array<BasicMetrics>> | null;
  refetchCampaignKeyword: (
    campaignId: string | null | undefined,
    adGroupId: string | null | undefined,
    criteriaId: string | null | undefined
  ) => void;
  disabledCriteriaIds: Immutable.Set<string>;
} {
  const [allKeywords, setAllKeywords] = useState<Array<
    KeywordWithCampaignId
  > | null>(null);
  const [keywordMetricsMap, setKeywordMetricsMap] = useState<Immutable.Map<
    string,
    BasicMetrics
  > | null>(null);
  const [
    searchTermMetricsMap,
    setSearchTermMetricsMap
  ] = useState<Immutable.Map<string, Array<BasicMetrics>> | null>(null);

  const [compareRangeStartDate, compareRangeEndDate] = useMemo(
    () => getCompareToDates(dateRangeStartDate, dateRangeEndDate, compareTo),
    [dateRangeStartDate, dateRangeEndDate, compareTo]
  );
  const [
    keywordCompareMetricsMap,
    setKeywordCompareMetricsMap
  ] = useState<Immutable.Map<string, BasicMetrics> | null>(null);

  useEffect(() => {
    setKeywordMetricsMap(null);
    setSearchTermMetricsMap(null);
  }, [dateRangeStartDate, dateRangeEndDate, campaignIds]);

  useEffect(() => {
    setKeywordCompareMetricsMap(null);
  }, [compareRangeStartDate, compareRangeEndDate, campaignIds]);

  const keywordsQueryResult = useQuery<Query, QueryAdwordsObjectsArgs>(
    CAMPAIGN_KEYWORDS_QUERY,
    {
      skip: !campaignIds || !dateRangeStartDate || !dateRangeEndDate,
      fetchPolicy: "no-cache",
      variables: {
        site: { siteAlias },
        endDate: moment()
          .endOf("day")
          .format("YYYY-MM-DD"),
        numWeeks: 8,
        campaignIds,
        campaignStatuses: [Status.Enabled, Status.Paused],
        adGroupStatuses: [Status.Enabled, Status.Paused],
        criteriaStatuses: [Status.Enabled, Status.Paused],
        queryId: `${CAMPAIGN_KEYWORDS_QUERY_ID}|${campaignIds}:${refreshCount}`
      }
    }
  );

  const keywordMetricsQueryResult = useQuery<Query, QueryAdwordsMetricsArgs>(
    CAMPAIGN_KEYWORD_METRICS_QUERY,
    {
      skip: !campaignIds || !dateRangeStartDate || !dateRangeEndDate,
      variables: {
        site: { siteAlias },
        startDate: dateRangeStartDate,
        endDate: dateRangeEndDate,
        campaignIds,
        objectType: AdwordsObjectType.Criteria,
        queryId: `${CAMPAIGN_KEYWORDS_METRICS_QUERY_ID}|${campaignIds}|${dateRangeStartDate}|${dateRangeEndDate}:${refreshCount}`,
        includeAmazonAttributionData: hasAmazonAccount
      }
    }
  );

  const keywordCompareMetricsQueryResult = useQuery<
    Query,
    QueryAdwordsMetricsArgs
  >(CAMPAIGN_KEYWORD_METRICS_QUERY, {
    skip: !campaignIds || !compareRangeStartDate || !compareRangeEndDate,
    variables: {
      site: { siteAlias },
      startDate: compareRangeStartDate,
      endDate: compareRangeEndDate,
      campaignIds,
      objectType: AdwordsObjectType.Criteria,
      queryId: `${CAMPAIGN_KEYWORDS_METRICS_QUERY_ID}|${campaignIds}|${compareRangeStartDate}|${compareRangeEndDate}:${refreshCount}`,
      includeAmazonAttributionData: hasAmazonAccount
    }
  });

  const searchTermMetricsQueryResult = useQuery<Query, QueryAdwordsMetricsArgs>(
    CAMPAIGN_KEYWORD_METRICS_QUERY,
    {
      skip:
        !collectSearchTermMetrics ||
        !campaignIds ||
        !dateRangeStartDate ||
        !dateRangeEndDate,
      variables: {
        site: { siteAlias },
        startDate: dateRangeStartDate,
        endDate: dateRangeEndDate,
        campaignIds,
        objectType: AdwordsObjectType.SearchTerm,
        queryId: `${CAMPAIGN_SEARCH_TERMS_METRICS_QUERY_ID}|${campaignIds}|${dateRangeStartDate}|${dateRangeEndDate}:${refreshCount}`,
        includeAmazonAttributionData: false
      }
    }
  );

  useEffect(() => {
    const adwordsObjects = keywordsQueryResult?.data?.adwordsObjects || {};

    if (adwordsObjects.campaigns) {
      const keywords: Array<KeywordWithCampaignId> = [];

      adwordsObjects.campaigns.forEach(campaign => {
        if (!campaign?.campaignId) {
          return;
        }

        campaign.adGroups?.forEach(adGroup => {
          if (adGroup?.positiveKeywords) {
            // Put the campaignId and defaultCpcBid directly in the keyword object for reference.
            adGroup.positiveKeywords.forEach(keyword => {
              if (keyword && campaign.campaignId) {
                const keywordWithCampaignId: KeywordWithCampaignId = keyword;
                keywordWithCampaignId.campaignId = campaign.campaignId;
                keywordWithCampaignId.defaultCpcBid = adGroup.defaultCpcBid;
                keywordWithCampaignId.effectiveDefaultCpcBid =
                  adGroup.effectiveDefaultCpcBid;
                keywords.push(keywordWithCampaignId);
              }
            });
          }
        });
      });

      setAllKeywords(keywords);
    }

    if (!keywordsQueryResult.loading) {
      setDisabledCriteriaIds(Immutable.Set());
    }
  }, [
    keywordsQueryResult.data,
    keywordsQueryResult.loading,
    keywordsQueryResult.variables
  ]);

  const [disabledCriteriaIds, setDisabledCriteriaIds] = useState<
    Immutable.Set<string>
  >(Immutable.Set());
  const [updateCount, setUpdateCount] = useState(0);

  const [updatedKeywordQuery] = useLazyQuery<Query, QueryAdwordsObjectsArgs>(
    CAMPAIGN_KEYWORDS_QUERY,
    {
      fetchPolicy: "no-cache",
      onCompleted: data => {
        const adwordsObjects = data?.adwordsObjects || {};

        if (adwordsObjects.campaigns && allKeywords) {
          const newAllKeywords = _.clone(allKeywords);

          adwordsObjects.campaigns.forEach(campaign => {
            if (!campaign?.campaignId) {
              return;
            }

            (campaign.adGroups || []).forEach(adGroup => {
              if (adGroup?.positiveKeywords) {
                adGroup.positiveKeywords.forEach(adGroupKeyword => {
                  if (
                    !adGroupKeyword?.criteriaId ||
                    !adGroupKeyword?.adGroupId ||
                    !campaign.campaignId
                  ) {
                    return;
                  }

                  const adGroupKeywordWithCampaignId: KeywordWithCampaignId = adGroupKeyword;
                  // Put the campaignId and defaultCpcBid directly in the keyword object for reference.
                  adGroupKeywordWithCampaignId.campaignId = campaign.campaignId;
                  adGroupKeywordWithCampaignId.defaultCpcBid =
                    adGroup.defaultCpcBid;
                  adGroupKeywordWithCampaignId.effectiveDefaultCpcBid =
                    adGroup.effectiveDefaultCpcBid;

                  let found = false;
                  for (
                    let existingIndex = 0;
                    existingIndex < allKeywords.length;
                    existingIndex++
                  ) {
                    const existingKeyword = allKeywords[existingIndex];
                    if (
                      adGroupKeywordWithCampaignId.criteriaId ===
                        existingKeyword.criteriaId &&
                      adGroupKeywordWithCampaignId.adGroupId ===
                        existingKeyword.adGroupId
                    ) {
                      found = true;
                      newAllKeywords[
                        existingIndex
                      ] = adGroupKeywordWithCampaignId;
                      setDisabledCriteriaIds(disableCriteriaIds =>
                        disableCriteriaIds.remove(
                          adGroupKeywordWithCampaignId.criteriaId || ""
                        )
                      );
                      break;
                    }
                  }
                  if (!found) {
                    newAllKeywords.push(adGroupKeywordWithCampaignId);
                  }
                  setDisabledCriteriaIds(disableCriteriaIds =>
                    disableCriteriaIds.remove("0")
                  );
                });
              }
            });
          });

          if (!_.isEqual(allKeywords, newAllKeywords)) {
            setAllKeywords(newAllKeywords);
          }
        }
      }
    }
  );

  const refetchCampaignKeyword = useCallback(
    (
      campaignId: string | null | undefined,
      adGroupId: string | null | undefined,
      criteriaId: string | null | undefined
    ) => {
      if (campaignId) {
        updatedKeywordQuery({
          variables: {
            site: { siteAlias },
            endDate: moment()
              .endOf("day")
              .format("YYYY-MM-DD"),
            numWeeks: 8,
            campaignIds: [campaignId],
            adGroupIds: adGroupId ? [adGroupId] : undefined,
            criteriaIds: criteriaId ? [criteriaId] : undefined,
            campaignStatuses: [Status.Enabled, Status.Paused],
            adGroupStatuses: [Status.Enabled, Status.Paused],
            criteriaStatuses: [Status.Enabled, Status.Paused],
            queryId:
              `${CAMPAIGN_KEYWORDS_QUERY_ID}"${campaignId}` +
              `|${adGroupId || ""}|${criteriaId || ""}:${updateCount +
                1}:${refreshCount}`
          }
        });

        setUpdateCount(updateCount + 1);
        if (criteriaId) {
          setDisabledCriteriaIds(disableCriteriaIds =>
            disableCriteriaIds.add(criteriaId)
          );
        } else {
          setDisabledCriteriaIds(disableCriteriaIds =>
            disableCriteriaIds.add("0")
          );
        }
      }
    },
    [siteAlias, updatedKeywordQuery, updateCount, refreshCount]
  );

  useEffect(() => {
    const metricsList =
      keywordMetricsQueryResult?.data?.adwordsMetrics?.basicMetrics || [];

    if (
      !keywordMetricsQueryResult.loading &&
      keywordMetricsQueryResult.variables.startDate === dateRangeStartDate &&
      keywordMetricsQueryResult.variables.endDate === dateRangeEndDate
    ) {
      if (!keywordMetricsMap) {
        let map: Immutable.Map<string, BasicMetrics> = Immutable.Map();
        metricsList.forEach(metrics => {
          if (metrics) {
            calculateDerivedMetrics(metrics, METRIC_COLUMNS);
            map = map.set(getKeywordMetricsMapKey(metrics), metrics);
          }
        });

        setKeywordMetricsMap(map);
      }
    }
  }, [
    keywordMetricsQueryResult.data,
    keywordMetricsQueryResult.variables,
    keywordMetricsQueryResult.loading,
    keywordMetricsMap,
    campaignIds,
    dateRangeStartDate,
    dateRangeEndDate
  ]);

  useEffect(() => {
    const metricsList =
      keywordCompareMetricsQueryResult?.data?.adwordsMetrics?.basicMetrics ||
      [];

    if (
      !keywordCompareMetricsQueryResult.loading &&
      keywordCompareMetricsQueryResult.variables.startDate ===
        compareRangeStartDate &&
      keywordCompareMetricsQueryResult.variables.endDate === compareRangeEndDate
    ) {
      if (!keywordCompareMetricsMap && compareTo) {
        let map: Immutable.Map<string, BasicMetrics> = Immutable.Map();
        metricsList.forEach(metrics => {
          if (metrics) {
            calculateDerivedMetrics(metrics, METRIC_COLUMNS);
            map = map.set(getKeywordMetricsMapKey(metrics), metrics);
          }
        });

        setKeywordCompareMetricsMap(map);
      }
    }
  }, [
    compareTo,
    keywordCompareMetricsQueryResult.data,
    keywordCompareMetricsQueryResult.variables,
    keywordCompareMetricsQueryResult.loading,
    keywordCompareMetricsMap,
    compareRangeStartDate,
    compareRangeEndDate
  ]);

  useEffect(() => {
    const metricsList =
      searchTermMetricsQueryResult?.data?.adwordsMetrics?.basicMetrics || [];

    if (
      !searchTermMetricsQueryResult.loading &&
      searchTermMetricsQueryResult.variables.startDate === dateRangeStartDate &&
      searchTermMetricsQueryResult.variables.endDate === dateRangeEndDate
    ) {
      if (!searchTermMetricsMap) {
        let map: Immutable.Map<string, Array<BasicMetrics>> = Immutable.Map();
        metricsList.forEach(metrics => {
          if (metrics) {
            const mapKey = getKeywordMetricsMapKey(metrics);
            calculateDerivedMetrics(metrics, METRIC_COLUMNS);
            const metricsList: Array<BasicMetrics> = map.get(mapKey, []);
            metricsList.push(metrics);
            map = map.set(mapKey, metricsList);
          }
        });

        setSearchTermMetricsMap(map);
      }
    }
  }, [
    searchTermMetricsQueryResult.data,
    searchTermMetricsQueryResult.variables,
    searchTermMetricsQueryResult.loading,
    searchTermMetricsMap,
    campaignIds,
    dateRangeStartDate,
    dateRangeEndDate
  ]);

  return {
    keywordsLoading:
      keywordsQueryResult.loading || keywordMetricsQueryResult.loading,
    keywordCompareMetricsLoading: keywordCompareMetricsQueryResult.loading,
    searchTermMetricsLoading: searchTermMetricsQueryResult.loading,
    allKeywords,
    keywordMetricsMap,
    keywordCompareMetricsMap,
    searchTermMetricsMap,
    refetchCampaignKeyword,
    disabledCriteriaIds
  };
}

export function getKeywordMetricsMapKey(
  keyword: KeywordWithCampaignId | BasicMetrics | null | undefined
): string {
  if (!keyword) {
    return "";
  }

  return `${keyword.adGroupId}-${keyword.criteriaId}`;
}

export function getKeywordMetricsFromMap(
  keywordMetricsMap: Immutable.Map<string, BasicMetrics> | null,
  keyword: KeywordWithCampaignId | BasicMetrics
): BasicMetrics | null {
  if (!keywordMetricsMap || !keyword) {
    return null;
  }

  const key = `${keyword.adGroupId}-${keyword.criteriaId}`;

  return keywordMetricsMap.get(key, null);
}

export default useKeywordObjects;
