import React, { JSX, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";

import {
  Button,
  Dropdown,
  DropdownItemProps,
  Icon,
  Message
} from "semantic-ui-react";
import { Flex } from "@rebass/grid";

import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsNoDataToDisplay from "highcharts/modules/no-data-to-display";

import { GlobalDateContext } from "ExtensionV2";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import {
  calculateDerivedMetrics,
  COLUMN_DATA_KEYS,
  COLUMN_DISPLAY_NAME_FROM_DATA_KEY,
  formatMetricColumnValue,
  isMetricColumn,
  MetricColumnKey,
  MetricColumnsMetrics
} from "./MetricColumns";
import {
  DashboardTableMetricsByCampaignIdByObjectType,
  DashboardTableObjectType,
  useDashboardTableMetrics
} from "../queries/useDashboardTableMetrics";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { Retailer } from "Common/proto/common/retailer_pb";
import { momentFromCommonDateProto } from "Common/utils/DateUtilities";
import { backgroundLight, backgroundMedium } from "ExtensionV2/styles/colors";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { popover } from "ExtensionV2/styles/zIndexes";
import { ALL_WALMART_CAMPAIGNS_COLUMNS } from "ExtensionV2/pages/CampaignsPage/CampaignsPage";
import {
  IconInButtonWithoutText,
  IconInButtonWithText
} from "ExtensionV2/components/ObjectFilterButton";
import {
  getStoredCampaignsPerformanceGraphExpand,
  getStoredCampaignsPerformanceGraphLeft,
  getStoredCampaignsPerformanceGraphRight,
  setStoredCampaignsPerformanceGraphExpand,
  setStoredCampaignsPerformanceGraphLeft,
  setStoredCampaignsPerformanceGraphOption,
  setStoredCampaignsPerformanceGraphRight
} from "Common/utils/savedTablePreferences";
import { removeNullAndUndefined } from "Common/utils/tsUtils";
import { extractErrorMessage } from "Common/errors/error";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";

if (Highcharts) {
  HighchartsNoDataToDisplay(Highcharts);
}

// The columns that are summable (or derived from summable columns) that can
// be shown on the Total row.
export const CHART_METRIC_COLUMNS: Array<MetricColumnKey> = [
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.averageCpc,
  COLUMN_DATA_KEYS.detailPageViews,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.cartRate,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.conversionRate,
  COLUMN_DATA_KEYS.unitsSold,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.brandReferralBonus,
  COLUMN_DATA_KEYS.newToBrandConversions,
  COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
  COLUMN_DATA_KEYS.newToBrandRevenue,
  COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
  COLUMN_DATA_KEYS.newToBrandUnitsSold,
  COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage
];

export const ALLOW_FRACTION_COLUMNS: Array<MetricColumnKey> = [
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.averageCpc,
  COLUMN_DATA_KEYS.cartRate,
  COLUMN_DATA_KEYS.conversionRate,
  COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
  COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
  COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage
];

const UNSET_VALUE = " ";
const UNSET_TEXT = "(None)";

const DATE_STRING_FORMAT = "YYYY-MM-DD";
type DateString = string;

const LeftMetricGraphics = {
  color: "blue",
  symbolName: "circle",
  symbolChar: "●"
};
const RightMetricGraphics = {
  color: "red",
  symbolName: "square",
  symbolChar: "■"
};

type SeriesInfo = Array<{
  column: MetricColumnKey;
  name: string;
  data: Array<[number, number]>;
  color?: string;
  symbolName?: string;
  symbolChar?: string;
  yAxis?: number;
}>;

export const GoogleAdsPerformanceChartControls: React.FC<{
  campaignIds: Array<string>;
  retailer: Retailer.Option;
  retailerCampaignIds: Array<string>;
  showPerformanceChart: boolean;
  setShowPerformanceChart: React.Dispatch<React.SetStateAction<boolean>>;
  setPerformanceChartComponent: React.Dispatch<
    React.SetStateAction<JSX.Element | null>
  >;
  disableTooltips: boolean;
}> = ({
  campaignIds,
  retailer,
  retailerCampaignIds,
  showPerformanceChart,
  setShowPerformanceChart,
  setPerformanceChartComponent,
  disableTooltips
}) => {
  const { siteAlias, siteCurrencyCode } = useSessionSite();

  const { startDate, endDate } = useContext(GlobalDateContext);

  const dateStrings = useMemo(
    () => getDateStringsForRange(startDate, endDate),
    [startDate, endDate]
  );

  const [leftMetric, setLeftMetric] = useState<
    MetricColumnKey | typeof UNSET_VALUE
  >(() => {
    const value: string | null = getStoredCampaignsPerformanceGraphLeft();
    if (isMetricColumn(value) || value === UNSET_VALUE) {
      return value;
    }

    return COLUMN_DATA_KEYS.clicks;
  });
  const [rightMetric, setRightMetric] = useState<
    MetricColumnKey | typeof UNSET_VALUE
  >(() => {
    const value: string | null = getStoredCampaignsPerformanceGraphRight();
    if (isMetricColumn(value) || value === UNSET_VALUE) {
      return value;
    }

    return COLUMN_DATA_KEYS.conversions;
  });
  const [expand, setExpand] = useState<boolean>(
    getStoredCampaignsPerformanceGraphExpand()
  );

  const {
    data: dashboardTableMetricsByCampaignIdByObjectType,
    error: dashboardTableMetricsError,
    isLoading: dashboardTableMetricsLoading
  } = useDashboardTableMetrics({
    objectTypes: [DashboardTableObjectType.CAMPAIGN],
    siteAlias: showPerformanceChart ? siteAlias : "",
    retailer: retailer,
    campaignPlatform: CampaignPlatform.Option.GOOGLE_ADS,
    campaignIds: retailerCampaignIds,
    dateRangeStartDate: startDate,
    dateRangeEndDate: endDate,
    queryByDay: true
  });

  const [
    leftMetricDropdownOptions,
    rightMetricDropdownOptions
  ] = useMemo(() => {
    const metricColumns = CHART_METRIC_COLUMNS.filter(
      (column: MetricColumnKey) =>
        retailer === Retailer.Option.AMAZON
          ? true
          : ALL_WALMART_CAMPAIGNS_COLUMNS.includes(column)
    );

    return [LeftMetricGraphics, RightMetricGraphics].map(graphics => {
      const dropdownOptions: Array<DropdownItemProps> = metricColumns.map(
        (column: MetricColumnKey) => ({
          key: column,
          value: column,
          text: (
            <span style={{ fontSize: "small" }}>
              <span
                style={{
                  color: graphics.color,
                  fontSize: "xx-small",
                  marginRight: ".5em"
                }}
              >
                {graphics.symbolChar}
              </span>
              {COLUMN_DISPLAY_NAME_FROM_DATA_KEY[column]}
            </span>
          )
        })
      );
      dropdownOptions.unshift({
        key: UNSET_TEXT,
        value: UNSET_VALUE,
        text: UNSET_TEXT
      });

      return dropdownOptions;
    });
  }, [retailer]);

  const [dailyMetrics, costCurrencyCode, revenueCurrencyCode]: [
    Map<DateString, MetricColumnsMetrics>,
    string,
    string
  ] = useMemo(() => {
    let _costCurrencyCode = siteCurrencyCode;
    let _revenueCurrencyCode = siteCurrencyCode;

    const _dailyMetrics: Map<DateString, MetricColumnsMetrics> = new Map(
      dateStrings.map((dateString: DateString) => {
        const totalMetrics = CHART_METRIC_COLUMNS.reduce<MetricColumnsMetrics>(
          (metrics: MetricColumnsMetrics, column: MetricColumnKey) => {
            metrics[column] = 0;
            return metrics;
          },
          {}
        );
        if (!dashboardTableMetricsByCampaignIdByObjectType) {
          return [dateString, totalMetrics];
        }

        campaignIds.forEach((campaignId: string) => {
          const [metrics, costCode, revenueCode] = getDailyMetricsForCampaign(
            dashboardTableMetricsByCampaignIdByObjectType,
            campaignId,
            dateString
          );
          if (metrics) {
            // Sum all Total metric columns for now.  The metrics that are not themselves
            // summable but are derived from summable metrics will be calculated after
            // the loop.  Treat all null and NaNs as zeros.
            CHART_METRIC_COLUMNS.forEach((column: MetricColumnKey) => {
              if (totalMetrics[column] != null) {
                totalMetrics[column] =
                  (totalMetrics?.[column] || 0) + (metrics?.[column] || 0);
              }
            });
          }
          if (costCode) {
            _costCurrencyCode = costCode;
          }
          if (revenueCode) {
            _revenueCurrencyCode = revenueCode;
          }
        });

        // Now calculate all derived Total column values.
        calculateDerivedMetrics(totalMetrics, CHART_METRIC_COLUMNS);

        return [dateString, totalMetrics];
      })
    );

    return [_dailyMetrics, _costCurrencyCode, _revenueCurrencyCode];
  }, [
    dashboardTableMetricsByCampaignIdByObjectType,
    campaignIds,
    dateStrings,
    siteCurrencyCode
  ]);

  const handleShowClick = () => {
    setShowPerformanceChart(show => {
      setStoredCampaignsPerformanceGraphOption(!show);
      return !show;
    });
  };

  const handleExpandClick = () => {
    setExpand(expand => {
      setStoredCampaignsPerformanceGraphExpand(!expand);
      return !expand;
    });
  };

  const chartComponent = useMemo(() => {
    if (!showPerformanceChart) {
      return null;
    } else if (dashboardTableMetricsLoading) {
      return (
        <LoadingSpinner>
          <p>Loading Daily Performance Chart</p>
        </LoadingSpinner>
      );
    } else if (dashboardTableMetricsError) {
      return (
        <Message error>
          There was a problem loading your Google Ads performance data.{" "}
          {extractErrorMessage(dashboardTableMetricsError)}
        </Message>
      );
    } else if (!dailyMetrics) {
      return (
        <span>No daily performance data was found for this campaign.</span>
      );
    }

    const leftPoints: Array<[number, number]> = [];
    const rightPoints: Array<[number, number]> = [];
    dateStrings.forEach(dateString => {
      const metrics = dailyMetrics.get(dateString);

      if (leftMetric !== UNSET_VALUE && metrics) {
        leftPoints.push([
          moment(dateString, DATE_STRING_FORMAT).valueOf(),
          metrics[leftMetric] || 0
        ]);
      }
      if (rightMetric !== UNSET_VALUE && metrics) {
        rightPoints.push([
          moment(dateString, DATE_STRING_FORMAT).valueOf(),
          metrics[rightMetric] || 0
        ]);
      }
    }, []);

    const seriesInfo: SeriesInfo = [];
    if (leftMetric !== UNSET_VALUE) {
      seriesInfo.push({
        ...LeftMetricGraphics,
        column: leftMetric,
        name: COLUMN_DISPLAY_NAME_FROM_DATA_KEY[leftMetric],
        data: leftPoints,
        yAxis: 0
      });
    }
    if (rightMetric !== UNSET_VALUE) {
      seriesInfo.push({
        ...RightMetricGraphics,
        column: rightMetric,
        name: COLUMN_DISPLAY_NAME_FROM_DATA_KEY[rightMetric],
        data: rightPoints,
        yAxis: 1
      });
    }

    const chartConfig = makeChartConfig({
      dateRangeStartDate: startDate,
      seriesInfo: seriesInfo,
      costCurrencyCode,
      revenueCurrencyCode,
      expand,
      disableTooltips
    });

    return <HighchartsReact highcharts={Highcharts} options={chartConfig} />;
  }, [
    costCurrencyCode,
    dailyMetrics,
    dashboardTableMetricsError,
    dashboardTableMetricsLoading,
    dateStrings,
    expand,
    leftMetric,
    revenueCurrencyCode,
    rightMetric,
    showPerformanceChart,
    disableTooltips,
    startDate
  ]);

  useEffect(() => {
    setPerformanceChartComponent(chartComponent);
  }, [chartComponent, setPerformanceChartComponent]);

  if (!chartComponent) {
    return (
      <SimpleTooltip tooltip="Show performance graph">
        <Button className="icon" onClick={handleShowClick}>
          <IconInButtonWithoutText name="line graph" />
        </Button>
      </SimpleTooltip>
    );
  }

  return (
    <Flex flexDirection="row" justifyContent="space-between">
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <Dropdown
          style={{
            marginRight: "1em",
            borderTop: "blue 2px solid",
            zIndex: popover
          }}
          text={
            leftMetric !== UNSET_VALUE
              ? COLUMN_DISPLAY_NAME_FROM_DATA_KEY[leftMetric]
              : UNSET_TEXT
          }
          options={leftMetricDropdownOptions}
          scrolling={true}
          onChange={(e, { value }) => {
            if (isMetricColumn(value) || value === UNSET_VALUE) {
              setLeftMetric(value);
            }
            setStoredCampaignsPerformanceGraphLeft(value);
          }}
          value={leftMetric}
        />
        <Dropdown
          style={{
            marginRight: "2em",
            borderTop: "red 2px solid",
            zIndex: popover
          }}
          text={
            rightMetric !== UNSET_VALUE
              ? COLUMN_DISPLAY_NAME_FROM_DATA_KEY[rightMetric]
              : UNSET_TEXT
          }
          options={rightMetricDropdownOptions}
          scrolling={true}
          onChange={(e, { value }) => {
            if (isMetricColumn(value) || value === UNSET_VALUE) {
              setRightMetric(value);
            }
            setStoredCampaignsPerformanceGraphRight(value);
          }}
          value={rightMetric}
        />
        <SimpleTooltip tooltip={expand ? "Shrink graph" : "Expand graph"}>
          <Button size="mini" onClick={handleExpandClick}>
            <IconInButtonWithoutText name={expand ? "compress" : "expand"} />
          </Button>
        </SimpleTooltip>
        <SimpleTooltip tooltip={"Hide performance graph"}>
          <Button size="mini" color="black" onClick={handleShowClick}>
            <Icon name="line graph" />
            <IconInButtonWithText name="close" />
          </Button>
        </SimpleTooltip>
      </div>
    </Flex>
  );
};

// Returns the Highcharts configuration for a single graph chart with multiple
// graphed series.
function makeChartConfig({
  dateRangeStartDate,
  seriesInfo,
  costCurrencyCode,
  revenueCurrencyCode,
  expand,
  noDataMessage,
  disableTooltips
}: {
  dateRangeStartDate: string;
  seriesInfo: SeriesInfo;
  costCurrencyCode: string;
  revenueCurrencyCode: string;
  expand: boolean;
  noDataMessage?: string;
  disableTooltips?: boolean;
}): Highcharts.Options {
  const columns: Array<MetricColumnKey | undefined> = [undefined, undefined];

  const series: Array<Highcharts.SeriesOptionsType> = seriesInfo.map(
    ({ column, name, data, color, symbolName, symbolChar, yAxis }) => {
      columns[yAxis || 0] = column;

      const seriesComponent: Highcharts.SeriesLineOptions = {
        type: "line",
        name: name,
        yAxis: yAxis,
        color,
        marker: {
          enabled: false,
          symbol: symbolName
        },
        clip: true,
        data: data,
        dataLabels: {
          format: "{point.val}"
        },
        tooltip: {
          pointFormatter: function(this: Highcharts.Point) {
            if (this?.y == null) {
              return "";
            }

            const str = String(
              formatMetricColumnValue(
                column,
                this?.y,
                costCurrencyCode,
                revenueCurrencyCode,
                true
              )
            );

            return (
              '<span style="color:' +
              this.series.color +
              '">' +
              symbolChar +
              "</span>" +
              " " +
              this.series.name +
              ": " +
              str +
              "<br/>"
            );
          }
        },
        enableMouseTracking: true,
        visible: true
      };

      return seriesComponent;
    }
  );

  // The main plot area.
  const yAxis: Array<Highcharts.YAxisOptions> = columns.map((column, index) => {
    const showFractions = column
      ? ALLOW_FRACTION_COLUMNS.includes(column)
      : false;

    return {
      height: "100%",
      type: "linear",
      title: undefined,
      opposite: index > 0,
      gridLineWidth: 1,
      allowDecimals: showFractions,
      labels: {
        formatter: function(this: Highcharts.AxisLabelsFormatterContextObject) {
          if (this?.value == null || !column) {
            return "";
          }
          return String(
            formatMetricColumnValue(
              column,
              Number(this?.value),
              costCurrencyCode,
              revenueCurrencyCode,
              showFractions
            )
          );
        }
      },
      tickInterval: undefined,
      tickPositioner: undefined
    };
  });

  return {
    chart: {
      type: "line",
      plotBackgroundColor: backgroundLight,
      animation: false,
      height: expand ? 380 : 150,
      marginBottom: expand ? 40 : 20,
      reflow: true
    },
    tooltip: {
      enabled: !disableTooltips,
      backgroundColor: "rgba(255,255,255,.9)",
      animation: false,
      hideDelay: 0,
      shared: true,
      outside: true,
      split: false,
      xDateFormat: "%a, %b %e, %Y",
      useHTML: true // prevent blurry tooltips
    },
    credits: {
      enabled: false
    },
    title: undefined,
    xAxis: {
      type: "datetime",
      crosshair: true,
      labels: {
        enabled: expand,
        overflow: "justify"
      },
      tickPosition: "outside",
      dateTimeLabelFormats: {
        day: "%b %e, %Y",
        week: "%b %e, %Y",
        hour: "",
        minute: "",
        second: ""
      },
      lineWidth: 0,
      tickLength: 0,
      plotBands: seriesInfo[0]?.data
        .map((point: [number, number]) => {
          const day = moment(point[0]);
          if (day.day() !== 0 && day.day() !== 6) {
            return null;
          }

          return {
            color: backgroundMedium,
            from: moment(point[0])
              .subtract(12, "hours")
              .valueOf(),
            to: moment(point[0])
              .add(12, "hours")
              .valueOf()
          };
        })
        .filter(removeNullAndUndefined)
    },
    yAxis,
    navigation: {
      menuItemStyle: {
        fontSize: "10px"
      }
    },
    legend: {
      enabled: false,
      layout: "horizontal" /* "proximate" */,
      align: "right",
      itemDistance: 3
    },
    lang: {
      noData: noDataMessage || "No data available"
    },
    plotOptions: {
      series: {
        animation: false
      },
      line: {
        dataLabels: {
          enabled: true
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 3
          }
        },
        marker: {
          enabled: true,
          symbol: "circle"
        },
        pointInterval: 24 * 60 * 60 * 1000, // one day
        pointStart: moment(dateRangeStartDate, "YYYY-MM-DD").valueOf()
      }
    },
    series
  };
}

export function getDateStringsForRange(
  startDate: string,
  endDate: string
): Array<DateString> {
  const dateStrings: Array<DateString> = [];

  const startDateMoment = moment(startDate).startOf("day");
  const endDateMoment = moment(endDate).startOf("day");

  for (
    let day = moment(endDateMoment);
    day.isSameOrAfter(startDateMoment);
    day = day.subtract(1, "day")
  ) {
    dateStrings.unshift(day.format(DATE_STRING_FORMAT));
  }

  return dateStrings;
}

function getDailyMetricsForCampaign(
  dashboardTableMetricsByCampaignIdByObjectType:
    | DashboardTableMetricsByCampaignIdByObjectType
    | undefined,
  campaignId: string,
  dateString: DateString
): [MetricColumnsMetrics | null, string | null, string | null] {
  if (!dashboardTableMetricsByCampaignIdByObjectType) {
    return [null, null, null];
  }

  const dashboardTableMetricsByCampaignId =
    dashboardTableMetricsByCampaignIdByObjectType[
      DashboardTableObjectType.CAMPAIGN
    ] || {};

  const metricsList = dashboardTableMetricsByCampaignId[campaignId] || [];
  for (const metrics of metricsList) {
    if (
      moment(dateString).isSame(
        momentFromCommonDateProto(metrics.dateRange?.startDate)
      )
    ) {
      return [metrics, metrics.costCurrencyCode, metrics.revenueCurrencyCode];
    }
  }

  return [null, null, null];
}

const GoogleAdsPerformanceChart: React.FC<{
  performanceChartComponent: JSX.Element | null;
}> = ({ performanceChartComponent }) => {
  if (!performanceChartComponent) {
    return null;
  }

  return (
    <Flex flexDirection="row" justifyContent="space-between" alignItems="start">
      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          minWidth: 0
        }}
      >
        {performanceChartComponent}
      </div>
    </Flex>
  );
};

export default GoogleAdsPerformanceChart;
