import React, { useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";

// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  UpdateCampaignAction,
  UpdateCampaignsRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { UpdateAdGroupCriteriaStatusAction } from "Common/proto/googleAdsPb/adGroupCriteria_pb";
import { AdGroupCriterionStatusEnum } from "Common/google/ads/googleads/v18/enums/ad_group_criterion_status_pb";
import { sendGAEvent } from "./GA";

import {
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnAnnotation,
  getMetricColumnCompareInfo,
  renderFormattedValue
} from "./MetricColumns";
import {
  KeywordText,
  EditDropdown,
  KeywordTextCell
} from "./CampaignsTableRow";
import {
  DataTableMetricCell,
  DataTableRowCell,
  SelectableDataTableRow
} from "./AmpdDataTable";
import { CAMPAIGN_DASHBOARD_GA_CATEGORY } from "ExtensionV2/ExtensionV2";
import { getKeywordMetricsMapKey } from "../state/useKeywordObjects";
import CampaignDetailsTableSearchTermRow, {
  CampaignDetailsTableMoreSearchTermsRow
} from "./CampaignDetailsTableSearchTermRow";
import { useKeywordStatusHistory } from "../queries/useChangeHistory";
import { EditIconWithHistory } from "./EditIconWithHistory";
import { useCanUserEditStatuses } from "../../Common/utils/siteUIBehavior";

export const KeywordStatusCellContent = ({
  siteAlias,
  campaignId,
  keywordStatus,
  googleAdsAccountId,
  adGroupId,
  criteriaId,
  setWillChange,
  refetchCampaignKeyword
}) => {
  const canEditStatuses = useCanUserEditStatuses();

  const [selectedKeywordStatus, setSelectedKeywordStatus] = useState(
    keywordStatus
  );
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  // Don't load history items unless the history popup is open.
  const historyItems = useKeywordStatusHistory(
    siteAlias,
    historyOpen ? campaignId : "",
    adGroupId,
    criteriaId
  );

  const handleUpdateCampaignStatus = async (_ev, { value: status }) => {
    if (!siteAlias || !campaignId || !googleAdsAccountId) {
      return;
    }

    const enabledSelected = status === "ENABLED";

    sendGAEvent(
      CAMPAIGN_DASHBOARD_GA_CATEGORY,
      "Update Keyword",
      siteAlias,
      enabledSelected ? "enable" : "pause"
    );

    const updateReq = proto.set(new UpdateCampaignsRequest(), {
      siteAlias,
      customerId: googleAdsAccountId,
      actions: [
        proto.set(new UpdateCampaignAction(), {
          updateAdGroupCriteriaStatus: proto.set(
            new UpdateAdGroupCriteriaStatusAction(),
            {
              adGroupId: adGroupId,
              criteriaId: criteriaId,
              newStatus: enabledSelected
                ? AdGroupCriterionStatusEnum.AdGroupCriterionStatus.ENABLED
                : AdGroupCriterionStatusEnum.AdGroupCriterionStatus.PAUSED
            }
          )
        })
      ]
    });

    try {
      setIsLoading(true);
      if (setWillChange) {
        setWillChange(true);
      }

      await GRPCWebClient.updateCampaigns(updateReq);
      setSelectedKeywordStatus(status);

      if (refetchCampaignKeyword) {
        refetchCampaignKeyword(campaignId, adGroupId, criteriaId);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      if (setWillChange) {
        setWillChange(false);
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "center"
      }}
    >
      <EditDropdown
        compact
        open={dropdownOpen}
        onOpen={() => setDropdownOpen(canEditStatuses)}
        onClose={() => setDropdownOpen(false)}
        icon={
          <EditIconWithHistory
            disabled={dropdownOpen}
            readonly={!canEditStatuses}
            historyOpen={historyOpen}
            setHistoryOpen={setHistoryOpen}
            historyItems={historyItems}
          />
        }
        disabled={isLoading}
        selection
        options={[
          {
            key: "ENABLED",
            text: "Enabled",
            value: "ENABLED"
          },
          {
            key: "PAUSED",
            text: "Paused",
            value: "PAUSED"
          }
        ]}
        value={selectedKeywordStatus}
        onChange={handleUpdateCampaignStatus}
      />
    </div>
  );
};

const CampaignDetailsTableRow = ({
  campaignId,
  columns,
  costCurrencyCode,
  revenueCurrencyCode,
  rowIndex,
  googleAdsAccountId,
  keywordDataObject,
  showFractions,
  showUnconvertedRevenue,
  siteAlias,
  disabled,
  refetchCampaignKeyword,
  showSearchTermsForKeywordKey,
  setShowSearchTermsForKeywordKey,
  setMaxSampleSearchTerms,
  searchTermCount,
  sampleSearchTermRows,
  allSearchTerms,
  searchTermMetricsLoading,
  onAddSearchTermAsKeyword,
  onAddSearchTermAsNegativeKeyword,
  keywords,
  negativeKeywords
}) => {
  const [mouseDownTime, setMouseDownTime] = useState(null);
  const keywordKey = getKeywordMetricsMapKey(keywordDataObject);
  const isShowingSearchTerms = showSearchTermsForKeywordKey === keywordKey;

  const [willChange, setWillChange] = useState(false);

  const searchTermRowComponents = useMemo(() => {
    const rows = (sampleSearchTermRows || []).map(
      (searchTermObject, searchTermIndex) => {
        const isLastSearchTerm =
          searchTermIndex === sampleSearchTermRows.length - 1;

        return (
          <CampaignDetailsTableSearchTermRow
            key={searchTermObject[COLUMN_DATA_KEYS.queryText]}
            columns={columns}
            siteAlias={siteAlias}
            customerId={googleAdsAccountId}
            campaignId={campaignId}
            adGroupId={keywordDataObject.adGroupId}
            refetchCampaignKeyword={refetchCampaignKeyword}
            costCurrencyCode={costCurrencyCode}
            isLastSearchTerm={isLastSearchTerm}
            searchTermObject={searchTermObject}
            rowIndex={rowIndex}
            showFractions={showFractions}
            onAddSearchTermAsKeyword={onAddSearchTermAsKeyword}
            onAddSearchTermAsNegativeKeyword={onAddSearchTermAsNegativeKeyword}
            keywords={keywords}
            negativeKeywords={negativeKeywords}
          />
        );
      }
    );
    return (
      <>
        <CampaignDetailsTableMoreSearchTermsRow
          columns={columns}
          allSearchTerms={allSearchTerms}
          searchTermsLoading={searchTermMetricsLoading}
          isLastSearchTermRow={rows.length === 0}
          sampleSearchTermRows={sampleSearchTermRows}
          setMaxSampleSearchTerms={setMaxSampleSearchTerms}
          rowIndex={rowIndex}
        />
        {rows}
      </>
    );
  }, [
    siteAlias,
    googleAdsAccountId,
    campaignId,
    keywordDataObject.adGroupId,
    refetchCampaignKeyword,
    allSearchTerms,
    sampleSearchTermRows,
    searchTermMetricsLoading,
    columns,
    costCurrencyCode,
    rowIndex,
    showFractions,
    setMaxSampleSearchTerms,
    onAddSearchTermAsKeyword,
    onAddSearchTermAsNegativeKeyword,
    keywords,
    negativeKeywords
  ]);

  const cells = [];
  for (const column of columns) {
    let value = keywordDataObject[column];
    let formattedValue;
    switch (column) {
      case COLUMN_DATA_KEYS.status:
        cells.push(
          <DataTableRowCell key={column} rowIndex={rowIndex}>
            <KeywordStatusCellContent
              key={column}
              siteAlias={siteAlias}
              campaignId={campaignId}
              keywordStatus={value}
              googleAdsAccountId={googleAdsAccountId}
              adGroupId={keywordDataObject.adGroupId}
              criteriaId={keywordDataObject.criteriaId}
              setWillChange={setWillChange}
              refetchCampaignKeyword={refetchCampaignKeyword}
            />
          </DataTableRowCell>
        );
        break;
      case COLUMN_DATA_KEYS.keywords:
        cells.push(
          <KeywordTextCell
            key={column}
            rowIndex={rowIndex}
            style={{ minWidth: "25em", width: "25em" }}
            onMouseDown={() => {
              setMouseDownTime(Date.now());
            }}
            onMouseUp={() => {
              // Customers may try to drag on the campaign name so they can copy it to their
              // clipboard. This mouseup/mousedown tries to account for that by not navigating
              // on when a long click is detected.
              if (Date.now() - mouseDownTime > 500) {
                return;
              }
              setShowSearchTermsForKeywordKey(
                isShowingSearchTerms ? null : keywordKey
              );
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start"
              }}
            >
              {!!searchTermCount && (
                <Icon
                  name={isShowingSearchTerms ? "angle down" : "angle right"}
                />
              )}
              <KeywordText>{value}</KeywordText>
            </div>
          </KeywordTextCell>
        );
        break;
      case COLUMN_DATA_KEYS.keywordMatchType:
        cells.push(
          <DataTableRowCell
            key={column}
            rowIndex={rowIndex}
            style={{ textAlign: "center" }}
          >
            {keywordDataObject[column]}
          </DataTableRowCell>
        );
        break;

      default: {
        formattedValue = formatMetricColumnValue(
          column,
          keywordDataObject[column],
          costCurrencyCode,
          revenueCurrencyCode,
          showFractions
        );

        const annotationValue = getMetricColumnAnnotation(
          column,
          keywordDataObject,
          keywordDataObject[COLUMN_DATA_KEYS.unconvertedRevenueCurrencyCode],
          showFractions,
          showUnconvertedRevenue
        );

        // If we want to show comparison annotations, we need to compile
        // some information based on any compareMetrics value stored in
        // the data object.
        const compareInfo = getMetricColumnCompareInfo(
          column,
          keywordDataObject,
          costCurrencyCode,
          revenueCurrencyCode
        );

        cells.push(
          <DataTableMetricCell key={column} rowIndex={rowIndex}>
            {renderFormattedValue(formattedValue, annotationValue, compareInfo)}
          </DataTableMetricCell>
        );
        break;
      }
    }
  }

  const isInactive = keywordDataObject[COLUMN_DATA_KEYS.status] !== "ENABLED";

  return (
    <>
      <SelectableDataTableRow
        disabled={disabled || willChange}
        isSelected={isShowingSearchTerms}
        keywordsOpen={isShowingSearchTerms}
        isInactive={isInactive}
      >
        {cells}
      </SelectableDataTableRow>

      {isShowingSearchTerms && searchTermRowComponents}
    </>
  );
};

export default CampaignDetailsTableRow;
