export const backgroundDark = "#dcdbdc";
export const backgroundMedium = "#ededed";
export const backgroundError = "#fff6f6";
export const backgroundInfo = "#f8ffff";
export const backgroundLight = "#f9f9f9";
export const backgroundPrimary = "#2185D0";

export const darkAccent = "#333332";
export const ampdRed = "#f93822";

// Ampd UI Comparison Colors
export const comparisonGreen = "#219a45";
export const comparisonRed = "#cf2a28";

// Semantic UI Colors
export const semanticGreen = "#21ba45";
export const semanticGreenHover = "#16ab39";

export const semanticYellow = "#FBBD08";
export const semanticYellowHover = "#eaae00";

export const semanticOrange = "#F2711C";
export const semanticOrangeHover = "#f26202";

export const semanticRed = "#DB2828";
export const semanticRedHover = "#d01919";
export const semanticError = "#9f3a38";
export const semanticErrorBackground = "#fff6f6";
export const semanticErrorBorder = "#e0b4b4";
export const semanticFocusBorder = "#96c8da";

export const semanticBorder = "#cccccc";
export const semanticDefaultDarkText = "#000000de";
