import React from "react";
import { Column } from "ExtensionV2/components/metricsTable/column";
import {
  FilterArguments,
  MatchOperator,
  numberFilterFn,
  NumberFilterUI,
  stringFilterFn,
  StringFilterUI,
  TimestampFilterUI
} from "ExtensionV2/components/metricsTable/filters";
import {
  numberSort,
  stringSort
} from "ExtensionV2/components/metricsTable/sorting";
import {
  EditFacebookBidAmountCell,
  EditFacebookDailyBudgetCell,
  EditFacebookLifetimeBudgetCell,
  EditFacebookStatusCell,
  FacebookTitleCell
} from "./cells";
import { FacebookTableData } from "./useFacebookTableData";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { CONFIGURED_STATUS } from "./configuredStatus";
import {
  LinkCell,
  SimpleCell
} from "ExtensionV2/components/metricsTable/cells/SimpleCell";
import { toCapitalCase } from "Common/utils/strings";
import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";
import { getTimestampFormatter } from "ExtensionV2/components/metricsTable/commonColumns";

const StatusMatchUI = (
  args: FilterArguments,
  setArgs: (args: FilterArguments) => void
): JSX.Element => {
  const dropdownOptions = [
    { key: "active", text: "Active", value: CONFIGURED_STATUS.ACTIVE },
    { key: "paused", text: "Paused", value: CONFIGURED_STATUS.PAUSED }
  ];

  const handleSelect = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    if (data.value) {
      setArgs({ text: String(data.value), textMatch: MatchOperator.EQUALS });
    }
  };

  return (
    <Dropdown selection options={dropdownOptions} onChange={handleSelect} />
  );
};

function formatFacebookCurrency(
  rawVal: unknown,
  offset = 100,
  showFractions?: boolean
): string {
  if (typeof rawVal !== "number") {
    return "";
  }

  return formatMetric(
    getCurrencyMetricDef("USD", showFractions),
    rawVal / offset
  ).toString();
}

// TODO: (jake) Update when adding support for non USD FB currencies
function facebookBudgetFilterFn<TData>(
  row: TData,
  dataName: keyof TData,
  args: FilterArguments
): boolean {
  const offSetArgs: FilterArguments = {};
  if (args.min) {
    offSetArgs.min = args.min * 100;
  }
  if (args.max) {
    offSetArgs.max = args.max * 100;
  }

  return numberFilterFn(row, dataName, offSetArgs);
}

export const FB_COL_NAME = new Column<FacebookTableData>({
  dataName: "name",
  displayName: "Name",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: FacebookTitleCell,
  filterUI: StringFilterUI
});

export const FB_COL_STATUS = new Column<FacebookTableData>({
  dataName: "status",
  displayName: "Status",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: EditFacebookStatusCell,
  filterUI: StatusMatchUI
});

export const FB_COL_EFFECTIVE_STATUS = new Column<FacebookTableData>({
  dataName: "effectiveStatus",
  displayName: "Effective Status",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(toCapitalCase(n)),
  tableCell: SimpleCell,
  filterUI: StatusMatchUI
});

export const FB_COL_DAILY_BUDGET = new Column<FacebookTableData>({
  dataName: "dailyBudgetCents",
  displayName: "Daily Budget",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: numberSort,
  filterFn: facebookBudgetFilterFn,
  formatFn: n => String(n),
  formatCSVFn: n => formatFacebookCurrency(n),
  tableCell: EditFacebookDailyBudgetCell,
  filterUI: NumberFilterUI
});

export const FB_COL_LIFETIME_BUDGET = new Column<FacebookTableData>({
  dataName: "lifetimeBudgetCents",
  displayName: "Lifetime Budget",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: numberSort,
  filterFn: facebookBudgetFilterFn,
  formatCSVFn: n => formatFacebookCurrency(n),
  formatFn: n => String(n),
  tableCell: EditFacebookLifetimeBudgetCell,
  filterUI: NumberFilterUI
});

export const FB_COL_BID_AMOUNT = new Column<FacebookTableData>({
  dataName: "bidAmountCents",
  displayName: "Bid Amount",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: numberSort,
  filterFn: facebookBudgetFilterFn,
  formatCSVFn: n => formatFacebookCurrency(n),
  formatFn: n => String(n),
  tableCell: EditFacebookBidAmountCell,
  filterUI: NumberFilterUI
});

export const FB_COL_AD_TARGET = new Column<FacebookTableData>({
  dataName: "adTarget",
  displayName: "Ad Target",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: LinkCell,
  filterUI: StringFilterUI
});

export const FB_COL_CAMPAIGN_NAME = new Column<FacebookTableData>({
  dataName: "campaignName",
  displayName: "Campaign Name",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const FB_COL_AD_SET_NAME = new Column<FacebookTableData>({
  dataName: "adSetName",
  displayName: "Ad Set Name",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const FB_COL_AD_NAME = new Column<FacebookTableData>({
  dataName: "adName",
  displayName: "Ad Name",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const FB_COL_ATTRIBUTION_START_DATE = new Column<FacebookTableData>({
  dataName: "attributionStartDate",
  displayName: "Attribution Start Date",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: getTimestampFormatter(),
  tableCell: SimpleCell,
  filterUI: TimestampFilterUI
});
