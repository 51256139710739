import { AmazonAdTarget, getTargetTypeFromURL } from "Common/utils/amazon";
import React from "react";
import styled from "styled-components";
import { DepthAwareTableCell } from "./DepthAwareTableCell";

const StyledSimpleCell = styled(DepthAwareTableCell)`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > p {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SimpleCell = ({
  rowID,
  val,
  formatFn,
  depth
}: {
  rowID: string;
  val: unknown;
  formatFn?: (rawVal: unknown, showFractions: boolean) => string;
  depth?: number;
}): JSX.Element => {
  return (
    <StyledSimpleCell key={rowID} depth={depth}>
      <p>{formatFn ? formatFn(val, false) : val}</p>
    </StyledSimpleCell>
  );
};

export const LinkCell = ({
  rowID,
  val,
  depth
}: {
  rowID: string;
  val: unknown;
  depth?: number;
}): JSX.Element => {
  if (typeof val !== "string" || !val.startsWith("http")) {
    return <SimpleCell rowID={rowID} val={val} depth={depth} />;
  }
  const amazonAdTarget = getTargetTypeFromURL(String(val));
  let linkText = "Custom";
  switch (amazonAdTarget) {
    case AmazonAdTarget.STOREFRONT:
      linkText = "Storefront";
      break;
    case AmazonAdTarget.PRODUCT:
      linkText = "Product";
      break;
    case AmazonAdTarget.SEARCH:
      linkText = "Search";
      break;
  }

  return (
    <StyledSimpleCell key={rowID} depth={depth}>
      <div>
        <a href={String(val)} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
      </div>
    </StyledSimpleCell>
  );
};
