import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext
} from "react";
import { Message } from "semantic-ui-react";

import {
  ALL_BUT_REMOVED_STATUS,
  ENABLED_STATUS,
  PAUSED_STATUS,
  REMOVED_STATUS
} from "ExtensionV2/components/MetricColumns";
import {
  calculateDerivedMetrics,
  COLUMN_DATA_KEYS,
  COLUMN_DISPLAY_NAME_FROM_DATA_KEY,
  METRIC_COLUMNS
} from "../../components/MetricColumns";
import useCampaignObjects, {
  getAuditInfoRefreshCount
} from "ExtensionV2/state/useCampaignObjects";
import CampaignsPageRenderer from "./CampaignsPageRenderer";
import useKeywordObjects, {
  getKeywordMetricsMapKey
} from "ExtensionV2/state/useKeywordObjects";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { useCampaignConfigurationsByCampaignId } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import {
  DashboardTableObjectType,
  useDashboardTableMetrics
} from "../../queries/useDashboardTableMetrics";
import { getItemizedCampaignConfiguration } from "../../queries/useItemizedCampaignConfiguration";
import Immutable from "immutable";
import { Retailer } from "../../../Common/proto/common/retailer_pb";
import { GlobalDateContext } from "ExtensionV2";
import {
  getStoredAmazonCampaignsTableDataColumns,
  setStoredAmazonCampaignsTableDateColumns,
  resetAmazonCampaignsTableOptions,
  getStoredWalmartCampaignsTableDataColumns,
  setStoredWalmartCampaignsTableDateColumns,
  resetWalmartCampaignsTableOptions
} from "Common/utils/savedTablePreferences";
import { useSearchParams } from "react-router-dom";
import {
  getMarketplaceInfoForDomain,
  isWalmartMarketplaceInfo
} from "../../../Common/utils/marketplace";
import { MARKETPLACE_QUERY_PARAM } from "../../ExtensionV2";
import getCompareToDates from "../../state/getCompareToDates";

export const AMAZON_AMPD_CAMPAIGN_TYPE = "amazonAmpdCampaigns";
export const WALMART_AMPD_CAMPAIGN_TYPE = "walmartAmpdCampaigns";
export const ANY_AMPD_CAMPAIGN_TYPE = "ampdCampaigns";
export const NON_AMPD_CAMPAIGN_TYPE = "nonAmpdCampaigns";
export const ALL_CAMPAIGN_TYPES = "allCampaignTypes";

// TODO (robert): Remove this set of options when we fully release the Walmart UI.
export const campaignTypeOptionsLegacy = [
  {
    key: AMAZON_AMPD_CAMPAIGN_TYPE,
    text: "Ampd Campaigns",
    value: AMAZON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: NON_AMPD_CAMPAIGN_TYPE,
    text: "Non-Ampd Campaigns",
    value: NON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: ALL_CAMPAIGN_TYPES,
    text: "All Campaigns",
    value: ALL_CAMPAIGN_TYPES
  }
];

export const campaignTypeOptionsWithWalmart = [
  {
    key: AMAZON_AMPD_CAMPAIGN_TYPE,
    text: "Amazon Ampd Campaigns",
    value: AMAZON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: WALMART_AMPD_CAMPAIGN_TYPE,
    text: "Walmart Ampd Campaigns",
    value: WALMART_AMPD_CAMPAIGN_TYPE
  },
  {
    key: NON_AMPD_CAMPAIGN_TYPE,
    text: "Non-Ampd Campaigns",
    value: NON_AMPD_CAMPAIGN_TYPE
  },
  {
    key: ALL_CAMPAIGN_TYPES,
    text: "All Campaigns",
    value: ALL_CAMPAIGN_TYPES
  }
];

export const campaignStatusOptions = [
  {
    key: ALL_BUT_REMOVED_STATUS,
    text: "Enabled and Paused",
    value: ALL_BUT_REMOVED_STATUS
  },
  { key: ENABLED_STATUS, text: "Enabled", value: ENABLED_STATUS },
  { key: PAUSED_STATUS, text: "Paused", value: PAUSED_STATUS },
  { key: REMOVED_STATUS, text: "Removed", value: REMOVED_STATUS }
];

export const UNHIDEABLE_CAMPAIGNS_COLUMNS = [COLUMN_DATA_KEYS.campaignName];

/**
 * @typedef {import("ExtensionV2/components/MetricColumns").MetricColumnKey} MetricColumnKey
 * @type {MetricColumnKey[]}
 */
export const DEFAULT_AMAZON_CAMPAIGNS_COLUMNS = [
  COLUMN_DATA_KEYS.campaignName,
  COLUMN_DATA_KEYS.status,
  COLUMN_DATA_KEYS.dailyBudget,
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.brandReferralBonus,
  COLUMN_DATA_KEYS.aacos
];

/**
 * @type {MetricColumnKey[]}
 */
export const ALL_AMAZON_CAMPAIGNS_COLUMNS = [
  COLUMN_DATA_KEYS.campaignName,
  COLUMN_DATA_KEYS.status,
  COLUMN_DATA_KEYS.bidAutomationStatus,
  COLUMN_DATA_KEYS.campaignStartDate,
  COLUMN_DATA_KEYS.dailyBudget,
  COLUMN_DATA_KEYS.adTarget,
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.cpcBid,
  COLUMN_DATA_KEYS.averageCpc,
  COLUMN_DATA_KEYS.costPerConversion,
  COLUMN_DATA_KEYS.costPerNewToBrandConversion,
  COLUMN_DATA_KEYS.impressionShare,
  COLUMN_DATA_KEYS.lostISLowRank,
  COLUMN_DATA_KEYS.lostISLowBudget,
  COLUMN_DATA_KEYS.clickShare,
  COLUMN_DATA_KEYS.attributedClicks,
  COLUMN_DATA_KEYS.detailPageViews,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.cartRate,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.conversionRate,
  COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
  COLUMN_DATA_KEYS.unitsSold,
  COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
  COLUMN_DATA_KEYS.brandReferralBonus,
  COLUMN_DATA_KEYS.averageOrderValue,
  COLUMN_DATA_KEYS.roas,
  COLUMN_DATA_KEYS.newToBrandRoas,
  COLUMN_DATA_KEYS.aroas,
  COLUMN_DATA_KEYS.acos,
  COLUMN_DATA_KEYS.aacos,
  COLUMN_DATA_KEYS.tacos,
  COLUMN_DATA_KEYS.purchaseFrequency,
  COLUMN_DATA_KEYS.annualizedRevenue,
  COLUMN_DATA_KEYS.annualizedRoas,
  COLUMN_DATA_KEYS.annualizedAroas,
  COLUMN_DATA_KEYS.annualizedAcos,
  COLUMN_DATA_KEYS.annualizedAacos
];

/**
 * @type {MetricColumnKey[]}
 */
export const DEFAULT_WALMART_CAMPAIGNS_COLUMNS = [
  COLUMN_DATA_KEYS.campaignName,
  COLUMN_DATA_KEYS.status,
  COLUMN_DATA_KEYS.dailyBudget,
  COLUMN_DATA_KEYS.adTarget,
  COLUMN_DATA_KEYS.impressions,
  COLUMN_DATA_KEYS.clicks,
  COLUMN_DATA_KEYS.clickThroughRate,
  COLUMN_DATA_KEYS.cost,
  COLUMN_DATA_KEYS.averageCpc,
  COLUMN_DATA_KEYS.impressionShare
];

/**
 * @type {MetricColumnKey[]}
 */
export const ALL_WALMART_CAMPAIGNS_COLUMNS = ALL_AMAZON_CAMPAIGNS_COLUMNS.filter(
  value =>
    ![
      COLUMN_DATA_KEYS.bidAutomationStatus,
      COLUMN_DATA_KEYS.attributedClicks,
      COLUMN_DATA_KEYS.detailPageViews,
      COLUMN_DATA_KEYS.carts,
      COLUMN_DATA_KEYS.cartRate,
      COLUMN_DATA_KEYS.costPerNewToBrandConversion,
      COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
      COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage,
      COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
      COLUMN_DATA_KEYS.brandReferralBonus,
      COLUMN_DATA_KEYS.newToBrandRoas,
      COLUMN_DATA_KEYS.aroas,
      COLUMN_DATA_KEYS.acos,
      COLUMN_DATA_KEYS.aacos,
      COLUMN_DATA_KEYS.tacos,
      COLUMN_DATA_KEYS.purchaseFrequency,
      COLUMN_DATA_KEYS.annualizedRevenue,
      COLUMN_DATA_KEYS.annualizedRoas,
      COLUMN_DATA_KEYS.annualizedAroas,
      COLUMN_DATA_KEYS.annualizedAcos,
      COLUMN_DATA_KEYS.annualizedAacos
    ].includes(value)
);

/**
 * The columns that are summable (or derived from summable columns) that can
 * be shown on the Total row.
 *
 * @type {MetricColumnKey[]}
 */
export const CAMPAIGN_TOTAL_METRIC_COLUMNS = ALL_AMAZON_CAMPAIGNS_COLUMNS.concat(
  [
    COLUMN_DATA_KEYS.adjustedCost, // Needed for derived metric
    COLUMN_DATA_KEYS.newToBrandConversions, // Needed for derived metric
    COLUMN_DATA_KEYS.newToBrandUnitsSold, // Needed for derived metric
    COLUMN_DATA_KEYS.newToBrandRevenue // Needed for derived metric
  ]
).filter(
  value =>
    ![
      COLUMN_DATA_KEYS.campaignName,
      COLUMN_DATA_KEYS.status,
      COLUMN_DATA_KEYS.bidAutomationStatus,
      COLUMN_DATA_KEYS.campaignStartDate,
      COLUMN_DATA_KEYS.adTarget,
      COLUMN_DATA_KEYS.cpcBid,
      COLUMN_DATA_KEYS.purchaseFrequency // Can't aggregate
    ].includes(value)
);

/**
 * The Campaigns page columns that are relevant for the keyword sub-table (all
 * the columns minus those that are not relevant).
 *
 * @type {MetricColumnKey[]}
 */
export const CAMPAIGN_KEYWORD_COLUMNS = _.difference(
  ALL_AMAZON_CAMPAIGNS_COLUMNS,
  [
    COLUMN_DATA_KEYS.dailyBudget,
    COLUMN_DATA_KEYS.adTarget,
    COLUMN_DATA_KEYS.campaignStartDate,
    COLUMN_DATA_KEYS.bidAutomationStatus
  ]
);

export const COLUMN_DISPLAY_NAME_FOR_CAMPAIGN_KEYWORDS = CAMPAIGN_KEYWORD_COLUMNS.reduce(
  (obj, column) => {
    if (column === COLUMN_DATA_KEYS.status) {
      obj[column] = "Keyword Status";
    } else {
      obj[column] = COLUMN_DISPLAY_NAME_FROM_DATA_KEY[column];
    }
    return obj;
  },
  {}
);

// The Campaigns page columns that are relevant for the impacted products sub-table.
export const IMPACTED_PRODUCT_COLUMNS = [
  COLUMN_DATA_KEYS.campaignName,
  COLUMN_DATA_KEYS.status, // Product
  COLUMN_DATA_KEYS.dailyBudget, // Product Category
  COLUMN_DATA_KEYS.clicks, // Detail Page Views
  COLUMN_DATA_KEYS.detailPageViews,
  COLUMN_DATA_KEYS.carts,
  COLUMN_DATA_KEYS.conversions,
  COLUMN_DATA_KEYS.unitsSold,
  COLUMN_DATA_KEYS.revenue,
  COLUMN_DATA_KEYS.newToBrandConversions,
  COLUMN_DATA_KEYS.newToBrandConversionsPercentage,
  COLUMN_DATA_KEYS.newToBrandRevenue,
  COLUMN_DATA_KEYS.newToBrandRevenuePercentage,
  COLUMN_DATA_KEYS.newToBrandUnitsSold,
  COLUMN_DATA_KEYS.newToBrandUnitsSoldPercentage
];

export const COLUMN_DISPLAY_NAME_FOR_IMPACTED_PRODUCTS = IMPACTED_PRODUCT_COLUMNS.reduce(
  (obj, column) => {
    if (column === COLUMN_DATA_KEYS.status) {
      obj[column] = "Product";
    } else if (column === COLUMN_DATA_KEYS.dailyBudget) {
      obj[column] = "Product Category";
    } else if (column === COLUMN_DATA_KEYS.clicks) {
      obj[column] = "Detail Page Views";
    } else {
      obj[column] = COLUMN_DISPLAY_NAME_FROM_DATA_KEY[column];
    }
    return obj;
  },
  {}
);

const CampaignsPage = () => {
  const {
    adwordsAccounts,
    siteAlias,
    siteCurrencyCode: currencyCode,
    amazonInfo: { advertisingProfiles, advertisingAccounts }
  } = useSessionSite();
  const amazonAccount = advertisingAccounts?.[0];

  const [showKeywordsForCampaignId, setShowKeywordsForCampaignId] = useState(
    null
  );

  const [queryRemovedCampaigns, setQueryRemovedCampaigns] = useState(false);

  const {
    startDate: dateRangeStartDateString,
    endDate: dateRangeEndDateString,
    compareTo
  } = useContext(GlobalDateContext);

  const {
    data: campaignConfigurationsByCampaignId,
    isLoading: campaignConfigurationsLoading,
    isSuccess: campaignConfigurationsSuccess,
    error: campaignConfigurationsError,
    refetch: refetchCampaignConfigurations
  } = useCampaignConfigurationsByCampaignId(siteAlias);

  useEffect(() => {
    // The first call is for stored configurations directly from our database, but upon
    // success immediately refetch to get the most up-to-date versions of the configurations
    // by querying Google Ads.
    if (campaignConfigurationsSuccess) {
      refetchCampaignConfigurations().finally();
    }
  }, [campaignConfigurationsSuccess, refetchCampaignConfigurations]);

  const [disabledCampaignIds, setDisableCampaignIds] = useState(
    Immutable.Set()
  );

  const refreshCount = getAuditInfoRefreshCount();

  // TODO: useCampaignObjects doesn't handle any network errors.
  const {
    campaignMetricsMap,
    campaignCompareMetricsLoading,
    campaignCompareMetricsMap
  } = useCampaignObjects({
    refreshCount,
    siteAlias,
    currencyCode,
    hasGoogleAdsAccounts: !!adwordsAccounts && adwordsAccounts.length > 0,
    hasAmazonAccount: !!amazonAccount,
    queryMetricsOnly: true,
    dateRangeStartDate: dateRangeStartDateString,
    dateRangeEndDate: dateRangeEndDateString,
    compareTo,
    queryRemovedCampaigns
  });

  const refetchCampaignConfiguration = useCallback(
    async campaignId => {
      setDisableCampaignIds(ids => ids.add(campaignId));

      const result = await refetchCampaignConfigurations();

      setDisableCampaignIds(ids => ids.remove(campaignId));

      return result;
    },
    [refetchCampaignConfigurations]
  );

  const campaignIdsByCampaignType = useMemo(() => {
    if (campaignConfigurationsByCampaignId) {
      const createdCampaignIds = {
        [AMAZON_AMPD_CAMPAIGN_TYPE]: [],
        [WALMART_AMPD_CAMPAIGN_TYPE]: [],
        [ANY_AMPD_CAMPAIGN_TYPE]: [],
        [NON_AMPD_CAMPAIGN_TYPE]: [],
        [ALL_CAMPAIGN_TYPES]: []
      };
      for (const [
        campaignId,
        ampdCampaignConfiguration
      ] of campaignConfigurationsByCampaignId) {
        if (ampdCampaignConfiguration?.ampdProductDetails) {
          createdCampaignIds[ANY_AMPD_CAMPAIGN_TYPE].push(campaignId);
          if (ampdCampaignConfiguration.ampdProductDetails.amazon) {
            createdCampaignIds[AMAZON_AMPD_CAMPAIGN_TYPE].push(campaignId);
          }
          if (ampdCampaignConfiguration.ampdProductDetails.walmart) {
            createdCampaignIds[WALMART_AMPD_CAMPAIGN_TYPE].push(campaignId);
          }
        } else {
          createdCampaignIds[NON_AMPD_CAMPAIGN_TYPE].push(campaignId);
        }
        createdCampaignIds[ALL_CAMPAIGN_TYPES].push(campaignId);
      }

      createdCampaignIds[AMAZON_AMPD_CAMPAIGN_TYPE].sort();
      createdCampaignIds[WALMART_AMPD_CAMPAIGN_TYPE].sort();
      createdCampaignIds[ANY_AMPD_CAMPAIGN_TYPE].sort();
      createdCampaignIds[NON_AMPD_CAMPAIGN_TYPE].sort();
      createdCampaignIds[ALL_CAMPAIGN_TYPES].sort();

      return createdCampaignIds;
    }
    return {};
  }, [campaignConfigurationsByCampaignId]);

  const walmartCampaignIdsSet = useMemo(
    () => new Set(campaignIdsByCampaignType[WALMART_AMPD_CAMPAIGN_TYPE]),
    [campaignIdsByCampaignType]
  );

  const {
    keywordsLoading,
    keywordCompareMetricsLoading,
    allKeywords,
    keywordMetricsMap,
    keywordCompareMetricsMap,
    refetchCampaignKeyword,
    disabledCriteriaIds
  } = useKeywordObjects({
    refreshCount,
    siteAlias,
    campaignIds: campaignIdsByCampaignType[ANY_AMPD_CAMPAIGN_TYPE],
    hasAmazonAccount: !!amazonAccount,
    dateRangeStartDate: dateRangeStartDateString,
    dateRangeEndDate: dateRangeEndDateString,
    compareTo
  });

  const [searchParams] = useSearchParams();

  const [selectedCampaignType, setSelectedCampaignType] = useState(
    getInitialCampaignTypeFromSearchParams(searchParams)
  );

  let tableOptionsArgs = {
    allColumns: ALL_AMAZON_CAMPAIGNS_COLUMNS,
    defaultColumns: DEFAULT_AMAZON_CAMPAIGNS_COLUMNS,
    unhideableColumns: UNHIDEABLE_CAMPAIGNS_COLUMNS,
    getStoredDataTableColumns: getStoredAmazonCampaignsTableDataColumns,
    setStoredDataTableColumns: setStoredAmazonCampaignsTableDateColumns,
    resetAllDataTableOptions: resetAmazonCampaignsTableOptions
  };
  if (selectedCampaignType !== AMAZON_AMPD_CAMPAIGN_TYPE) {
    tableOptionsArgs = {
      allColumns: ALL_WALMART_CAMPAIGNS_COLUMNS,
      defaultColumns: DEFAULT_WALMART_CAMPAIGNS_COLUMNS,
      unhideableColumns: UNHIDEABLE_CAMPAIGNS_COLUMNS,
      getStoredDataTableColumns: getStoredWalmartCampaignsTableDataColumns,
      setStoredDataTableColumns: setStoredWalmartCampaignsTableDateColumns,
      resetAllDataTableOptions: resetWalmartCampaignsTableOptions
    };
  }

  const [
    dashboardTableMetricsObjectTypes,
    setDashboardTableMetricsObjectTypes
  ] = useState([]);

  let dashboardTableMetricsRetailer = Retailer.Option.UNKNOWN;
  if (selectedCampaignType === AMAZON_AMPD_CAMPAIGN_TYPE) {
    dashboardTableMetricsRetailer = Retailer.Option.AMAZON;
  } else if (selectedCampaignType === WALMART_AMPD_CAMPAIGN_TYPE) {
    dashboardTableMetricsRetailer = Retailer.Option.WALMART;
  }

  useEffect(() => {
    setDashboardTableMetricsObjectTypes(objectTypes => {
      // When applicable load Walmart Campaigns & Keywords metrics
      if (
        campaignIdsByCampaignType[WALMART_AMPD_CAMPAIGN_TYPE]?.length > 0 &&
        !objectTypes.includes(DashboardTableObjectType.CAMPAIGN)
      ) {
        return [
          ...objectTypes,
          DashboardTableObjectType.CAMPAIGN,
          DashboardTableObjectType.AD_GROUP_CRITERIA
        ];
      }
      return objectTypes;
    });
  }, [campaignIdsByCampaignType]);

  const {
    data: dashboardTableMetricsByCampaignIdByObjectType,
    isLoading: dashboardTableMetricsLoading,
    refreshServerCache: dashboardTableMetricsRefresh
  } = useDashboardTableMetrics({
    objectTypes: dashboardTableMetricsObjectTypes,
    siteAlias,
    retailer: dashboardTableMetricsRetailer,
    campaignIds: campaignIdsByCampaignType[ANY_AMPD_CAMPAIGN_TYPE],
    dateRangeStartDate: dateRangeStartDateString,
    dateRangeEndDate: dateRangeEndDateString
  });

  const [compareRangeStartDate, compareRangeEndDate] = useMemo(
    () =>
      getCompareToDates(
        dateRangeStartDateString,
        dateRangeEndDateString,
        compareTo
      ),
    [dateRangeStartDateString, dateRangeEndDateString, compareTo]
  );

  const {
    data: dashboardTableCompareMetricsByCampaignIdByObjectType
  } = useDashboardTableMetrics({
    objectTypes: dashboardTableMetricsObjectTypes,
    siteAlias,
    retailer: dashboardTableMetricsRetailer,
    campaignIds: campaignIdsByCampaignType[ANY_AMPD_CAMPAIGN_TYPE],
    dateRangeStartDate: compareRangeStartDate,
    dateRangeEndDate: compareRangeEndDate
  });

  // Only load the metrics for impacted products when they are revealed for
  // the first time (when loadImpactedProducts is called). Includes impacted
  // product metrics with the original array of ObjectTypes.
  const loadImpactedProducts = useCallback(() => {
    setDashboardTableMetricsObjectTypes(objectTypes => {
      if (objectTypes.includes(DashboardTableObjectType.IMPACTED_PRODUCT)) {
        return objectTypes;
      }
      return [...objectTypes, DashboardTableObjectType.IMPACTED_PRODUCT];
    });
  }, []);

  // TODO: These will eventually be ALL Campaign metrics, once the API returns
  // all necessary metrics data for Amazon also. For now, it is focused on
  // Walmart data & filtered to only the Walmart campaigns.
  const walmartCampaignMetricsByCampaign = useMemo(() => {
    return getCampaignMetricsByCampaignId(
      dashboardTableMetricsByCampaignIdByObjectType,
      DashboardTableObjectType.CAMPAIGN,
      walmartCampaignIdsSet
    );
  }, [dashboardTableMetricsByCampaignIdByObjectType, walmartCampaignIdsSet]);

  const walmartCampaignCompareMetricsByCampaign = useMemo(() => {
    return getCampaignMetricsByCampaignId(
      dashboardTableCompareMetricsByCampaignIdByObjectType,
      DashboardTableObjectType.CAMPAIGN,
      walmartCampaignIdsSet
    );
  }, [
    dashboardTableCompareMetricsByCampaignIdByObjectType,
    walmartCampaignIdsSet
  ]);

  // TODO: These will eventually be ALL Keywords metrics, once the API returns
  // all necessary metrics data for Amazon also. For now, it is focused on
  // Walmart data & filtered to only the Walmart campaigns.
  const walmartKeywordMetricsByCampaign = useMemo(() => {
    return getCampaignMetricsByCampaignId(
      dashboardTableMetricsByCampaignIdByObjectType,
      DashboardTableObjectType.AD_GROUP_CRITERIA,
      walmartCampaignIdsSet
    );
  }, [dashboardTableMetricsByCampaignIdByObjectType, walmartCampaignIdsSet]);

  const walmartKeywordCompareMetricsByCampaign = useMemo(() => {
    return getCampaignMetricsByCampaignId(
      dashboardTableCompareMetricsByCampaignIdByObjectType,
      DashboardTableObjectType.AD_GROUP_CRITERIA,
      walmartCampaignIdsSet
    );
  }, [
    dashboardTableCompareMetricsByCampaignIdByObjectType,
    walmartCampaignIdsSet
  ]);

  const impactedProducts =
    dashboardTableMetricsByCampaignIdByObjectType?.[
      DashboardTableObjectType.IMPACTED_PRODUCT
    ];

  // Fields of each CampaignData object:
  //     itemizedCampaignConfiguration - attributes of the campaign.
  //     metrics - metrics of the campaign.
  //     compareMetrics - different set of campaign metrics for comparison.
  //     compareMetricsLoading - true when still loading compareMetrics.
  //     keywords:
  //         keywordsLoading - true when keyword data is still loading.
  //         data:
  //             ...metrics - metrics of the keyword.
  //             compareMetrics - different set of keyword metrics for comparison.
  //             compareMetricsLoading - true when still loading compareMetrics.
  //             ...keywords - attributes of the keyword
  //
  const campaignDataByCampaignId = useMemo(() => {
    const campaignData = {};
    if (
      campaignConfigurationsByCampaignId &&
      (campaignMetricsMap || !_.isEmpty(walmartCampaignMetricsByCampaign))
    ) {
      for (const [
        campaignId,
        ampdCampaignConfiguration
      ] of campaignConfigurationsByCampaignId) {
        const itemizedCampaignConfiguration = getItemizedCampaignConfiguration(
          ampdCampaignConfiguration,
          advertisingProfiles
        );

        const walmartKeywordMetricsByKey = getKeywordMetricsByKey(
          walmartKeywordMetricsByCampaign,
          campaignId
        );
        const walmartKeywordCompareMetricsByKey = getKeywordMetricsByKey(
          walmartKeywordCompareMetricsByCampaign,
          campaignId
        );

        const keywordData = {};
        for (const keyword of allKeywords || []) {
          if (String(keyword.campaignId) !== String(campaignId)) {
            continue;
          }
          const keywordKey = getKeywordMetricsMapKey(keyword);
          let keywordMetrics = keywordMetricsMap?.get(keywordKey) || {};
          keywordMetrics = applyWalmartMetrics(
            keywordMetrics,
            walmartKeywordMetricsByKey?.[keywordKey]
          );

          let keywordCompareMetrics = undefined;
          if (keywordCompareMetricsMap) {
            keywordCompareMetrics =
              keywordCompareMetricsMap.get(keywordKey) || {};
            keywordCompareMetrics = applyWalmartMetrics(
              keywordCompareMetrics,
              walmartKeywordCompareMetricsByKey?.[keywordKey]
            );
          }

          keywordData[keywordKey] = {
            ...keywordMetrics,
            compareMetricsLoading: keywordCompareMetricsLoading,
            compareMetrics: keywordCompareMetrics,

            // Unpack object after metrics.
            ...keyword
          };
        }

        let campaignMetrics = campaignMetricsMap?.get(campaignId) || {};
        campaignMetrics = applyWalmartMetrics(
          campaignMetrics,
          walmartCampaignMetricsByCampaign?.[campaignId]?.[0]
        );

        let campaignCompareMetrics = undefined;
        if (campaignCompareMetricsMap) {
          campaignCompareMetrics = campaignCompareMetricsMap.get(campaignId);
          campaignCompareMetrics = applyWalmartMetrics(
            campaignCompareMetrics,
            walmartCampaignCompareMetricsByCampaign?.[campaignId]?.[0]
          );
        }

        campaignData[campaignId] = {
          itemizedCampaignConfiguration,
          metrics: campaignMetrics,
          compareMetricsLoading: campaignCompareMetricsLoading,
          compareMetrics: campaignCompareMetrics,
          keywords: {
            keywordsLoading,
            data: {
              ...keywordData
            }
          }
        };
      }
    }

    return campaignData;
  }, [
    campaignConfigurationsByCampaignId,
    advertisingProfiles,
    campaignMetricsMap,
    campaignCompareMetricsLoading,
    campaignCompareMetricsMap,
    keywordsLoading,
    keywordCompareMetricsLoading,
    allKeywords,
    keywordMetricsMap,
    keywordCompareMetricsMap,
    walmartCampaignMetricsByCampaign,
    walmartCampaignCompareMetricsByCampaign,
    walmartKeywordMetricsByCampaign,
    walmartKeywordCompareMetricsByCampaign
  ]);

  if (campaignConfigurationsError) {
    return <Message error>Unable to load campaign information.</Message>;
  }

  let isTableLoading = campaignConfigurationsLoading || !campaignMetricsMap;
  if (walmartCampaignIdsSet.size > 0) {
    // Consider the table still loading when we are waiting for the initial Walmart
    // campaign and keyword metrics (so they don't appear as zero while we are still
    // waiting for them).  However, don't consider the whole table still loading
    // when we are lazy loading the metrics for Impacted Products.
    if (
      dashboardTableMetricsLoading &&
      !dashboardTableMetricsObjectTypes.includes(
        DashboardTableObjectType.IMPACTED_PRODUCT
      )
    ) {
      isTableLoading = true;
    }
  }

  return (
    <CampaignsPageRenderer
      tableOptionsArgs={tableOptionsArgs}
      selectedCampaignType={selectedCampaignType}
      setSelectedCampaignType={setSelectedCampaignType}
      campaignIdsByCampaignType={campaignIdsByCampaignType}
      campaignDataByCampaignId={campaignDataByCampaignId}
      campaignConfigurationsByCampaignId={campaignConfigurationsByCampaignId}
      campaignConfigurationsLoading={isTableLoading}
      refetchCampaignConfiguration={refetchCampaignConfiguration}
      currencyCode={currencyCode}
      disabledCampaignIds={disabledCampaignIds}
      disabledCriteriaIds={disabledCriteriaIds}
      refetchCampaignKeyword={refetchCampaignKeyword}
      setShowKeywordsForCampaignId={setShowKeywordsForCampaignId}
      showKeywordsForCampaignId={showKeywordsForCampaignId}
      siteAlias={siteAlias}
      campaignCompareMetricsMap={campaignCompareMetricsMap}
      campaignCompareMetricsLoading={campaignCompareMetricsLoading}
      setQueryRemovedCampaigns={setQueryRemovedCampaigns}
      loadImpactedProducts={loadImpactedProducts}
      keywordsLoading={keywordsLoading}
      impactedProductsLoading={
        dashboardTableMetricsLoading &&
        dashboardTableMetricsObjectTypes.includes(
          DashboardTableObjectType.IMPACTED_PRODUCT
        )
      }
      impactedProducts={impactedProducts}
      refreshMetrics={dashboardTableMetricsRefresh}
    />
  );
};

function getInitialCampaignTypeFromSearchParams(searchParams) {
  const marketplaceInfo = getMarketplaceInfoForDomain(
    searchParams.get(MARKETPLACE_QUERY_PARAM)
  );

  if (!marketplaceInfo) {
    return ANY_AMPD_CAMPAIGN_TYPE;
  }

  if (isWalmartMarketplaceInfo(marketplaceInfo)) {
    return WALMART_AMPD_CAMPAIGN_TYPE;
  }

  return AMAZON_AMPD_CAMPAIGN_TYPE;
}

// Returns map from campaign id to metrics for a specified object type and only
// for the specified campaign ids (in a set).
function getCampaignMetricsByCampaignId(
  dashboardTableMetricsByCampaignIdByObjectType,
  objectType,
  onlyCampaignIdsSet
) {
  const objectMetricsByCampaignId =
    dashboardTableMetricsByCampaignIdByObjectType?.[objectType] || {};

  // Populate all calculated/derived metrics on each Walmart campaign
  Object.entries(objectMetricsByCampaignId).forEach(([campaignId]) => {
    if (!onlyCampaignIdsSet.has(campaignId)) {
      // Remove it from the object of Walmart campaigns, so it can't take
      // precedence over the graphql-sourced Amazon campaign data.
      delete objectMetricsByCampaignId[campaignId];
    }
  });
  return objectMetricsByCampaignId;
}

// Returns a map from keywords (by agid+kwid key) to metrics for a particular campaign,
// if available.
function getKeywordMetricsByKey(keywordMetricsByCampaign, campaignId) {
  return keywordMetricsByCampaign?.[campaignId]?.reduce(
    (metricsByKey, keywordMetrics) => {
      metricsByKey[getKeywordMetricsMapKey(keywordMetrics)] = keywordMetrics;
      return metricsByKey;
    },
    {}
  );
}

// Returns a new version of the metrics object with the conversion side metrics,
// if any, coming from the dashboard table metrics (because the graphql metrics
// don't include the walmart conversion side metrics).
function applyWalmartMetrics(metrics, walmartMetrics) {
  if (!metrics || !walmartMetrics) {
    return metrics;
  }

  const newMetrics = {
    ...metrics,
    retailer: Retailer.Option.WALMART,
    conversions: walmartMetrics.conversions,
    unitsSold: walmartMetrics.unitsSold,
    revenue: walmartMetrics.revenue
  };
  calculateDerivedMetrics(newMetrics, METRIC_COLUMNS);

  return newMetrics;
}

export default CampaignsPage;
