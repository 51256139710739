// source: proto/ampdPb/facebookConfiguration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_facebook_pb = require('../../proto/common/facebook_pb.js');
goog.object.extend(proto, proto_common_facebook_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.Option', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.Status', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdSetDetails', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.Option', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.Option', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdSetDetails.Status', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.CampaignDetails', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.Option', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.CampaignDetails.Status', null, global);
goog.exportSymbol('proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.displayName = 'proto.ampdPb.FacebookConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ampdPb.FacebookConfiguration.CampaignDetails.repeatedFields_, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.CampaignDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.CampaignDetails.displayName = 'proto.ampdPb.FacebookConfiguration.CampaignDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.CampaignDetails.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.displayName = 'proto.ampdPb.FacebookConfiguration.CampaignDetails.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.displayName = 'proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ampdPb.FacebookConfiguration.AdSetDetails.repeatedFields_, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdSetDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdSetDetails.displayName = 'proto.ampdPb.FacebookConfiguration.AdSetDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdSetDetails.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.displayName = 'proto.ampdPb.FacebookConfiguration.AdSetDetails.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.displayName = 'proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.displayName = 'proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.Status.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.repeatedFields_, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.displayName = 'proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    campaignDetails: (f = msg.getCampaignDetails()) && proto.ampdPb.FacebookConfiguration.CampaignDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration}
 */
proto.ampdPb.FacebookConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration;
  return proto.ampdPb.FacebookConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration}
 */
proto.ampdPb.FacebookConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = new proto.ampdPb.FacebookConfiguration.CampaignDetails;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.CampaignDetails.deserializeBinaryFromReader);
      msg.setCampaignDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCampaignDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ampdPb.FacebookConfiguration.CampaignDetails.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.CampaignDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    effectiveStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dailyBudget: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lifetimeBudget: jspb.Message.getFieldWithDefault(msg, 6, 0),
    bidStrategy: jspb.Message.getFieldWithDefault(msg, 7, 0),
    objective: jspb.Message.getFieldWithDefault(msg, 8, ""),
    spendCap: jspb.Message.getFieldWithDefault(msg, 9, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stopTime: jspb.Message.getFieldWithDefault(msg, 11, ""),
    adSetDetailsList: jspb.Message.toObjectList(msg.getAdSetDetailsList(),
    proto.ampdPb.FacebookConfiguration.AdSetDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.CampaignDetails;
  return proto.ampdPb.FacebookConfiguration.CampaignDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option} */ (reader.readEnum());
      msg.setEffectiveStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDailyBudget(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLifetimeBudget(value);
      break;
    case 7:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.Option} */ (reader.readEnum());
      msg.setBidStrategy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjective(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpendCap(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStopTime(value);
      break;
    case 12:
      var value = new proto.ampdPb.FacebookConfiguration.AdSetDetails;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdSetDetails.deserializeBinaryFromReader);
      msg.addAdSetDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.CampaignDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEffectiveStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDailyBudget();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLifetimeBudget();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getBidStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getObjective();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSpendCap();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStopTime();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAdSetDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.ampdPb.FacebookConfiguration.AdSetDetails.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.CampaignDetails.Status;
  return proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  ACTIVE: 2,
  PAUSED: 3,
  DELETED: 4,
  ARCHIVED: 5,
  IN_PROCESS: 6,
  WITH_ISSUES: 7
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy;
  return proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  LOWEST_COST_WITHOUT_CAP: 2,
  LOWEST_COST_WITH_BID_CAP: 3,
  COST_CAP: 4,
  LOWEST_COST_WITH_MIN_ROAS: 5
};

/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Status.Option effective_status = 3;
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getEffectiveStatus = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setEffectiveStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Status.Option status = 4;
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getStatus = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.Status.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 daily_budget = 5;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getDailyBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 lifetime_budget = 6;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getLifetimeBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setLifetimeBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional BidStrategy.Option bid_strategy = 7;
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.Option}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getBidStrategy = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.Option} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.CampaignDetails.BidStrategy.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setBidStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string objective = 8;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getObjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setObjective = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 spend_cap = 9;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getSpendCap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setSpendCap = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string start_time = 10;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string stop_time = 11;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getStopTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setStopTime = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated AdSetDetails ad_set_details = 12;
 * @return {!Array<!proto.ampdPb.FacebookConfiguration.AdSetDetails>}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.getAdSetDetailsList = function() {
  return /** @type{!Array<!proto.ampdPb.FacebookConfiguration.AdSetDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.FacebookConfiguration.AdSetDetails, 12));
};


/**
 * @param {!Array<!proto.ampdPb.FacebookConfiguration.AdSetDetails>} value
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
*/
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.setAdSetDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails}
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.addAdSetDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.ampdPb.FacebookConfiguration.AdSetDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.FacebookConfiguration.CampaignDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.CampaignDetails.prototype.clearAdSetDetailsList = function() {
  return this.setAdSetDetailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.repeatedFields_ = [18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdSetDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    adSetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    effectiveStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dailyBudget: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dailyMinSpendTarget: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dailySpendCap: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lifetimeBudget: jspb.Message.getFieldWithDefault(msg, 8, 0),
    lifetimeMinSpendTarget: jspb.Message.getFieldWithDefault(msg, 9, 0),
    lifetimeSpendCap: jspb.Message.getFieldWithDefault(msg, 10, 0),
    bidAmount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    bidStrategy: jspb.Message.getFieldWithDefault(msg, 12, 0),
    billingEvent: jspb.Message.getFieldWithDefault(msg, 13, ""),
    optimizationGoal: jspb.Message.getFieldWithDefault(msg, 14, 0),
    destinationType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 16, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 17, ""),
    adDetailsList: jspb.Message.toObjectList(msg.getAdDetailsList(),
    proto.ampdPb.FacebookConfiguration.AdDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdSetDetails;
  return proto.ampdPb.FacebookConfiguration.AdSetDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdSetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option} */ (reader.readEnum());
      msg.setEffectiveStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDailyBudget(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDailyMinSpendTarget(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDailySpendCap(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLifetimeBudget(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLifetimeMinSpendTarget(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLifetimeSpendCap(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBidAmount(value);
      break;
    case 12:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.Option} */ (reader.readEnum());
      msg.setBidStrategy(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingEvent(value);
      break;
    case 14:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.Option} */ (reader.readEnum());
      msg.setOptimizationGoal(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 18:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.deserializeBinaryFromReader);
      msg.addAdDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdSetDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdSetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEffectiveStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDailyBudget();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDailyMinSpendTarget();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDailySpendCap();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLifetimeBudget();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getLifetimeMinSpendTarget();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getLifetimeSpendCap();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getBidAmount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getBidStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getBillingEvent();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOptimizationGoal();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getDestinationType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAdDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdSetDetails.Status;
  return proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  ACTIVE: 2,
  PAUSED: 3,
  DELETED: 4,
  CAMPAIGN_PAUSED: 5,
  ARCHIVED: 6,
  IN_PROCESS: 7,
  WITH_ISSUES: 8
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy;
  return proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  LOWEST_COST_WITHOUT_CAP: 2,
  LOWEST_COST_WITH_BID_CAP: 3,
  COST_CAP: 4,
  LOWEST_COST_WITH_MIN_ROAS: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal;
  return proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  NONE: 2,
  APP_INSTALLS: 3,
  AD_RECALL_LIFT: 4,
  ENGAGED_USERS: 5,
  EVENT_RESPONSES: 6,
  IMPRESSIONS: 7,
  LEAD_GENERATION: 8,
  QUALITY_LEAD: 9,
  LINK_CLICKS: 10,
  OFFSITE_CONVERSIONS: 11,
  PAGE_LIKES: 12,
  POST_ENGAGEMENT: 13,
  QUALITY_CALL: 14,
  REACH: 15,
  LANDING_PAGE_VIEWS: 16,
  VISIT_INSTAGRAM_PROFILE: 17,
  VALUE: 18,
  THRUPLAY: 19,
  DERIVED_EVENTS: 20,
  APP_INSTALLS_AND_OFFSITE_CONVERSIONS: 21,
  CONVERSATIONS: 22,
  IN_APP_VALUE: 23,
  MESSAGING_PURCHASE_CONVERSION: 24,
  SUBSCRIBERS: 25,
  REMINDERS_SET: 26,
  MEANINGFUL_CALL_ATTEMPT: 27,
  PROFILE_VISIT: 28,
  MESSAGING_APPOINTMENT_CONVERSION: 29
};

/**
 * optional string ad_set_id = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getAdSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setAdSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Status.Option effective_status = 3;
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getEffectiveStatus = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setEffectiveStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Status.Option status = 4;
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getStatus = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.Status.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 daily_budget = 5;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getDailyBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 daily_min_spend_target = 6;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getDailyMinSpendTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setDailyMinSpendTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 daily_spend_cap = 7;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getDailySpendCap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setDailySpendCap = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 lifetime_budget = 8;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getLifetimeBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setLifetimeBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 lifetime_min_spend_target = 9;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getLifetimeMinSpendTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setLifetimeMinSpendTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 lifetime_spend_cap = 10;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getLifetimeSpendCap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setLifetimeSpendCap = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 bid_amount = 11;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getBidAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setBidAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional BidStrategy.Option bid_strategy = 12;
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.Option}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getBidStrategy = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.Option} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.BidStrategy.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setBidStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string billing_event = 13;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getBillingEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setBillingEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional OptimizationGoal.Option optimization_goal = 14;
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.Option}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getOptimizationGoal = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.Option} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdSetDetails.OptimizationGoal.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setOptimizationGoal = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string destination_type = 15;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getDestinationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setDestinationType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string start_time = 16;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string end_time = 17;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated AdDetails ad_details = 18;
 * @return {!Array<!proto.ampdPb.FacebookConfiguration.AdDetails>}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.getAdDetailsList = function() {
  return /** @type{!Array<!proto.ampdPb.FacebookConfiguration.AdDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails, 18));
};


/**
 * @param {!Array<!proto.ampdPb.FacebookConfiguration.AdDetails>} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
*/
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.setAdDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails}
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.addAdDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.ampdPb.FacebookConfiguration.AdDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.FacebookConfiguration.AdSetDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdSetDetails.prototype.clearAdDetailsList = function() {
  return this.setAdDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    configuredForAmpdTime: (f = msg.getConfiguredForAmpdTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    effectiveStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bidAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    adActiveTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adCreative: (f = msg.getAdCreative()) && proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails;
  return proto.ampdPb.FacebookConfiguration.AdDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConfiguredForAmpdTime(value);
      break;
    case 3:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option} */ (reader.readEnum());
      msg.setEffectiveStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBidAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdActiveTime(value);
      break;
    case 7:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.deserializeBinaryFromReader);
      msg.setAdCreative(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfiguredForAmpdTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBidAmount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAdActiveTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdCreative();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.Status.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.Status}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.Status;
  return proto.ampdPb.FacebookConfiguration.AdDetails.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.Status}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  ACTIVE: 2,
  PAUSED: 3,
  DELETED: 4,
  PENDING_REVIEW: 5,
  DISAPPROVED: 6,
  PREAPPROVED: 7,
  PENDING_BILLING_INFO: 8,
  CAMPAIGN_PAUSED: 9,
  ARCHIVED: 10,
  ADSET_PAUSED: 11,
  IN_PROCESS: 12,
  WITH_ISSUES: 13
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.toObject = function(includeInstance, msg) {
  var f, obj = {
    creativeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    body: jspb.Message.getFieldWithDefault(msg, 5, ""),
    callToActionType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    linkUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    templateUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    urlTags: jspb.Message.getFieldWithDefault(msg, 9, ""),
    objectUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    objectStoryId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    assetFeedSpec: (f = msg.getAssetFeedSpec()) && proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.toObject(includeInstance, f),
    objectStorySpec: (f = msg.getObjectStorySpec()) && proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreativeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallToActionType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTags(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectUrl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectStoryId(value);
      break;
    case 12:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.deserializeBinaryFromReader);
      msg.setAssetFeedSpec(value);
      break;
    case 13:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.deserializeBinaryFromReader);
      msg.setObjectStorySpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreativeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCallToActionType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLinkUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTemplateUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUrlTags();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getObjectUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getObjectStoryId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAssetFeedSpec();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.serializeBinaryToWriter
    );
  }
  f = message.getObjectStorySpec();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkData: (f = msg.getLinkData()) && proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.toObject(includeInstance, f),
    templateData: (f = msg.getTemplateData()) && proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.toObject(includeInstance, f),
    videoData: (f = msg.getVideoData()) && proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.deserializeBinaryFromReader);
      msg.setLinkData(value);
      break;
    case 2:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.deserializeBinaryFromReader);
      msg.setTemplateData(value);
      break;
    case 3:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.deserializeBinaryFromReader);
      msg.setVideoData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.serializeBinaryToWriter
    );
  }
  f = message.getTemplateData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.serializeBinaryToWriter
    );
  }
  f = message.getVideoData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.serializeBinaryToWriter
    );
  }
};


/**
 * optional LinkData link_data = 1;
 * @return {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.getLinkData = function() {
  return /** @type{?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData, 1));
};


/**
 * @param {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData|undefined} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.setLinkData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.clearLinkData = function() {
  return this.setLinkData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.hasLinkData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LinkData template_data = 2;
 * @return {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.getTemplateData = function() {
  return /** @type{?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData, 2));
};


/**
 * @param {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData|undefined} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.setTemplateData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.clearTemplateData = function() {
  return this.setTemplateData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.hasTemplateData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VideoData video_data = 3;
 * @return {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.getVideoData = function() {
  return /** @type{?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData, 3));
};


/**
 * @param {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData|undefined} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.setVideoData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.clearVideoData = function() {
  return this.setVideoData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec.prototype.hasVideoData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.LinkData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkCaption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link_caption = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.prototype.getLinkCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.VideoData.prototype.setLinkCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    adFormatsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    bodiesList: jspb.Message.toObjectList(msg.getBodiesList(),
    proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.toObject, includeInstance),
    linkUrlsList: jspb.Message.toObjectList(msg.getLinkUrlsList(),
    proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.toObject, includeInstance),
    titlesList: jspb.Message.toObjectList(msg.getTitlesList(),
    proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdFormats(values[i]);
      }
      break;
    case 2:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.deserializeBinaryFromReader);
      msg.addBodies(value);
      break;
    case 3:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.deserializeBinaryFromReader);
      msg.addLinkUrls(value);
      break;
    case 4:
      var value = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title;
      reader.readMessage(value,proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.deserializeBinaryFromReader);
      msg.addTitles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdFormatsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getBodiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.serializeBinaryToWriter
    );
  }
  f = message.getLinkUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.serializeBinaryToWriter
    );
  }
  f = message.getTitlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    urlTags: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrlTags();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url_tags = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.prototype.getUrlTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body.prototype.setUrlTags = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    carouselSeeMoreUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deeplinkUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    urlTags: jspb.Message.getFieldWithDefault(msg, 4, ""),
    websiteUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarouselSeeMoreUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeeplinkUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTags(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarouselSeeMoreUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeeplinkUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrlTags();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWebsiteUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string carousel_see_more_url = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.getCarouselSeeMoreUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.setCarouselSeeMoreUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deeplink_url = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.getDeeplinkUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.setDeeplinkUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_url = 3;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.getDisplayUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.setDisplayUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string url_tags = 4;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.getUrlTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.setUrlTags = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string website_url = 5;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.getWebsiteUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl.prototype.setWebsiteUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    urlTags: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrlTags();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url_tags = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.prototype.getUrlTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title.prototype.setUrlTags = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated common.Facebook.API.AssetFeedSpec.AdFormat.Option ad_formats = 1;
 * @return {!Array<!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option>}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.getAdFormatsList = function() {
  return /** @type {!Array<!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option>} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.setAdFormatsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option} value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.addAdFormats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.clearAdFormatsList = function() {
  return this.setAdFormatsList([]);
};


/**
 * repeated Body bodies = 2;
 * @return {!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body>}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.getBodiesList = function() {
  return /** @type{!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body, 2));
};


/**
 * @param {!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body>} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.setBodiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.addBodies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Body, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.clearBodiesList = function() {
  return this.setBodiesList([]);
};


/**
 * repeated LinkUrl link_urls = 3;
 * @return {!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl>}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.getLinkUrlsList = function() {
  return /** @type{!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl, 3));
};


/**
 * @param {!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl>} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.setLinkUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.addLinkUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.LinkUrl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.clearLinkUrlsList = function() {
  return this.setLinkUrlsList([]);
};


/**
 * repeated Title titles = 4;
 * @return {!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title>}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.getTitlesList = function() {
  return /** @type{!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title, 4));
};


/**
 * @param {!Array<!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title>} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.setTitlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.addTitles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.Title, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec.prototype.clearTitlesList = function() {
  return this.setTitlesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status;
  return proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.Status.Option = {
  UNSPECIFIED: 0,
  UNKNOWN: 1,
  ACTIVE: 2,
  IN_PROCESS: 3,
  WITH_ISSUES: 4,
  DELETED: 5
};

/**
 * optional string creative_id = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getCreativeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setCreativeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string body = 5;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string call_to_action_type = 6;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getCallToActionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setCallToActionType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string link_url = 7;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getLinkUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setLinkUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string template_url = 8;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getTemplateUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setTemplateUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string url_tags = 9;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getUrlTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setUrlTags = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string object_url = 10;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getObjectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setObjectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string object_story_id = 11;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getObjectStoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setObjectStoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional AssetFeedSpec asset_feed_spec = 12;
 * @return {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getAssetFeedSpec = function() {
  return /** @type{?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec, 12));
};


/**
 * @param {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.AssetFeedSpec|undefined} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setAssetFeedSpec = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.clearAssetFeedSpec = function() {
  return this.setAssetFeedSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.hasAssetFeedSpec = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ObjectStorySpec object_story_spec = 13;
 * @return {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.getObjectStorySpec = function() {
  return /** @type{?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec, 13));
};


/**
 * @param {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.ObjectStorySpec|undefined} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.setObjectStorySpec = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.clearObjectStorySpec = function() {
  return this.setObjectStorySpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative.prototype.hasObjectStorySpec = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp configured_for_ampd_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.getConfiguredForAmpdTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.setConfiguredForAmpdTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.clearConfiguredForAmpdTime = function() {
  return this.setConfiguredForAmpdTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.hasConfiguredForAmpdTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Status.Option effective_status = 3;
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.getEffectiveStatus = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.setEffectiveStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Status.Option status = 4;
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.getStatus = function() {
  return /** @type {!proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ampdPb.FacebookConfiguration.AdDetails.Status.Option} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 bid_amount = 5;
 * @return {number}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.getBidAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.setBidAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string ad_active_time = 6;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.getAdActiveTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.setAdActiveTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional AdCreative ad_creative = 7;
 * @return {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.getAdCreative = function() {
  return /** @type{?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative, 7));
};


/**
 * @param {?proto.ampdPb.FacebookConfiguration.AdDetails.AdCreative|undefined} value
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
*/
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.setAdCreative = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.FacebookConfiguration.AdDetails} returns this
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.clearAdCreative = function() {
  return this.setAdCreative(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.FacebookConfiguration.AdDetails.prototype.hasAdCreative = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.ampdPb.FacebookConfiguration.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ampdPb.FacebookConfiguration} returns this
 */
proto.ampdPb.FacebookConfiguration.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CampaignDetails campaign_details = 2;
 * @return {?proto.ampdPb.FacebookConfiguration.CampaignDetails}
 */
proto.ampdPb.FacebookConfiguration.prototype.getCampaignDetails = function() {
  return /** @type{?proto.ampdPb.FacebookConfiguration.CampaignDetails} */ (
    jspb.Message.getWrapperField(this, proto.ampdPb.FacebookConfiguration.CampaignDetails, 2));
};


/**
 * @param {?proto.ampdPb.FacebookConfiguration.CampaignDetails|undefined} value
 * @return {!proto.ampdPb.FacebookConfiguration} returns this
*/
proto.ampdPb.FacebookConfiguration.prototype.setCampaignDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ampdPb.FacebookConfiguration} returns this
 */
proto.ampdPb.FacebookConfiguration.prototype.clearCampaignDetails = function() {
  return this.setCampaignDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ampdPb.FacebookConfiguration.prototype.hasCampaignDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.ampdPb);
