import { useMemo } from "react";

import { Retailer } from "Common/proto/common/retailer_pb";
import {
  DashboardTable,
  DashboardTableMetrics
} from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";
import {
  DashboardTableMetricsByCampaignIdByObjectType,
  useDashboardTableMetrics
} from "ExtensionV2/queries/useDashboardTableMetrics";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { momentFromCommonDateProto } from "Common/utils/DateUtilities";

// Fetched from MDS, a combination of live FB calls
// and calls to our attribution database.
export type FacebookTableRowMetrics = {
  rowID: string;
  addToCartRate: number;
  addToCartClicks: number;
  brandReferralBonus: number;
  clicks: number;
  clickRate: number;
  conversionRate: number;
  conversions: number;
  conversionValue: number;
  costPerConversion: number;
  costPerAddToCart: number;
  costPerClick: number;
  costPerDetailPageView: number;
  costPerMille: number;
  costPerNTBConversion: number;
  detailPageViewRate: number;
  detailPageViews: number;
  impressions: number;
  marketplaceClickRate: number;
  marketplaceClicks: number;
  marketplaceCostPerClick: number;
  ntbConversionRate: number;
  ntbConversions: number;
  ntbRevenue: number;
  ntbRevenueRate: number;
  ntbROAS: number;
  ntbUnitsSold: number;
  ntbUnitsSoldRate: number;
  roas: number;
  spend: number;
  unitsSold: number;
  metricsStart?: number;
  metricsEnd?: number;
};

export const useFacebookTableRowMetrics = (
  siteAlias: string,
  campaignIds: Array<string>,
  startDate: string,
  endDate: string,
  respectAdAttributionDate: boolean
): {
  data: Array<FacebookTableRowMetrics> | undefined;
  isLoading: boolean;
  isFetching: boolean;
  error: unknown;
} => {
  const facebookRowMetricsQuery = useDashboardTableMetrics({
    objectTypes: [
      DashboardTable.ObjectType.Option.CAMPAIGN,
      DashboardTable.ObjectType.Option.FACEBOOK_AD_SET,
      DashboardTable.ObjectType.Option.FACEBOOK_AD
    ],
    campaignPlatform: CampaignPlatform.Option.FACEBOOK,
    siteAlias: campaignIds.length > 0 ? siteAlias : "",
    retailer: Retailer.Option.AMAZON,
    campaignIds,
    dateRangeStartDate: startDate,
    dateRangeEndDate: endDate,
    queryByDay: false,
    respectAdAttributionDate
  });

  const metricRows = useMemo(
    () => mapCampaignMetricsToRowMetrics(facebookRowMetricsQuery.data || {}),
    [facebookRowMetricsQuery.data]
  );

  return {
    ...facebookRowMetricsQuery,
    data: metricRows
  };
};

export function mapCampaignMetricsToRowMetrics(
  metrics: DashboardTableMetricsByCampaignIdByObjectType
): Array<FacebookTableRowMetrics> {
  const rows: Array<FacebookTableRowMetrics & {
    prev?: FacebookTableRowMetrics;
  }> = [];

  const metricsByLevel: Array<DashboardTableMetrics.AsObject> = [
    ...Object.values(
      metrics[DashboardTable.ObjectType.Option.CAMPAIGN] ?? {}
    ).flat(),
    ...Object.values(
      metrics[DashboardTable.ObjectType.Option.FACEBOOK_AD_SET] ?? {}
    ).flat(),
    ...Object.values(
      metrics[DashboardTable.ObjectType.Option.FACEBOOK_AD] ?? {}
    ).flat()
  ];

  for (const metric of metricsByLevel) {
    const readMetrics = {
      addToCartClicks: metric.addToCartClicks,
      brandReferralBonus: metric.brandReferralBonus,
      conversions: metric.conversions,
      conversionValue: metric.revenue,
      detailPageViews: metric.detailPageViewClicks,
      clicks: metric.clicks,
      impressions: metric.impressions,
      marketplaceClicks: metric.attributedClicks,
      ntbConversions: metric.newToBrandConversions,
      ntbRevenue: metric.newToBrandRevenue,
      ntbUnitsSold: metric.newToBrandUnitsSold,
      spend: metric.cost,
      unitsSold: metric.unitsSold,
      metricsStart: momentFromCommonDateProto(
        metric.dateRange?.startDate
      )?.valueOf(),
      metricsEnd: momentFromCommonDateProto(
        metric.dateRange?.endDate
      )?.valueOf()
    };

    const calculatedMetrics = {
      addToCartRate: calcAddToCartRate(metric),
      conversionRate: calcConversionRate(metric),
      costPerAddToCart: calcCostPerAddToCart(metric),
      costPerConversion: calcCostPerConversion(metric),
      costPerDetailPageView: calcCostPerDetailPageView(metric),
      costPerMille: calcCostPerMille(metric),
      costPerNTBConversion: calcCostPerNTBConversion(metric),
      detailPageViewRate: calcDetailPageViewRate(metric),
      costPerClick: calcCostPerClick(metric),
      clickRate: calcClickRate(metric),
      marketplaceClickRate: calcMarketplaceClickRate(metric),
      marketplaceCostPerClick: calcMarketplaceCostPerClick(metric),
      ntbConversionRate: calcNewToBrandConversionRate(metric),
      ntbRevenueRate: calcNewToBrandRevenueRate(metric),
      ntbROAS: calcNewToBrandROAS(metric),
      ntbUnitsSoldRate: calcNewToBrandUnitsSoldRate(metric),
      roas: calcROAS(metric)
    };

    let rowId = metric.campaignId;
    if (metric.facebookAdSetId) {
      rowId += `-${metric.facebookAdSetId}`;
    }

    if (metric.facebookAdId) {
      rowId += `-${metric.facebookAdId}`;
    }

    rows.push({
      rowID: rowId,
      ...readMetrics,
      ...calculatedMetrics
    });
  }

  return rows;
}

// Click Rates are the number of clicks per Facebook impression.
function calcClickRate(performance: DashboardTableMetrics.AsObject): number {
  return performance.clicks / performance.impressions;
}

// Be aware that sometimes we see more Amazon attributed clicks than Facebook
// clicks (which should not be impossible), so we consider the Amazon click rate
// more as a second Click Rate metric than an attribution metric.
function calcMarketplaceClickRate(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.attributedClicks / performance.impressions;
}

// The attributed metrics rates are the rate of attributed action per Facebook click
function calcAddToCartRate(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.addToCartClicks / performance.clicks;
}

function calcConversionRate(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.conversions / performance.clicks;
}

function calcDetailPageViewRate(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.detailPageViewClicks / performance.clicks;
}

// Cost metrics are calculated as a ratio of spend to the relevant metric
function calcMarketplaceCostPerClick(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.cost / performance.attributedClicks;
}

function calcCostPerClick(performance: DashboardTableMetrics.AsObject): number {
  return performance.cost / performance.clicks;
}

const MILLE = 1_000;
// "cost per 1,000" impressions
function calcCostPerMille(performance: DashboardTableMetrics.AsObject): number {
  return (performance.cost / performance.impressions) * MILLE;
}

function calcCostPerDetailPageView(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.cost / performance.detailPageViewClicks;
}

function calcCostPerAddToCart(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.cost / performance.addToCartClicks;
}

function calcCostPerConversion(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.cost / performance.conversions;
}

function calcCostPerNTBConversion(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.cost / performance.newToBrandConversions;
}

function calcROAS(performance: DashboardTableMetrics.AsObject): number {
  return performance.revenue / performance.cost;
}

function calcNewToBrandConversionRate(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.newToBrandConversions / performance.conversions;
}

function calcNewToBrandUnitsSoldRate(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.newToBrandUnitsSold / performance.unitsSold;
}

function calcNewToBrandRevenueRate(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.newToBrandRevenue / performance.revenue;
}

function calcNewToBrandROAS(
  performance: DashboardTableMetrics.AsObject
): number {
  return performance.newToBrandRevenue / performance.cost;
}
