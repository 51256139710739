import _ from "lodash";
import moment from "moment";

import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import {
  Button,
  DropdownItemProps,
  DropdownProps,
  Form,
  Message
} from "semantic-ui-react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Colors } from "app.css";
import {
  DashboardTableMetrics,
  DashboardTableMetricsByCampaignIdByObjectType,
  DashboardTableObjectType,
  useDashboardTableMetrics
} from "../queries/useDashboardTableMetrics";
import { Retailer } from "Common/proto/common/retailer_pb";
import {
  DATE_STRING_FORMAT,
  DateString,
  useBudgetHistoryDayByDay
} from "../queries/useChangeHistoryForDayByDayBudgets";
import { getDateStringsForRange } from "./GoogleAdsPerformanceChart";
import { GoogleAdsResourceStatus } from "Common/proto/ampdPb/googleAdsConfiguration_pb";
import {
  convertMicrosToCurrencyUnit,
  getCurrencyMetricDef
} from "Common/utils/money";
import { CampaignConfigurationsByCampaignIdResult } from "ExtensionV2/queries/useCampaignConfigurationsByCampaignId";
import {
  formatDateRange,
  momentFromCommonDateProto
} from "Common/utils/DateUtilities";
import { MetricDefinition } from "graphql/graphql";
import { formatMetric } from "Common/utils/metrics";
import { pluralize } from "Common/utils/strings";
import { getCurrencySymbol } from "Common/utils/googleAds";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { useSession } from "ExtensionV2/queries/useSession";
import { headerDropdown } from "ExtensionV2/styles/zIndexes";
import { AccountBudgetTarget } from "Common/proto/entity/site_pb";
import { getNameFilterLabel } from "ExtensionV2/components/ObjectFilterButton";
import { CampaignPlatform } from "Common/proto/common/campaignPlatform_pb";
import { useUpdateSiteAccountBudgetTarget } from "ExtensionV2/queries/useUpdateSiteAccountBudgetTarget";
import { extractErrorMessage } from "Common/errors/error";
import { useSessionUser } from "ExtensionV2/queries/useSessionUser";
import { CampaignStatusEnum } from "Common/google/ads/googleads/v18/enums/campaign_status_pb";
import { semanticFocusBorder } from "ExtensionV2/styles/colors";
import BudgetPlanningDateRangePicker from "ExtensionV2/components/BudgetPlanningDateRangePicker";

export const ControlLabel = styled.p`
  display: block;
  margin: 0 0 0.3em 0.5em;
  font-weight: bold;
  font-size: small;
`;

export const TargetDropdown = styled(Form.Dropdown)`
  z-index: ${headerDropdown};
  width: 12em;
  & .ui.dropdown {
    .menu {
      left: 0;
      right: auto;
      border-top: 1px solid ${semanticFocusBorder} !important;
      border-radius: 0.28571429rem 0 0.28571429rem 0.28571429rem;
      width: max-content !important;
      min-width: 200px;
      max-height: 20rem;
      .text {
        display: inline-block;
        line-height: normal;
      }
      .description {
        font-size: smaller;
        word-break: break-all;
        margin-bottom: 3px;
        line-height: normal;
      }
    }
    .menu > .item > .text,
    .menu > .item > .description {
      max-width: 21rem;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const BudgetPlanningChart: React.FC<{
  campaignPlatform: CampaignPlatform.Option;
  retailer: Retailer.Option;
  campaignNameFilter: string;
  setCampaignNameFilter: (filter: string) => void;
  filteredCampaignIds: Array<string>;
  campaignConfigurationsByCampaignId: CampaignConfigurationsByCampaignIdResult;
}> = ({
  campaignPlatform,
  retailer,
  campaignNameFilter,
  setCampaignNameFilter,
  filteredCampaignIds,
  campaignConfigurationsByCampaignId
}) => {
  const { invalidateSessionQuery } = useSession();

  const {
    siteAlias,
    siteCurrencyCode: currencyCode,
    accountBudgetTargets
  } = useSessionSite();
  const { isCurrentSiteAdmin } = useSessionUser();

  const {
    isLoading: updateSiteAccountBudgetTargetLoading,
    error: updateSiteAccountBudgetTargetError,
    mutateAsync: updateSiteAccountBudgetTarget
  } = useUpdateSiteAccountBudgetTarget();

  const [startDate, setStartDate] = useState(
    moment()
      .startOf("month")
      .format(DATE_STRING_FORMAT)
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf("month")
      .format(DATE_STRING_FORMAT)
  );

  const relevantBudgetTargets = useMemo(
    () =>
      accountBudgetTargets.filter(
        target =>
          target.campaignPlatform === campaignPlatform &&
          target.retailer === retailer
      ),
    [accountBudgetTargets, campaignPlatform, retailer]
  );

  const [targetKey, setTargetKey] = useState("");
  const [editBudgetValue, setEditBudgetValue] = useState(0.0);

  const targetDropdownOptions = useMemo(() => {
    const currencyMetricDef = getCurrencyMetricDef(currencyCode, true);
    const options: Array<DropdownItemProps> = [
      {
        key: "__EDIT__",
        value: "\t",
        text: editBudgetValue
          ? `${formatMetric(currencyMetricDef, editBudgetValue)}`
          : "",
        description: ""
      }
    ];

    relevantBudgetTargets.forEach(target => {
      const value = getTargetKey(
        target.campaignNameFilter,
        target.startDate,
        target.endDate
      );
      const text = `${formatMetric(
        currencyMetricDef,
        convertMicrosToCurrencyUnit(target.budgetAmountMicros)
      )}`;
      options.push({
        key: value,
        value: value,
        text: text,
        description: getTargetDescription(
          target.campaignNameFilter,
          target.startDate,
          target.endDate
        )
      });
    });

    return _.sortBy(options, "value");
  }, [currencyCode, relevantBudgetTargets, editBudgetValue]);

  const dateStrings = useMemo(
    () => getDateStringsForRange(startDate, endDate),
    [startDate, endDate]
  );

  const [
    ampdCampaignIds,
    startDateByCampaignId,
    currentStatusByCampaignId,
    currentBudgetByCampaignId
  ] = useMemo(() => {
    const campaignIds = [];
    const startByCampaignId = new Map();
    const statusByCampaignId = new Map();
    const budgetByCampaignId = new Map();

    if (campaignConfigurationsByCampaignId != null) {
      for (const [
        campaignId,
        campaignConfiguration
      ] of campaignConfigurationsByCampaignId) {
        campaignIds.push(campaignId);

        if (
          campaignConfiguration?.ampdResourceConfiguration?.googleAds
            ?.campaignConfiguration?.status ==
          GoogleAdsResourceStatus.Option.ENABLED
        ) {
          statusByCampaignId.set(
            campaignId,
            CampaignStatusEnum.CampaignStatus.ENABLED
          );
        } else {
          statusByCampaignId.set(
            campaignId,
            CampaignStatusEnum.CampaignStatus.PAUSED
          );
        }

        budgetByCampaignId.set(
          campaignId,
          convertMicrosToCurrencyUnit(
            campaignConfiguration?.ampdResourceConfiguration?.googleAds
              ?.budgetConfiguration?.budgetAmountMicros || 0
          )
        );

        startByCampaignId.set(
          campaignId,
          campaignConfiguration?.ampdResourceConfiguration?.googleAds
            ?.campaignConfiguration?.startDate || ""
        );
      }
    }

    return [
      campaignIds.sort(),
      startByCampaignId,
      statusByCampaignId,
      budgetByCampaignId
    ];
  }, [campaignConfigurationsByCampaignId]);

  const {
    data: dashboardTableMetricsByCampaignIdByObjectType,
    isLoading: dashboardTableMetricsLoading
  } = useDashboardTableMetrics({
    objectTypes: [DashboardTableObjectType.CAMPAIGN],
    siteAlias: siteAlias,
    retailer: Retailer.Option.UNKNOWN,
    campaignIds: ampdCampaignIds,
    dateRangeStartDate: startDate,
    dateRangeEndDate: endDate,
    queryByDay: true
  });

  const budgetHistoryByDayByCampaignId = useBudgetHistoryDayByDay(
    siteAlias,
    ampdCampaignIds,
    startDate,
    endDate,
    startDateByCampaignId,
    currentStatusByCampaignId,
    currentBudgetByCampaignId
  );

  const pacingChartConfig = useMemo(() => {
    if (dashboardTableMetricsLoading) {
      return makeChartConfig({
        dateRangeStartDate: startDate,
        seriesInfo: [],
        currencyCode,
        noDataMessage: "Loading..."
      });
    }

    const pacingChartConfig = makePacingChartConfig({
      budgetValue: editBudgetValue,
      filteredCampaignIds,
      startDate,
      dateStrings,
      currencyCode,
      campaignNameFilter,
      budgetHistoryByDayByCampaignId,
      dashboardTableMetricsByCampaignIdByObjectType
    });

    return pacingChartConfig;
  }, [
    startDate,
    dateStrings,
    campaignNameFilter,
    filteredCampaignIds,
    currencyCode,
    editBudgetValue,
    budgetHistoryByDayByCampaignId,
    dashboardTableMetricsByCampaignIdByObjectType,
    dashboardTableMetricsLoading
  ]);

  const setDatesAndSelectTarget = (startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);

    const targetKey = getTargetKey(campaignNameFilter, startDate, endDate);
    const target = getTargetFromKey(targetKey, relevantBudgetTargets);
    if (target) {
      const num = convertMicrosToCurrencyUnit(target.budgetAmountMicros);
      setEditBudgetValue(num);
      setTargetKey(targetKey);
    } else {
      setEditBudgetValue(0);
      setTargetKey("");
    }
  };

  useEffect(() => {
    setDatesAndSelectTarget(startDate, endDate);
    // This should only be called on mount and when the list of targets changes
    // (to select any existing target for the current month), so we don't want
    // a complete dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountBudgetTargets]);

  const handleTargetChanged = (
    _e: SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    const budgetTarget = getTargetFromKey(
      String(data.value),
      relevantBudgetTargets
    );

    if (budgetTarget) {
      setStartDate(budgetTarget.startDate);
      setEndDate(budgetTarget.endDate);
      const num = convertMicrosToCurrencyUnit(budgetTarget.budgetAmountMicros);
      setEditBudgetValue(num);
      setTargetKey(String(data.value));
      if (budgetTarget.campaignNameFilter !== campaignNameFilter) {
        setCampaignNameFilter(budgetTarget.campaignNameFilter);
      }
    } else {
      const num =
        Number(
          String(data.value)
            .replace(getCurrencySymbol(currencyCode), "")
            .replace(",", "")
        ) || 0;
      setEditBudgetValue(num);
      setTargetKey("");
    }
  };

  const handleSave = (budgetAmount: number) => async () => {
    if (budgetAmount === 0) {
      setEditBudgetValue(0);
    }

    await updateSiteAccountBudgetTarget({
      siteAlias,
      budgetAmount,
      currencyCode,
      campaignPlatform,
      retailer,
      campaignNameFilter,
      startDate,
      endDate
    });

    invalidateSessionQuery();
  };

  return (
    <>
      <Flex
        style={{ gap: "1em" }}
        alignItems="center"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <BudgetPlanningDateRangePicker
          startDate={startDate}
          endDate={endDate}
          setDatesAndSelectTarget={setDatesAndSelectTarget}
        />

        <div>
          <ControlLabel>
            Budget Target ({pluralize(dateStrings.length, "day")})
          </ControlLabel>
          <TargetDropdown
            fluid
            options={targetDropdownOptions}
            onChange={handleTargetChanged}
            value={targetKey || "\t"}
            allowAdditions={true}
            additionLabel={`${getCurrencySymbol(currencyCode)} `}
            selection
            search={true}
            selectOnNavigation={false}
          />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ControlLabel></ControlLabel>
          {isCurrentSiteAdmin && !!targetKey && (
            <Button
              loading={updateSiteAccountBudgetTargetLoading}
              onClick={handleSave(0)}
            >
              Remove
            </Button>
          )}
          {isCurrentSiteAdmin && !targetKey && (
            <Button
              loading={updateSiteAccountBudgetTargetLoading}
              primary={!!editBudgetValue}
              disabled={!editBudgetValue}
              onClick={handleSave(editBudgetValue)}
            >
              Save
            </Button>
          )}
        </div>
      </Flex>
      <div style={{ marginTop: "1em" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={pacingChartConfig}
          immutable={false}
        />
      </div>
      {!!updateSiteAccountBudgetTargetError && (
        <Message negative>
          There was an error saving the budget target:{" "}
          {extractErrorMessage(updateSiteAccountBudgetTargetError)}
        </Message>
      )}
    </>
  );
};

function getDailyMetricsForCampaign(
  dashboardTableMetricsByCampaignIdByObjectType:
    | DashboardTableMetricsByCampaignIdByObjectType
    | undefined,
  campaignId: string,
  dateString: DateString
): DashboardTableMetrics | null {
  if (!dashboardTableMetricsByCampaignIdByObjectType) {
    return null;
  }

  const dashboardTableMetricsByCampaignId =
    dashboardTableMetricsByCampaignIdByObjectType[
      DashboardTableObjectType.CAMPAIGN
    ] || {};

  const metricsList = dashboardTableMetricsByCampaignId[campaignId] || [];
  for (const metrics of metricsList) {
    if (
      moment(dateString).isSame(
        momentFromCommonDateProto(metrics.dateRange?.startDate)
      )
    ) {
      return metrics;
    }
  }

  return null;
}

function makePacingChartConfig({
  budgetValue,
  filteredCampaignIds,
  startDate,
  dateStrings,
  currencyCode,
  campaignNameFilter,
  budgetHistoryByDayByCampaignId,
  dashboardTableMetricsByCampaignIdByObjectType
}: {
  budgetValue: number;
  filteredCampaignIds: Array<string>;
  startDate: string;
  dateStrings: Array<string>;
  currencyCode: string;
  campaignNameFilter: string;
  budgetHistoryByDayByCampaignId: Map<
    string,
    Map<string, [CampaignStatusEnum.CampaignStatus, number]>
  >;
  dashboardTableMetricsByCampaignIdByObjectType:
    | DashboardTableMetricsByCampaignIdByObjectType
    | undefined;
}) {
  const targetBudgetPerDay = budgetValue / dateStrings.length;
  const targetBudgetPoints: Array<[string, number]> = [];
  let targetBudgetAccum = 0;
  const budgetedPointsForFiltered: Array<[string, number]> = [];
  let budgetedAccumForFiltered = 0;
  const spentPointsForFiltered: Array<[string, number]> = [];
  let spentAccumForFiltered = 0;

  dateStrings.forEach(dateString => {
    const dateLabel = moment(dateString).format("MMM D");
    const isFuture = moment(dateString).isAfter(moment());

    targetBudgetAccum += targetBudgetPerDay;
    targetBudgetPoints.push([dateLabel, targetBudgetAccum]);

    filteredCampaignIds.forEach((campaignId: string) => {
      const metrics = getDailyMetricsForCampaign(
        dashboardTableMetricsByCampaignIdByObjectType,
        campaignId,
        dateString
      );
      let spent = 0;
      if (metrics) {
        spent = metrics.cost;
      }

      let budgeted = 0;
      const budgetHistory = budgetHistoryByDayByCampaignId.get(campaignId);
      if (budgetHistory) {
        const [status, budget] = budgetHistory.get(dateString) || [];

        if (status == CampaignStatusEnum.CampaignStatus.ENABLED) {
          budgeted = budget || 0;
        }
      }

      spentAccumForFiltered += spent;
      budgetedAccumForFiltered += budgeted;
    });

    budgetedPointsForFiltered.push([dateLabel, budgetedAccumForFiltered]);
    if (!isFuture) {
      spentPointsForFiltered.push([dateLabel, spentAccumForFiltered]);
    }
  });

  let campaignsCaption = `${pluralize(filteredCampaignIds.length, "campaign")}`;
  if (campaignNameFilter) {
    campaignsCaption += ` (${getNameFilterLabel(campaignNameFilter, "Name ")})`;
  }
  campaignsCaption = `<b>${campaignsCaption}</b> - campaigns are only counted as Budgeted on days they are enabled`;

  const seriesInfo: Array<Highcharts.SeriesLineOptions> = [
    {
      type: "line",
      name: "Target Budget",
      marker: { symbol: "triangle" },
      data: targetBudgetPoints,
      color: "blue",
      visible: true
    },
    {
      type: "line",
      name: `Budgeted`,
      marker: { symbol: "square" },
      data: budgetedPointsForFiltered,
      color: "orange",
      visible: true
    },
    {
      type: "line",
      name: `Spent`,
      marker: { symbol: "circle" },
      data: spentPointsForFiltered,
      color: "green",
      visible: true
    }
  ];

  const pacingChartConfig = makeChartConfig({
    dateRangeStartDate: startDate,
    seriesInfo,
    currencyCode,
    caption: campaignsCaption
  });

  return pacingChartConfig;
}

function makeChartConfig({
  title,
  caption,
  dateRangeStartDate,
  seriesInfo,
  currencyCode,
  noDataMessage
}: {
  title?: string;
  caption?: string;
  dateRangeStartDate: string;
  seriesInfo: Array<Highcharts.SeriesLineOptions>;
  currencyCode: string;
  noDataMessage?: string;
}): Highcharts.Options {
  const currencyMetricDef = getCurrencyMetricDef(currencyCode, false);
  const currencyMetricDefWithCents = getCurrencyMetricDef(currencyCode, true);

  const currencyLabelFormatter = buildCurrencyLabelFormatter(currencyMetricDef);

  const series: Array<Highcharts.SeriesOptionsType> = seriesInfo.map(
    options => {
      const seriesComponent: Highcharts.SeriesLineOptions = {
        ...options,
        clip: true,
        tooltip: {
          pointFormatter: function(this: Highcharts.Point) {
            if (this?.y == null) {
              return "";
            }

            const str = String(
              formatMetric(currencyMetricDefWithCents, this?.y)
            );

            return (
              '<span style="color:' +
              this.series.color +
              '">' +
              "</span>" +
              " " +
              this.series.name +
              ": " +
              str +
              "<br/>"
            );
          }
        },
        enableMouseTracking: true
      };

      return seriesComponent;
    }
  );

  // The main plot area.
  const yAxis: Array<Highcharts.YAxisOptions> = [
    {
      height: "100%",
      gridLineWidth: 1,
      allowDecimals: false,
      labels: {
        formatter: currencyLabelFormatter
      },
      title: {
        text: undefined
      }
    }
  ];

  return {
    chart: {
      type: "line",
      plotBackgroundColor: Colors.lightGrey,
      plotBorderColor: Colors.black,
      animation: false
    },
    tooltip: {
      backgroundColor: "rgba(255,255,255,.9)",
      animation: false,
      hideDelay: 0,
      shared: true,
      outside: true,
      split: false,
      xDateFormat: "%b %e, %Y",
      useHTML: true // prevent blurry tooltips
    },
    credits: {
      enabled: false
    },
    title: {
      text: title
    },
    caption: {
      margin: 0,
      text: caption
    },
    xAxis: {
      type: "datetime",
      labels: {
        overflow: "justify"
      },
      tickPosition: "outside",
      dateTimeLabelFormats: {
        day: "%b %e",
        week: "%b %e",
        hour: "",
        minute: "",
        second: ""
      },
      lineWidth: 0,
      tickLength: 0
    },
    yAxis,
    navigation: {
      menuItemStyle: {
        fontSize: "10px"
      }
    },
    legend: {
      layout: "horizontal" /* "proximate" */,
      align: "right",
      itemDistance: 3
    },
    lang: {
      noData: noDataMessage || "No data available"
    },
    plotOptions: {
      series: {
        animation: false
      },
      line: {
        dataLabels: {
          enabled: true,
          formatter: currencyLabelFormatter
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 3
          }
        },
        marker: {
          enabled: true,
          symbol: "circle"
        },
        pointInterval: 24 * 60 * 60 * 1000, // one day
        pointStart: moment(dateRangeStartDate, DATE_STRING_FORMAT).valueOf()
      }
    },
    series
  };
}

const getTargetKey = (
  filter: string,
  startDate: string,
  endDate: string
): string => {
  return `${filter}\n${endDate}\n${startDate}`;
};

const getTargetFromKey = (
  key: string,
  budgetTargets: Array<AccountBudgetTarget.AsObject>
): AccountBudgetTarget.AsObject | null => {
  if (!key.includes("\n")) {
    return null;
  }

  const [filter, endDate, startDate] = String(key).split("\n");

  for (const target of budgetTargets) {
    if (
      target.campaignNameFilter === filter &&
      target.startDate === startDate &&
      target.endDate === endDate
    ) {
      return target;
    }
  }

  return null;
};

const getTargetDescription = (
  filter: string,
  startDate: string,
  endDate: string
): string => {
  let filterDesc = "";
  if (filter) {
    filterDesc = ` (filter: ${getNameFilterLabel(filter, null)})`;
  }

  return `${formatDateRange(startDate, endDate)}${filterDesc}`;
};

const buildCurrencyLabelFormatter = (
  currencyMetricDef: MetricDefinition
): (() => string) => {
  return function() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:next-line
    return this?.point?.y
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:next-line
        String(formatMetric(currencyMetricDef, this?.point.y))
      : "";
  };
};

export default BudgetPlanningChart;
