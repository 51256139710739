import { Set as ImmutableSet } from "immutable";
import React, { useMemo, useState } from "react";
import {
  Message,
  Segment,
  Icon,
  Checkbox,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Table,
  Label,
  Input,
  Button
} from "semantic-ui-react";
import styled from "styled-components";

import { extractErrorMessage } from "Common/errors/error";
import { useFacebookMarketingResources } from "ExtensionV2/queries/useFacebookMarketingResources";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { EnableAttributionConfirmationModalLauncher } from "./EnableAttributionModal";
import {
  adRetailer,
  hasRetailerAds,
  FACEBOOK_AD_FIELDS,
  FACEBOOK_CAMPAIGN_FIELDS,
  extractTargetURLs
} from "Common/utils/facebook";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { Facebook } from "Common/proto/common/facebook_pb";
import { useStoredFacebookCampaignConfigurations } from "ExtensionV2/queries/useFacebookCampaignConfigurations";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import { removeNullAndUndefined } from "Common/utils/tsUtils";
import { Retailer } from "Common/proto/common/retailer_pb";
import { useNavigate } from "react-router-dom";

const StyledCampaignInfoSegment = styled(Segment)`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  min-width: 60em;

  > div:first-child {
    display: grid;
    grid-template-columns: 2.5fr 0.5fr 1fr 1fr;
    gap: 1em;
    align-items: center;

    > h3 {
      margin-bottom: 0.5em;
    }

    div > h5 {
      margin-bottom: 0;
    }

    div > p {
      margin-bottom: 0;
    }
  }
`;

const StyledCampaignAttributionStatusMessage = styled(Message)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: fit-content;
  max-width: 40em;
`;

const StyledAdAttributionStatusTable = styled(Table)`
  overflow-x: auto;
  width: 100%;
  table-layout: fixed;

  td,
  th {
    white-space: nowrap;
  }

  .checkbox {
    width: 7em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ad-name {
    max-width: 20em;
    min-width: 10em;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ad-status {
    max-width: 0;
    min-width: 10em;
    width: 15%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .destination-url {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export enum CampaignTrackingStatus {
  // The campaign has at least 1 ad that is configured for Ampd Attribution
  // and all eligible ads are configured.
  ALL_ADS_TRACKING = "ALL_ADS_TRACKING",
  // Some eligible ads in the campaign are configured and there are
  // remaining ads that are eligible.
  SOME_ADS_TRACKING = "SOME_ADS_TRACKING",
  // No ads in the campaign are configured, but there is at least one eligible ad.
  NO_ADS_TRACKING = "NO_ADS_TRACKING",
  // No ads in the campaign are eligible for configuration.
  NO_ADS_ELIGIBLE = "NO_ADS_ELIGIBLE"
}

export function FacebookCampaignSetupPage(): JSX.Element {
  const { siteAlias } = useSessionSite();

  // Fetch the list of campaign data from Facebook.
  const {
    data: fbCampaigns,
    isLoading: facebookCampaignsLoading,
    isFetching: facebookCampaignsFetching,
    error: facebookCampaignsError
  } = useFacebookMarketingResources({
    enabled: true,
    fields: FACEBOOK_CAMPAIGN_FIELDS
  });

  // Fetch the list of stored Facebook campaign configurations so we can show the user
  // which campaigns have Ampd Attribution enabled.
  const {
    data: campaignConfigurations,
    isLoading: configurationsLoading,
    error: configurationsError
  } = useStoredFacebookCampaignConfigurations({
    siteAlias
  });

  const adsWithConfigurations = useMemo(() => {
    return new Set(
      campaignConfigurations
        ?.flatMap(c =>
          c.ampdResourceConfiguration?.facebook?.campaignDetails?.adSetDetailsList.flatMap(
            adSet => adSet.adDetailsList.flatMap(ad => ad.adId)
          )
        )
        .filter(removeNullAndUndefined) || []
    );
  }, [campaignConfigurations]);

  let tabContent = <></>;
  if (facebookCampaignsLoading || configurationsLoading) {
    tabContent = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          height: "100%"
        }}
      >
        <LoadingSpinner>Loading your Meta Campaigns</LoadingSpinner>
      </div>
    );
  } else if (facebookCampaignsError || configurationsError) {
    const errorMessage = extractErrorMessage(
      facebookCampaignsError || configurationsError
    );
    tabContent = <Message error>{errorMessage}</Message>;
  } else if (fbCampaigns) {
    tabContent = (
      <ManageCampaigns
        fbCampaigns={fbCampaigns.campaignsList}
        resourcesFetching={facebookCampaignsFetching}
        adsWithConfigurations={adsWithConfigurations}
      />
    );
  }

  return (
    <Segment
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column"
      }}
    >
      {tabContent}
    </Segment>
  );
}

function ManageCampaigns({
  adsWithConfigurations,
  fbCampaigns,
  resourcesFetching
}: {
  adsWithConfigurations: Set<string>;
  fbCampaigns: Array<Facebook.API.Campaign.AsObject>;
  resourcesFetching: boolean;
}) {
  const navigate = useNavigate();

  const [campaignFilterValue, setCampaignFilterValue] = useState("");

  const filteredFbCampaigns = useMemo(() => {
    return fbCampaigns.filter(
      campaign =>
        campaign.name
          .toLowerCase()
          .includes(campaignFilterValue.toLowerCase()) ||
        campaign.id.toLowerCase() === campaignFilterValue.toLowerCase()
    );
  }, [fbCampaigns, campaignFilterValue]);

  const [selectedCampaigns, setSelectedCampaigns] = useState<
    ImmutableSet<Facebook.API.Campaign.AsObject>
  >(ImmutableSet());

  const [expandedCampaigns, setExpandedCampaigns] = useState<
    ImmutableSet<Facebook.API.Campaign.AsObject>
  >(ImmutableSet());

  const [selectedAds, setSelectedAds] = useState<
    ImmutableSet<Facebook.API.Ad.AsObject>
  >(ImmutableSet());

  const toggleAd = (
    ad: Facebook.API.Ad.AsObject,
    campaign: Facebook.API.Campaign.AsObject,
    campaignAds: Array<Facebook.API.Ad.AsObject>
  ) => {
    if (selectedAds.has(ad)) {
      // Toggle the ad off and the campaign off too.
      setSelectedAds(selectedAds.delete(ad));
      setSelectedCampaigns(selectedCampaigns.delete(campaign));
    } else {
      // Toggle the ad on, and if all the sibling ads are on, toggle the campaign on too.
      const ads = selectedAds.add(ad);
      setSelectedAds(ads);
      if (
        campaignAds
          .filter(ad =>
            [Retailer.Option.AMAZON, Retailer.Option.WALMART].includes(
              adRetailer(ad)
            )
          )
          .every(ad => ads.has(ad))
      ) {
        setSelectedCampaigns(selectedCampaigns.add(campaign));
      }
    }
  };

  const toggleCampaign = (
    campaign: Facebook.API.Campaign.AsObject,
    campaignAds: Array<Facebook.API.Ad.AsObject>
  ) => {
    if (selectedCampaigns.has(campaign)) {
      // Toggle the campaign and all ads off.
      setSelectedCampaigns(selectedCampaigns.delete(campaign));
      const ads = selectedAds.filter(ad => ad.campaignId !== campaign.id);
      setSelectedAds(ads);
    } else {
      // Toggle the campaign and all eligible ads on.
      setSelectedCampaigns(selectedCampaigns.add(campaign));

      let nextSelectedAds = selectedAds;
      for (const ad of campaignAds) {
        const retailer = adRetailer(ad);
        if (
          [Retailer.Option.AMAZON, Retailer.Option.WALMART].includes(retailer)
        ) {
          nextSelectedAds = nextSelectedAds.add(ad);
        }
      }

      setSelectedAds(nextSelectedAds);
    }
  };

  const eligibleSelectedAds = useMemo(
    () => selectedAds.filter(a => !adsWithConfigurations.has(a.id)).toArray(),
    [adsWithConfigurations, selectedAds]
  );

  return (
    <>
      <div
        style={{
          flexShrink: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          alignContent: "center",
          marginBottom: "1em"
        }}
      >
        <div>
          <EnableAttributionConfirmationModalLauncher
            selectedAds={eligibleSelectedAds}
            disabled={resourcesFetching}
          />
          {/* Campaign Filter */}
          <Input
            style={{ width: "20em" }}
            placeholder="Filter by campaign name or ID"
            icon="filter"
            value={campaignFilterValue}
            onChange={e => setCampaignFilterValue(e.target.value)}
          />
          {resourcesFetching && (
            <Label>
              <Icon loading name="spinner" size="large" />
              Refreshing
            </Label>
          )}
        </div>
        <div>
          <Button
            primary
            onClick={() => navigate("../dashboard/meta/campaigns")}
          >
            View Performance
          </Button>
        </div>
      </div>
      <div
        style={{
          overflow: "auto",
          boxShadow: "0px 0px 1px 0px gray",
          borderRadius: "5px"
        }}
      >
        {filteredFbCampaigns.map(campaign => {
          return (
            <StyledCampaignInfoSegment key={campaign.id}>
              <CampaignInfo key={campaign.id} campaign={campaign} />

              {/* More Info Expanding Section */}
              <Accordion>
                <AccordionTitle
                  active={expandedCampaigns.has(campaign)}
                  index={campaign.id}
                  onClick={() => {
                    setExpandedCampaigns(() => {
                      if (expandedCampaigns.has(campaign)) {
                        return expandedCampaigns.delete(campaign);
                      } else {
                        return expandedCampaigns.add(campaign);
                      }
                    });
                  }}
                >
                  <Icon name="dropdown" />
                  See Ads
                </AccordionTitle>
                {expandedCampaigns.has(campaign) && (
                  <AccordionContent
                    active={expandedCampaigns.has(campaign)}
                    style={{ overflow: "auto" }}
                  >
                    <AdAttributionStatusTable
                      adsWithConfigurations={adsWithConfigurations}
                      campaign={campaign}
                      campaignSelected={selectedCampaigns.has(campaign)}
                      onToggleSelectAd={toggleAd}
                      onToggleSelectCampaign={toggleCampaign}
                      resourcesFetching={resourcesFetching}
                      selectedAds={selectedAds}
                    />
                  </AccordionContent>
                )}
              </Accordion>
            </StyledCampaignInfoSegment>
          );
        })}
      </div>
    </>
  );
}

function CampaignInfo({
  campaign
}: {
  campaign: Facebook.API.Campaign.AsObject;
}) {
  return (
    <div>
      <div>
        <h3>{campaign.name}</h3>
      </div>

      <div>
        <h5>Status</h5>
        <p>{campaign.effectiveStatus}</p>
      </div>

      <div>
        <h5>Objective</h5>
        <p>{campaign.objective}</p>
      </div>

      <div>
        <h5>ID</h5>
        <p>{campaign.id}</p>
      </div>
    </div>
  );
}

function CampaignAttributionStatusMessage({
  adsWithConfigurations,
  campaign,
  campaignAds,
  isSelected,
  onToggleSelectCampaign
}: {
  adsWithConfigurations: Set<string>;
  campaign: Facebook.API.Campaign.AsObject;
  campaignAds: Array<Facebook.API.Ad.AsObject>;
  isSelected: boolean;
  onToggleSelectCampaign: (campaignId: Facebook.API.Campaign.AsObject) => void;
}) {
  const status = campaignAdStatus(campaignAds, adsWithConfigurations);

  let attributionContent = <></>;
  let messageType: "info" | "positive" | "warning" = "info";
  if (status === CampaignTrackingStatus.ALL_ADS_TRACKING) {
    messageType = "positive";
    attributionContent = (
      <>
        <Icon name="check circle" color="green" />
        <div style={{ width: "fit-content" }}>
          <p>All eligible ads are collecting Ampd Attribution</p>
        </div>
      </>
    );
  } else if (status === CampaignTrackingStatus.SOME_ADS_TRACKING) {
    messageType = "positive";
    attributionContent = (
      <Checkbox
        checked={isSelected}
        onChange={() => {
          onToggleSelectCampaign(campaign);
        }}
        label="Some ads are collecting attribution. Turn on Ampd Attribution for the remaining eligible ads."
      />
    );
  } else if (status === CampaignTrackingStatus.NO_ADS_TRACKING) {
    messageType = "info";
    attributionContent = (
      <Checkbox
        checked={isSelected}
        onChange={() => {
          onToggleSelectCampaign(campaign);
        }}
        label="Turn on Ampd Attribution for this campaign"
      />
    );
  } else {
    messageType = "warning";
    attributionContent = (
      <>
        <Icon name="exclamation triangle" color="yellow" />
        <div style={{ width: "fit-content", maxWidth: "30em" }}>
          <p>This campaign is not eligible for Ampd Attribution.</p>
          <p>
            At least one Ad Creative must have a destination URL targeting an
            amazon page.
          </p>
        </div>
      </>
    );
  }

  return (
    <StyledCampaignAttributionStatusMessage
      warning={messageType === "warning"}
      positive={messageType === "positive"}
      info={messageType === "info"}
    >
      {attributionContent}
    </StyledCampaignAttributionStatusMessage>
  );
}

function AdAttributionStatusTable({
  adsWithConfigurations,
  campaign,
  selectedAds,
  campaignSelected,
  onToggleSelectAd,
  onToggleSelectCampaign,
  resourcesFetching
}: {
  adsWithConfigurations: Set<string>;
  campaign: Facebook.API.Campaign.AsObject;
  selectedAds: ImmutableSet<Facebook.API.Ad.AsObject>;
  campaignSelected: boolean;
  onToggleSelectAd: (
    ad: Facebook.API.Ad.AsObject,
    campaign: Facebook.API.Campaign.AsObject,
    campaignAds: Array<Facebook.API.Ad.AsObject>
  ) => void;
  onToggleSelectCampaign: (
    campaign: Facebook.API.Campaign.AsObject,
    campaignAds: Array<Facebook.API.Ad.AsObject>
  ) => void;
  resourcesFetching: boolean;
}) {
  const {
    data: ads,
    isLoading: adsLoading,
    error: adsError
  } = useFacebookMarketingResources({
    enabled: true,
    fields: FACEBOOK_AD_FIELDS,
    campaignIds: [campaign.id]
  });

  const handleToggleSelectCampaign = () => {
    onToggleSelectCampaign(campaign, ads?.adsList ?? []);
  };

  const handleToggleSelectAd = (ad: Facebook.API.Ad.AsObject) => {
    onToggleSelectAd(ad, campaign, ads?.adsList ?? []);
  };

  let content = <></>;
  if (adsLoading) {
    content = <LoadingSpinner>Loading Ads...</LoadingSpinner>;
  } else if (adsError) {
    content = <Message error>{extractErrorMessage(adsError)}</Message>;
  } else if (!ads) {
    content = <Message>No Ads found</Message>;
  } else {
    content = (
      <>
        <CampaignAttributionStatusMessage
          adsWithConfigurations={adsWithConfigurations}
          campaign={campaign}
          campaignAds={ads.adsList}
          isSelected={campaignSelected}
          onToggleSelectCampaign={handleToggleSelectCampaign}
        />
        <StyledAdAttributionStatusTable size="small" compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="checkbox">
                <p>
                  Attribution
                  <br />
                  Enabled
                </p>
              </Table.HeaderCell>
              <Table.HeaderCell className="ad-name">Ad Name</Table.HeaderCell>
              <Table.HeaderCell className="ad-status">
                Ad Status
              </Table.HeaderCell>
              <Table.HeaderCell className="destination-url">
                Destination URL
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {ads?.adsList.map(ad => {
              return (
                <AdAttributionStatusRow
                  key={ad.id}
                  ad={ad}
                  hasConfiguration={adsWithConfigurations.has(ad.id)}
                  selected={selectedAds.has(ad)}
                  onToggleSelectAd={handleToggleSelectAd}
                  resourcesFetching={resourcesFetching}
                />
              );
            })}
          </Table.Body>
        </StyledAdAttributionStatusTable>
      </>
    );
  }

  return content;
}

function AdAttributionStatusRow({
  ad,
  hasConfiguration,
  selected,
  onToggleSelectAd,
  resourcesFetching
}: {
  ad: Facebook.API.Ad.AsObject;
  hasConfiguration: boolean;
  selected: boolean;
  onToggleSelectAd: (ad: Facebook.API.Ad.AsObject) => void;
  resourcesFetching: boolean;
}): JSX.Element {
  const retailer = adRetailer(ad);

  let attributionCellContent = <></>;

  if (selected && resourcesFetching) {
    attributionCellContent = <Icon loading name="spinner" />;
  } else if (hasConfiguration) {
    // The ad has been configured, no actions for the user to take.
    attributionCellContent = <Icon name="check circle" color="green" />;
  } else if (retailer === Retailer.Option.AMAZON) {
    // The ad is eligible for tracking, make it selectable.
    attributionCellContent = (
      <Checkbox checked={selected} onChange={() => onToggleSelectAd(ad)} />
    );
  } else {
    // The ad is not eligible for tracking.
    attributionCellContent = <>N/A</>;
  }

  const targetURLs = extractTargetURLs(ad);
  const destinationUrl =
    targetURLs.size > 0 ? targetURLs.values().next().value : "";

  return (
    <Table.Row key={ad.id}>
      <Table.Cell className="checkbox">{attributionCellContent}</Table.Cell>
      <SimpleTooltip tooltip={ad.name}>
        <Table.Cell className="ad-name">{ad.name}</Table.Cell>
      </SimpleTooltip>
      <Table.Cell className="ad-status">{ad.effectiveStatus}</Table.Cell>
      <SimpleTooltip tooltip={destinationUrl}>
        <Table.Cell className="destination-url">{destinationUrl}</Table.Cell>
      </SimpleTooltip>
    </Table.Row>
  );
}

// Based on the tracking status of the ads in a given campaign,
// determine which status message to display to the user.
export function campaignAdStatus(
  campaignAds: Array<Facebook.API.Ad.AsObject>,
  adsWithConfigurations: Set<string>
): CampaignTrackingStatus {
  let configuredAdsCount = 0;
  const unconfiguredAds = [];
  for (const ad of campaignAds) {
    if (adsWithConfigurations.has(ad.id)) {
      configuredAdsCount += 1;
    } else {
      unconfiguredAds.push(ad);
    }
  }

  const { hasAtLeastOneAmazonDotComAd } = hasRetailerAds(unconfiguredAds);

  if (configuredAdsCount === 0) {
    return hasAtLeastOneAmazonDotComAd
      ? CampaignTrackingStatus.NO_ADS_TRACKING
      : CampaignTrackingStatus.NO_ADS_ELIGIBLE;
  }

  return hasAtLeastOneAmazonDotComAd
    ? CampaignTrackingStatus.SOME_ADS_TRACKING
    : CampaignTrackingStatus.ALL_ADS_TRACKING;
}
