import React, { SyntheticEvent } from "react";
import {
  Dimmer,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Loader,
  Message
} from "semantic-ui-react";

import { formatCustomerId } from "Common/utils/googleAds";
import { None } from "Common/utils/tsUtils";
import { GoogleAdsCustomerAccount } from "Common/proto/edge/grpcwebPb/grpcweb_GoogleAds_pb";
import { sendGAEvent } from "./GA";

import { AUDIT_PAGE_GA_CATEGORY } from "ExtensionV2/ExtensionV2";

export type GoogleAdsCustomerClientAccount = GoogleAdsCustomerAccount.AsObject;

const GoogleAdsConnectAccountContent: React.FC<{
  siteAlias: string;
  email: string;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  accounts: Array<GoogleAdsCustomerClientAccount> | None;
  linkingAccount: boolean;
  accountsLoading: boolean;
  selectedAccount: GoogleAdsCustomerClientAccount | None;
  setSelectedAccount: React.Dispatch<
    React.SetStateAction<GoogleAdsCustomerClientAccount | None>
  >;
}> = ({
  siteAlias,
  email,
  searchString,
  setSearchString,
  accounts,
  linkingAccount,
  accountsLoading,
  selectedAccount,
  setSelectedAccount
}) => {
  // Handles the selection of a new account from the dropdown.
  const handleAccountChange = (customerId: string | None) => {
    if (!customerId || !accounts) {
      setSelectedAccount(null);
      return;
    }

    sendGAEvent(AUDIT_PAGE_GA_CATEGORY, "Select Account", siteAlias);

    const account = accounts.find(account => account.customerId === customerId);

    setSelectedAccount(account);
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <GoogleAdsAccountSelector
        loading={accountsLoading}
        email={email}
        searchString={searchString}
        setSearchString={setSearchString}
        accounts={accounts}
        disabled={linkingAccount}
        selectedAccount={selectedAccount}
        onAccountChange={handleAccountChange}
      />
    </div>
  );
};

// Displays a selector for the Google Ads account.
const GoogleAdsAccountSelector: React.FC<{
  accounts: Array<GoogleAdsCustomerClientAccount> | None;
  selectedAccount: GoogleAdsCustomerClientAccount | None;
  onAccountChange: (customerId: string | None) => void;
  email: string;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  disabled: boolean;
}> = ({
  accounts,
  selectedAccount,
  onAccountChange,
  email,
  searchString,
  setSearchString,
  loading,
  disabled
}) => {
  const options = (accounts || []).map(account => {
    let text = formatCustomerId(account.customerId);
    if (account.name) {
      text += ` - ${account.name}`;
    }

    text += !account.currencyCode
      ? " (no currency)"
      : ` (currency: ${account.currencyCode})`;

    return {
      key: account.customerId,
      text: text,
      value: account.customerId
    };
  });

  const doDynamicSearch = !!setSearchString;

  if (!doDynamicSearch) {
    options.unshift({
      key: "NO_ACCOUNT",
      text: "(No account)",
      value: ""
    });
  }

  const handleSelectChange = (_e: SyntheticEvent, { value }: DropdownProps) => {
    onAccountChange(String(value));
  };

  const handleSearchChange = (
    _e: SyntheticEvent,
    { searchQuery }: DropdownProps
  ) => {
    if (setSearchString) {
      setSearchString(String(searchQuery));
    }
  };

  return (
    <>
      <Dimmer.Dimmable>
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>

        {((loading || accounts?.length || doDynamicSearch) && (
          <Dropdown
            options={options}
            disabled={disabled}
            onChange={handleSelectChange}
            value={selectedAccount?.customerId || ""}
            placeholder={
              doDynamicSearch
                ? "Enter at least first 3 digits of ID, or at least 3 chars in account name"
                : "Select an account"
            }
            search={doDynamicSearch ? customSearch : true}
            searchQuery={
              doDynamicSearch && !selectedAccount ? searchString : undefined
            }
            onSearchChange={doDynamicSearch ? handleSearchChange : undefined}
            selectOnBlur={true}
            selectOnNavigation={true}
            fluid
            selection
          />
        )) || (
          <Message info>
            <p>
              Your account, <b>{email}</b>, doesn't have access to any Google
              Ads accounts.
            </p>
            <p>
              Create a new Google Ads account below or sign in with a different
              Google account above.
            </p>
          </Message>
        )}
      </Dimmer.Dimmable>
    </>
  );
};

function customSearch(options: Array<DropdownItemProps>, query: string) {
  const queryScrubbed = query.replaceAll("-", "");

  return options.filter(
    opt =>
      String(opt.text)
        .toLowerCase()
        .includes(query.toLowerCase()) ||
      String(opt.value).startsWith(queryScrubbed)
  );
}

export default GoogleAdsConnectAccountContent;
