import { Table } from "semantic-ui-react";
import styled from "styled-components";

export const DepthAwareTableCell = styled(Table.Cell)<{
  depth?: number;
}>`
  p {
    font-size: ${({ depth }) => (depth ?? 0 > 0 ? 0.8 : 1)}rem;
  }

  div[role="listbox"] {
    font-size: ${({ depth }) => (depth ?? 0 > 0 ? 0.8 : 1)}rem;
  }
`;
