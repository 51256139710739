export const CSV_FLOAT_PRECISION = 5;
/**
 * Convert a 2D array into a CSV string
 */
export function arrayToCSV(data: Array<Array<string>>): string {
  return data
    .map(
      row =>
        row
          .map(String) // convert every value to String
          .map(v => v.replace(/"/g, '""')) // escape double quotes
          .map(v => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}
