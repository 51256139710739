import React, { useCallback, useMemo, useRef, useState } from "react";
import { Accordion, Button, Icon, Popup, Table } from "semantic-ui-react";

import styled from "styled-components";

import { FilterArguments } from "./filters";
import { SortOption } from "./sorting";
import { None } from "Common/utils/tsUtils";
import { ampdRed, backgroundDark } from "ExtensionV2/styles/colors";
import {
  FilterContainer,
  FilterManager,
  StyledFilterManager
} from "./FilterManager";
import { SEMANTIC_DARK_ACCENT } from "ExtensionV2/components/AmpdDataTable";
import { toast } from "react-toastify";
import SimpleTooltip from "../SimpleTooltip";
import { CompareStyles } from "./cells/MetricCell";
import { ColumnsManagerModal } from "./ColumnsManager";
import { Column } from "./column";
import { ShowFractionsContext } from "./contexts";
import { OptionsManagerModal } from "./TableOptionsManager";
import { CSVExportManager, CSVExportOptions } from "./CSVExportManager";

const selectBoxWidth = "3rem";
const minCellWidthPixels = 85;

const insetTop = `inset 0px 1px 0px 0px ${ampdRed}`;
const insetRight = `inset -1px 0px 0px 0px ${ampdRed}`;
const insetLeft = `inset 1px 0px 0px 0px ${ampdRed}`;
const insetBottom = `inset 0px -1px 0px 0px ${ampdRed}`;

const insetTopLeft = `inset 1px 1px 0px 0px ${ampdRed}`;
const insetTopRight = `inset -1px 1px 0px 0px ${ampdRed}`;
const insetBottomRight = `inset -1px -1px 0px 0px ${ampdRed}`;
const insetBottomLeft = `inset 1px -1px 0px 0px ${ampdRed}`;

const outsideTop = "0px -1px 0px 0px red";

// simulated left/right border for the first column that holds its position when horizontal
// scrolling
const simulatedSideBorders = `inset 1px 0px 0px 0px ${backgroundDark}, inset -1px 0px 0px 0px ${backgroundDark}`;

const StyledTable = styled(Table)`
  border: none !important;
  table-layout: fixed;

  tbody {
    :hover {
      // show the left border of cell in the first column
      td:first-child {
        box-shadow: ${insetLeft};
      }

      // show the right border of cell in the last column
      td:last-child {
        box-shadow: ${insetRight};
      }

      td:nth-child(2) {
        box-shadow: ${simulatedSideBorders};
      }

      // for the first row of every grouping show the top border of every cell
      // and the top/left border for the first column and the top/right border
      // for the last column
      > tr:first-child {
        td {
          box-shadow: ${insetTop};
        }
        td:first-child {
          box-shadow: ${insetTopLeft};
        }
        td:nth-child(2) {
          box-shadow: ${insetTop}, ${simulatedSideBorders};
        }
        td:last-child {
          box-shadow: ${insetTopRight};
        }
      }

      // for the last row of every grouping show the bottom border of every cell
      // and the bottom/left border for the first column and the bottom/right border
      // for the last column
      > tr:last-child {
        td {
          box-shadow: ${insetBottom};
        }
        td:first-child {
          box-shadow: ${insetBottomLeft};
        }
        td:nth-child(2) {
          box-shadow: ${insetBottom}, ${simulatedSideBorders};
        }
        td:last-child {
          box-shadow: ${insetBottomRight};
        }
      }

      tr:only-child {
        td {
          box-shadow: ${insetTop}, ${insetBottom};
        }
        td:first-child {
          box-shadow: ${insetTopLeft}, ${insetBottom};
        }
        td:nth-child(2) {
          box-shadow: ${insetTop}, ${insetBottom}, ${simulatedSideBorders};
        }
        td:last-child {
          box-shadow: ${insetTopRight}, ${insetBottom};
        }
      }
    }

    // use outline shadow for the top border on every row except the first row
    :not(:first-of-type) {
      :hover {
        tr:first-child {
          td {
            box-shadow: ${outsideTop};
          }

          td:nth-child(2) {
            box-shadow: ${outsideTop}, ${simulatedSideBorders};
          }

          td:last-child {
            box-shadow: ${outsideTop}, ${insetRight};
          }
          td:first-child {
            box-shadow: ${outsideTop}, ${insetLeft};
          }
        }

        tr:only-child {
          td {
            box-shadow: ${outsideTop}, ${insetBottom};
          }
          td:first-child {
            box-shadow: ${outsideTop}, ${insetLeft}, ${insetBottom};
          }
          td:nth-child(2) {
            box-shadow: ${outsideTop}, ${insetBottom}, ${simulatedSideBorders};
          }
          td:last-child {
            box-shadow: ${outsideTop}, ${insetBottom}, ${insetRight};
          }
        }
      }
    }
  }

  tbody {
    :nth-child(even) {
      tr:nth-child(odd) {
        td {
          background-color: white;
        }
      }
      tr:nth-child(even) {
        td {
          background-color: ${SEMANTIC_DARK_ACCENT};
        }
      }
    }

    :nth-child(odd) {
      tr:nth-child(even) {
        td {
          background-color: white;
        }
      }
      tr:nth-child(odd) {
        td {
          background-color: ${SEMANTIC_DARK_ACCENT};
        }
      }
    }
  }

  tr {
    :hover {
      td {
        background-color: ${backgroundDark} !important;
      }
    }
  }

  thead {
    tr {
      th {
        width: 8rem;
        border: 1px solid ${backgroundDark};
        position: sticky;
        top: 0;
        border-bottom: 1px solid ${backgroundDark} !important;
        padding: 0.5rem 0 0.5rem 0.5rem !important;
        z-index: 3;

        .resizing {
          -webkit-user-select: none; /* Safari */
          user-select: none; /* Standard syntax */
          background-color: ${backgroundDark};
          border: 1px solid red !important;
        }

        > div:first-child {
          display: flex;
          justify-content: space-between;

          > div {
            display: flex;
            align-items: center;

            h3 {
              display: inline;
              grid-area: name;
              margin-bottom: 0;
              font-size: 0.9rem;
              overflow: hidden;
              white-space: normal;
              text-overflow: ellipsis;
              cursor: pointer;
            }

            .sort-icon {
              margin-left: 0.5rem;
            }
          }   

          .resize-handle {
            grid-area: resize-handle;
            cursor: col-resize;
            background-color: transparent;
            width: 10px;
            justify-self: end;
          }

          
        }

        // Select Box Column Header
        :nth-child(1) {
          z-index: 4
          width: ${selectBoxWidth};
          left: 0;
          position: sticky;
          border-left: none;
          border-right: none;
          box-shadow: inset 1px 0px 0px 0px ${backgroundDark};
          border-radius: 0 !important;
          padding: 1em !important;
        }

        // Name Column Header
        :nth-child(2) {
          z-index: 4
          width: 30rem;
          left: ${selectBoxWidth};
          position: sticky;
          border-left: none;
          border-right: none;
          box-shadow: ${simulatedSideBorders};
        }

        :nth-child(3) {
          border-left: none;
        }
      }
    }
  }

  td {
    border: 1px solid ${backgroundDark};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: none;
    background-color: ${backgroundDark};

    // Select Box Column
    :nth-child(1) {
      text-align: center;
      width: ${selectBoxWidth};
      left: 0;
      position: sticky;
      border-left: none;
      border-right: none;
      box-shadow: inset 1px 0px 0px 0px ${backgroundDark};
      z-index: 3;
    }

    // Name Column
    :nth-child(2) {
      left: ${selectBoxWidth};
      position: sticky;
      border-left: none;
      border-right: none;
      box-shadow: ${simulatedSideBorders};
      z-index: 3;
    }

    :nth-child(3) {
      border-left: none;
    }
  }
`;

// MetricsTable can operate on any data type that is in the shape of an
// object and has the keys "rowID" and "prev" (optional)
// ex: {
//  rowID: "1",
//  name: "Gandalf The White",
//  age: 5000,
//  slainBalrog: true,
//  prev: {
//    name: "Gandalf The Grey",
//    age: 2000,
//    slainBalrog: false
//  }
// }
export interface MetricsTableData {
  rowID: string;
  prev?: Omit<MetricsTableData, "rowID">;
  [key: string]: unknown;
}

export type MetricsTableConfig<T extends MetricsTableData> = {
  defaultColumns: Map<Column<T>, boolean>;
  rows: Array<T>;
  BodyElement: MetricTableBody<T>;
  FooterElement?: MetricTableBody<T>;
  tableOptions?: {
    storageKey: string;
    urlParam: string;
    defaultValue: Record<string, unknown>;
  };
};

export type MetricsSort<T extends MetricsTableData> = {
  column: Column<T>;
  direction: SortOption;
};

export type MetricsFilter<T extends MetricsTableData> = {
  column: Column<T>;
  args: FilterArguments;
};

export type MetricsTableCell<T extends MetricsTableData> = (props: {
  rowID: string;
  val: unknown;
  rowData: T;
  prev?: unknown;
  formatFn?: (rawVal: unknown, showFraction: boolean) => string;
  compareStyles?: CompareStyles;
  depth?: number;
  childRows?: Array<T>;
}) => React.ReactElement<HTMLTableCellElement>;

export type MetricTableBody<T extends MetricsTableData> = (props: {
  rows: Array<T>;
  columns: Array<Column<T>>;
}) => React.ReactElement<HTMLTableSectionElement>;

export const ExpandedRowContext = React.createContext<
  [Set<string>, (expandedRows: Set<string>) => void]
>([
  new Set(),
  () => {
    return;
  }
]);

// Every row must have a unique rowID
export const MetricsTable = <T extends MetricsTableData>(props: {
  tableConfig: MetricsTableConfig<T>;
  initialFilters?: Array<MetricsFilter<T>>;
  initialSort?: MetricsSort<T> | None;
  initialExpandedRows?: Set<string>;
  initialColumns?: Map<Column<T>, boolean>;
  initialShowFractions?: boolean;
  initialRespectAdAttributionDate?: boolean;
  excludeAmazonLagPeriod?: boolean;
  primaryButtonText?: string;
  onUpdateSort?: (s: MetricsSort<T>) => void;
  onSetExpandedRows?: (rows: Set<string>) => void;
  onUpdateFilters?: (filters: Array<MetricsFilter<T>>) => void;
  onUpdateColumns?: (columns: Map<Column<T>, boolean>) => void;
  onSetShowFractions?: (show: boolean) => void;
  onSetExcludeAmazonLagPeriod?: (exclude: boolean) => void;
  onSetRespectAdAttributionDate?: (respect: boolean) => void;
  onPrimaryActionClick?: () => void;
  csvGenerator?: (
    columns: Array<Column<T>>,
    exportOptions: CSVExportOptions
  ) => Promise<[string, string]>;
}): JSX.Element => {
  const [sortColumn, setSortColumn] = useState<MetricsSort<T> | None>(
    props.initialSort
  );

  const [filters, setFilters] = useState<Array<MetricsFilter<T>>>(
    props.initialFilters || []
  );

  const [expandedRows, setExpandedRows] = useState<Set<string>>(
    props.initialExpandedRows || new Set()
  );

  const [showFractions, setShowFractions] = useState(
    props.initialShowFractions ?? false
  );

  const [filterPillsOpen, setFilterPillsOpen] = useState(true);

  const [optionsManagerOpen, setOptionsManagerOpen] = useState(false);

  const [columnsManagerOpen, setColumnsManagerOpen] = useState(false);

  const [columns, setColumns] = useState<Map<Column<T>, boolean>>(
    props.initialColumns && props.initialColumns.size > 0
      ? props.initialColumns
      : props.tableConfig.defaultColumns
  );

  const onUpdateColumnsProp = props.onUpdateColumns;
  const handleUpdateColumns = useCallback(
    (columns: Map<Column<T>, boolean> | null) => {
      if (!columns) {
        columns = props.tableConfig.defaultColumns;
      }
      setColumns(columns);
      onUpdateColumnsProp && onUpdateColumnsProp(columns);
    },
    [onUpdateColumnsProp, props.tableConfig.defaultColumns]
  );

  const onSetExpandedRowsProp = props.onSetExpandedRows;
  const onSetExpandedRows = useCallback(
    (nextExpandedRows: Set<string>) => {
      onSetExpandedRowsProp && onSetExpandedRowsProp(nextExpandedRows);
      setExpandedRows(nextExpandedRows);
    },
    [onSetExpandedRowsProp]
  );

  const allRowsExpanded =
    props.tableConfig.rows.length > 0 &&
    expandedRows.size === props.tableConfig.rows.length;
  const toggleExpandAllRows = () => {
    const nextExpandedRows = allRowsExpanded
      ? new Set<string>()
      : new Set(props.tableConfig.rows.map(row => row.rowID));
    onSetExpandedRowsProp && onSetExpandedRowsProp(nextExpandedRows);
    setExpandedRows(nextExpandedRows);
  };

  const onUpdateSortProp = props.onUpdateSort;
  const onUpdateSort = useCallback(
    (sort: MetricsSort<T>) => {
      setSortColumn(sort);
      onUpdateSortProp && onUpdateSortProp(sort);
    },
    [onUpdateSortProp]
  );

  const onUpdateFiltersProp = props.onUpdateFilters;
  const onUpdateFilters = useCallback(
    (filters: Array<MetricsFilter<T>>) => {
      setFilters(filters);
      onUpdateFiltersProp && onUpdateFiltersProp(filters);
    },
    [onUpdateFiltersProp]
  );

  const onSetShowFractionProp = props.onSetShowFractions;
  const onSetShowFractions = useCallback(
    (show: boolean) => {
      setShowFractions(show);
      onSetShowFractionProp && onSetShowFractionProp(show);
    },
    [onSetShowFractionProp]
  );

  const handleShareView = () => {
    if (!props.tableConfig.tableOptions) {
      return;
    }
    const {
      storageKey,
      urlParam,
      defaultValue
    } = props.tableConfig.tableOptions;

    let storedViewOptions = localStorage.getItem(storageKey);
    if (!storedViewOptions) {
      storedViewOptions = JSON.stringify(defaultValue);
    }

    const url = new URL(window.location.href);
    url.searchParams.set(urlParam, storedViewOptions);
    navigator.clipboard.writeText(url.toString());
    toast.success("Link copied to clipboard", {
      autoClose: 2000,
      hideProgressBar: true
    });
  };

  const managerColumns = useMemo(() => {
    return Array.from(columns.keys()).sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
  }, [columns]);

  const activeColumns = useMemo(() => {
    return [...columns.entries()]
      .filter(([_, isActive]) => isActive)
      .map(([col]) => col);
  }, [columns]);

  return (
    <ExpandedRowContext.Provider value={[expandedRows, onSetExpandedRows]}>
      <ShowFractionsContext.Provider
        value={[showFractions, onSetShowFractions]}
      >
        <ColumnsManagerModal
          open={columnsManagerOpen}
          setOpen={(open: boolean) => setColumnsManagerOpen(open)}
          columns={columns}
          onUpdateColumns={handleUpdateColumns}
        />

        <div className="metrics-table-controls-area">
          {/* Primary Action */}
          {props.onPrimaryActionClick && (
            <Button
              className="metrics-table-primary-action-button"
              color="green"
              onClick={props.onPrimaryActionClick}
            >
              {props.primaryButtonText}
            </Button>
          )}

          <div className="metrics-table-controls">
            {/* Filters Manager */}
            <div>
              <SimpleTooltip tooltip="Filter Rows" position="left center">
                <div>
                  <FilterManager<T>
                    columns={managerColumns}
                    filters={filters}
                    onSetFilters={onUpdateFilters}
                  />
                </div>
              </SimpleTooltip>
            </div>

            {/* Share Button */}
            <div>
              <SimpleTooltip
                tooltip="Share Link to Table"
                position="bottom left"
              >
                <Button icon="share" onClick={handleShareView} />
              </SimpleTooltip>
            </div>

            {/* Expand All Rows button */}
            <div>
              <SimpleTooltip
                tooltip={
                  allRowsExpanded ? "Collapse All Rows" : "Expand All Rows"
                }
                position="bottom left"
              >
                <Button
                  icon={
                    <Icon
                      name="arrows alternate vertical"
                      color={allRowsExpanded ? "blue" : "black"}
                    />
                  }
                  onClick={toggleExpandAllRows}
                />
              </SimpleTooltip>
            </div>

            {/* Columns Manager */}
            <div>
              <SimpleTooltip tooltip="Manage Columns" position="bottom left">
                <Button
                  icon="th list"
                  onClick={() => setColumnsManagerOpen(true)}
                />
              </SimpleTooltip>
            </div>

            {/* Table Options Manager */}
            <div>
              <SimpleTooltip tooltip="Table Options" position="bottom left">
                <div>
                  <OptionsManagerModal
                    open={optionsManagerOpen}
                    setOpen={(open: boolean) => setOptionsManagerOpen(open)}
                    showFractions={showFractions}
                    setShowFractions={onSetShowFractions}
                    excludeAmazonLagPeriod={
                      props.excludeAmazonLagPeriod ?? false
                    }
                    setExcludeAmazonLagPeriod={
                      props.onSetExcludeAmazonLagPeriod
                    }
                    respectAdAttributionDate={
                      props.initialRespectAdAttributionDate
                    }
                    setRespectAdAttributionDate={
                      props.onSetRespectAdAttributionDate
                    }
                  />
                </div>
              </SimpleTooltip>
            </div>
            {/* CSV Download Manager */}
            <SimpleTooltip tooltip="Download CSV" position="bottom left">
              <div>
                {props.csvGenerator && (
                  <CSVExportManager<T>
                    columns={activeColumns}
                    csvGenerator={props.csvGenerator}
                  />
                )}
              </div>
            </SimpleTooltip>
          </div>
        </div>

        {/* Filter Pills */}
        {filters.length > 0 && (
          <StyledFilterManager
            className={`metrics-table-filters-area ${
              filterPillsOpen ? "expanded" : "collapsed"
            }`}
          >
            <Accordion>
              <Accordion.Title
                active={filterPillsOpen}
                onClick={() => setFilterPillsOpen(open => !open)}
              >
                <Icon name="dropdown" />
                Active Filters ({filters.length})
              </Accordion.Title>
              <Accordion.Content active={filterPillsOpen}>
                {filterPillsOpen && (
                  <FilterContainer
                    filters={filters}
                    onSetFilters={onUpdateFilters}
                  />
                )}
              </Accordion.Content>
            </Accordion>
          </StyledFilterManager>
        )}

        <div className="metrics-table-table-area">
          <BasicMetricsTable
            tableConfig={props.tableConfig}
            columns={columns}
            filters={filters}
            expandedRows={expandedRows}
            sort={sortColumn}
            onUpdateSort={onUpdateSort}
          />
        </div>
      </ShowFractionsContext.Provider>
    </ExpandedRowContext.Provider>
  );
};

const BasicMetricsTable = <T extends MetricsTableData>({
  tableConfig,
  columns,
  filters,
  sort,
  onUpdateSort
}: {
  tableConfig: MetricsTableConfig<T>;
  columns: Map<Column<T>, boolean>;
  filters: Array<MetricsFilter<T>>;
  sort: MetricsSort<T> | None;
  expandedRows: Set<string>;
  onUpdateSort: (s: MetricsSort<T>) => void;
}): JSX.Element => {
  const { rows } = tableConfig;

  const sortedAndFilteredRows = useMemo(() => {
    let filteredRows = [...rows];
    for (const filter of filters) {
      filteredRows = filteredRows.filter(row =>
        filter.column.filter(row, filter.args)
      );
    }

    if (!sort) {
      return filteredRows;
    }

    return filteredRows.sort((a, b) =>
      sort.column.compare(a, b, sort.direction)
    );
  }, [filters, rows, sort]);

  const handleSetSort = (nextSort: MetricsSort<T>) => {
    onUpdateSort(nextSort);
  };

  const activeColumns = [...columns.entries()]
    .filter(([_, isActive]) => isActive)
    .map(([col]) => col);

  return (
    <>
      <StyledTable celled unstackable>
        <Table.Header>
          <Table.Row>
            <th>
              <input type="checkbox" />
            </th>
            {activeColumns.map(column => {
              return (
                <Header
                  key={String(column.dataName)}
                  column={column}
                  onClickSort={handleSetSort}
                  sortDirection={
                    sort?.column === column ? sort.direction : undefined
                  }
                />
              );
            })}
          </Table.Row>
        </Table.Header>
        <tableConfig.BodyElement
          columns={activeColumns}
          rows={sortedAndFilteredRows}
        />
        {tableConfig.FooterElement && (
          <tableConfig.FooterElement
            columns={activeColumns}
            rows={sortedAndFilteredRows}
          />
        )}
      </StyledTable>
    </>
  );
};

const Header = <T extends MetricsTableData>({
  column,
  onClickSort,
  sortDirection
}: {
  column: Column<T>;
  onClickSort: (s: MetricsSort<T>) => void;
  sortDirection?: SortOption;
}): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  /* Start Draggable Column Header Stuff */
  const startX = useRef(0);
  const startWidth = useRef(0);
  const columnRef = useRef<HTMLTableCellElement>(null);

  function handleMouseDown(ev: React.MouseEvent) {
    startX.current = ev.pageX;
    startWidth.current = ev.currentTarget.parentElement?.offsetWidth || 0;
    columnRef.current?.classList.add("resizing");
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }

  function handleMouseMove(ev: MouseEvent) {
    const diffX = ev.pageX - startX.current;
    const nextWidth = startWidth.current + diffX;
    if (columnRef.current && nextWidth >= minCellWidthPixels) {
      columnRef.current.style.width = `${startWidth.current + diffX}px`;
    }
  }

  function handleMouseUp() {
    columnRef.current?.classList.remove("resizing");
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  }
  /* End Draggable Column Header Stuff */

  function handleClickSort() {
    let direction: SortOption;
    if (sortDirection === "asc") {
      direction = "desc";
    } else if (sortDirection === "desc") {
      direction = "asc";
    } else {
      direction = column.defaultSortDirection || "asc";
    }

    onClickSort({
      column: column,
      direction: direction
    });
  }

  return (
    <Popup
      style={{ padding: 10 }}
      animation="none"
      trigger={
        <th
          ref={columnRef}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        >
          <div>
            <div>
              <h3 onClick={handleClickSort}>{column.displayName}</h3>
              <SortingIcon
                sortDirection={sortDirection}
                onClick={handleClickSort}
              />
            </div>
            <div className="resize-handle" onMouseDown={handleMouseDown} />
          </div>
        </th>
      }
      position={"bottom center"}
      open={tooltipOpen}
      disabled={!column.tooltip}
    >
      <strong>
        <small>{column.tooltip}</small>
      </strong>
    </Popup>
  );
};

function SortingIcon({
  sortDirection,
  onClick
}: {
  sortDirection?: SortOption;
  onClick: () => void;
}): JSX.Element {
  return (
    <Icon
      className="sort-icon"
      style={{
        alignSelf: "center",
        visibility: sortDirection ? "visible" : "hidden"
      }}
      name={
        sortDirection == null
          ? "sort"
          : sortDirection === "asc"
          ? "arrow up"
          : "arrow down"
      }
      size="small"
      onClick={onClick}
    />
  );
}
